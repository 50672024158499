import { notification } from 'antd';
import { action, observable, runInAction, toJS } from 'mobx';
import agent from '../../api/agent';
import { IPriceListMain } from '../models/servicesCorrelations/PriceList';
import { IServiceCorrelation } from '../models/servicesCorrelations/ServiceCorrelation';
import { IServicePrice } from '../models/servicesCorrelations/ServicePrice';
import { RootStore } from './rootStore';

export default class ServicesCorrelationsStore {
	rootStore: RootStore;

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
	}

	@observable serviceCorrelationsTableEditMode: boolean = false;
	@observable servicePricesTableEditMode: boolean = false;

	@observable servicesCorrelations: IServiceCorrelation[] | undefined;
	@observable servicesCorrelationsForMdd: IServiceCorrelation[] | undefined;
	@observable servicesCorrelationsTemporaryData:
		| IServiceCorrelation[]
		| undefined;

	@observable priceListForCurrentSelectedServiceCorrelation:
		| IServicePrice[]
		| undefined;

	@observable generatedPriceList: IPriceListMain | undefined;

	@observable currentSelectedServiceCorrelationId: number | undefined;
	@observable currentSelectedServiceCorrelationCode: string | undefined;

	@action setServiceCorrelationTableEditMode = (mode: boolean) => {
		this.serviceCorrelationsTableEditMode = mode;
	};

	@action setServicePricesTableEditMode = (mode: boolean) => {
		this.servicePricesTableEditMode = mode;
	};

	@action setCurrentSelectedServiceCorrelationId = (
		id: number | undefined
	) => {
		this.currentSelectedServiceCorrelationId! = id;
	};

	@action setCurrentSelectedServiceCorrelationCode = (
		code: string | undefined
	) => {
		this.currentSelectedServiceCorrelationCode! = code;
	};

	@action getServicesCorrelationsForMdd = async () => {
		try {
			this.rootStore.loadersStore.loadingAllTable = true;
			let res = await agent.ServicesCorrelations.GetAllForMdd();
			runInAction(() => {
				this.servicesCorrelationsForMdd = res;
				if (
					this.servicesCorrelationsForMdd !== undefined &&
					this.servicesCorrelationsForMdd.length > 0
				) {
					for (
						let i = 0;
						i < this.servicesCorrelationsForMdd.length;
						i++
					) {
						this.servicesCorrelationsForMdd[i].key = (
							i + 1
						).toString();
					}
				} else {
					this.servicesCorrelations.push({
						key: '1',
						id: undefined,
						baseServiceName: '',
						baseServiceSystemCode: '',
						nhifCode: '',
						code: '',
						additionalInformation: '',
						servicePrices: [],
					});
				}

				this.rootStore.loadersStore.loadingAllTable = false;
			});
		} catch (error) {
			runInAction(() => {
				notification.error({
					message: error.data,
					className: 'success-messages',
					duration: 5,
				});
				this.rootStore.loadersStore.loadingAllTable = false;
			});
		}
	};

	@action getServicesCorrelations = async () => {
		try {
			this.rootStore.loadersStore.loadingAllTable = true;
			let res = await agent.ServicesCorrelations.GetAll();
			runInAction(() => {
				this.servicesCorrelations = res;
				if (
					this.servicesCorrelations !== undefined &&
					this.servicesCorrelations.length > 0
				) {
					for (let i = 0; i < this.servicesCorrelations.length; i++) {
						this.servicesCorrelations[i].key = (i + 1).toString();
					}
				}

				this.rootStore.loadersStore.loadingAllTable = false;
			});
		} catch (error) {
			runInAction(() => {
				notification.error({
					message: error.data,
					className: 'success-messages',
					duration: 5,
				});
				this.rootStore.loadersStore.loadingAllTable = false;
			});
		}
	};

	@action setServicesCorrelations = (
		servicesCorrelations: IServiceCorrelation[] | undefined
	) => {
		this.servicesCorrelations = servicesCorrelations;
	};

	@action setServicePrices = (servicePrices: IServicePrice[]) => {
		this.priceListForCurrentSelectedServiceCorrelation = servicePrices;
	};

	@action setPriceListForCurrentSelectedServiceCorrelation = (
		priceList: IServicePrice[] | undefined
	) => {
		this.priceListForCurrentSelectedServiceCorrelation = priceList;
		if (priceList !== undefined) {
			for (
				let i = 0;
				i < this.priceListForCurrentSelectedServiceCorrelation.length;
				i++
			) {
				this.priceListForCurrentSelectedServiceCorrelation[i].key = (
					i + 1
				).toString();
			}
		}
	};

	@action createServiceCorrelation = async (
		serviceCorrelation: IServiceCorrelation
	) => {
		this.rootStore.loadersStore.setLoadingAllTables(true);
		try {
			let serviceCorrelationId = await agent.ServicesCorrelations.CreateServiceCorrelationWithPrices(
				serviceCorrelation
			);
			runInAction(() => {
				let index = this.servicesCorrelations.findIndex(
					(el) => el.key === serviceCorrelation.key
				);
				this.servicesCorrelations[index] = {
					id: serviceCorrelationId,
					key: serviceCorrelation.key,
					additionalInformation:
						serviceCorrelation.additionalInformation,
					baseServiceName: serviceCorrelation.baseServiceName,
					baseServiceSystemCode:
						serviceCorrelation.baseServiceSystemCode,
					code: serviceCorrelation.code,
					nhifCode: serviceCorrelation.nhifCode,
					servicePrices: [],
				};
				let newOrgData = toJS(this.servicesCorrelations);
				this.setServicesCorrelations(newOrgData);

				this.rootStore.loadersStore.setLoadingAllTables(false);
			});
		} catch (error) {
			runInAction(() => {
				this.getServicesCorrelations();
				notification.error({
					message: error.data,
					className: 'success-messages',
					duration: 5,
				});
				this.rootStore.loadersStore.setLoadingAllTables(false);
			});
		}
	};

	@action updateServiceCorrelation = async (
		serviceCorrelation: IServiceCorrelation
	) => {
		this.rootStore.loadersStore.setLoadingAllTables(true);
		try {
			await agent.ServicesCorrelations.UpdateServiceCorrelation(
				serviceCorrelation
			);
			runInAction(() => {
				let index = this.servicesCorrelations.findIndex(
					(el) => el.id === serviceCorrelation.id
				);
				this.servicesCorrelations[index] = serviceCorrelation;

				let newOrgData = toJS(this.servicesCorrelations);
				this.setServicesCorrelations(newOrgData);
				this.rootStore.loadersStore.setLoadingAllTables(false);
			});
		} catch (error) {
			runInAction(() => {
				this.getServicesCorrelations();
				notification.error({
					message: error.data,
					className: 'success-messages',
					duration: 5,
				});
				this.rootStore.loadersStore.setLoadingAllTables(false);
			});
		}
	};

	@action deleteServiceCorrelation = async (id: number) => {
		this.rootStore.loadersStore.setLoadingAllTables(true);
		try {
			await agent.ServicesCorrelations.DeleteServiceCorrelation(id);
			runInAction(() => {
				var removeIndex = this.servicesCorrelations
					.map(function (item) {
						return item.id;
					})
					.indexOf(id);
				this.servicesCorrelations.splice(removeIndex, 1);
				this.setServicesCorrelations(toJS(this.servicesCorrelations));

				for (let i = 0; i < this.servicesCorrelations.length; i++) {
					this.servicesCorrelations[i].key = (i + 1).toString();
				}
				this.rootStore.loadersStore.setLoadingAllTables(false);
			});
		} catch (error) {
			this.getServicesCorrelations();
			runInAction(() => {
				notification.error({
					message: error.data,
					className: 'success-messages',
					duration: 5,
				});
				this.rootStore.loadersStore.setLoadingAllTables(false);
			});
		}
	};

	@action createServicePrice = async (servicePrice: IServicePrice) => {
		this.rootStore.loadersStore.setLoadingAllTables(true);
		try {
			let servicePriceId = await agent.ServicesCorrelations.CreatePrice(
				servicePrice
			);
			runInAction(() => {
				let index = this.priceListForCurrentSelectedServiceCorrelation.findIndex(
					(el) => el.key === servicePrice.key
				);
				this.priceListForCurrentSelectedServiceCorrelation[index] = {
					id: servicePriceId,
					key: servicePrice.key,
					code:
						servicePrice.code !== undefined &&
						servicePrice.code.length > 0
							? servicePrice.code
							: this.servicesCorrelations[
									this.servicesCorrelations.findIndex(
										(el) =>
											el.id ===
											this
												.currentSelectedServiceCorrelationId
									)
							  ].code,
					insuranceCompanyId: servicePrice.insuranceCompanyId,
					insuranceCompanyName: servicePrice.insuranceCompanyName,
					price: servicePrice.price,
					prophylacticPrice: servicePrice.prophylacticPrice,
					specialityCode: servicePrice.specialityCode,
					specialityName: servicePrice.specialityName,
					toServiceCorrelationId: servicePrice.toServiceCorrelationId,
					insuranceCompanyCode: servicePrice.insuranceCompanyCode,
				};
				let newOrgData = toJS(
					this.priceListForCurrentSelectedServiceCorrelation
				);
				this.setPriceListForCurrentSelectedServiceCorrelation(
					newOrgData
				);

				this.servicesCorrelations[
					this.servicesCorrelations.findIndex(
						(el) =>
							el.id === this.currentSelectedServiceCorrelationId
					)
				].servicePrices.push({
					id: servicePriceId,
					key: servicePrice.key,
					code: servicePrice.code,
					insuranceCompanyId: servicePrice.insuranceCompanyId,
					insuranceCompanyName: servicePrice.insuranceCompanyName,
					price: servicePrice.price,
					prophylacticPrice: servicePrice.prophylacticPrice,
					specialityCode: servicePrice.specialityCode,
					specialityName: servicePrice.specialityName,
					toServiceCorrelationId: servicePrice.toServiceCorrelationId,
					insuranceCompanyCode: servicePrice.insuranceCompanyCode,
				});

				this.rootStore.loadersStore.setLoadingAllTables(false);
			});
		} catch (error) {
			runInAction(() => {
				this.getServicesCorrelations();
				let currentServiceCorrelationPriceList = this.servicesCorrelations.find(
					(el) => el.id === servicePrice.toServiceCorrelationId
				)?.servicePrices;
				this.setPriceListForCurrentSelectedServiceCorrelation(
					currentServiceCorrelationPriceList
				);

				notification.error({
					message: error.data,
					className: 'success-messages',
					duration: 5,
				});
				this.rootStore.loadersStore.setLoadingAllTables(false);
			});
		}
	};

	@action updateServicePrice = async (servicePrice: IServicePrice) => {
		this.rootStore.loadersStore.setLoadingAllTables(true);
		try {
			await agent.ServicesCorrelations.UpdatePrice(servicePrice);
			runInAction(() => {
				let index = this.priceListForCurrentSelectedServiceCorrelation.findIndex(
					(el) => el.id === servicePrice.id
				);
				this.priceListForCurrentSelectedServiceCorrelation[
					index
				] = servicePrice;

				let newOrgData = toJS(
					this.priceListForCurrentSelectedServiceCorrelation
				);
				this.setPriceListForCurrentSelectedServiceCorrelation(
					newOrgData
				);

				let indexForPrice = this.servicesCorrelations[
					this.servicesCorrelations.findIndex(
						(el) =>
							el.id === this.currentSelectedServiceCorrelationId
					)
				].servicePrices.findIndex((o) => o.id === servicePrice.id);
				this.servicesCorrelations[
					this.servicesCorrelations.findIndex(
						(el) =>
							el.id === this.currentSelectedServiceCorrelationId
					)
				].servicePrices[indexForPrice] = servicePrice;

				this.rootStore.loadersStore.setLoadingAllTables(false);
			});
		} catch (error) {
			runInAction(() => {
				this.getServicesCorrelations();
				let currentServiceCorrelationPriceList = this.servicesCorrelations.find(
					(el) => el.id === servicePrice.toServiceCorrelationId
				)?.servicePrices;
				this.setPriceListForCurrentSelectedServiceCorrelation(
					currentServiceCorrelationPriceList
				);
				notification.error({
					message: error.data,
					className: 'success-messages',
					duration: 5,
				});
				this.rootStore.loadersStore.setLoadingAllTables(false);
			});
		}
	};

	@action deleteServicePrice = async (id: number) => {
		this.rootStore.loadersStore.setLoadingAllTables(true);
		try {
			await agent.ServicesCorrelations.DeletePrice(id);
			runInAction(() => {
				var removeIndex = this.priceListForCurrentSelectedServiceCorrelation
					.map(function (item) {
						return item.id;
					})
					.indexOf(id);
				this.priceListForCurrentSelectedServiceCorrelation.splice(
					removeIndex,
					1
				);
				for (
					let i = 0;
					i <
					this.priceListForCurrentSelectedServiceCorrelation.length;
					i++
				) {
					this.priceListForCurrentSelectedServiceCorrelation[
						i
					].key = (i + 1).toString();
				}

				this.setPriceListForCurrentSelectedServiceCorrelation(
					toJS(this.priceListForCurrentSelectedServiceCorrelation)
				);
				this.setServicePrices(
					toJS(this.priceListForCurrentSelectedServiceCorrelation)
				);

				let currentSelectedCorrelation = this.servicesCorrelations.find(
					(el) => el.id === this.currentSelectedServiceCorrelationId
				);
				let priceIndexToDelete = currentSelectedCorrelation.servicePrices.findIndex(
					(el) => el.id === id
				);
				currentSelectedCorrelation.servicePrices.splice(
					priceIndexToDelete,
					1
				);

				this.setServicesCorrelations(toJS(this.servicesCorrelations));

				this.rootStore.loadersStore.setLoadingAllTables(false);
			});
		} catch (error) {
			runInAction(() => {
				this.getServicesCorrelations();
				this.rootStore.loadersStore.setLoadingAllTables(false);
				notification.error({
					message: error.data,
					className: 'success-messages',
					duration: 5,
				});
			});
		}
	};

	@action generatePriceList = async (insuranceCompanyId: number) => {
		this.rootStore.loadersStore.setLoadingAllTables(true);
		try {
			let res = await agent.ServicesCorrelations.generatePriceList(
				insuranceCompanyId
			);
			runInAction(() => {
				this.generatedPriceList = res;
				this.rootStore.loadersStore.setLoadingAllTables(false);
			});
		} catch (error) {
			runInAction(() => {
				notification.error({
					message: error.data,
					className: 'success-messages',
					duration: 5,
				});
				this.rootStore.loadersStore.setLoadingAllTables(false);
			});
		}
	};

	@action removeServiceCorrelations = async () => {
		this.servicesCorrelations = undefined;
		this.setServiceCorrelationTableEditMode(false);
		this.setServicePricesTableEditMode(false);
	};
}
