import { Form, Input, Select } from 'antd';
import { Picker, List } from 'antd-mobile';
import { FormInstance } from 'antd/lib/form';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { RootStoreContext } from '../../stores/rootStore';
import Constants from '../../constants/constants';
import { v4 as uuidv4 } from 'uuid';

export interface IProps {
	form: FormInstance;
}

const PerformerFormItem: React.FC<IProps> = ({ form }) => {
	const rootStore = useContext(RootStoreContext);
	const {
		getDoctorByUin,
		doctorByUin,
		specialities,
		getSpecialities,
	} = rootStore.operationsStore;

	const { electronicDocumentById } = rootStore.electronicDocumentStore;
	const { currentUser } = rootStore.identityStore;

	const [isMobile, setIsMobile] = useState(false);

	const searchForDoctor = async (issuerUin: string) => {
		if (
			!currentUser.uinCode &&
			(issuerUin.length === 10 || issuerUin.length === 9)
		) {
			await getDoctorByUin(issuerUin);
		} else {
			getDoctorByUin(undefined);
			form.setFieldsValue({
				issuerName: '',
				issuerSpecialityCode: '',
			});
		}
	};

	const handleWindowResize = () => {
		setIsMobile(window.innerWidth < 770);
	};

	useEffect(() => {
		handleWindowResize();
		window.addEventListener('resize', handleWindowResize);

		form.setFieldsValue({
			performerName: doctorByUin ? doctorByUin!.fullName : '',
			performerSpecialityCode: doctorByUin
				? doctorByUin!.specialityCode
				: '',
		});

		if (!specialities) {
			getSpecialities();
		}
	}, [doctorByUin, form, getSpecialities, specialities]);

	const { Option } = Select;

	const dataSpecialties = specialities?.map((p) => ({
		value: p.code,
		label: p.name,
		children: [],
	}));

	return (
		<Form.Item
			label='Данни за лекаря, желаещ да запази назначението: '
			style={{ marginBottom: 0 }}
			rules={[
				{
					required: true,
				},
			]}>
			<Form.Item
				name='performerUin'
				className='uin-input'
				rules={[
					{
						required: true,
						message: Constants.FormMessages.RequiredField,
					},
					({ getFieldValue }) => ({
						validator(rule, value) {
							const expression = new RegExp('^[0-9]*$');
							if (expression.test(value)) {
								return Promise.resolve();
							}
							return Promise.reject(
								'УИН-ът трябва да съдържа само цифри!'
							);
						},
					}),
					({ isFieldsTouched }) => ({
						validator(rule, value) {
							if (isFieldsTouched() === false) {
								return Promise.reject();
							}
							return Promise.resolve();
						},
					}),
					{
						min: 9,
						message: ' УИН-ът трябва да е  9 или 10 цифри! ',
					},
					{
						max: 10,
						message: ' УИН-ът трябва да е  9 или 10 цифри! ',
					},
				]}>
				<Input
					placeholder='УИН на лекаря'
					onInput={(e: any) => {
						if (e.target.value.length === 10) {
							searchForDoctor(e.target.value);
						} else {
							searchForDoctor('');
						}
					}}
					onBlur={(e: any) => {
						if (e.target.value.length === 9) {
							searchForDoctor(e.target.value);
						} else if (e.target.value.length === 0) {
							searchForDoctor('');
						}
					}}
					disabled={
						electronicDocumentById?.status ===
							Constants.MedicalReferralStatus.Performed ||
						(!electronicDocumentById &&
							currentUser!.uinCode.length > 0)
					}
				/>
			</Form.Item>
			<Form.Item
				name='performerName'
				className='docName-input'
				rules={[
					{
						required: true,
						message:
							' Полето е задължително! Ако не се е попълнило автоматично, дадения лекар не е все още въведен в сиситемата! Попълнете го лично. ',
					},
					({ isFieldsTouched }) => ({
						validator(rule, value) {
							if (isFieldsTouched() === false) {
								return Promise.reject();
							}
							return Promise.resolve();
						},
					}),
				]}>
				<Input
					placeholder='Име на лекаря'
					disabled={
						electronicDocumentById?.status ===
							Constants.MedicalReferralStatus.Performed ||
						(!electronicDocumentById &&
							currentUser!.uinCode.length > 0)
					}
				/>
			</Form.Item>
			<Form.Item
				name='performerSpecialityCode'
				className='spec-input'
				rules={[
					{
						required: true,
						message: ' Полето е задължително! ',
					},
					({ isFieldsTouched }) => ({
						validator(rule, value) {
							if (isFieldsTouched() === false) {
								return Promise.reject();
							}
							return Promise.resolve();
						},
					}),
				]}>
				{!isMobile ? (
					<Select
						showSearch
						placeholder='Специалност'
						optionFilterProp='title'
						disabled={
							electronicDocumentById?.status ===
							Constants.MedicalReferralStatus.Performed
						}>
						{specialities?.map((speciality) => (
							<Option
								key={uuidv4()}
								value={
									speciality.code
										? speciality.code
										: doctorByUin?.specialityCode!
								}
								title={speciality.code + '-' + speciality.name}>
								{speciality.code + '-' + speciality.name}
							</Option>
						))}
					</Select>
				) : (
					<Picker
						data={dataSpecialties!}
						cols={1}
						extra={
							electronicDocumentById
								? electronicDocumentById.issuerSpecialityName
								: doctorByUin
								? doctorByUin.specialityName
								: 'Избери'
						}
						okText='Избери'
						dismissText=' Обратно'
						disabled={
							electronicDocumentById?.status ===
							Constants.MedicalReferralStatus.Performed
						}>
						<List.Item key={uuidv4()} arrow='horizontal' extra=''>
							{' '}
						</List.Item>
					</Picker>
				)}
			</Form.Item>
		</Form.Item>
	);
};

export default observer(PerformerFormItem);
