import { Form, Select } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { toJS } from 'mobx';
import React, { useContext, useState } from 'react';
import { IAttachment } from '../../../../models/electronicDocument/ElectronicDocument';
import { RootStoreContext } from '../../../../stores/rootStore';
import Enums from '../,,/../../../../constants/enums';
import { observer } from 'mobx-react-lite';
import Constants from '../../../../constants/constants';
import { v4 as uuidv4 } from 'uuid';

interface IProps {
	formTmp: FormInstance;
	dataIndex: string;
	record: IAttachment;
}

const AttachmentTypeItem: React.FC<IProps> = ({
	formTmp,
	dataIndex,
	record,
}) => {
	const rootStore = useContext(RootStoreContext);

	const { attachmentType } = rootStore.operationsStore;
	const {
		attachmentsOriginData,
		setAttachmentsOriginData,
		electronicDocumentById,
		setIsElectronicDocumentAttaching,
		isElectronicDocumentAttaching,
		setIsUploadFormTouched,
	} = rootStore.electronicDocumentStore;

	const [value, setValue] = useState(
		rootStore.electronicDocumentStore.typeOfDocument ===
			Constants.DocumentType.MedicalReferral ||
			rootStore.electronicDocumentStore.typeOfDocument ===
				Constants.DocumentType.AmbSheet ||
			rootStore.electronicDocumentStore.typeOfDocument ===
				Constants.DocumentType.LabResults ||
			rootStore.electronicDocumentStore.typeOfDocument ===
				Constants.DocumentType.Preventions
			? Enums.AttachmentTypeEnum.PhysicalCopy
			: isElectronicDocumentAttaching
			? Enums.AttachmentTypeEnum.ElectronicDocument
			: Enums.AttachmentTypeEnum.PhysicalCopy
	);

	const onChangeAttachmentType = (value: any) => {
		setIsUploadFormTouched(true);
		setValue(value);
		let currentValueDescription = attachmentType!.find(
			(el) => el.value === value
		)?.description;
		setIsElectronicDocumentAttaching(
			value === Enums.AttachmentTypeEnum.PhysicalCopy ? false : true
		);

		formTmp.resetFields();
		formTmp.setFields([
			{ name: 'attachmentType', value: currentValueDescription },
		]);
		let newData = toJS(attachmentsOriginData);
		let currentElementIndex = newData!.findIndex(
			(el) => el.key === record.key
		);
		newData![currentElementIndex].attachmentType = currentValueDescription;
		setAttachmentsOriginData(newData);
	};
	const { Option } = Select;

	formTmp.setFields([
		{
			name: 'attachmentType',
			value:
				value === 0
					? Constants.AttachmentType.ElectronicDocument
					: Constants.AttachmentType.PhysicalCopy,
		},
	]);

	return (
		<Form.Item style={{ minWidth: 100 }} name={dataIndex}>
			<Select
				placeholder='Избери тип на документ'
				defaultValue={value}
				onSelect={(value) => onChangeAttachmentType(value)}
				disabled={
					(electronicDocumentById !== undefined && record.id !== 0) ||
					(electronicDocumentById === undefined &&
						rootStore.electronicDocumentStore.typeOfDocument ===
							Constants.DocumentType.MedicalReferral) ||
					rootStore.electronicDocumentStore.typeOfDocument ===
						Constants.DocumentType.MedicalReferral ||
					(electronicDocumentById === undefined &&
						rootStore.electronicDocumentStore.typeOfDocument ===
							Constants.DocumentType.AmbSheet) ||
					rootStore.electronicDocumentStore.typeOfDocument ===
						Constants.DocumentType.AmbSheet ||
					(electronicDocumentById === undefined &&
						rootStore.electronicDocumentStore.typeOfDocument ===
							Constants.DocumentType.LabResults) ||
					rootStore.electronicDocumentStore.typeOfDocument ===
						Constants.DocumentType.LabResults ||
					(electronicDocumentById === undefined &&
						rootStore.electronicDocumentStore.typeOfDocument ===
							Constants.DocumentType.Preventions) ||
					rootStore.electronicDocumentStore.typeOfDocument ===
						Constants.DocumentType.Preventions
				}>
				{attachmentType?.map((attachType) => (
					<Option key={uuidv4()} value={attachType.value}>
						{attachType.description}
					</Option>
				))}
			</Select>
		</Form.Item>
	);
};

export default observer(AttachmentTypeItem);
