import { Button, Modal, Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useContext, useRef, useState } from 'react';
import { FileSearchOutlined, PrinterOutlined } from '@ant-design/icons';
import { useReactToPrint } from 'react-to-print';
import { IDownloadAttachment } from '../models/electronicDocument/DownloadAttachment';
import { RootStoreContext } from '../stores/rootStore';
import Moment from 'react-moment';
import PrintSubElDocument from '../../features/print/PrintSubElDocument';
import { v4 as uuidv4 } from 'uuid';
import Constants from '../constants/constants';
import constants from '../constants/constants';

const ElectronicDocumentPreview: React.FC = () => {
	const rootStore = useContext(RootStoreContext);

	const {
		electronicSubDocumentById,
		downloadAttachment,
		getElectronicSubDocumentById,
	} = rootStore.electronicDocumentStore;

	const { loadingModal } = rootStore.loadersStore;
	const [show, setShow] = useState(false);

	const preview = async (id: number) => {
		if (id) {
			await getElectronicSubDocumentById(id);
		}

		setShow(true);
	};

	const download = async (
		patientUid: string,
		fileName: string,
		documentType: string
	) => {
		let model: IDownloadAttachment = {
			documentType: documentType,
			patientUid: patientUid,
			fileName: fileName,
		};

		await downloadAttachment(model);

		if (rootStore.electronicDocumentStore.attachmentDownloadResult) {
			const url = window.URL.createObjectURL(
				new Blob([
					rootStore.electronicDocumentStore.attachmentDownloadResult!,
				])
			);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `${model.documentType}.pdf`);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	};

	const componentRef = useRef(null);
	const handlePrint = useReactToPrint({
		content: () => componentRef.current!,
	});

	return (
		<Spin spinning={loadingModal}>
			<div>
				<h3 style={{ textAlign: 'center' }}>
					{electronicSubDocumentById?.documentType} №
					{electronicSubDocumentById?.documentNumber} от{' '}
					<Moment format={Constants.Common.ShortDate_Format}>
						{electronicSubDocumentById?.usedOn}
					</Moment>
					г.
				</h3>

				<span
					style={{
						display:
							electronicSubDocumentById?.documentType.toString() ===
							constants.DocumentType.PriorApproval
								? 'inherit'
								: 'none',
					}}>
					<div className='view-referral-div'>
						Статус: {electronicSubDocumentById?.documentStatus}
					</div>
					<div className='view-referral-div'>
						На дата:{' '}
						<Moment format={Constants.Common.ShortDate_Format}>
							{electronicSubDocumentById?.lastModifiedOn}
						</Moment>
						г.
					</div>
					<br />
				</span>

				<span
					style={{
						display:
							electronicSubDocumentById?.documentType.toString() ===
								constants.DocumentType.PriorApproval &&
							electronicSubDocumentById.insuranceCompanyNotes
								? 'inherit'
								: 'none',
					}}>
					<div className='view-referral-div'>
						Резолюция:{' '}
						{electronicSubDocumentById?.insuranceCompanyNotes}
					</div>
					<br />
				</span>

				<span
					style={{
						display:
							electronicSubDocumentById?.documentType.toString() ===
							constants.DocumentType.PriorApproval
								? 'inherit'
								: 'none',
					}}>
					<h3>Данни за лечебното заведение:</h3>
					<div className='view-referral-div'>
						Л.З.:{' '}
						{electronicSubDocumentById?.medicalFacilityIssuerName}
					</div>
					<div className='view-referral-div'>
						РЗИ:{' '}
						{
							electronicSubDocumentById?.medicalFacilityIssuerRziCode
						}
					</div>
					<div className='view-referral-div'>
						Населено място:{' '}
						{electronicSubDocumentById?.medicalFacilityIssuerCity}{' '}
					</div>

					<br />
				</span>
				<span
					style={{
						display: electronicSubDocumentById?.patientUid
							? 'inherit'
							: 'none',
					}}>
					<h3> Данни за пациента: </h3>
					<div className='view-referral-div'>
						ЕГН: {electronicSubDocumentById?.patientUid}
					</div>
					<div className='view-referral-div'>
						Име: {electronicSubDocumentById?.patientName}
					</div>
					<div className='view-referral-div'>
						Имейл: {electronicSubDocumentById?.pacientEmail}
					</div>
					<br />
				</span>

				<span
					style={{
						display: electronicSubDocumentById?.issuerUin
							? 'inherit'
							: 'none',
					}}>
					<h3>Данни за назначилия лекар:</h3>
					<div className='view-referral-div'>
						РЗИ/ЛЗ:{' '}
						{
							electronicSubDocumentById?.medicalFacilityIssuerRziCode
						}
						/{electronicSubDocumentById?.medicalFacilityIssuerName}/
						{electronicSubDocumentById?.medicalFacilityIssuerCity}{' '}
					</div>
					<div className='view-referral-div'>
						УИН: {electronicSubDocumentById?.issuerUin}
					</div>
					<div className='view-referral-div'>
						Име: {electronicSubDocumentById?.issuerName}
					</div>
					<div className='view-referral-div'>
						Специалност:{' '}
						{electronicSubDocumentById?.issuerSpecialityName}
					</div>
					<br />
				</span>

				<span
					style={{
						display: electronicSubDocumentById?.performerName
							? 'inherit'
							: 'none',
					}}>
					<h3>Данни за лекаря, запазил назначението:</h3>
					<div className='view-referral-div'>
						УИН: {electronicSubDocumentById?.performerUin}
					</div>
					<div className='view-referral-div'>
						Име: {electronicSubDocumentById?.performerName}
					</div>
					<div className='view-referral-div'>
						Специалност:{' '}
						{electronicSubDocumentById?.performerSpecialityName}
					</div>
					<br />
				</span>

				<span
					style={{
						display: electronicSubDocumentById?.insuranceCompanyName
							? 'inherit'
							: 'none',
					}}>
					<h3> Застрахователна компания: </h3>
					<div className='view-referral-div'>
						{electronicSubDocumentById?.insuranceCompanyName ?? ''}
					</div>
					<br />
				</span>

				<span
					style={{
						display: electronicSubDocumentById?.mkbCode
							? 'inherit'
							: 'none',
					}}>
					<h3> Данни за диагнозата: </h3>
					<div className='view-referral-div'>
						{electronicSubDocumentById?.mkbCode} -{' '}
						{electronicSubDocumentById?.mkbName}
					</div>
					<br />
				</span>

				<span
					style={{
						display: electronicSubDocumentById?.toSpecialistSpecialityName
							? 'inherit'
							: 'none',
					}}>
					<h3> Специалност, към която се отнася: </h3>
					<div className='view-referral-div'>
						{electronicSubDocumentById?.toSpecialistSpecialityName}
					</div>
					<br />
				</span>

				<h3> Назначени дейности: </h3>
				<table
					style={{
						borderCollapse: 'collapse',
						textAlign: 'center',
						width: '100%',
						color: 'black',
						fontSize: 12,
						fontWeight: 600,
					}}>
					<thead>
						<tr style={{ border: '1px solid ' }}>
							<th style={{ border: '1px solid ', width: '10%' }}>
								Код по МКБ 9КМ / НЗОК
							</th>
							<th style={{ border: '1px solid ', width: '40%' }}>
								Дейност
							</th>
							<th style={{ border: '1px solid ', width: '20%' }}>
								Брой
							</th>
							<th style={{ border: '1px solid ', width: '30%' }}>
								Допълнителна информация
							</th>
						</tr>
					</thead>
					<tbody style={{ border: '1px solid ' }}>
						{electronicSubDocumentById?.details.map((detail) => (
							<tr key={uuidv4()}>
								<td
									style={{
										border: '1px solid ',
										textAlign: 'center',
									}}>
									{detail.nhifCode}
								</td>
								<td
									style={{
										border: '1px solid ',
										textAlign: 'left',
									}}>
									{detail.name}
								</td>
								<td style={{ border: '1px solid ' }}>
									{detail.count}
								</td>
								<td style={{ border: '1px solid ' }}>
									{detail.description}
								</td>
							</tr>
						))}
					</tbody>
				</table>

				<br />

				<span
					style={{
						display:
							electronicSubDocumentById?.attachments.length === 0
								? 'none'
								: 'inherit',
					}}>
					<h3> Други документи: </h3>

					<table
						style={{
							borderCollapse: 'collapse',
							textAlign: 'center',
							width: '100%',
							color: 'black',
							fontSize: 12,
						}}>
						<thead>
							<tr style={{ border: '1px solid ' }}>
								<th
									style={{
										border: '1px solid ',
										width: '5%',
									}}>
									{' '}
									Тип на документ{' '}
								</th>
								<th
									style={{
										border: '1px solid ',
										width: '30%',
									}}>
									{' '}
									Съдържание тип{' '}
								</th>
								<th
									style={{
										border: '1px solid ',
										width: '10%',
									}}>
									{' '}
									Номер на документ{' '}
								</th>
								<th
									style={{
										border: '1px solid ',
										width: '15%',
									}}>
									{' '}
									Дата на документ{' '}
								</th>
								<th
									style={{
										border: '1px solid ',
										width: '30%',
									}}>
									Допълнителна информация / Лекар
								</th>
								<th
									style={{
										border: '1px solid ',
										width: '10%',
									}}>
									Запазване
								</th>
							</tr>
						</thead>
						<tbody style={{ border: '1px solid ' }}>
							{electronicSubDocumentById?.attachments.map(
								(attachment) => (
									<tr key={uuidv4()}>
										<td
											style={{
												border: '1px solid ',
												textAlign: 'left',
											}}>
											{attachment.attachmentType}
										</td>
										<td
											style={{
												border: '1px solid ',
												textAlign: 'left',
											}}>
											{attachment.documentType}
										</td>
										<td
											style={{
												border: '1px solid ',
												textAlign: 'center',
											}}>
											{attachment.eDocumentNumber}
										</td>
										<td
											style={{
												border: '1px solid ',
												textAlign: 'center',
											}}>
											{attachment.eDocumentDate !==
											null ? (
												<Moment
													format={
														Constants.Common
															.ShortDate_Format
													}>
													{attachment.eDocumentDate}
												</Moment>
											) : (
												''
											)}
										</td>
										<td
											style={{
												border: '1px solid ',
												textAlign: 'left',
											}}>
											{attachment.description
												? attachment.description
												: attachment.eDocumentDoctorName}
										</td>
										<td
											style={{
												border: '1px solid ',
												textAlign: 'center',
											}}>
											<Button
												type='link'
												onClick={() =>
													attachment.attachmentType ===
													Constants.AttachmentType
														.PhysicalCopy
														? download(
																electronicSubDocumentById!
																	.patientUid,
																attachment.fileName,
																attachment.documentType
														  )
														: preview(
																attachment!
																	.toAttachmentId
														  )
												}>
												<FileSearchOutlined
													style={{
														color: '#e48800',
														fontSize: 18,
														fontWeight: 700,
													}}
												/>
											</Button>
										</td>
									</tr>
								)
							)}
						</tbody>
					</table>
					<br />
				</span>

				<span
					style={{
						display: electronicSubDocumentById?.medicalNotes
							? 'inherit'
							: 'none',
					}}>
					<h3> Пояснения: </h3>

					<div style={{ border: '1px solid', paddingLeft: 10 }}>
						{electronicSubDocumentById?.medicalNotes}
					</div>
					<br />
				</span>

				<div style={{ display: 'none' }}>
					<PrintSubElDocument ref={componentRef} />
				</div>
				<Button
					type='primary'
					className='btn-groupe'
					shape='round'
					style={{
						margin: 0,
						backgroundColor: '#234254',
						borderRadius: 20,
					}}
					onClick={handlePrint}>
					<PrinterOutlined /> Принтирай
				</Button>
			</div>
			<Modal
				className='appointment-modal'
				title={'Преглед на електронен документ'}
				centered
				maskClosable={false}
				transitionName='none'
				maskTransitionName='none'
				onCancel={() => setShow(false)}
				visible={show}
				footer={false}
				destroyOnClose={true}>
				{' '}
				<ElectronicDocumentPreview />
			</Modal>
		</Spin>
	);
};

export default observer(ElectronicDocumentPreview);
