import React, { useState, useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Table, Input, Button, Modal, notification, Space } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import { TablePaginationConfig } from 'antd/lib/table';
import InsuranceStatusResultHeader from './insuranceStatusResult/InsuranceStatusResultHeader';
import Moment from 'react-moment';
import { IInsuranceStatusMainResponse } from '../../app/models/insuranceStatusCheck/InuranceStatusMainResponse';
import { RootStoreContext } from '../../app/stores/rootStore';
import Constants from '../../app/constants/constants';
import { DateRangePicker } from 'rsuite';

const InsuranceStatusTable: React.FC<TablePaginationConfig> = ({ total }) => {
	const rootStore = useContext(RootStoreContext);

	const {
		insuranceStatusHistoryResponse,
		insuranceStatusCurrentHeader,
		setCurrentSelectedResponseId,
		getInsuranceStatusHistoryResponse,
		currentSelectResponseId,
		loadingInitial,
		loadResponse,
		setInsuranceProduct,
		setPeriod,
		period,
		removeInsuranceStatusCheckHistory,
	} = rootStore.insuranceStatusStore;

	const [filterTable, setFilterTable] = useState(
		[] as IInsuranceStatusMainResponse[]
	);

	const [rowClassName, setRowClassName] = useState(
		'ant-table-row ant-table-row-level-0'
	);

	const [rowKey, setRowKey] = useState(insuranceStatusCurrentHeader?.id);
	const [show, setShow] = useState(false);

	const openModal = async (id: number) => {
		setCurrentSelectedResponseId(id);
		loadResponse(id);
		await setInsuranceProduct(id);

		setShow(true);
	};

	const closeModal = async () => {
		setShow(false);
		setCurrentSelectedResponseId(undefined!);
		await getInsuranceStatusHistoryResponse(period);
	};

	useEffect(() => {
		if (!insuranceStatusHistoryResponse) {
			getInsuranceStatusHistoryResponse(period);
		}

		return () => {
			removeInsuranceStatusCheckHistory();
		};
	}, []);

	const data = insuranceStatusHistoryResponse;

	const changeDatesRange = (dates: Date[]) => {
		setPeriod(dates[0], dates[1]);
		getInsuranceStatusHistoryResponse(
			rootStore.insuranceStatusStore.period
		);
	};

	const columns = [
		{
			title: '№',
			dataIndex: 'id',
			key: 'id',
			width: '5%',
			render: (_text: any, row: any, _index: any) => (
				<div
					className='table-div-centered'
					onClick={() => [
						setRowClassName(
							'ant-table-row ant-table-row-level-0 ant-table-row-selected'
						),
						setRowKey(row.id),
						row.responseStatus ===
						Constants.InsuranceCheckResponseStatus.HasData
							? openModal(row.id)
							: notification.warn({
									message: `Заявка ${row?.id} е в процес на обработка или няма налични данни!`,
									duration: 3,
							  }),
					]}>
					{row.id}
				</div>
			),
		},
		{
			title: 'Дата на питане',
			dataIndex: 'requestTime',
			key: 'requestTime',
			width: '12%',
			render: (_text: any, row: any, _index: any) => (
				<div
					className='table-div-centered'
					onClick={() => [
						setRowClassName(
							'ant-table-row ant-table-row-level-0 ant-table-row-selected'
						),
						setRowKey(row.id),
						row.responseStatus ===
						Constants.InsuranceCheckResponseStatus.HasData
							? openModal(row.id)
							: notification.warn({
									message: `Заявка ${row?.id} е в процес на обработка или няма налични данни!`,
									duration: 3,
							  }),
					]}>
					<div>
						<Moment format={Constants.Common.FullDate_Format}>
							{row.requestTime}
						</Moment>
					</div>
				</div>
			),
		},
		{
			title: 'Дата на отговор',
			dataIndex: 'answerTime',
			key: 'answerTime',
			width: '12%',
			render: (_text: any, row: any, _index: any) => (
				<div
					className='table-div-centered'
					onClick={() => [
						setRowClassName(
							'ant-table-row ant-table-row-level-0 ant-table-row-selected'
						),
						setRowKey(row.id),
						row.responseStatus ===
						Constants.InsuranceCheckResponseStatus.HasData
							? openModal(row.id)
							: notification.warn({
									message: `Заявка ${row?.id} е в процес на обработка или няма налични данни!`,
									duration: 3,
							  }),
					]}>
					{row.responseStatus !==
					Constants.InsuranceCheckResponseStatus.Processing ? (
						<div>
							<Moment format={Constants.Common.FullDate_Format}>
								{row.answerTime}
							</Moment>
						</div>
					) : (
						' '
					)}
				</div>
			),
		},
		{
			title: 'Застрахователна компания',
			dataIndex: 'companyName',
			key: 'companyName',
			width: '25%',
			render: (_text: any, row: any, _index: any) => (
				<div
					className='table-div-centered'
					onClick={() => [
						setRowClassName(
							'ant-table-row ant-table-row-level-0 ant-table-row-selected'
						),
						setRowKey(row.id),
						row.responseStatus ===
						Constants.InsuranceCheckResponseStatus.HasData
							? openModal(row.id)
							: notification.warn({
									message: `Заявка ${row?.id} е в процес на обработка или няма налични данни!`,
									duration: 3,
							  }),
					]}>
					{row.companyName}
				</div>
			),
		},
		{
			title: 'ЕГН',
			dataIndex: 'patientUid',
			key: 'patientUid',
			width: '15%',

			render: (_text: any, row: any, _index: any) => (
				<div
					className='table-div-centered'
					onClick={() => [
						setRowClassName(
							'ant-table-row ant-table-row-level-0 ant-table-row-selected'
						),
						setRowKey(row.id),
						row.responseStatus ===
						Constants.InsuranceCheckResponseStatus.HasData
							? openModal(row.id)
							: notification.warn({
									message: `Заявка ${row?.id} е в процес на обработка или няма налични данни!`,
									duration: 3,
							  }),
					]}>
					{row.patientUid}
				</div>
			),
		},
		{
			title: 'Номер на карта',
			dataIndex: 'patientCardNumber',
			key: 'patientCardNumber',
			width: '15%',

			render: (_text: any, row: any, _index: any) => (
				<div
					className='table-div-centered'
					onClick={() => [
						setRowClassName(
							'ant-table-row ant-table-row-level-0 ant-table-row-selected'
						),
						setRowKey(row.id),
						row.responseStatus ===
						Constants.InsuranceCheckResponseStatus.HasData
							? openModal(row.id)
							: notification.warn({
									message: `Заявка ${row?.id} е в процес на обработка или няма налични данни!`,
									duration: 3,
							  }),
					]}>
					{row.patientCardNumber}
				</div>
			),
		},

		{
			title: 'Статус',
			dataIndex: 'responseStatus',
			key: 'responseStatus',
			width: '15%',

			render: (_text: any, row: any, _index: any) => (
				<div
					className='table-div-centered'
					onClick={() => [
						setRowClassName(
							'ant-table-row ant-table-row-level-0 ant-table-row-selected'
						),
						setRowKey(row.id),
						row.responseStatus ===
						Constants.InsuranceCheckResponseStatus.HasData
							? openModal(row.id)
							: notification.warn({
									message: `Заявка ${row?.id} е в процес на обработка или няма налични данни!`,
									duration: 3,
							  }),
					]}>
					{row.responseStatus ===
					Constants.InsuranceCheckResponseStatus.HasData ? (
						<span
							style={{
								color:
									row.status ===
									Constants.InsuranceContractStatus.Active
										? 'green'
										: 'red',
							}}>
							{row.status}
						</span>
					) : (
						row.responseStatus
					)}
				</div>
			),
		},
	];

	const search = (value: any) => {
		if (value.length >= 1) {
			const tableFilterResult = data!.filter((o: any) =>
				Object.keys(o).some((k) =>
					String(o[k]).toLowerCase().includes(value.toLowerCase())
				)
			);
			setFilterTable(tableFilterResult as []);
		} else if (value.length === 0) {
			setFilterTable([] as IInsuranceStatusMainResponse[]);
		}
	};
	const title = (
		<div>
			Отговор по заявка {insuranceStatusCurrentHeader?.id}/{' '}
			<Moment format={Constants.Common.FullDate_Format}>
				{insuranceStatusCurrentHeader?.answerTime}
			</Moment>
		</div>
	);

	return (
		<div>
			<Space className='space-table-head'>
				<Button
					className='btn-groupe'
					style={{
						background: '#234254',
					}}
					type='primary'
					icon={<SyncOutlined />}
					onClick={() => getInsuranceStatusHistoryResponse(period)}>
					Опресняване
				</Button>
				<DateRangePicker
					placeholder={['дд.мм.гггг', 'дд.мм.гггг']}
					cleanable={false}
					className='date-range-tables'
					format={Constants.Common.ShortDate_Format}
					value={[
						rootStore.insuranceStatusStore.period.dateFrom,
						rootStore.insuranceStatusStore.period.dateTo,
					]}
					onOk={(date: Date[]) => changeDatesRange(date)}
					locale={Constants.ReactSuiteCalendarLocale}
					size='xs'
					ranges={[]}
					isoWeek={true}
				/>
				<Input.Search
					className='search-input'
					placeholder='Търсене...'
					enterButton
					onSearch={search}
				/>
			</Space>

			<Table
				loading={loadingInitial}
				columns={columns}
				dataSource={filterTable.length < 1 ? data : filterTable}
				locale={{ emptyText: 'Няма налични данни' }}
				sortDirections={['descend', 'ascend']}
				showSorterTooltip={false}
				pagination={{
					showSizeChanger: true,
					showQuickJumper: true,
					total: total,
					showTotal: (total) => `Брой резултати: ${total}`,
				}}
				rowClassName={(_record, index) => {
					return _record.id === rowKey ? rowClassName : '';
				}}
				rowKey={rowKey?.toString()}
			/>
			<Modal
				confirmLoading={loadingInitial}
				key={
					insuranceStatusCurrentHeader?.id
						? currentSelectResponseId
						: undefined
				}
				maskClosable={false}
				transitionName='none'
				maskTransitionName='none'
				width='90%'
				title={title}
				centered
				onCancel={() => closeModal()}
				visible={show}
				footer={false}
				destroyOnClose={true}>
				<InsuranceStatusResultHeader />
			</Modal>
		</div>
	);
};
export default observer(InsuranceStatusTable);
