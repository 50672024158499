import { Button, Form, Input, Tooltip } from 'antd';
import React, { useContext, useEffect } from 'react';
import { MailOutlined } from '@ant-design/icons';
import { FormInstance } from 'antd/lib/form';
import { RootStoreContext } from '../../stores/rootStore';
import { observer } from 'mobx-react-lite';
import Constants from '../../constants/constants';

export interface IProps {
	form: FormInstance;
}

const PatientFormItem: React.FC<IProps> = ({ form }) => {
	const rootStore = useContext(RootStoreContext);
	const {
		sendPatientEmail,
		setActivateSendEmailButton,
	} = rootStore.medicalReferralStore;

	const {
		electronicDocumentById,
		setAttachmentsOriginData,
		attachmentsOriginData,
		setAttachmentTableVisible,
	} = rootStore.electronicDocumentStore;

	const {
		getPatientByEgn,
		patientByEgn,
		getElectronicDocumentsForPatient,
	} = rootStore.operationsStore;

	const isFormEditingDisabled =
		electronicDocumentById?.documentStatus &&
		electronicDocumentById?.documentStatus !==
			Constants.DocumentProcessStatus.Registered;

	const searchForPatient = async (egn: string) => {
		if (egn.length === 10) {
			await getPatientByEgn(egn);
			if (
				rootStore.electronicDocumentStore.typeOfDocument ===
				Constants.DocumentType.PriorApproval
			) {
				await getElectronicDocumentsForPatient(egn);
			}
		} else {
			await getPatientByEgn(undefined);
			if (
				rootStore.electronicDocumentStore.typeOfDocument ===
				Constants.DocumentType.PriorApproval
			) {
				await getElectronicDocumentsForPatient(undefined);
			}
			form.setFieldsValue({
				patientName: '',
				pacientEmail: '',
			});
		}
	};

	useEffect(() => {
		if (patientByEgn?.fullName) {
			form.setFieldsValue({
				patientName: patientByEgn!.fullName,
				pacientEmail: patientByEgn!.email,
			});
		}
	}, [form, getPatientByEgn, patientByEgn]);

	const sendEmailOnTableChange = () => {
		sendPatientEmail(electronicDocumentById!.id, true, false);
		setActivateSendEmailButton(false);
	};

	return (
		<Form.Item
			className='patient-form-item'
			label='Пациент: '
			rules={[
				{
					required: true,
				},
			]}>
			<Form.Item
				name='patientUid'
				className='egn-input'
				validateStatus={
					rootStore.operationsStore.patientByEgn?.isValidEgn !==
						undefined &&
					!rootStore.operationsStore.patientByEgn?.isValidEgn
						? 'warning'
						: ''
				}
				hasFeedback={
					rootStore.operationsStore.patientByEgn?.isValidEgn !==
						undefined &&
					!rootStore.operationsStore.patientByEgn?.isValidEgn
				}
				rules={[
					{
						required: true,
						message: Constants.FormMessages.RequiredField,
					},
					({ getFieldValue }) => ({
						validator(rule, value) {
							const expression = new RegExp('^[0-9]*$');
							if (expression.test(value)) {
								return Promise.resolve();
							}
							return Promise.reject(
								'EГН-то трябва да е 10 цифри!'
							);
						},
					}),
					{
						min: 10,
						message: ' EГН-то трябва да е 10 цифри! ',
					},
					{
						max: 10,
						message: ' EГН-то трябва да е 10 цифри! ',
					},
				]}>
				<Input
					placeholder='ЕГН'
					onChange={(e: any) => {
						searchForPatient(e.target.value);
						if (
							(rootStore.electronicDocumentStore
								.typeOfDocument ===
								Constants.DocumentType.AmbSheet ||
								rootStore.electronicDocumentStore
									.typeOfDocument ===
									Constants.DocumentType.LabResults) &&
							attachmentsOriginData?.length > 0
						) {
							setAttachmentsOriginData([]);
							rootStore.ambulatorySheetsStore.setAttachCount(1);
							rootStore.laboratoryResultsStore.setAttachCount(1);
							setAttachmentTableVisible(false);
							rootStore.electronicDocumentStore.clearElDoc();
						}
					}}
					disabled={
						electronicDocumentById?.status ===
							Constants.MedicalReferralStatus.Performed ||
						electronicDocumentById?.isActive === false ||
						isFormEditingDisabled ||
						electronicDocumentById?.documentType.toString() ===
							Constants.DocumentType.AmbSheet ||
						electronicDocumentById?.documentType.toString() ===
							Constants.DocumentType.LabResults ||
						electronicDocumentById?.isAutomatic ===
							Constants.ExaminationImportType.Automatic ||
						electronicDocumentById?.isAttachedToClaim
					}
				/>
			</Form.Item>

			<Form.Item
				name='patientName'
				className='pacName-input'
				rules={[
					{
						required: true,
						message: Constants.FormMessages.RequiredField,
					},
					({ getFieldValue }) => ({
						validator(rule, value) {
							const expression = new RegExp(
								"^^[a-zA-Zа-яА-Я]+(([',. -][a-zA-Zа-яА-Я ])?[a-zA-Zа-яА-Я]*)*$"
							);
							if (expression.test(value)) {
								return Promise.resolve();
							}
							return Promise.reject('');
						},
					}),
					({ isFieldsTouched }) => ({
						validator(rule, value) {
							if (isFieldsTouched() === false) {
								return Promise.reject();
							}
							return Promise.resolve();
						},
					}),
				]}>
				<Input
					placeholder='Име'
					disabled={
						electronicDocumentById?.status ===
							Constants.MedicalReferralStatus.Performed ||
						electronicDocumentById?.isActive === false ||
						isFormEditingDisabled ||
						electronicDocumentById?.documentType.toString() ===
							Constants.DocumentType.AmbSheet ||
						electronicDocumentById?.documentType.toString() ===
							Constants.DocumentType.LabResults ||
						electronicDocumentById?.isAutomatic ===
							Constants.ExaminationImportType.Automatic ||
						electronicDocumentById?.isAttachedToClaim
					}
				/>
			</Form.Item>
			{rootStore.electronicDocumentStore.typeOfDocument !==
			Constants.DocumentType.Preventions ? (
				<Form.Item
					name='pacientEmail'
					className='email-input'
					rules={[
						{
							type: 'email',
							message: 'Моля, попълнете валиден имейл!',
						},
						({ isFieldsTouched }) => ({
							validator(rule, value) {
								if (isFieldsTouched() === false) {
									return Promise.reject();
								}
								return Promise.resolve();
							},
						}),
					]}>
					<Input
						placeholder='Имейл'
						disabled={
							electronicDocumentById?.status ===
								Constants.MedicalReferralStatus.Performed ||
							electronicDocumentById?.isActive === false ||
							isFormEditingDisabled ||
							electronicDocumentById?.documentType.toString() ===
								Constants.DocumentType.AmbSheet ||
							electronicDocumentById?.documentType.toString() ===
								Constants.DocumentType.LabResults ||
							electronicDocumentById?.isAutomatic ===
								Constants.ExaminationImportType.Automatic ||
							electronicDocumentById?.isAttachedToClaim
						}
					/>
				</Form.Item>
			) : null}

			<Form.Item
				style={{
					display:
						rootStore.electronicDocumentStore.typeOfDocument ===
							Constants.DocumentType.AmbSheet ||
						rootStore.electronicDocumentStore.typeOfDocument ===
							Constants.DocumentType.LabResults ||
						rootStore.electronicDocumentStore.typeOfDocument ===
							Constants.DocumentType.Preventions
							? 'inline-block'
							: 'none',
				}}
				name='patientCard'
				className={
					rootStore.electronicDocumentStore.typeOfDocument ===
						Constants.DocumentType.AmbSheet ||
					rootStore.electronicDocumentStore.typeOfDocument ===
						Constants.DocumentType.LabResults
						? 'card-input'
						: rootStore.electronicDocumentStore.typeOfDocument ===
						  Constants.DocumentType.Preventions
						? 'prevention-card-input'
						: ''
				}>
				<Input
					style={{
						display:
							rootStore.electronicDocumentStore.typeOfDocument ===
								Constants.DocumentType.AmbSheet ||
							rootStore.electronicDocumentStore.typeOfDocument ===
								Constants.DocumentType.LabResults ||
							rootStore.electronicDocumentStore.typeOfDocument ===
								Constants.DocumentType.Preventions
								? 'inherit'
								: 'none',
					}}
					placeholder='Номер на карта'
					disabled={
						electronicDocumentById?.isActive === false ||
						electronicDocumentById?.isAutomatic ===
							Constants.ExaminationImportType.Automatic ||
						electronicDocumentById?.isAttachedToClaim
					}
				/>
			</Form.Item>

			<Button
				onClick={sendEmailOnTableChange}
				style={{
					display:
						rootStore.medicalReferralStore
							.activateSendEmailButton &&
						electronicDocumentById?.pacientEmail &&
						rootStore.electronicDocumentStore.typeOfDocument ===
							Constants.DocumentType.MedicalReferral
							? 'inline-block'
							: 'none',
					margin: 5,
				}}>
				<Tooltip title='Изпащане на имейл на пациент след редакция на някой от списъците с назначени дейности и/или прикачени документи!'>
					<MailOutlined
						style={{
							color: 'green',
							fontSize: 23,
							fontWeight: 700,
						}}
					/>
				</Tooltip>
			</Button>
		</Form.Item>
	);
};

export default observer(PatientFormItem);
