import React, { useState, useContext, useEffect } from 'react';
import { Button, Form, Input, Modal, Spin } from 'antd';
import { FormOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../app/stores/rootStore';
import Constants from '../../app/constants/constants';

const PasswordModal: React.FC = () => {
	const rootStore = useContext(RootStoreContext);
	const {
		isUserHavePassword,
		checkUserPassword,
		currentUser,
		activateUserPassword,
		loadingInitial,
		currentSelectedUser,
	} = rootStore.identityStore;

	const [form] = Form.useForm();
	const [show, setShow] = useState(false);
	const [newPassword, setNewPassword] = useState();

	const [repeatNewPassword, setRepeatNewPassword] = useState();
	const [oldPassword, setOldPassword] = useState();

	const openModal = (id: string) => {
		checkUserPassword(currentUser!.userId);
		setShow(true);
	};

	const closeModal = () => {
		form.resetFields();
		setNewPassword(null!);
		setOldPassword(null!);
		setRepeatNewPassword(null!);
		setShow(false);
	};

	const handlePasswordSubmit = () => {
		form.resetFields();
		activateUserPassword(
			currentUser!.userId,
			newPassword!,
			repeatNewPassword!,
			oldPassword!
		);
		closeModal();
	};

	useEffect(() => {
		if (show) {
			form.setFieldsValue({
				password: newPassword,
				repeatPassword: repeatNewPassword,
				oldPassword: oldPassword,
			});
		} else {
			form.resetFields();
		}
	}, [form, show]);

	return (
		<div className='btn-profile-group'>
			<Button
				type='primary'
				shape='round'
				className='btn-profile'
				onClick={() => openModal(currentUser!.userId)}>
				<FormOutlined /> Парола
			</Button>
			<Modal
				key={currentUser?.userId ? currentSelectedUser : undefined}
				width='600px'
				title={
					isUserHavePassword === false
						? 'Заявяване на парола'
						: 'Смяна на парола'
				}
				centered
				maskClosable={false}
				transitionName='none'
				maskTransitionName='none'
				onCancel={() => closeModal()}
				visible={show}
				footer={false}
				confirmLoading={loadingInitial}
				destroyOnClose={true}>
				{isUserHavePassword === false ? (
					<Spin tip='Зареждане...' spinning={loadingInitial}>
						<Form
							key={currentUser?.userName}
							layout='vertical'
							form={form}
							initialValues={{
								password: newPassword,
								repeatPassword: repeatNewPassword,
							}}
							onFinish={() => handlePasswordSubmit()}>
							<div className='password-form-message'>
								Ако желаете да влизате в системата с ограничен
								достъп чрез потребителско име и парола, моля да
								заявите вашата парола тук, като попълните и
								изпратите формата!
							</div>
							<input
								hidden
								type='text'
								autoComplete='username'
								value='{{...}}'></input>
							<Form.Item
								label='Парола'
								name='password'
								rules={[
									{
										required: true,
										message:
											Constants.FormMessages
												.RequiredField,
									},
									{
										min: 8,
										message:
											'Паролата трябва да е поне 8 символа',
									},
									{
										max: 16,
										message:
											'Паролата трябва да е до 16 символа',
									},
									({ getFieldValue }) => ({
										validator(rule, value) {
											const expression = new RegExp(
												'^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d]+$'
											);
											if (expression.test(value)) {
												return Promise.resolve();
											}
											return Promise.reject(
												'Паролата трябва да съдържа поне една малка буква, главна буква и цифра!'
											);
										},
									}),
								]}>
								<Input.Password
									autoComplete='new-password'
									type='password'
									onChange={(e: any) =>
										setNewPassword(e.target.value)
									}
								/>
							</Form.Item>
							<Form.Item
								label='Повтори парола'
								name='repeatPassword'
								rules={[
									{
										required: true,
										message:
											Constants.FormMessages
												.RequiredField,
									},
									({ getFieldValue }) => ({
										validator(rule, value) {
											if (
												!value ||
												getFieldValue('password') ===
													value
											) {
												return Promise.resolve();
											}
											return Promise.reject(
												'Паролите не съвпадат!'
											);
										},
									}),
								]}>
								<Input.Password
									autoComplete='new-password-repeat'
									type='password'
									onChange={(e: any) =>
										setRepeatNewPassword(e.target.value)
									}
								/>
							</Form.Item>
							<Form.Item>
								<Button
									className='btn-primary'
									type='primary'
									htmlType='submit'>
									Активирай
								</Button>
							</Form.Item>
						</Form>
					</Spin>
				) : (
					<Spin tip='Зареждане...' spinning={loadingInitial}>
						<Form
							layout='vertical'
							form={form}
							initialValues={{
								password: newPassword,
								repeatPassword: repeatNewPassword,
								oldPassword: oldPassword,
							}}
							onFinish={() => handlePasswordSubmit()}>
							<input
								hidden
								type='text'
								autoComplete='username'
								value='{{...}}'></input>
							<Form.Item
								label='Стара парола'
								name='oldPassword'
								rules={[
									{
										required: true,
										message:
											Constants.FormMessages
												.RequiredField,
									},
									{
										min: 8,
										message:
											'Паролата трябва да е поне 8 символа',
									},
									{
										max: 16,
										message:
											'Паролата трябва да е до 16 символа',
									},
								]}>
								<Input.Password
									autoComplete='old-password'
									type='password'
									onInput={(e: any) =>
										setOldPassword(e.target.value)
									}
								/>
							</Form.Item>
							<Form.Item
								label='Нова парола'
								name='password'
								rules={[
									{
										required: true,
										message:
											Constants.FormMessages
												.RequiredField,
									},
									{
										min: 8,
										message:
											'Паролата трябва да е поне 8 символа',
									},
									{
										max: 16,
										message:
											'Паролата трябва да е до 16 символа',
									},
									({ getFieldValue }) => ({
										validator(rule, value) {
											const expression = new RegExp(
												'^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d]+$'
											);
											if (expression.test(value)) {
												return Promise.resolve();
											}
											return Promise.reject(
												'Паролата трябва да съдържа поне една малка буква, главна буква и цифра !'
											);
										},
									}),
									({ getFieldValue }) => ({
										validator(rule, value) {
											if (
												!value ||
												getFieldValue('oldPassword') !==
													value
											) {
												return Promise.resolve();
											}
											return Promise.reject(
												'Новата парола трябва да е различна от старата!'
											);
										},
									}),
								]}>
								<Input.Password
									autoComplete='new-password'
									type='password'
									onChange={(e: any) =>
										setNewPassword(e.target.value)
									}
								/>
							</Form.Item>
							<Form.Item
								label='Повтори парола'
								name='repeatPassword'
								rules={[
									{
										required: true,
										message:
											Constants.FormMessages
												.RequiredField,
									},
									({ getFieldValue }) => ({
										validator(rule, value) {
											if (
												!value ||
												getFieldValue('password') ===
													value
											) {
												return Promise.resolve();
											}
											return Promise.reject(
												'Паролите не съвпадат!'
											);
										},
									}),
								]}>
								<Input.Password
									autoComplete='new-password-repeat'
									type='password'
									onChange={(e: any) =>
										setRepeatNewPassword(e.target.value)
									}
								/>
							</Form.Item>
							<Form.Item>
								<Button
									className='btn-primary'
									type='primary'
									htmlType='submit'>
									Промени
								</Button>
							</Form.Item>
						</Form>
					</Spin>
				)}
			</Modal>
		</div>
	);
};
export default observer(PasswordModal);
