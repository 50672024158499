import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import Moment from 'react-moment';
import { RootStoreContext } from '../../app/stores/rootStore';
import { v4 as uuidv4 } from 'uuid';
import Constants from '../../app/constants/constants';

const PrintElectronicDocumentFile: React.FC = () => {
	const rootStore = useContext(RootStoreContext);

	const { electronicDocumentById } = rootStore.electronicDocumentStore;
	const { currentUser } = rootStore.identityStore;

	return (
		<div style={{ margin: '10px' }}>
			<h3
				style={{
					textAlign: 'center',
				}}>
				Здравно заведение: {currentUser?.medicalFacilityName}{' '}
			</h3>
			<h3
				style={{
					textAlign: 'center',
				}}>
				{' '}
				РЗИ: {currentUser?.rziCode}
			</h3>
			<br />

			<h3 style={{ textAlign: 'center' }}>
				{' '}
				{electronicDocumentById?.documentType}{' '}
				{electronicDocumentById?.documentNumber} от{' '}
				<Moment format={Constants.Common.FullDate_Format}>
					{electronicDocumentById?.createdOn}
				</Moment>
				г.
			</h3>
			<br />

			{/* <h4> Данни за лечебното заведение: </h4>
			<div className="referral-print-div">
				ЛЗ: {electronicDocumentById?.medicalFacilityIssuerName}
			</div>
			<div className="referral-print-div">
				Населено място:{" "}
				{electronicDocumentById?.medicalFacilityIssuerCity}
			</div>
			<br /> */}

			<div
				style={{
					display: electronicDocumentById?.insuranceCompanyName
						? 'inherit'
						: 'none',
				}}>
				<h5> До застрахователна компания: </h5>
				<div className='referral-print-div'>
					{electronicDocumentById?.insuranceCompanyName}
				</div>
				<br />
			</div>

			<h5
				style={{
					display: electronicDocumentById?.documentStatus
						? 'inherit'
						: 'none',
				}}>
				Приоритет:{' '}
				{electronicDocumentById?.isImportant === false
					? 'Обикновен'
					: ' Спешен'}
				<br />
			</h5>

			<h5
				style={{
					display: electronicDocumentById?.documentStatus
						? 'inherit'
						: 'none',
				}}>
				Актуален статус:{' '}
				{!electronicDocumentById?.isActive
					? 'Неактивен'
					: electronicDocumentById?.documentStatus}
			</h5>

			<div
				style={{
					display:
						electronicDocumentById?.documentStatus ===
							Constants.DocumentProcessStatus.Approved ||
						electronicDocumentById?.documentStatus ===
							Constants.DocumentProcessStatus.Rejected
							? 'inherit'
							: 'none',
				}}>
				<h4> Резолюция: </h4>

				<div className='referral-print-div'>
					{electronicDocumentById?.insuranceCompanyNotes}
				</div>
				<br />
			</div>

			<h5> Данни за пациента: </h5>
			<div className='referral-print-div'>
				{' '}
				ЕГН: {electronicDocumentById?.patientUid}{' '}
			</div>
			<div className='referral-print-div'>
				{' '}
				Име: {electronicDocumentById?.patientName}
			</div>
			<div
				className='referral-print-div'
				style={{
					display:
						electronicDocumentById?.status ===
							Constants.MedicalReferralStatus.Performed ||
						!electronicDocumentById?.performerKey
							? 'none'
							: 'inherit',
				}}>
				Код за достъп: {electronicDocumentById?.performerKey}{' '}
			</div>
			<br />

			<span
				style={{
					display: electronicDocumentById?.issuerUin
						? 'inherit'
						: 'none',
				}}>
				<h5> Данни за назначилия лекар: </h5>
				<div className='referral-print-div'>
					РЗИ/ЛЗ:{' '}
					{electronicDocumentById?.medicalFacilityIssuerRziCode}/{' '}
					{electronicDocumentById?.medicalFacilityIssuerName}/{' '}
					{electronicDocumentById?.medicalFacilityIssuerCity}{' '}
				</div>
				<div className='referral-print-div'>
					{' '}
					УИН: {electronicDocumentById?.issuerUin}{' '}
				</div>
				<div className='referral-print-div'>
					{' '}
					Име: {electronicDocumentById?.issuerName}{' '}
				</div>
				<div className='referral-print-div'>
					{' '}
					Специалност: {
						electronicDocumentById?.issuerSpecialityName
					}{' '}
				</div>
				<br />
			</span>

			<span
				style={{
					display:
						electronicDocumentById?.status ===
							Constants.MedicalReferralStatus.Performed ||
						electronicDocumentById?.performerUin
							? 'inherit'
							: 'none',
				}}>
				<h5>Данни за лекаря, запазил назначението:</h5>

				<div className='referral-print-div'>
					УИН: {electronicDocumentById?.performerUin}
				</div>
				<div className='referral-print-div'>
					Име: {electronicDocumentById?.performerName}
				</div>
				<div className='referral-print-div'>
					Специалност:{' '}
					{electronicDocumentById?.performerSpecialityName}
				</div>
				<br />
			</span>

			<h5> Данни за диагнозата: </h5>
			<div className='referral-print-div'>
				{electronicDocumentById?.mkbCode} -{' '}
				{electronicDocumentById?.mkbName}
			</div>
			<br />

			<span
				style={{
					display:
						electronicDocumentById?.toSpecialistSpecialityName &&
						rootStore.electronicDocumentStore.typeOfDocument ===
							Constants.DocumentType.MedicalReferral
							? 'inherit'
							: 'none',
				}}>
				<h4> Специалност, към която се отнася: </h4>
				<div className='referral-print-div'>
					{electronicDocumentById?.toSpecialistSpecialityName}
				</div>
				<br />
			</span>

			<h5> Назначени дейности: </h5>
			<table
				style={{
					borderCollapse: 'collapse',
					textAlign: 'center',
					width: '100%',
					color: 'black',
					fontSize: 11,
				}}>
				<thead>
					<tr style={{ border: '1px solid ' }}>
						<th style={{ border: '1px solid ', width: '10%' }}>
							{' '}
							Код по МКБ 9КМ / НЗОК{' '}
						</th>
						<th style={{ border: '1px solid ', width: '40%' }}>
							{' '}
							Дейност{' '}
						</th>
						<th style={{ border: '1px solid ', width: '20%' }}>
							{' '}
							Брой
						</th>
						<th style={{ border: '1px solid ', width: '30%' }}>
							{' '}
							Допълнителна информация{' '}
						</th>
					</tr>
				</thead>
				<tbody style={{ border: '1px solid ' }}>
					{electronicDocumentById?.details.map((detail) => (
						<tr key={uuidv4()}>
							<td
								style={{
									border: '1px solid ',
									textAlign: 'center',
								}}>
								{detail.nhifCode}
							</td>
							<td
								style={{
									border: '1px solid ',
									textAlign: 'left',
								}}>
								{detail.name}
							</td>
							<td style={{ border: '1px solid ' }}>
								{' '}
								{detail.count}{' '}
							</td>
							<td style={{ border: '1px solid ' }}>
								{' '}
								{detail.description}{' '}
							</td>
						</tr>
					))}
				</tbody>
			</table>
			<br />

			<span
				style={{
					display:
						electronicDocumentById?.attachments.length === 0
							? 'none'
							: 'inherit',
				}}>
				<h5> Други документи: </h5>
				{rootStore.electronicDocumentStore.typeOfDocument ===
				Constants.DocumentType.MedicalReferral ? (
					<table
						style={{
							borderCollapse: 'collapse',
							textAlign: 'center',
							width: '100%',
							color: 'black',
							fontSize: 11,
						}}>
						<thead>
							<tr style={{ border: '1px solid ' }}>
								<th
									style={{
										border: '1px solid ',
										width: '10%',
									}}>
									{' '}
									Тип на документ{' '}
								</th>
								<th
									style={{
										border: '1px solid ',
										width: '40%',
									}}>
									{' '}
									Съдържание тип{' '}
								</th>
								<th
									style={{
										border: '1px solid ',
										width: '40%',
									}}>
									Допълнителна информация
								</th>
							</tr>
						</thead>
						<tbody style={{ border: '1px solid ' }}>
							{electronicDocumentById?.attachments.map(
								(attachment) => (
									<tr key={uuidv4()}>
										<td
											style={{
												border: '1px solid ',
												textAlign: 'left',
											}}>
											{attachment.attachmentType}
										</td>
										<td
											style={{
												border: '1px solid ',
												textAlign: 'left',
											}}>
											{attachment.documentType}
										</td>

										<td
											style={{
												border: '1px solid ',
												textAlign: 'left',
											}}>
											{attachment.description}
										</td>
									</tr>
								)
							)}
						</tbody>
					</table>
				) : (
					<table
						style={{
							borderCollapse: 'collapse',
							textAlign: 'center',
							width: '100%',
							color: 'black',
							fontSize: 11,
						}}>
						<thead>
							<tr style={{ border: '1px solid ' }}>
								<th
									style={{
										border: '1px solid ',
										width: '15%',
									}}>
									{' '}
									Тип на документ{' '}
								</th>
								<th
									style={{
										border: '1px solid ',
										width: '30%',
									}}>
									{' '}
									Съдържание тип{' '}
								</th>
								<th
									style={{
										border: '1px solid ',
										width: '10%',
									}}>
									{' '}
									Номер на документ{' '}
								</th>
								<th
									style={{
										border: '1px solid ',
										width: '15%',
									}}>
									{' '}
									Дата на документ{' '}
								</th>
								<th
									style={{
										border: '1px solid ',
										width: '30%',
									}}>
									Допълнителна информация / Лекар
								</th>
							</tr>
						</thead>
						<tbody style={{ border: '1px solid ' }}>
							{electronicDocumentById?.attachments.map(
								(attachment) => (
									<tr key={uuidv4()}>
										<td
											style={{
												border: '1px solid ',
												textAlign: 'left',
											}}>
											{attachment.attachmentType}
										</td>
										<td
											style={{
												border: '1px solid ',
												textAlign: 'left',
											}}>
											{attachment.documentType}
										</td>
										<td
											style={{
												border: '1px solid ',
												textAlign: 'center',
											}}>
											{attachment.eDocumentNumber}
										</td>
										<td
											style={{
												border: '1px solid ',
												textAlign: 'center',
											}}>
											{attachment.eDocumentDate !==
											null ? (
												<Moment
													format={
														Constants.Common
															.ShortDate_Format
													}>
													{attachment.eDocumentDate}
												</Moment>
											) : (
												''
											)}
										</td>
										<td
											style={{
												border: '1px solid ',
												textAlign: 'left',
											}}>
											{attachment.description
												? attachment.description
												: attachment.eDocumentDoctorName}
										</td>
									</tr>
								)
							)}
						</tbody>
					</table>
				)}
				<br />
			</span>

			<span
				style={{
					display: electronicDocumentById?.medicalNotes
						? 'inherit'
						: 'none',
				}}>
				<h5> Пояснения: </h5>

				<div style={{ border: '1px solid', paddingLeft: 10 }}>
					{electronicDocumentById?.medicalNotes}
				</div>
			</span>
		</div>
	);
};

export default observer(PrintElectronicDocumentFile);
