import React, { useRef, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { Row, Col, Button } from 'antd';
import InsiranceStatusResultTable from './insuranceStatusResultTable/InsiranceStatusResultTable';
import { useReactToPrint } from 'react-to-print';
import PrintInsuranceStatus from '../../print/PrintInsuranceStatus';
import { RootStoreContext } from '../../../app/stores/rootStore';
import Constants from '../../../app/constants/constants';

const InsuranceStatusResultHeader: React.FC = () => {
	const rootStore = useContext(RootStoreContext);

	const {
		insuranceProduct,
		insuranceStatusCurrentHeader,
	} = rootStore.insuranceStatusStore;

	const componentRef = useRef(null);

	const handlePrint = useReactToPrint({
		content: () => componentRef.current!,
	});

	return (
		<div style={{ fontSize: 16, color: 'black' }}>
			<Row>
				<Col xs={12} sm={12} md={8} lg={8} xl={8}>
					<div style={{ fontWeight: 'bold', fontSize: 20 }}>
						Общи данни
					</div>
					<div>От: {insuranceStatusCurrentHeader?.userName}</div>
					<div>Към ЗК: {insuranceProduct?.companyName}</div>
					<div style={{ display: 'none' }}>
						<PrintInsuranceStatus ref={componentRef} />
					</div>
					<div></div>
					<Button
						style={{ marginLeft: 0 }}
						className='btn-primary'
						onClick={handlePrint}>
						Принтирай
					</Button>
				</Col>
				<Col xs={12} sm={12} md={8} lg={8} xl={8}>
					<div style={{ fontWeight: 'bold', fontSize: 20 }}>
						Данни на пациент
					</div>
					<div>ЕГН: {insuranceStatusCurrentHeader?.patientUid} </div>
					<div>
						Карта номер:{' '}
						{insuranceStatusCurrentHeader?.patientCardNumber}{' '}
					</div>
				</Col>
				<Col xs={12} sm={12} md={8} lg={8} xl={8}>
					<div style={{ fontWeight: 'bold', fontSize: 20 }}>
						Резултат
					</div>
					<div>Продукт: {insuranceProduct?.name}</div>
					<div
						style={{
							display: insuranceProduct?.optionCode
								? 'block'
								: 'none',
						}}>
						Опция: {insuranceProduct?.optionCode}{' '}
					</div>
					<div>
						{' '}
						Период на валидност:{' '}
						{insuranceStatusCurrentHeader?.period}
					</div>
					<div>
						Статус:{' '}
						<span
							style={{
								color:
									insuranceStatusCurrentHeader?.status ===
									Constants.InsuranceContractStatus.Active
										? 'green'
										: 'red',
							}}>
							{insuranceStatusCurrentHeader?.status}
						</span>
					</div>
				</Col>
			</Row>
			<InsiranceStatusResultTable />
		</div>
	);
};
export default observer(InsuranceStatusResultHeader);
