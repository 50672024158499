import {
	HubConnection,
	HubConnectionBuilder,
	HubConnectionState,
	LogLevel,
} from '@microsoft/signalr';
import { notification } from 'antd';
import { action, computed, observable, runInAction } from 'mobx';
import agent from '../../api/agent';
import { INotification } from '../models/notifications/Notification';
import { RootStore } from './rootStore';

export default class NotificationStore {
	rootStore: RootStore;

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
	}

	@observable.ref notificationHubConnection: HubConnection | null = null;
	@observable notReadNotifications: INotification[] | undefined;
	@observable allNotifications: INotification[] | undefined;

	@action createNotificationHubConnection = () => {
		this.notificationHubConnection = new HubConnectionBuilder()
			.withUrl(process.env.REACT_APP_NOTIFICATIONS_HUB_ADDRESS!, {
				accessTokenFactory: () =>
					this.rootStore.identityStore.accessToken!,
			})
			.configureLogging(LogLevel.None)
			.withAutomaticReconnect()
			.build();

		this.notificationHubConnection
			.start()
			.then(() => {
				if (
					this.notificationHubConnection?.state ===
					HubConnectionState.Connected
				) {
					this.notificationHubConnection!.invoke(
						'AddToGroup',
						this.rootStore.identityStore.currentUser!.userId
					);
				}
			})
			.then(() => {
				if (
					this.notificationHubConnection?.state ===
					HubConnectionState.Connected
				) {
					this.notificationHubConnection!.invoke(
						'AddToGroup',
						`unreadMessages_${
							this.rootStore.identityStore.currentUser!.userId
						}`
					);
				}
			})
			.then(async () => {
				if (!this.notReadNotifications) {
					await this.getNotReadNotifications();
				}
			});

		this.notificationHubConnection.on(
			'ChangedDocumentNotification',
			(res) => {
				try {
					runInAction(() => {
						this.notReadNotifications!.unshift(res);
						notification.info({
							message: res.content,
							className: 'success-messages',
							duration: 5,
						});
					});
				} catch (error) {}
			}
		);

		this.notificationHubConnection.on('GetMarkedAsRead', (res) => {
			try {
				let index = this.notReadNotifications.findIndex(
					(el) => el.id === res
				);
				runInAction(() => {
					this.notReadNotifications[index].isRead = true;
				});
			} catch (error) {}
		});

		this.notificationHubConnection.on('GetAllMarkedAsRead', (res) => {
			try {
				runInAction(() => {
					this.notReadNotifications?.forEach((element) => {
						element.isRead = true;
					});
				});
			} catch (error) {}
		});

		this.notificationHubConnection!.on(
			'CreatedUnreadMessagesNotification',
			(res) => {
				try {
					runInAction(() => {
						this.rootStore.priorApprovalStore.allPriorApprovalsWithUnreadMessages = res;
					});
				} catch (error) {}
			}
		);

		this.notificationHubConnection!.on(
			'RemovedUnreadMessagesNotification',
			(res) => {
				try {
					runInAction(() => {
						this.rootStore.priorApprovalStore.allPriorApprovalsWithUnreadMessages = res;
					});
				} catch (error) {}
			}
		);
	};

	@action stopHubConnection = () => {
		this.notificationHubConnection!.invoke(
			'RemoveFromGroup',
			this.rootStore.identityStore.currentUser!.userId
		)
			.then(() => {
				this.notificationHubConnection!.invoke(
					'RemoveFromGroup',
					`unreadMessages_${
						this.rootStore.identityStore.currentUser!.userId
					}`
				);
			})
			.then(() => {
				this.notificationHubConnection!.stop();
			});
	};

	@computed get unreadNotificationsCount() {
		let counter = 0;
		this.notReadNotifications?.forEach((el) => {
			if (!el.isRead) {
				counter++;
			}
		});
		return counter;
	}

	@action getAllNotifications = async () => {
		this.rootStore.loadersStore.loadingNotifications = true;
		try {
			let res = await agent.Notifications.GetAllNotifications();
			runInAction(() => {
				this.allNotifications = res;
				this.rootStore.loadersStore.loadingNotifications = false;
			});
		} catch (error) {
			notification.error({
				message: error.data,
				duration: 5,
				className: 'success-messages',
			});
			this.rootStore.loadersStore.loadingNotifications = false;
		}
	};

	@action getNotReadNotifications = async () => {
		this.rootStore.loadersStore.loadingNotifications = true;
		try {
			let res = await agent.Notifications.GetNotReadNotifications();
			runInAction(() => {
				this.notReadNotifications = res;
				this.rootStore.loadersStore.loadingNotifications = false;
			});
		} catch (error) {
			notification.error({
				message: error.data,
				duration: 5,
				className: 'success-messages',
			});
			this.rootStore.loadersStore.loadingNotifications = false;
		}
	};

	@action markNotificationAsRead = async (notificationId: string) => {
		try {
			await this.notificationHubConnection!.invoke(
				'MarkAsRead',
				notificationId
			);
		} catch (error) {
			notification.error({
				message: error.data,
				duration: 5,
				className: 'success-messages',
			});
		}
	};

	@action markAllNotificationsAsRead = async () => {
		try {
			await this.notificationHubConnection!.invoke('MarkAllAsRead');
		} catch (error) {
			notification.error({
				message: error.data,
				duration: 5,
				className: 'success-messages',
			});
		}
	};

	@action addToOtherNotificationForUnreadMessage = async (
		insurerUserId: number,
		electronicDocumentId: number
	) => {
		try {
			await this.notificationHubConnection!.invoke(
				'CreateUnreadMessagesNotification',
				insurerUserId,
				electronicDocumentId
			);
		} catch (error) {}
	};

	@action removeSelfNotificationForUnreadMessages = async (
		medicalUserId: number,
		electronicDocumentId: number
	) => {
		try {
			await this.notificationHubConnection!.invoke(
				'RemoveUnreadMessagesNotification',
				medicalUserId,
				electronicDocumentId
			);
		} catch (error) {}
	};
}
