import { IInsuranceStatusMainResponse } from './../models/insuranceStatusCheck/InuranceStatusMainResponse';
import { observable, action, runInAction } from 'mobx';
import { IInsuranceProduct } from '../models/insuranceStatusCheck/InsuranceProduct';
import { IInsuranceStatusRequest } from '../models/insuranceStatusCheck/InsuranceStatusRequest';
import agent from '../../api/agent';
import { notification } from 'antd';
import { RootStore } from './rootStore';
import { IByPeriodSearch } from '../models/electronicDocument/ByPeriodSearch';
import moment from 'moment';

export default class InsuranceStatusStore {
	rootStore: RootStore;

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
	}

	@observable insuranceProduct: IInsuranceProduct | undefined;

	@observable insuranceStatusCurrentHeader:
		| IInsuranceStatusMainResponse
		| undefined;

	@observable insuranceStatusHistoryResponse:
		| IInsuranceStatusMainResponse[]
		| undefined;

	@observable currentSelectResponseId: number | undefined;
	@observable loadingInitial = false;

	@observable period: IByPeriodSearch = {
		dateFrom: moment()
			.subtract(1, 'month')
			.startOf('month')
			.local()
			.toDate(),
		dateTo: moment().local().toDate(),
	};

	@action setPeriod = (dateFrom: Date, dateTo: Date) => {
		this.period.dateFrom = dateFrom;
		this.period.dateTo = dateTo;
	};

	@action setCurrentSelectedResponseId = (id: number) => {
		this.currentSelectResponseId = id;
	};

	@action setInsuranceProduct = async (id: number) => {
		this.loadingInitial = true;
		try {
			let res = await agent.InsuranceStatus.check(id);
			runInAction(() => {
				this.insuranceProduct = res;
				this.loadingInitial = false;
			});
		} catch (error) {
			notification.error({
				message: error.data,
				className: 'success-messages',
				duration: 5,
			});
		}
	};

	@action getInsuranceStatusHistoryResponse = async (
		period: IByPeriodSearch
	) => {
		this.loadingInitial = true;
		try {
			let res = await agent.InsuranceStatus.getResponseHistory(period);
			runInAction(() => {
				this.insuranceStatusHistoryResponse = res;
				this.loadingInitial = false;
			});
		} catch (error) {
			notification.error({
				message: error.data,
				className: 'success-messages',
				duration: 5,
			});
		}
	};

	@action loadResponse = async (id: number) => {
		this.loadingInitial = true;
		try {
			let res = this.insuranceStatusHistoryResponse.find(
				(el) => el.id === id
			);
			runInAction(() => {
				this.insuranceStatusCurrentHeader = res;
				this.loadingInitial = false;
			});
		} catch (error) {
			notification.error({
				message: error.data,
				className: 'success-messages',
				duration: 5,
			});
		}
	};

	@action createRequest = async (request: IInsuranceStatusRequest) => {
		this.loadingInitial = true;
		try {
			let res = await agent.InsuranceStatus.createRequest(request);
			if (res) {
				runInAction(() => {
					this.loadingInitial = false;
				});
				await this.getInsuranceStatusHistoryResponse(this.period);
				notification.success({
					message:
						'Направена е заявка за проверка на застрахователен статус.',
					duration: 5,
					className: 'success-messages',
				});
			}
		} catch (error) {
			notification.error({
				message: error.data,
				duration: 5,
				className: 'success-messages',
			});
			runInAction(() => {
				this.loadingInitial = false;
			});
		}
	};

	@action removeInsuranceStatusCheckHistory = () => {
		this.insuranceStatusHistoryResponse = undefined;
		this.setPeriod(
			moment().local().subtract(1, 'month').startOf('month').toDate(),
			moment().local().toDate()
		);
	};
}
