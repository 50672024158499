import { Form } from 'antd';
import { FormInstance } from 'antd/lib/form';
import React, { useContext } from 'react';
import UploadEditableTable from '../FormItems/AttachmentsFormItem/UploadEditableTable';
import { RootStoreContext } from '../../stores/rootStore';
import { observer } from 'mobx-react-lite';
import Constants from '../../constants/constants';

export interface IProps {
	form: FormInstance;
	openModal: (id: number | undefined) => Promise<void>;
	closeModal: () => Promise<void>;
}
const AttachmentsFormItem: React.FC<IProps> = ({
	form,
	openModal,
	closeModal,
}) => {
	const rootStore = useContext(RootStoreContext);
	const { electronicDocumentById } = rootStore.electronicDocumentStore;

	return (
		<Form.Item
			className={
				rootStore.electronicDocumentStore.uploadEditMode! === true
					? ' '
					: 'upload-form-item'
			}
			name='attachments'
			style={{
				display:
					(electronicDocumentById?.attachments.length === 0 &&
						electronicDocumentById?.isActive === false) ||
					(electronicDocumentById?.attachments.length === 0 &&
						electronicDocumentById?.status ===
							Constants.MedicalReferralStatus.Performed) ||
					(electronicDocumentById?.documentStatus ===
						Constants.DocumentProcessStatus.Open &&
						electronicDocumentById?.attachments.length === 0) ||
					(electronicDocumentById?.documentStatus ===
						Constants.DocumentProcessStatus.Approved &&
						electronicDocumentById?.attachments.length === 0) ||
					(electronicDocumentById?.documentStatus ===
						Constants.DocumentProcessStatus.Rejected &&
						electronicDocumentById?.attachments.length === 0)
						? ' none'
						: 'block',
			}}
			label={
				electronicDocumentById?.status ===
					Constants.MedicalReferralStatus.Performed ||
				electronicDocumentById?.isActive === false
					? 'Други документи'
					: 'Прикачи нов документ '
			}>
			<UploadEditableTable
				passedForm={form}
				openModal={openModal}
				closeModal={closeModal}
			/>
		</Form.Item>
	);
};

export default observer(AttachmentsFormItem);
