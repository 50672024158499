import { IXmlImportResponse } from './../models/xmlImport/XmlImportRequest';
import { action, observable, runInAction } from 'mobx';
import { RootStore } from './rootStore';
import agent from '../../api/agent';
import moment from 'moment';
import { notification } from 'antd';

export default class XmlImportStore {
	rootStore: RootStore;

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
	}

	@observable allXmls: IXmlImportResponse[] | undefined;
	@observable xmlById: IXmlImportResponse | undefined;
	@observable period = moment().local().subtract(1, 'months').toDate();
	@observable xmlDownloadResult: any;

	@action getAllXmls = async (period: Date) => {
		this.rootStore.loadersStore.setLoadingAllTables(true);
		try {
			let res = await agent.XmlImport.getAllByPeriod(period);
			runInAction(() => {
				this.allXmls = res;
				this.rootStore.loadersStore.setLoadingAllTables(false);
			});
		} catch (error) {
			notification.error({
				message: error.data,
				className: 'success-messages',
				duration: 5,
			});
			this.rootStore.loadersStore.setLoadingAllTables(false);
		}
	};

	@action getXmlById = async (id: number | undefined) => {
		if (id) {
			this.rootStore.loadersStore.setLoadingAllTables(true);
			this.rootStore.loadersStore.setLoadingModal(true);
			try {
				let res = await agent.XmlImport.byId(id);
				runInAction(() => {
					this.xmlById = res;
					this.rootStore.loadersStore.setLoadingModal(false);
					this.rootStore.loadersStore.setLoadingAllTables(false);
				});
			} catch (error) {
				notification.error({
					message: error.data,
					className: 'success-messages',
					duration: 5,
				});
				this.rootStore.loadersStore.setLoadingModal(false);
				this.rootStore.loadersStore.setLoadingAllTables(false);
			}
		} else {
			this.xmlById = undefined;
		}
	};

	@action removeAllXmls = () => {
		this.allXmls = undefined;
	};

	@action createXmlRequest = async (formData: FormData) => {
		try {
			let res = await agent.XmlImport.create(formData);
			if (res > 0) {
				notification.info({
					message: 'В процес на обработка',
					className: 'success-messages',
					duration: 5,
				});

				return res;
			}
		} catch (error) {
			notification.error({
				message: error.data,
				className: 'success-messages',
				duration: 5,
			});
		}
	};

	@action updateXmlRequest = async (formData: FormData) => {
		try {
			await agent.XmlImport.update(formData);
			notification.info({
				message: 'В процес на обработка',
				className: 'success-messages',
				duration: 5,
			});
		} catch (error) {
			notification.error({
				message: error.data,
				className: 'success-messages',
				duration: 5,
			});
		}
	};

	@action deleteXmlRequest = async (xmlImportId: number) => {
		this.rootStore.loadersStore.setLoadingModal(true);
		try {
			await agent.XmlImport.delete(xmlImportId);
			runInAction(() => {
				this.xmlById = undefined;
				this.rootStore.loadersStore.setLoadingModal(false);
			});
		} catch (error) {
			runInAction(() => {
				this.rootStore.loadersStore.setLoadingModal(false);
				notification.error({
					message: error.data,
					className: 'success-messages',
					duration: 5,
				});
			});
		}
	};

	@action downloadXml = async (xmlImportId: number) => {
		this.rootStore.loadersStore.setLoadingModal(true);
		try {
			let res = await agent.XmlImport.downloadXml(xmlImportId);
			runInAction(() => {
				this.xmlDownloadResult = res;
				this.rootStore.loadersStore.setLoadingModal(false);
			});
		} catch (error) {
			this.rootStore.loadersStore.setLoadingModal(false);
		}
	};

	@action setPeriod = (date: Date) => {
		this.period = date;
	};
}
