import { notification } from 'antd';
import { action, observable, runInAction } from 'mobx';
import moment from 'moment';
import agent from '../../api/agent';
import { IClaim } from '../models/claims/Claim';
import { IClaimRequest } from '../models/claims/ClaimRequest';
import { RootStore } from './rootStore';

export default class ClaimStore {
	rootStore: RootStore;

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
	}

	@observable allClaims: IClaim[] | undefined;
	@observable claimById: IClaim | undefined;
	@observable period = moment().local().subtract(1, 'months').toDate();
	@observable requestModel: IClaimRequest | undefined;
	@observable isPrintingClaimDoc = false;
	@observable isPrintingClaimDetails = false;

	@action setIsPrintingClaimDoc = (val: boolean) => {
		this.isPrintingClaimDoc = val;
	};
	@action setIsPrintingClaimDetails = (val: boolean) => {
		this.isPrintingClaimDetails = val;
	};

	@action getAllClaims = async (period: Date) => {
		this.rootStore.loadersStore.setLoadingAllTables(true);
		try {
			let res = await agent.Claim.getAllByPeriod(period);
			runInAction(() => {
				this.allClaims = res;
				this.rootStore.loadersStore.setLoadingAllTables(false);
			});
		} catch (error) {
			notification.error({
				message: error.data,
				className: 'success-messages',
				duration: 5,
			});
			this.rootStore.loadersStore.setLoadingAllTables(false);
		}
	};

	@action getClaimById = async (id: number | undefined) => {
		if (id) {
			this.rootStore.loadersStore.setLoadingAllTables(true);
			try {
				let res = await agent.Claim.byId(id);
				runInAction(() => {
					this.claimById = res;
					this.rootStore.loadersStore.setLoadingAllTables(false);
				});
			} catch (error) {
				notification.error({
					message: error.data,
					className: 'success-messages',
					duration: 5,
				});
				this.rootStore.loadersStore.setLoadingAllTables(false);
			}
		} else {
			this.claimById = undefined;
		}
	};

	@action createClaim = async (request: IClaimRequest) => {
		this.rootStore.loadersStore.setLoadingModal(true);
		this.rootStore.loadersStore.setLoadingAllTables(true);
		try {
			await agent.Claim.create(request);
			runInAction(() => {
				this.rootStore.loadersStore.setLoadingModal(false);
				this.rootStore.loadersStore.setLoadingAllTables(false);
			});
		} catch (error) {
			runInAction(() => {
				this.rootStore.loadersStore.setLoadingModal(false);
				this.rootStore.loadersStore.setLoadingAllTables(false);
			});
			notification.error({
				message: error.data,
				className: 'success-messages',
				duration: 5,
			});
		}
	};

	@action setPeriod = (date: Date) => {
		this.period = date;
	};

	@action deleteClaim = async (claimId: number) => {
		this.rootStore.loadersStore.setLoadingModal(true);
		try {
			let res = await agent.Claim.delete(claimId);
			runInAction(() => {
				if (res) {
					this.claimById = undefined;
				} else {
					notification.error({
						message: 'Възникна грешка при изтриване на иск!',
						className: 'success-messages',
						duration: 5,
					});
				}
				this.rootStore.loadersStore.setLoadingModal(false);
			});
		} catch (error) {
			runInAction(() => {
				this.rootStore.loadersStore.setLoadingModal(false);
				notification.error({
					message: error.data,
					className: 'success-messages',
					duration: 5,
				});
			});
		}
	};

	@action SetInProcessingStatus = async (id: number | undefined) => {
		try {
			let res = await agent.Claim.setInProcessingStatus(id);
			if (res === false) {
				notification.success({
					message: 'Възникна грешка при депозиране на иск!',
					className: 'success-messages',
					duration: 5,
				});
			}
		} catch (error) {
			notification.error({
				message: error.data,
				className: 'success-messages',
				duration: 5,
			});
		}
	};

	@action removeAllClaims = () => {
		this.allClaims = undefined;
		this.setPeriod(moment().local().subtract(1, 'months').toDate());
	};
}
