import { Form, Select } from 'antd';
import React, { useContext } from 'react';
import { RootStoreContext } from '../../stores/rootStore';
import { v4 as uuidv4 } from 'uuid';
import { FormInstance } from 'antd/lib/form';
import { observer } from 'mobx-react-lite';

export interface IProps {
	form: FormInstance;
}
const InsuranceCompanyNameFormItem: React.FC<IProps> = ({ form }) => {
	const rootStore = useContext(RootStoreContext);

	const { electronicDocumentById } = rootStore.electronicDocumentStore;

	const { companies } = rootStore.operationsStore;

	const { Option } = Select;
	return (
		<Form.Item
			label='Застрахователна компания:'
			rules={[
				{
					required: true,
				},
			]}>
			<Form.Item
				name='companyName'
				style={{ display: 'inline-block', margin: '0px 8px' }}>
				<Select
					showSearch
					optionFilterProp='title'
					placeholder='Застрахователна компания'
					disabled={
						(electronicDocumentById &&
							electronicDocumentById?.isActive === false) ||
						electronicDocumentById?.documentStatus !== undefined
					}>
					{companies?.map((company) => (
						<Option
							key={uuidv4()}
							value={company.id}
							title={company.name}>
							{company.name}
						</Option>
					))}
				</Select>
			</Form.Item>
		</Form.Item>
	);
};
export default observer(InsuranceCompanyNameFormItem);
