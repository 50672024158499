import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import Moment from 'react-moment';
import { RootStoreContext } from '../../app/stores/rootStore';
import Constants from '../../app/constants/constants';
import { v4 as uuidv4 } from 'uuid';

const PrintSubElDocumentFile: React.FC = () => {
	const rootStore = useContext(RootStoreContext);

	const { electronicSubDocumentById } = rootStore.electronicDocumentStore;
	const { currentUser } = rootStore.identityStore;

	return (
		<div style={{ margin: '10px' }}>
			<h4
				style={{
					textAlign: 'center',
					display:
						electronicSubDocumentById?.status ===
						Constants.MedicalReferralStatus.Performed
							? 'none'
							: 'inherit',
				}}>
				{' '}
				Здравно заведение: {currentUser?.medicalFacilityName}{' '}
			</h4>
			<h4
				style={{
					textAlign: 'center',
					display:
						electronicSubDocumentById?.status ===
						Constants.MedicalReferralStatus.Performed
							? 'none'
							: 'inherit',
				}}>
				{' '}
				РЗИ: {currentUser?.rziCode}
			</h4>
			<br />
			<h4 style={{ textAlign: 'center' }}>
				{' '}
				{electronicSubDocumentById?.documentType}{' '}
				{electronicSubDocumentById?.documentNumber} от{' '}
				<Moment format={Constants.Common.FullDate_Format}>
					{electronicSubDocumentById?.createdOn}
				</Moment>
				г.
			</h4>

			<br />

			<h5> Данни за пациента: </h5>
			<div className='referral-print-div'>
				{' '}
				ЕГН: {electronicSubDocumentById?.patientUid}{' '}
			</div>
			<div className='referral-print-div'>
				{' '}
				Име: {electronicSubDocumentById?.patientName}
			</div>

			<div
				className='referral-print-div'
				style={{
					display:
						electronicSubDocumentById?.status ===
						Constants.MedicalReferralStatus.Performed
							? 'none'
							: 'inherit',
				}}>
				{' '}
				Код за достъп: {electronicSubDocumentById?.performerKey}{' '}
			</div>
			<br />

			<span
				style={{
					display: electronicSubDocumentById?.issuerUin
						? 'inherit'
						: 'none',
				}}>
				<h5> Данни за назначилия лекар: </h5>
				<div className='referral-print-div'>
					РЗИ/ЛЗ:{' '}
					{electronicSubDocumentById?.medicalFacilityIssuerRziCode}/{' '}
					{electronicSubDocumentById?.medicalFacilityIssuerName}/{' '}
					{electronicSubDocumentById?.medicalFacilityIssuerCity}{' '}
				</div>
				<div className='referral-print-div'>
					{' '}
					УИН: {electronicSubDocumentById?.issuerUin}{' '}
				</div>
				<div className='referral-print-div'>
					{' '}
					Име: {electronicSubDocumentById?.issuerName}{' '}
				</div>
				<div className='referral-print-div'>
					{' '}
					Специалност:{' '}
					{electronicSubDocumentById?.issuerSpecialityName}{' '}
				</div>
				<br />
			</span>

			<span
				style={{
					display:
						electronicSubDocumentById?.status ===
							Constants.MedicalReferralStatus.Performed ||
						electronicSubDocumentById?.performerUin
							? 'inherit'
							: 'none',
				}}>
				<h5>Данни за лекаря, запазил назначението:</h5>
				<div className='referral-print-div'>
					УИН: {electronicSubDocumentById?.performerUin}
				</div>
				<div className='referral-print-div'>
					Име: {electronicSubDocumentById?.performerName}
				</div>
				<div className='referral-print-div'>
					Специалност:{' '}
					{electronicSubDocumentById?.performerSpecialityName}
				</div>
				<br />
			</span>

			<span
				style={{
					display: electronicSubDocumentById?.insuranceCompanyName
						? 'inherit'
						: 'none',
				}}>
				<h5> Застрахователна компания: </h5>
				<div className='referral-print-div'>
					{electronicSubDocumentById?.insuranceCompanyName}
				</div>
				<br />
			</span>

			<h4> Данни за диагнозата: </h4>
			<div className='referral-print-div'>
				{electronicSubDocumentById?.mkbCode} -{' '}
				{electronicSubDocumentById?.mkbName}
			</div>

			<br />

			<span
				style={{
					display: electronicSubDocumentById?.toSpecialistSpecialityName
						? 'inherit'
						: 'none',
				}}>
				<h5> Специалност, към която се отнася: </h5>
				<div className='referral-print-div'>
					{electronicSubDocumentById?.toSpecialistSpecialityName}
				</div>
				<br />
			</span>

			<h5> Назначени дейности: </h5>

			<table
				style={{
					borderCollapse: 'collapse',
					textAlign: 'center',
					width: '100%',
					color: 'black',
					fontSize: 11,
				}}>
				<thead>
					<tr style={{ border: '1px solid ' }}>
						<th style={{ border: '1px solid ', width: '10%' }}>
							{' '}
							Код по МКБ 9КМ / НЗОК{' '}
						</th>
						<th style={{ border: '1px solid ', width: '40%' }}>
							{' '}
							Дейност{' '}
						</th>
						<th style={{ border: '1px solid ', width: '20%' }}>
							{' '}
							Брой
						</th>
						<th style={{ border: '1px solid ', width: '30%' }}>
							{' '}
							Допълнителна информация{' '}
						</th>
					</tr>
				</thead>
				<tbody style={{ border: '1px solid ' }}>
					{electronicSubDocumentById?.details.map((detail) => (
						<tr key={uuidv4()}>
							<td
								style={{
									border: '1px solid ',
									textAlign: 'center',
								}}>
								{detail.nhifCode}
							</td>
							<td
								style={{
									border: '1px solid ',
									textAlign: 'left',
								}}>
								{detail.name}
							</td>
							<td style={{ border: '1px solid ' }}>
								{' '}
								{detail.count}{' '}
							</td>
							<td style={{ border: '1px solid ' }}>
								{' '}
								{detail.description}{' '}
							</td>
						</tr>
					))}
				</tbody>
			</table>

			<br />
			<span
				style={{
					display:
						electronicSubDocumentById?.attachments.length === 0
							? 'none'
							: 'inherit',
				}}>
				<h5> Други документи: </h5>
				{rootStore.electronicDocumentStore.typeOfDocument ===
				Constants.DocumentType.MedicalReferral ? (
					<table
						style={{
							borderCollapse: 'collapse',
							textAlign: 'center',
							width: '100%',
							color: 'black',
							fontSize: 11,
						}}>
						<thead>
							<tr style={{ border: '1px solid ' }}>
								<th
									style={{
										border: '1px solid ',
										width: '10%',
									}}>
									{' '}
									Тип на документ{' '}
								</th>
								<th
									style={{
										border: '1px solid ',
										width: '40%',
									}}>
									{' '}
									Съдържание тип{' '}
								</th>
								<th
									style={{
										border: '1px solid ',
										width: '40%',
									}}>
									Допълнителна информация
								</th>
							</tr>
						</thead>
						<tbody style={{ border: '1px solid ' }}>
							{electronicSubDocumentById?.attachments.map(
								(attachment) => (
									<tr key={uuidv4()}>
										<td
											style={{
												border: '1px solid ',
												textAlign: 'left',
											}}>
											{attachment.attachmentType}
										</td>
										<td
											style={{
												border: '1px solid ',
												textAlign: 'left',
											}}>
											{attachment.documentType}
										</td>

										<td
											style={{
												border: '1px solid ',
												textAlign: 'left',
											}}>
											{attachment.description}
										</td>
									</tr>
								)
							)}
						</tbody>
					</table>
				) : (
					<table
						style={{
							borderCollapse: 'collapse',
							textAlign: 'center',
							width: '100%',
							color: 'black',
							fontSize: 11,
						}}>
						<thead>
							<tr style={{ border: '1px solid ' }}>
								<th
									style={{
										border: '1px solid ',
										width: '15%',
									}}>
									{' '}
									Тип на документ{' '}
								</th>
								<th
									style={{
										border: '1px solid ',
										width: '30%',
									}}>
									{' '}
									Съдържание тип{' '}
								</th>
								<th
									style={{
										border: '1px solid ',
										width: '10%',
									}}>
									{' '}
									Номер на документ{' '}
								</th>
								<th
									style={{
										border: '1px solid ',
										width: '15%',
									}}>
									{' '}
									Дата на документ{' '}
								</th>
								<th
									style={{
										border: '1px solid ',
										width: '30%',
									}}>
									Допълнителна информация / Лекар
								</th>
							</tr>
						</thead>
						<tbody style={{ border: '1px solid ' }}>
							{electronicSubDocumentById?.attachments.map(
								(attachment) => (
									<tr key={uuidv4()}>
										<td
											style={{
												border: '1px solid ',
												textAlign: 'left',
											}}>
											{attachment.attachmentType}
										</td>
										<td
											style={{
												border: '1px solid ',
												textAlign: 'left',
											}}>
											{attachment.documentType}
										</td>
										<td
											style={{
												border: '1px solid ',
												textAlign: 'center',
											}}>
											{attachment.eDocumentNumber}
										</td>
										<td
											style={{
												border: '1px solid ',
												textAlign: 'center',
											}}>
											{attachment.eDocumentDate !==
											null ? (
												<Moment
													format={
														Constants.Common
															.ShortDate_Format
													}>
													{attachment.eDocumentDate}
												</Moment>
											) : (
												''
											)}
										</td>
										<td
											style={{
												border: '1px solid ',
												textAlign: 'left',
											}}>
											{attachment.description
												? attachment.description
												: attachment.eDocumentDoctorName}
										</td>
									</tr>
								)
							)}
						</tbody>
					</table>
				)}
			</span>

			<br />

			<span
				style={{
					display: electronicSubDocumentById?.medicalNotes
						? 'inherit'
						: 'none',
				}}>
				<h5> Пояснения: </h5>

				<div style={{ border: '1px solid', paddingLeft: 10 }}>
					{electronicSubDocumentById?.medicalNotes}
				</div>
			</span>
		</div>
	);
};

export default observer(PrintSubElDocumentFile);
