import { Button, Form, Popconfirm, Table, Tooltip, Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useContext, useRef, useState } from 'react';
import { IServicePrice } from '../../app/models/servicesCorrelations/ServicePrice';
import { RootStoreContext } from '../../app/stores/rootStore';
import {
	FormOutlined,
	DeleteOutlined,
	CloseOutlined,
	CheckCircleOutlined,
	PrinterOutlined,
} from '@ant-design/icons';
import { toJS } from 'mobx';
import PricesEditableCell from './PriceEditableCell';
import { useReactToPrint } from 'react-to-print';
import PrintPriceListForInsuranceCompany from '../print/PrintPriceListForInsuranceCompany';

const ServicesPricesEditableTable = () => {
	const rootStore = useContext(RootStoreContext);

	const {
		priceListForCurrentSelectedServiceCorrelation,
		setPriceListForCurrentSelectedServiceCorrelation,
		currentSelectedServiceCorrelationId,
		currentSelectedServiceCorrelationCode,
		createServicePrice,
		updateServicePrice,
		deleteServicePrice,
		setServicePricesTableEditMode,
	} = rootStore.servicesCorrelationsStore;

	const { loadingAllTable } = rootStore.loadersStore;

	const emptyRowInitialData = (key: string) => {
		return {
			key: key,
			id: undefined,
			toServiceCorrelationId: currentSelectedServiceCorrelationId,
			insuranceCompanyId: null,
			insuranceCompanyName: '',
			price: null,
			prophylacticPrice: null,
			specialityCode: '',
			specialityName: '',
			code: currentSelectedServiceCorrelationCode,
			insuranceCompanyCode: '',
		};
	};

	const [form] = Form.useForm();
	const [editingKey, setEditingKey] = useState('');
	const [rowPriceClassName, setRowPriceClassName] = useState(
		'ant-table-row ant-table-row-level-0'
	);
	const [rowPriceKey, setRowPriceKey] = useState('');

	const [count, setCount] = useState(
		priceListForCurrentSelectedServiceCorrelation
			? priceListForCurrentSelectedServiceCorrelation.length + 1
			: 2
	);
	const [temporaryData, setTemporaryData] = useState([] as IServicePrice[]);

	const isEditing = (record: IServicePrice) => record.key === editingKey;

	const handleAdd = () => {
		setRowPriceKey('');
		setServicePricesTableEditMode(true);
		setTemporaryData(priceListForCurrentSelectedServiceCorrelation!);
		const newData = [
			...priceListForCurrentSelectedServiceCorrelation!,
			emptyRowInitialData(
				(
					priceListForCurrentSelectedServiceCorrelation!.length + 1
				).toString()
			),
		];
		setPriceListForCurrentSelectedServiceCorrelation(newData);
		form.resetFields();
		if (priceListForCurrentSelectedServiceCorrelation) {
			setCount(newData!.length + 1);
		} else {
			setCount(count + 1);
		}
		setEditingKey(
			(
				priceListForCurrentSelectedServiceCorrelation!.length + 1
			).toString()
		);

		setTimeout(() => {
			let newElement = document.querySelectorAll(
				`[data-row-key="${
					priceListForCurrentSelectedServiceCorrelation!.length + 1
				}"]`
			)[1];
			if (newElement !== undefined) {
				newElement.scrollIntoView();
			}
		}, 500);
	};

	const edit = (record: IServicePrice) => {
		setTemporaryData(priceListForCurrentSelectedServiceCorrelation!);
		form.setFieldsValue({ ...record });
		setEditingKey(record.key);
		setServicePricesTableEditMode(true);
	};

	const handleCancel = (record: IServicePrice) => {
		if (temporaryData.length === 0) {
			const newData = [emptyRowInitialData('1')];

			record = newData[0];
			setPriceListForCurrentSelectedServiceCorrelation(newData);
			setEditingKey('1');

			form.resetFields();
		} else {
			let newData = toJS(temporaryData);
			for (let index = 0; index < newData.length; index++) {
				newData[index].key = (index + 1).toString();
			}
			setCount(newData!.length + 1);
			setPriceListForCurrentSelectedServiceCorrelation(newData);
			setEditingKey('');
			setServicePricesTableEditMode(false);
		}

		let priceListTableHeaderElement = document.querySelectorAll(
			'.ant-table-thead'
		)[1];
		if (priceListTableHeaderElement !== undefined) {
			priceListTableHeaderElement.scrollIntoView();
		}
	};

	const deleteRecord = async (id: number) => {
		await deleteServicePrice(id);
		setEditingKey('');
	};

	const onSaveButtonMouseEnter = async (record) => {
		let inputPriceValue = form.getFieldValue('price');
		let prophylacticPriceValue = form.getFieldValue('prophylacticPrice');
		let inputCode = form.getFieldValue('code');
		let inputInsuranceCompanyCode = form.getFieldValue('code');
		let newData = toJS(priceListForCurrentSelectedServiceCorrelation);
		let currentElementIndex = newData!.findIndex(
			(el) => el.key === record.key
		);

		if (inputPriceValue !== undefined && inputPriceValue.length > 0) {
			let priceRegex = new RegExp('[0-9]+(\\.[0-9][0-9]?)?');
			if (
				inputPriceValue.length > 0 &&
				inputPriceValue.match(priceRegex)
			) {
				let formattedPrice = parseFloat(inputPriceValue)
					.toFixed(2)
					.toString();

				if (formattedPrice) {
					form.setFieldsValue({ price: formattedPrice });

					newData![currentElementIndex].price = formattedPrice;
				} else {
					newData![currentElementIndex].price = '0.00';
				}
			}
		}
		if (
			prophylacticPriceValue !== undefined &&
			prophylacticPriceValue.length > 0
		) {
			let priceRegex = new RegExp('[0-9]+(\\.[0-9][0-9]?)?');
			if (
				prophylacticPriceValue.length > 0 &&
				prophylacticPriceValue.match(priceRegex)
			) {
				let formattedPrice = parseFloat(prophylacticPriceValue)
					.toFixed(2)
					.toString();

				if (formattedPrice) {
					form.setFieldsValue({ prophylacticPrice: formattedPrice });

					newData![
						currentElementIndex
					].prophylacticPrice = formattedPrice;
				} else {
					newData![currentElementIndex].prophylacticPrice = '';
				}
			}
		}

		if (inputCode !== undefined && inputCode.length > 0) {
			if (inputCode) {
				newData![currentElementIndex].code = inputCode;
			} else {
				newData![
					currentElementIndex
				].code = currentSelectedServiceCorrelationCode;
			}
		}
		if (
			inputInsuranceCompanyCode !== undefined &&
			inputInsuranceCompanyCode.length > 0
		) {
			if (inputInsuranceCompanyCode) {
				newData![
					currentElementIndex
				].insuranceCompanyCode = inputInsuranceCompanyCode;
			} else {
				newData![currentElementIndex].insuranceCompanyCode = ' ';
			}
		}
		setPriceListForCurrentSelectedServiceCorrelation(newData);

		await form.validateFields();
	};

	const save = async (key: React.Key) => {
		try {
			const row = (await form.validateFields()) as IServicePrice;

			let element = toJS(
				priceListForCurrentSelectedServiceCorrelation.find(
					(item) => item.key === key
				)
			);
			element.toServiceCorrelationId = currentSelectedServiceCorrelationId;
			element.prophylacticPrice =
				row.prophylacticPrice?.length === 0
					? null
					: row.prophylacticPrice;
			element.code =
				row.code === null || row.code?.length === 0
					? currentSelectedServiceCorrelationCode
					: row.code;
			element.insuranceCompanyCode =
				row.insuranceCompanyCode === null ||
				row.insuranceCompanyCode?.length === 0
					? null
					: row.insuranceCompanyCode;

			if (element.price === null) {
				element.price = '0.00';
			}
			if (element.id !== undefined) {
				await updateServicePrice(element);
			} else {
				await createServicePrice(element);
			}
			setEditingKey('');
			setServicePricesTableEditMode(false);
		} catch (errInfo) {}
	};

	const componentRef = useRef(null);
	const handlePrint = useReactToPrint({
		content: () => componentRef.current!,
	});

	const columns = [
		{
			title: '',
			dataIndex: 'key',
			width: '4%',
			render: (row: any, record: any) => {
				return <div className='table-div-centered'>{record.key}</div>;
			},
		},
		{
			title: 'Застрахователна компания',
			dataIndex: 'insuranceCompanyName',
			width: '35%',
			editable: true,
		},
		{
			title: 'Специалност',
			dataIndex: 'specialityName',
			width: '31%',
			editable: true,
		},
		{
			title: 'Код',
			dataIndex: 'code',
			width: '5%',
			editable: true,
			render: (row: any, record: any) => {
				return <div className='table-div-centered'>{record.code}</div>;
			},
		},
		{
			title: 'Код ЗК',
			dataIndex: 'insuranceCompanyCode',
			width: '5%',
			editable: true,
			render: (row: any, record: any) => {
				return (
					<div className='table-div-centered'>
						{record.insuranceCompanyCode}
					</div>
				);
			},
		},
		{
			title: 'Ед. Цена',
			dataIndex: 'price',
			width: '7%',
			editable: true,
			render: (row: any, record: any) => {
				return <div style={{ float: 'right' }}>{record.price}</div>;
			},
		},
		{
			title: 'Ед. Цена (проф.)',
			dataIndex: 'prophylacticPrice',
			width: '7%',
			editable: true,
			render: (row: any, record: any) => {
				return (
					<div style={{ float: 'right' }}>
						{record.prophylacticPrice}
					</div>
				);
			},
		},
		{
			title: '',
			dataIndex: 'insuranceCompanyId',
			width: '3%',
			render: (_: any, record: IServicePrice) => {
				const editable = isEditing(record);
				return editable ? (
					<div
						className='table-div-centered'
						onClick={() => save(record.key)}
						hidden={
							rootStore.servicesCorrelationsStore
								.serviceCorrelationsTableEditMode
						}
						onMouseEnter={() =>
							record.insuranceCompanyName.length === 0
								? undefined
								: onSaveButtonMouseEnter(record)
						}>
						<Tooltip title='Запази'>
							<CheckCircleOutlined
								style={{
									fontSize: 18,
									fontWeight: 700,
									color:
										record.insuranceCompanyName.length === 0
											? 'grey'
											: 'green',
								}}
								disabled={
									record.insuranceCompanyName.length === 0
								}
							/>
						</Tooltip>
					</div>
				) : (
					<div className='table-div-centered'>
						<Tooltip title='Редактирай'>
							<FormOutlined
								style={{
									fontSize: 18,
									fontWeight: 700,
									color: editingKey !== '' ? 'grey' : 'blue',
								}}
								hidden={
									rootStore.servicesCorrelationsStore
										.serviceCorrelationsTableEditMode
								}
								onClick={() => edit(record)}
								disabled={editingKey !== ''}
							/>
						</Tooltip>
					</div>
				);
			},
		},
		{
			title: '',
			dataIndex: 'operation',
			width: '3%',
			render: (_: any, record: IServicePrice) => {
				const editable = isEditing(record);
				return editable ? (
					<div className='table-div-centered'>
						<CloseOutlined
							style={{
								color: 'red',
								fontSize: 18,
								fontWeight: 700,
							}}
							hidden={
								rootStore.servicesCorrelationsStore
									.serviceCorrelationsTableEditMode
							}
							onClick={() => handleCancel(record)}
						/>
					</div>
				) : (
					<div className='table-div-centered'>
						<Popconfirm
							title='Сигурни ли сте, че искате да изтриете реда?'
							onConfirm={async () =>
								await deleteRecord(record.id)
							}
							disabled={editingKey !== ''}>
							<DeleteOutlined
								style={{
									fontSize: 18,
									fontWeight: 700,
									color: editingKey !== '' ? 'grey' : 'red',
								}}
								hidden={
									rootStore.servicesCorrelationsStore
										.serviceCorrelationsTableEditMode
								}
								disabled={editingKey !== ''}
							/>
						</Popconfirm>
					</div>
				);
			},
		},
		{
			title: '',
			dataIndex: 'operation',
			width: '3%',
			render: (_: any, record: IServicePrice) => {
				return rowPriceKey === record.key ? (
					<div
						className='table-div-centered'
						onClick={async () => {
							await rootStore.servicesCorrelationsStore.generatePriceList(
								record.insuranceCompanyId
							);
							setTimeout(() => {
								handlePrint();
							}, 500);
						}}>
						<div style={{ display: 'none' }}>
							<PrintPriceListForInsuranceCompany
								ref={componentRef}
							/>
						</div>
						<PrinterOutlined
							style={{
								fontSize: 18,
								fontWeight: 700,
								color: 'yellow',
							}}
						/>
					</div>
				) : null;
			},
		},
	];

	const mergedColumns = columns.map((col) => {
		if (!col.editable) {
			return col;
		}
		return {
			...col,
			onCell: (record: IServicePrice) => ({
				record,
				inputType: col.dataIndex === 'age' ? 'number' : 'text',
				dataIndex: col.dataIndex,
				title: col.title,
				editing: isEditing(record),
				form: form,
			}),
		};
	});

	return (
		<Form form={form} component={false}>
			<div>
				<Button
					onClick={() => {
						handleAdd();
					}}
					type='primary'
					className='add-service-correlation-btn'
					style={{ float: 'left', height: 25 }}
					disabled={
						editingKey !== '' ||
						rootStore.servicesCorrelationsStore
							.serviceCorrelationsTableEditMode ||
						rootStore.servicesCorrelationsStore.servicesCorrelations
							?.length === 0 ||
						rootStore.servicesCorrelationsStore
							.currentSelectedServiceCorrelationId === undefined
					}>
					Добави
				</Button>
				<Typography.Title level={3} style={{ display: 'inline-block' }}>
					Ценоразпис
				</Typography.Title>
			</div>
			<div className='scroll-table-service-correlations'>
				<Table
					onRow={(record, index) => {
						return {
							onClick: () => {
								if (record.id) {
									setRowPriceClassName(
										'ant-table-row ant-table-row-level-0 ant-table-row-selected'
									);
									setRowPriceKey(record.key);
								}
							},
						};
					}}
					style={{ marginTop: 10 }}
					scroll={{ y: '120px' }}
					components={{
						body: {
							cell: PricesEditableCell,
						},
					}}
					pagination={false}
					loading={loadingAllTable}
					bordered
					dataSource={priceListForCurrentSelectedServiceCorrelation}
					columns={mergedColumns}
					rowKey={rowPriceKey}
					rowClassName={(_record, index) => {
						return _record.key === rowPriceKey
							? rowPriceClassName
							: '';
					}}
				/>
			</div>
		</Form>
	);
};

export default observer(ServicesPricesEditableTable);
