import { observer } from 'mobx-react-lite';
import React, {
	Fragment,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import Moment from 'react-moment';
import { IElectronicDocument } from '../../app/models/electronicDocument/ElectronicDocument';
import Constants from '../../app/constants/constants';
import {
	Button,
	Input,
	Modal,
	Space,
	Popconfirm,
	Spin,
	Popover,
	Empty,
	Radio,
} from 'antd';
import Title from 'antd/lib/typography/Title';
import {
	PlusOutlined,
	SyncOutlined,
	CloseOutlined,
	InfoCircleOutlined,
} from '@ant-design/icons';

import { RootStoreContext } from '../../app/stores/rootStore';
import ReactDataGrid from 'react-data-grid';

import { DateRangePicker } from 'rsuite';
import DeseaseHistoryModal from './DeseaseHistoryModal';

const DeseaseHistoryGrid: React.FC = () => {
	const rootStore = useContext(RootStoreContext);

	const {
		electronicDocumentById,
		getElectronicDocumentById,
		setDetailsEditMode,
		setUploadEditMode,
		setDetailsOriginDataFromElectronicDocument,
		setAttachmentsOriginDataFromElectronicDocument,
		setDetailsOriginData,
		setAttachmentsOriginData,
		setCurrentSelectedElectronicDocument,
		setIsFormTouched,
		isFormTouched,
		setIsUploadFormTouched,
		automaticallySearchForElectronicDocumentsForAbulatorySheetsAndMdd,
		setAttachmentTableVisible,
	} = rootStore.electronicDocumentStore;

	const { getPatientByEgn, getDoctorByUin } = rootStore.operationsStore;

	const { currentUser } = rootStore.identityStore;

	const { loadingAllTable } = rootStore.loadersStore;

	const { setTypeOfDocument } = rootStore.electronicDocumentStore;

	const [show, setShow] = useState(false);
	const [filterTable, setFilterTable] = useState(
		([] as IElectronicDocument[]) || null
	);

	const [isV, setisV] = useState(false);

	const handleWindowResize = () => {
		setisV(window.innerWidth < 1300);
	};

	const {
		allDeseaseHistory,
		getDeseaseHistoryByPeriod,
		period,
		setPeriod,
		removeAllDeseaseHistory,
		sortDeseaseHistory,
		setFilterType,
		spliceAllFilteredServiceCorrelations,
	} = rootStore.deseaseHistoryStore;

	useEffect(() => {
		if (!allDeseaseHistory) {
			getDeseaseHistoryByPeriod(period);
		}
		handleWindowResize();
		window.addEventListener('resize', handleWindowResize);
		return () => {
			removeAllDeseaseHistory();
			setFilterType('active');
		};
	}, []);

	const data = allDeseaseHistory;

	const openModal = async (id: number | undefined) => {
		if (id) {
			await getElectronicDocumentById(id);

			if (
				rootStore.electronicDocumentStore.electronicDocumentById
					?.attachments &&
				rootStore.electronicDocumentStore.electronicDocumentById!
					.attachments?.length >= 1
			) {
				setUploadEditMode(true);
				setAttachmentTableVisible(true);
			} else {
				setUploadEditMode(false);
				setAttachmentTableVisible(false);
			}
		} else {
			setAttachmentTableVisible(false);
			setDetailsEditMode(true);
			setUploadEditMode(false);
			setDetailsOriginDataFromElectronicDocument(undefined);
			setAttachmentsOriginDataFromElectronicDocument(undefined);
			setDetailsOriginData(undefined);
			setAttachmentsOriginData(undefined);
			await getElectronicDocumentById(undefined);
		}
		setTypeOfDocument(Constants.DocumentType.DeseaseHistory);

		setShow(true);
	};

	const closeModal = async () => {
		setShow(false);
		if (isFormTouched || rootStore.electronicDocumentStore.getElDocsAgain) {
			getDeseaseHistoryByPeriod(period);
		}
		spliceAllFilteredServiceCorrelations();
		setCurrentSelectedElectronicDocument(undefined);
		setDetailsOriginDataFromElectronicDocument(undefined);
		setAttachmentsOriginDataFromElectronicDocument(undefined);
		setDetailsOriginData(undefined);
		setAttachmentsOriginData(undefined);
		setDetailsEditMode(false);
		rootStore.deseaseHistoryStore.setIsLongListIsDisable(true);
		setIsFormTouched(false);
		setIsUploadFormTouched(false);
		await getPatientByEgn(undefined);
		setTypeOfDocument(undefined);
		await automaticallySearchForElectronicDocumentsForAbulatorySheetsAndMdd(
			undefined
		);
		if (!currentUser.uinCode || currentUser.uinCode.length === 0) {
			await getDoctorByUin(undefined);
		}
	};

	let inputSearchRef = useRef(null);

	const changeDatesRange = (dates: Date[]) => {
		setPeriod(dates[0], dates[1]);
		setFilterTable([] as IElectronicDocument[]);
		inputSearchRef.current.state.value = '';
		getDeseaseHistoryByPeriod(rootStore.deseaseHistoryStore.period);
	};

	const title =
		electronicDocumentById?.isActive === false ||
		electronicDocumentById?.claimProcessStatus ===
			Constants.ClaimProcessStatus.New ||
		electronicDocumentById?.claimProcessStatus ===
			Constants.ClaimProcessStatus.Processed ||
		electronicDocumentById?.claimProcessStatus ===
			Constants.ClaimProcessStatus.Processing ||
		(electronicDocumentById?.claimProcessStatus ===
			Constants.ClaimProcessStatus.ChangeRequested &&
			electronicDocumentById?.isForEditing === false) ? (
			<div>
				ИЗ № {electronicDocumentById?.documentNumber} /{' '}
				<Moment
					format={Constants.Common.FullDate_Format_Without_Seconds}>
					{electronicDocumentById?.documentDate}
				</Moment>{' '}
				до ЗК " {electronicDocumentById?.insuranceCompanyName} "
			</div>
		) : (
			<div>
				Редактиране на{' '}
				
				ИЗ № {electronicDocumentById?.documentNumber} /{' '}
				<Moment
					format={Constants.Common.FullDate_Format_Without_Seconds}>
					{electronicDocumentById?.documentDate}
				</Moment>{' '}
				до ЗК " {electronicDocumentById?.insuranceCompanyName} "
			</div>
		);

	const search = (value: any) => {
		if (value.length >= 1) {
			const tableFilterResult = data!.filter((o: any) =>
				Object.keys(o).some((k) =>
					String(o[k]).toLowerCase().includes(value.toLowerCase())
				)
			);
			setFilterTable(tableFilterResult);
		} else if (value.length === 0) {
			setFilterTable([] as IElectronicDocument[]);
		}
	};

	const contentInfo = (row: IElectronicDocument) => (
		<div>
			<div>
				<strong>Последна редакция:</strong>{' '}
				<Moment format={Constants.Common.FullDate_Format}>
					{row.lastModifiedOn}
				</Moment>
			</div>
			<div>
				<strong>Потребител:</strong> {row.lastModifiedByUsername}
			</div>
			<div>
				<strong>Прикачен към иск:</strong>{' '}
				{row.isAttachedToClaim ? 'Да' : 'Не'}
			</div>
			{row.isAttachedToClaim ? (
				<div>
					<strong>Статус:</strong> {row.claimProcessStatus}
				</div>
			) : null}

			<div>{row.toPrimaryInfo}</div>
		</div>
	);

	const columnsNew = [
		{
			key: 'documentNumber',
			name: '№',
			resizable: true,
			width: isV ? 70 : 85,
			formatter: (row: any) => (
				<div
					className='table-div-centered'
					onDoubleClick={() => [openModal(row.row.id)]}>
					{row.row.documentNumber}
				</div>
			),
		},
		{
			key: 'documentDate',
			name: 'Дата',
			resizable: true,
			width: isV ? 110 : 130,
			formatter: (row: any) => (
				<div
					className='table-div-centered'
					onDoubleClick={() => [openModal(row.row.id)]}>
					<Moment
						format={
							Constants.Common.FullDate_Format_Without_Seconds
						}>
						{row.row.documentDate}
					</Moment>
				</div>
			),
		},
		{
			key: 'insuranceCompanyName',
			name: 'Застр. компания',
			sortable: true,
			resizable: true,
			width: isV ? 200 : null,
			formatter: (row: any) => (
				<div
					className='table-div-centered'
					onDoubleClick={() => [openModal(row.row.id)]}>
					{row.row.insuranceCompanyName}
				</div>
			),
		},
		{
			key: 'patientUid',
			name: 'ЕГН',
			sortable: true,
			resizable: true,
			width: isV ? 85 : 110,
			formatter: (row: any) => (
				<div
					className='table-div-centered'
					onDoubleClick={() => [openModal(row.row.id)]}>
					{row.row.patientUid}
				</div>
			),
		},
		{
			key: 'patientName',
			name: 'Име на пациент',
			sortable: true,
			resizable: true,
			width: isV ? 200 : null,
			formatter: (row: any) => (
				<div
					className='table-div-centered'
					onDoubleClick={() => [openModal(row.row.id)]}>
					{row.row.patientName}
				</div>
			),
		},
		{
			key: 'issuerUin',
			name: 'УИН',
			sortable: true,
			resizable: true,
			width: isV ? 85 : 110,
			formatter: (row: any) => (
				<div
					className='table-div-centered'
					onDoubleClick={() => [openModal(row.row.id)]}>
					{row.row.issuerUin}
				</div>
			),
		},
		{
			key: 'issuerName',
			name: 'Име на лекар',
			sortable: true,
			resizable: true,
			width: isV ? 200 : null,
			formatter: (row: any) => (
				<div
					className='table-div-centered'
					onDoubleClick={() => [openModal(row.row.id)]}>
					{row.row.issuerName}
				</div>
			),
		},
		{
			key: 'isActive',
			name: 'Акт.',
			sortable: true,
			resizable: true,
			width: isV ? 55 : 75,
			formatter: (row: any) => (
				<div
					className={'table-div-centered'}
					onDoubleClick={() => [openModal(row.row.id)]}>
					<span
						style={{
							color:
								row.row.isActive === false
									? 'red'
									: row.row.status ===
									  Constants.MedicalReferralStatus.Performed
									? 'auto'
									: 'green',
						}}>
						{row.row.isActive === true ? 'Дa' : 'Нe'}
					</span>
				</div>
			),
		},
		{
			key: 'totalAmount',
			name: 'Сума',
			resizable: true,
			width: 60,
			formatter: (row: any) => (
				<div
					style={{ textAlignLast: 'right' }}
					onDoubleClick={() => [openModal(row.row.id)]}>
					{row.row.totalAmount}
				</div>
			),
		},
		{
			key: 'isForEditing',
			name: 'Иск',
			sortable: true,
			resizable: true,
			width: 60,
			formatter: (row: any) => (
				<div
					className={'table-div-centered'}
					style={{
						color: row.row.isForEditing
							? 'red'
							: row.row.isAttachedToClaim
							? 'green'
							: 'red',
					}}
					onDoubleClick={() => [openModal(row.row.id)]}>
					{row.row.isForEditing
						? 'За кор.'
						: row.row.isAttachedToClaim
						? 'Да'
						: 'Не'}
				</div>
			),
		},
		{
			key: 'id',
			name: '',
			width: 30,
			formatter: (row: any) => (
				<div className='table-div-centered'>
					<Popover
						content={contentInfo(row.row)}
						trigger='hover'
						placement='topRight'
						style={{ fontSize: 18 }}>
						<InfoCircleOutlined />
					</Popover>
				</div>
			),
		},
	];

	const sortRows = (initialRows, sortColumn, sortDirection) => {
		const comparer = (a, b) => {
			if (sortDirection === 'ASC') {
				return a[sortColumn] > b[sortColumn] ? 1 : -1;
			} else if (sortDirection === 'DESC') {
				return a[sortColumn] < b[sortColumn] ? 1 : -1;
			}
		};
		sortDeseaseHistory(comparer, sortDirection);
	};

	const markSelectedRow = (rowIndex: number, row: IElectronicDocument) => {
		if (row !== undefined) {
			var allSelected = document.querySelectorAll(
				'.react-grid-Cell-selected'
			);
			allSelected.forEach((el) =>
				el.classList.remove('react-grid-Cell-selected')
			);

			let targetRowToSelect = document.querySelector(
				'[value="' + row.id + '"]'
			).parentElement;
			let childrenArray = Array.from(
				targetRowToSelect.children as HTMLCollectionOf<HTMLElement>
			);

			childrenArray.forEach((element) => {
				element.classList.add('react-grid-Cell-selected');
			});
		}
	};

	return (
		<Fragment>
			<div className='home-grid-col-3'>
				<Title level={3}>История на заболяването</Title>
				<Space className='space-table-head'>
					<Button
						className='btn-groupe'
						style={{
							background:
								'radial-gradient(circle, rgba(30,134,17,0.908000700280112) 28%, rgba(27,180,32,0.9248074229691877) 100%)',
						}}
						type='primary'
						icon={<PlusOutlined />}
						onClick={async () => await openModal(undefined!)}>
						Създаване
					</Button>

					<Button
						className='btn-groupe'
						style={{
							background: '#234254',
						}}
						type='primary'
						icon={<SyncOutlined />}
						onClick={() => getDeseaseHistoryByPeriod(period)}>
						Опресняване
					</Button>
				</Space>
				<Space className='space-table-head'>
					<Radio.Group
						defaultValue='active'
						buttonStyle='solid'
						size='small'
						onChange={async (event) => {
							setFilterType(event.target.value);
							await getDeseaseHistoryByPeriod(period);
						}}>
						<Radio.Button value='active'>Активни</Radio.Button>
						<Radio.Button value='all'>Всички</Radio.Button>
						<Radio.Button value='editable'>
							За корекция
						</Radio.Button>
					</Radio.Group>

					<DateRangePicker
						placeholder={['дд.мм.гггг', 'дд.мм.гггг']}
						cleanable={false}
						className='date-range-tables'
						format={Constants.Common.ShortDate_Format}
						value={[
							rootStore.deseaseHistoryStore.period.dateFrom,
							rootStore.deseaseHistoryStore.period.dateTo,
						]}
						onOk={(date: Date[]) => changeDatesRange(date)}
						locale={Constants.ReactSuiteCalendarLocale}
						size='xs'
						color='green'
						ranges={[]}
						isoWeek={true}
					/>
					<Input.Search
						name='search'
						id='custom-search'
						className='search-input'
						placeholder='Търсене...'
						defaultValue={' '}
						enterButton
						onSearch={search}
						ref={inputSearchRef}
					/>
				</Space>

				<Spin size='large' spinning={loadingAllTable}>
					<div
						className='scroll-table-service-correlations ambSheets-table-grid'
						style={{
							maxHeight: 'calc(100vh - 210px)',
						}}>
						{allDeseaseHistory !== undefined ? (
							<ReactDataGrid
								rowHeight={isV ? 50 : 35}
								columns={columnsNew}
								rowGetter={(i) =>
									filterTable.length < 1
										? data[i]
										: filterTable[i]
								}
								rowsCount={
									filterTable.length < 1
										? data.length
										: filterTable.length
								}
								onRowClick={(
									rowIndex: number,
									row: IElectronicDocument
								) => markSelectedRow(rowIndex, row)}
								emptyRowsView={() => <Empty />}
								minHeight={1000}
								onGridSort={(sortColumn, sortDirection) => {
									return sortRows(
										filterTable.length < 1
											? data
											: filterTable,
										sortColumn,
										sortDirection
									);
								}}
							/>
						) : null}
					</div>
				</Spin>

				<div
					style={{
						display: 'inline-flex',
						paddingRight: '54px',
						fontSize: 16,
						color: ' black',
						width: '100%',
						justifyContent: ' flex-end',
					}}>
					Брой резултати:{' '}
					{filterTable === undefined ||
					(filterTable !== undefined && filterTable!.length === 0)
						? data?.length
						: filterTable!.length}{' '}
					<div style={{ margin: '0px 0px 0px 26px' }}>
						Сума:{' '}
						{(filterTable === undefined ||
							(filterTable !== undefined &&
								filterTable!.length === 0)) &&
						data !== undefined &&
						data.length !== 0
							? data
									?.map((a) =>
										parseFloat(a.totalAmount ?? '0')
									)
									.reduce((a, b) => a + b)
									.toFixed(2)
							: filterTable.length >= 1
							? filterTable
									?.map((a) =>
										parseFloat(a.totalAmount ?? '0')
									)
									.reduce((a, b) => a + b)
									.toFixed(2)
							: 0.0}{' '}
					</div>
				</div>

				<Modal
					key={
						electronicDocumentById?.id
							? electronicDocumentById!.id
							: undefined
					}
					className='appointment-modal'
					title={
						electronicDocumentById
							? title
							: 'Създаване на ИЗ'
					}
					centered
					maskClosable={false}
					transitionName='none'
					maskTransitionName='none'
					closeIcon={
						isFormTouched ? (
							<Popconfirm
								title='Сигурни ли сте, че искате да затворите прозореца. Вашите промени няма да бъдат запазени!'
								onConfirm={async () => await closeModal()}>
								<CloseOutlined />
							</Popconfirm>
						) : (
							<CloseOutlined
								onClick={async () => await closeModal()}
							/>
						)
					}
					visible={show}
					footer={false}
					destroyOnClose={true}>
					<DeseaseHistoryModal
						closeModal={closeModal}
						openModal={openModal}
					/>
				</Modal>
			</div>
		</Fragment>
	);
};
export default observer(DeseaseHistoryGrid);
