import React from 'react';
import PriorApprovalTable from './PriorApprovalTable';
import { observer } from 'mobx-react-lite';

const PriorApproval: React.FC = () => {
	return (
		<div>
			<PriorApprovalTable />
		</div>
	);
};
export default observer(PriorApproval);
