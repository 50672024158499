import { notification } from 'antd';
import { action, observable, runInAction, toJS } from 'mobx';
import agent from '../../api/agent';
import { IDynamicAttachmentMetadata } from '../models/electronicDocument/Attachment';
import { IDownloadAttachment } from '../models/electronicDocument/DownloadAttachment';
import {
	IAttachment,
	IDetail,
	IElectronicDocument,
	IUpdateElectronicDocument,
} from '../models/electronicDocument/ElectronicDocument';
import { RootStore } from './rootStore';
import Enums from '../constants/enums';
import { IPredefinedServicesCorrelationsSearchRequest } from '../models/electronicDocument/PredefinedServicesCorrelationsSearchRequest';
import { IAutomaticSearchRequest } from '../models/electronicDocument/AutomaticSearchRequest';
import moment from 'moment';

const DocumentTypeEnum = Enums.DocumentTypeEnum;

export default class ElectronicDocumentStore {
	rootStore: RootStore;

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
	}

	@observable allElectronicDocuments: IElectronicDocument[] | undefined;

	@observable electronicDocumentById: IElectronicDocument | undefined;
	@observable electronicSubDocumentById: IElectronicDocument | undefined;

	@observable typeOfDocument: string | undefined;

	@observable isFormTouched = false;

	@observable isDetailsFormTouched = false;
	@observable isUploadFormTouched = false;

	@observable isElectronicDocumentAttaching = false;

	@observable detailsEditMode = false;
	@observable uploadEditMode = false;

	@observable attachmentTableVisible = false;

	@observable getElDocsAgain = false;

	@observable attachmentDownloadResult: any;
	@observable claimDetailsExcelDownloadResult: any;
	@observable claimAttachmentsExcelDownloadResult: any;

	@observable detailsOriginData: IDetail[] | undefined;
	@observable attachmentsOriginData: IAttachment[] | undefined;

	@observable detailsOriginSubData: IDetail[] | undefined;
	@observable attachmentsOriginSubData: IAttachment[] | undefined;

	@observable serviceCorrelationSearchResult: IDetail | undefined;
	@observable serviceCorrelationForPreventionSearchResult:
		| IDetail
		| undefined;

	@observable electronicDocumentsAutomaticSearchResult:
		| IElectronicDocument[]
		| undefined;

	@action setGetElDocsAgain = (value: boolean) => {
		this.getElDocsAgain = value;
	};

	@action searchForPredefinedServiceCorrelation = async (
		model: IPredefinedServicesCorrelationsSearchRequest
	) => {
		this.rootStore.loadersStore.setLoadingAllTables(true);
		try {
			let res = await agent.ElectronicDocument.searchForPredefinedServiceCorrelations(
				model
			);
			runInAction(() => {
				this.serviceCorrelationSearchResult = res;
				this.rootStore.loadersStore.setLoadingAllTables(false);
			});
		} catch (error) {
			this.rootStore.loadersStore.setLoadingAllTables(false);
		}
	};

	@action searchForPredefinedServiceCorrelationForPrevention = async (
		model: IPredefinedServicesCorrelationsSearchRequest
	) => {
		this.rootStore.loadersStore.setLoadingAllTables(true);
		try {
			let res = await agent.ElectronicDocument.searchForPredefinedServiceCorrelationsForPrevention(
				model
			);
			runInAction(() => {
				this.serviceCorrelationForPreventionSearchResult = res;
				this.rootStore.loadersStore.setLoadingAllTables(false);
			});
		} catch (error) {
			this.rootStore.loadersStore.setLoadingAllTables(false);
		}
	};

	@action
	automaticallySearchForElectronicDocumentsForAbulatorySheetsAndMdd = async (
		model: IAutomaticSearchRequest | undefined
	) => {
		if (model === undefined) {
			this.electronicDocumentsAutomaticSearchResult = undefined;
		} else {
			this.rootStore.loadersStore.setLoadingAllTables(true);
			this.rootStore.loadersStore.setLoadingModal(true);
			try {
				let res = await agent.ElectronicDocument.getAutomatedElectronicDocumentsForPatient(
					model
				);
				runInAction(() => {
					if (res.length === 0) {
						notification.warning({
							message: 'Няма налични електронни документи!',
							duration: 5,
							className: 'success-messages',
						});
					} else {
						this.electronicDocumentsAutomaticSearchResult = res;
					}
					this.getElectronicDocumentById(
						this.electronicDocumentById.id
					);
					this.rootStore.loadersStore.setLoadingModal(false);
					this.rootStore.loadersStore.setLoadingAllTables(false);
				});
			} catch (error) {
				this.rootStore.loadersStore.setLoadingModal(false);
				this.rootStore.loadersStore.setLoadingAllTables(false);
			}
		}
	};

	@action clearElDoc = () => {
		this.electronicDocumentsAutomaticSearchResult = undefined;
	};

	@action setTypeOfDocument = (value: string) => {
		this.typeOfDocument = value;
	};

	@action setCurrentSelectedElectronicDocument = (
		medicalReferral: IElectronicDocument | undefined
	) => {
		if (medicalReferral) {
			this.electronicDocumentById = medicalReferral;
		} else {
			this.electronicDocumentById = undefined;
		}
	};

	@action setIsElectronicDocumentAttaching = (value: boolean) => {
		this.isElectronicDocumentAttaching = value;
	};

	@action setIsFormTouched = (value: boolean) => {
		this.isFormTouched = value;
	};

	@action setIsDetailsFormTouched = (value: boolean) => {
		runInAction(() => {
			this.isDetailsFormTouched = value;
		});
	};

	@action setIsUploadFormTouched = (value: boolean) => {
		runInAction(() => {
			this.isUploadFormTouched = value;
		});
	};

	@action setAttachmentTableVisible = (value: boolean) => {
		runInAction(() => {
			this.attachmentTableVisible = value;
		});
	};

	@action setDetailsEditMode = (flag: boolean) => {
		this.detailsEditMode = flag;
	};

	@action setUploadEditMode = (flag: boolean) => {
		this.uploadEditMode = flag;
	};

	@action setDetailsOriginDataFromElectronicDocument = (
		electronicDocumentById: IElectronicDocument | undefined,
		isSubData: boolean = false
	) => {
		if (
			electronicDocumentById &&
			electronicDocumentById.details.length > 0
		) {
			let tempArr: IDetail[] = [];
			for (
				let index = 0;
				index < electronicDocumentById!.details.length;
				index++
			) {
				tempArr.push(toJS(electronicDocumentById!.details[index]));
				tempArr[index].key = (index + 1).toString();
				if (tempArr[index].price.length > 0) {
					tempArr[index].totalAmount = (
						parseFloat(tempArr[index].price) * tempArr[index].count
					)
						.toFixed(2)
						.toString();
				}
			}
			runInAction(() => {
				if (!isSubData) {
					this.detailsOriginData = tempArr;
				} else {
					this.detailsOriginSubData = tempArr;
				}
			});
		} else {
			runInAction(() => {
				if (!isSubData) {
					this.detailsOriginData = [
						{
							key: '1',
							code: '',
							systemCode: '',
							nhifCode: '',
							name: '',
							count: 1,
							description: '',
							id: 0,
							toMainId: 0,
							lastModifiedByUsername: ' ',
							lastModifiedOn: null,
							price: '',
							totalAmount: '',
							isPayable: true,
							specialityCode: '',
							specialityName: '',
						},
					];
				} else {
					this.detailsOriginSubData = [
						{
							key: '1',
							code: '',
							systemCode: '',
							nhifCode: '',
							name: '',
							count: 1,
							description: '',
							id: 0,
							toMainId: 0,
							lastModifiedByUsername: ' ',
							lastModifiedOn: null,
							price: '',
							totalAmount: '',
							isPayable: true,
							specialityCode: '',
							specialityName: '',
						},
					];
				}
			});
		}
	};

	@action setAttachmentsOriginDataFromElectronicDocument = (
		electronicDocumentById: IElectronicDocument | undefined,
		isSubData: boolean = false
	) => {
		if (
			electronicDocumentById &&
			electronicDocumentById.attachments.length > 0
		) {
			let tempArr: IAttachment[] = [];
			for (
				let index = 0;
				index < electronicDocumentById!.attachments.length;
				index++
			) {
				tempArr.push(toJS(electronicDocumentById!.attachments[index]));
				tempArr[index].key = (index + 1).toString();
			}

			runInAction(() => {
				if (!isSubData) {
					this.attachmentsOriginData = tempArr;
				} else {
					this.attachmentsOriginSubData = tempArr;
				}
			});
		} else {
			runInAction(() => {
				if (!isSubData) {
					this.attachmentsOriginData = [
						{
							key: '1',
							id: 0,
							documentType: '',
							attachmentType: '',
							eDocumentNumber: '',
							eDocumentDate: undefined,
							eDocumentDoctorName: '',
							fileName: '',
							description: '',
							file: undefined,
							toAttachmentId: 0,
							lastModifiedByUsername: ' ',
							lastModifiedOn: null,
						},
					];
				} else {
					this.attachmentsOriginSubData = [
						{
							key: '1',
							id: 0,
							documentType: '',
							attachmentType: '',
							eDocumentNumber: '',
							eDocumentDate: undefined,
							eDocumentDoctorName: '',
							fileName: '',
							description: '',
							file: undefined,
							toAttachmentId: 0,
							lastModifiedByUsername: ' ',
							lastModifiedOn: null,
						},
					];
				}
			});
		}
	};

	@action downloadAttachment = async (attachment: IDownloadAttachment) => {
		this.rootStore.loadersStore.setLoadingModal(true);
		try {
			let res = await agent.ElectronicDocument.downloadAttachment(
				attachment
			);
			runInAction(() => {
				this.attachmentDownloadResult = res;
				this.rootStore.loadersStore.setLoadingModal(false);
			});
		} catch (error) {
			this.rootStore.loadersStore.setLoadingModal(false);
		}
	};

	@action exportClaimDetailsToExcel = async (claimId: number) => {
		this.rootStore.loadersStore.setLoadingModal(true);
		try {
			let res = await agent.ElectronicDocument.downloadClaimDetailsToExcel(
				claimId
			);
			runInAction(() => {
				this.claimDetailsExcelDownloadResult = res;
				this.rootStore.loadersStore.setLoadingModal(false);
			});
		} catch (error) {
			this.rootStore.loadersStore.setLoadingModal(false);
		}
	};

	@action exportClaimAttachmentsToExcel = async (claimId: number) => {
		this.rootStore.loadersStore.setLoadingModal(true);
		try {
			let res = await agent.ElectronicDocument.downloadClaimAttachmentsToExcel(
				claimId
			);
			runInAction(() => {
				this.claimAttachmentsExcelDownloadResult = res;
				this.rootStore.loadersStore.setLoadingModal(false);
			});
		} catch (error) {
			this.rootStore.loadersStore.setLoadingModal(false);
		}
	};

	@action setDetailsOriginData = (
		detailsOriginData: IDetail[] | undefined
	) => {
		this.rootStore.loadersStore.setLoadingModal(true);
		if (detailsOriginData) {
			runInAction(() => {
				this.detailsOriginData = detailsOriginData;
			});
			this.rootStore.loadersStore.setLoadingModal(false);
		} else {
			runInAction(() => {
				this.detailsOriginData = undefined;
			});
			this.rootStore.loadersStore.setLoadingModal(false);
		}
	};

	@action setAttachmentsOriginData = (
		attachmentsOriginData: IAttachment[] | undefined
	) => {
		this.rootStore.loadersStore.setLoadingModal(true);
		if (attachmentsOriginData) {
			this.attachmentsOriginData = attachmentsOriginData;
			this.rootStore.loadersStore.setLoadingModal(false);
		} else {
			this.attachmentsOriginData = undefined;
			this.rootStore.loadersStore.setLoadingModal(false);
		}
	};

	@action createAttachment = async (form: FormData, key: string) => {
		this.rootStore.loadersStore.setLoadingModal(true);
		try {
			let createdAttachment = await agent.ElectronicDocument.createAttachment(
				form
			);
			runInAction(() => {
				this.electronicDocumentById.attachments.push(createdAttachment);
				let index = this.attachmentsOriginData.findIndex(
					(el) => el.key === key
				);

				this.attachmentsOriginData[index] = createdAttachment;
				this.attachmentsOriginData[index].key = key;

				let newOrgData = toJS(this.attachmentsOriginData);
				this.setAttachmentsOriginData(newOrgData);

				this.setUploadEditMode(false);
				this.setIsUploadFormTouched(false);
				this.setGetElDocsAgain(true);
				this.rootStore.loadersStore.setLoadingModal(false);
			});
		} catch (error) {
			runInAction(async () => {
				await this.getElectronicDocumentById(
					this.electronicDocumentById.id
				);
				this.rootStore.loadersStore.setLoadingModal(false);
				notification.error({
					message: error.data,
					duration: 5,
					className: 'success-messages',
				});
			});
		}
	};

	@action updateAttachment = async (
		attachment: IDynamicAttachmentMetadata,
		key: string
	) => {
		this.rootStore.loadersStore.setLoadingModal(true);
		try {
			let updatedAttachment = await agent.ElectronicDocument.updateAttachment(
				attachment
			);
			runInAction(() => {
				let attachmentIndex = this.electronicDocumentById.attachments.findIndex(
					(o) => o.id === updatedAttachment.id
				);
				this.electronicDocumentById.attachments[
					attachmentIndex
				] = updatedAttachment;
				let index = this.attachmentsOriginData.findIndex(
					(el) => el.id === updatedAttachment.id
				);
				this.attachmentsOriginData[index] = updatedAttachment;
				this.attachmentsOriginData[index].key = key;
				let newOrgData = toJS(this.attachmentsOriginData);
				this.setAttachmentsOriginData(newOrgData);

				this.setIsDetailsFormTouched(false);
				this.setIsUploadFormTouched(false);
				this.setUploadEditMode(false);
				this.setGetElDocsAgain(true);
				this.rootStore.loadersStore.setLoadingAllTables(false);
				this.rootStore.loadersStore.setLoadingModal(false);
			});
		} catch (error) {
			runInAction(async () => {
				await this.getElectronicDocumentById(
					this.electronicDocumentById.id
				);
				this.rootStore.loadersStore.setLoadingModal(false);
				notification.error({
					message: error.data,
					duration: 5,
					className: 'success-messages',
				});
			});
		}
	};

	@action deleteAttachment = async (attachmentId: number) => {
		this.rootStore.loadersStore.setLoadingModal(true);
		try {
			await agent.ElectronicDocument.deleteAttachment(attachmentId);
			runInAction(() => {
				var removeIndex = this.electronicDocumentById.attachments
					.map(function (item) {
						return item.id;
					})
					.indexOf(attachmentId);
				this.electronicDocumentById.attachments.splice(removeIndex, 1);

				let tempArr: IAttachment[] = [];
				for (
					let index = 0;
					index < this.electronicDocumentById!.attachments.length;
					index++
				) {
					tempArr.push(
						toJS(this.electronicDocumentById!.attachments[index])
					);
					tempArr[index].key = (index + 1).toString();
					this.attachmentsOriginData = tempArr;
				}
				this.rootStore.loadersStore.setLoadingModal(false);
			});
		} catch (error) {
			runInAction(() => {
				this.getElectronicDocumentById(this.electronicDocumentById.id);
				this.rootStore.loadersStore.setLoadingModal(false);
				notification.error({
					message: error.data,
					duration: 5,
					className: 'success-messages',
				});
			});
		}
	};

	@action createDetail = async (detail: IDetail) => {
		this.rootStore.loadersStore.setLoadingModal(true);
		this.rootStore.loadersStore.setLoadingAllTables(true);
		try {
			let createdRecordId = await agent.ElectronicDocument.createDetail(
				detail
			);
			if (createdRecordId) {
				runInAction(() => {
					this.electronicDocumentById.details.push({
						key: detail.key,
						code: detail.code,
						systemCode: detail.systemCode,
						nhifCode: detail.nhifCode,
						name: detail.name,
						count: detail.count,
						description: detail.description,
						id: createdRecordId,
						toMainId: this.electronicDocumentById.id,
						lastModifiedByUsername: this.rootStore.identityStore
							.currentUser.userName,
						lastModifiedOn: moment().toDate(),
						price: detail.price,
						totalAmount: detail.totalAmount,
						isPayable: true,
						specialityCode: detail.specialityCode,
						specialityName: detail.specialityName,
					});

					let index = this.detailsOriginData.findIndex(
						(el) => el.key === detail.key
					);
					this.detailsOriginData[index] = {
						key: detail.key,
						code: detail.code,
						systemCode: detail.systemCode,
						nhifCode: detail.nhifCode,
						name: detail.name,
						count: detail.count,
						description: detail.description,
						id: createdRecordId,
						toMainId: this.electronicDocumentById.id,
						lastModifiedByUsername: this.rootStore.identityStore
							.currentUser.userName,
						lastModifiedOn: moment().toDate(),
						price: detail.price,
						totalAmount: detail.totalAmount,
						isPayable: true,
						specialityCode: detail.specialityCode,
						specialityName: detail.specialityName,
					};

					let newOrgData = toJS(this.detailsOriginData);
					this.setDetailsOriginData(newOrgData);
					this.setDetailsEditMode(false);
					this.setIsDetailsFormTouched(false);
					this.setGetElDocsAgain(true);
					this.rootStore.loadersStore.setLoadingAllTables(false);
					this.rootStore.loadersStore.setLoadingModal(false);
				});
			}
		} catch (error) {
			runInAction(async () => {
				await this.getElectronicDocumentById(
					this.electronicDocumentById.id
				);
				this.rootStore.loadersStore.setLoadingAllTables(false);
				this.rootStore.loadersStore.setLoadingModal(false);
				notification.error({
					message: error.data,
					duration: 5,
					className: 'success-messages',
				});
			});
		}
	};

	@action updateDetail = async (detail: IDetail) => {
		this.rootStore.loadersStore.setLoadingModal(true);
		this.rootStore.loadersStore.setLoadingAllTables(true);
		try {
			let res = await agent.ElectronicDocument.updateDetail(detail);
			if (res) {
				runInAction(() => {
					let elDocIndex = this.electronicDocumentById.details.findIndex(
						(o) => o.id === detail.id
					);
					this.electronicDocumentById.details[elDocIndex] = detail;
					let index = this.detailsOriginData.findIndex(
						(el) => el.id === detail.id
					);
					this.detailsOriginData[index] = detail;

					let newOrgData = toJS(this.detailsOriginData);
					this.setDetailsOriginData(newOrgData);

					this.setIsDetailsFormTouched(false);
					this.setIsUploadFormTouched(false);
					this.setGetElDocsAgain(true);
					this.rootStore.loadersStore.setLoadingAllTables(false);
					this.rootStore.loadersStore.setLoadingModal(false);
				});
			}
		} catch (error) {
			runInAction(async () => {
				await this.getElectronicDocumentById(
					this.electronicDocumentById.id
				);
				this.rootStore.loadersStore.setLoadingAllTables(false);
				this.rootStore.loadersStore.setLoadingModal(false);
				notification.error({
					message: error.data,
					duration: 5,
					className: 'success-messages',
				});
			});
		}
	};

	@action deleteDetail = async (detailId: number) => {
		this.rootStore.loadersStore.setLoadingModal(true);
		this.rootStore.loadersStore.setLoadingAllTables(true);
		try {
			let res = await agent.ElectronicDocument.deleteDetail(detailId);
			if (res) {
				runInAction(() => {
					var removeIndex = this.electronicDocumentById.details
						.map(function (item) {
							return item.id;
						})
						.indexOf(detailId);
					this.electronicDocumentById.details.splice(removeIndex, 1);

					let tempArr: IDetail[] = [];
					for (
						let index = 0;
						index < this.electronicDocumentById!.details.length;
						index++
					) {
						tempArr.push(
							toJS(this.electronicDocumentById!.details[index])
						);
						tempArr[index].key = (index + 1).toString();
						if (tempArr[index].price.length > 0) {
							tempArr[index].totalAmount = (
								parseFloat(tempArr[index].price) *
								tempArr[index].count
							)
								.toFixed(2)
								.toString();
						}

						this.detailsOriginData = tempArr;
					}
					// var removeIndexOfDetails = this.detailsOriginData
					// 	.map(function (item) {
					// 		return item.id;
					// 	})
					// 	.indexOf(detailId);
					// this.detailsOriginData.splice(removeIndexOfDetails, 1);

					// let newOrgData = toJS(this.detailsOriginData);
					// this.setDetailsOriginData(newOrgData);

					this.setIsDetailsFormTouched(false);
					this.setIsUploadFormTouched(false);
					this.setGetElDocsAgain(true);
					this.rootStore.loadersStore.setLoadingAllTables(false);
					this.rootStore.loadersStore.setLoadingModal(false);
				});
			}
		} catch (error) {
			runInAction(async () => {
				await this.getElectronicDocumentById(
					this.electronicDocumentById.id
				);
				this.rootStore.loadersStore.setLoadingAllTables(false);
				this.rootStore.loadersStore.setLoadingModal(false);
				notification.error({
					message: error.data,
					duration: 5,
					className: 'success-messages',
				});
			});
		}
	};

	@action getElectronicDocumentById = async (id: number | undefined) => {
		if (id) {
			this.rootStore.loadersStore.setLoadingModal(true);
			this.rootStore.loadersStore.setLoadingAllTables(true);
			try {
				let res = await agent.ElectronicDocument.getById(id);
				runInAction(async () => {
					this.electronicDocumentById = res;
					this.setDetailsOriginDataFromElectronicDocument(
						this.electronicDocumentById
					);
					this.setAttachmentsOriginDataFromElectronicDocument(
						this.electronicDocumentById
					);

					if (
						this.rootStore.servicesCorrelationsStore
							.servicesCorrelations === undefined
					) {
						await this.rootStore.servicesCorrelationsStore.getServicesCorrelations();
					}

					this.rootStore.ambulatorySheetsStore.filterServiceCorelationsOnChangeCompanyName(
						this.electronicDocumentById.insuranceCompanyId,
						this.electronicDocumentById.issuerSpecialityCode
					);
					this.rootStore.deseaseHistoryStore.filterServiceCorelationsOnChangeCompanyName(
						this.electronicDocumentById.insuranceCompanyId,
						this.electronicDocumentById.issuerSpecialityCode
					);
					this.rootStore.loadersStore.setLoadingModal(false);
					this.rootStore.loadersStore.setLoadingAllTables(false);
				});
			} catch (error) {
				runInAction(() => {
					this.rootStore.loadersStore.setLoadingModal(false);
					this.rootStore.loadersStore.setLoadingAllTables(false);

					notification.error({
						message: error.data,
						className: 'success-messages',
						duration: 5,
					});
				});
			}
		} else {
			this.electronicDocumentById = undefined;
		}
	};

	@action getElectronicSubDocumentById = async (id: number | undefined) => {
		if (id) {
			this.rootStore.loadersStore.setLoadingModal(true);
			try {
				let res = await agent.ElectronicDocument.getById(id);
				runInAction(() => {
					this.electronicSubDocumentById = res;
					this.setDetailsOriginDataFromElectronicDocument(
						this.electronicSubDocumentById,
						true
					);
					this.setAttachmentsOriginDataFromElectronicDocument(
						this.electronicSubDocumentById,
						true
					);
					this.rootStore.loadersStore.setLoadingModal(false);
				});
			} catch (error) {
				runInAction(() => {
					notification.error({
						message: error.data,
						className: 'success-messages',
						duration: 5,
					});
					this.rootStore.loadersStore.setLoadingModal(false);
				});
			}
		} else {
			this.electronicDocumentById = undefined;
		}
	};

	@action createElectronicDocument = async (
		referral: FormData,
		documentType: number
	) => {
		this.rootStore.loadersStore.setLoadingAllTables(true);
		this.rootStore.loadersStore.setLoadingModal(true);
		try {
			let createdMedicalReferralId = 0;
			if (documentType === DocumentTypeEnum.PriorApproval) {
				createdMedicalReferralId = await agent.PriorApproval.create(
					referral
				);
			} else if (documentType === DocumentTypeEnum.MedicalReferral) {
				createdMedicalReferralId = await agent.MedicalRefferal.create(
					referral
				);
			} else if (documentType === DocumentTypeEnum.AmbSheet) {
				createdMedicalReferralId = await agent.AmbulatorySheets.create(
					referral
				);
			} else if (documentType === DocumentTypeEnum.LabResults) {
				createdMedicalReferralId = await agent.LaboratoryResults.create(
					referral
				);
			} else if (documentType === DocumentTypeEnum.Preventions) {
				createdMedicalReferralId = await agent.Prevention.create(
					referral
				);
			}
			else if (documentType === DocumentTypeEnum.DeseaseHistory) {
				createdMedicalReferralId = await agent.DeseaseHistory.create(
					referral
				);
			}
			if (this.rootStore.identityStore.currentUser.uinCode.length === 0) {
				await this.rootStore.operationsStore.getDoctorByUin(undefined);
			}
			this.rootStore.loadersStore.setLoadingAllTables(false);
			this.rootStore.loadersStore.setLoadingModal(false);

			return createdMedicalReferralId;
		} catch (error) {
			this.rootStore.loadersStore.setLoadingAllTables(false);
			this.rootStore.loadersStore.setLoadingModal(false);
			notification.error({
				message: error.data,
				duration: 5,
				className: 'success-messages',
			});
		}
	};

	@action editElectronicDocument = async (
		referral: IUpdateElectronicDocument,
		documentType: number
	) => {
		this.rootStore.loadersStore.setLoadingAllTables(true);
		this.rootStore.loadersStore.setLoadingModal(true);
		try {
			if (documentType === DocumentTypeEnum.PriorApproval) {
				await agent.PriorApproval.edit(referral);
			} else if (documentType === DocumentTypeEnum.MedicalReferral) {
				await agent.MedicalRefferal.edit(referral);
			} else if (documentType === DocumentTypeEnum.AmbSheet) {
				await agent.AmbulatorySheets.edit(referral);
			} else if (documentType === DocumentTypeEnum.LabResults) {
				await agent.LaboratoryResults.edit(referral);
			} else if (documentType === DocumentTypeEnum.Preventions) {
				await agent.Prevention.edit(referral);
			}
			else if (documentType === DocumentTypeEnum.DeseaseHistory) {
				await agent.DeseaseHistory.edit(referral);
			}
			runInAction(() => {
				if (documentType === DocumentTypeEnum.PriorApproval) {
				} else if (documentType === DocumentTypeEnum.MedicalReferral) {
					this.electronicDocumentById.patientUid =
						referral.patientUid;
					this.electronicDocumentById.patientName =
						referral.patientName;
					this.electronicDocumentById.pacientEmail =
						referral.pacientEmail;
					this.electronicDocumentById.issuerUin = referral.issuerUin;
					this.electronicDocumentById.issuerName =
						referral.issuerName;
					this.electronicDocumentById.issuerSpecialityCode =
						referral.issuerSpecialityCode;
					this.electronicDocumentById.issuerSpecialityName =
						referral.issuerSpecialityName;
					this.electronicDocumentById.mkbCode = referral.mkbCode;
					this.electronicDocumentById.mkbName = referral.mkbName;
					this.electronicDocumentById.toSpecialistSpecialityName =
						referral.toSpecialistSpecialityName;
					this.electronicDocumentById.toSpecialistSpecialityCode =
						referral.toSpecialistSpecialityCode;
					this.electronicDocumentById.medicalNotes =
						referral.medicalNotes;
					this.electronicDocumentById.isActive = referral.isActive;

					this.setIsFormTouched(false);
					this.setGetElDocsAgain(true);
				} else if (documentType === DocumentTypeEnum.AmbSheet) {
				} else if (documentType === DocumentTypeEnum.LabResults) {
				}
				this.rootStore.loadersStore.setLoadingAllTables(false);
				this.rootStore.loadersStore.setLoadingModal(false);
				// notification.success({
				// 	message: "Документът е успешно променен!",
				// 	duration: 5,
				// 	className: "success-messages",
				// });
			});
		} catch (error) {
			runInAction(() => {
				this.rootStore.loadersStore.setLoadingAllTables(false);
				this.rootStore.loadersStore.setLoadingModal(false);
				notification.error({
					message: error.data,
					duration: 5,
					className: 'success-messages',
				});
			});
		}
	};

	@action removeAllElectronicDocuments = () => {
		this.allElectronicDocuments = undefined;
		this.rootStore.medicalReferralStore.allIssuedMedicalRefferals = undefined;
		this.rootStore.priorApprovalStore.allPriorApprovals = undefined;
	};
}
