import { observer } from 'mobx-react-lite';
import React from 'react';
import PreventionsTable from './PreventionsTable';

const Preventions: React.FC = () => {
	return (
		<div>
			<PreventionsTable />
		</div>
	);
};

export default observer(Preventions);
