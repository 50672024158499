import { notification } from 'antd';
import { action, observable, runInAction } from 'mobx';
import moment from 'moment';
import agent from '../../api/agent';
import { IByPeriodSearch } from '../models/electronicDocument/ByPeriodSearch';
import { IElectronicDocument } from '../models/electronicDocument/ElectronicDocument';
import { RootStore } from './rootStore';

export default class PriorApprovalStore {
	rootStore: RootStore;

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
	}

	@observable allPriorApprovals: IElectronicDocument[] | undefined;
	@observable allPriorApprovalsWithUnreadMessages: number[] | undefined;
	@observable period: IByPeriodSearch = {
		dateFrom: moment()
			.local()
			.subtract(1, 'month')
			.startOf('month')
			.toDate(),
		dateTo: moment().local().toDate(),
	};

	@observable filterType = true;

	@action setFilterType = (value: boolean) => {
		this.filterType = value;
	};

	@action setPeriod = (dateFrom: Date, dateTo: Date) => {
		this.period.dateFrom = dateFrom;
		this.period.dateTo = dateTo;
	};

	@action setAllPriorApprovalsByPeriod = async (period: IByPeriodSearch) => {
		this.rootStore.loadersStore.setLoadingAllTables(true);
		try {
			let res = [] as IElectronicDocument[];
			if (this.filterType !== undefined) {
				switch (this.filterType) {
					case true:
						res = await agent.PriorApproval.getAllByPeriod(period);
						break;
					case false:
						res = await agent.PriorApproval.getAllOpenByPeriod(
							period
						);
						break;
				}
			}
			runInAction('set prior approvals by period', () => {
				this.rootStore.electronicDocumentStore.allElectronicDocuments = res;
				this.allPriorApprovals = res;
				this.rootStore.electronicDocumentStore.setGetElDocsAgain(false);
				this.rootStore.loadersStore.setLoadingAllTables(false);
				this.setFilterType(true);
			});
		} catch (error) {
			notification.error({
				message: error.data,
				className: 'success-messages',
				duration: 5,
			});
			runInAction(() => {
				this.rootStore.loadersStore.setLoadingAllTables(false);
			});
		}
	};

	@observable getAllPriorApprovalsWithUnreadMessages = async () => {
		try {
			let res = await agent.Notifications.GetAllElectronicDocumentsWithUnreadNotifications();
			runInAction(() => {
				this.allPriorApprovalsWithUnreadMessages = res;
			});
		} catch (error) {}
	};

	@action getBackInOpenMode = async (id: number, message: string) => {
		this.rootStore.loadersStore.setLoadingModal(true);
		try {
			let res = await agent.PriorApproval.getBackInOpenMode(id, message);
			if (res) {
				notification.success({
					message: 'Изпратена е заявка за промяна на ИПО.',
					className: 'success-messages',
					duration: 5,
				});
			}
			runInAction(() => {
				this.rootStore.loadersStore.setLoadingModal(false);
			});
		} catch (error) {
			notification.error({
				message: error.data,
				className: 'success-messages',
				duration: 5,
			});
			runInAction(() => {
				this.rootStore.loadersStore.setLoadingModal(false);
			});
		}
	};

	@action removeAllPriorApprovals = () => {
		this.allPriorApprovals = undefined;
		this.setPeriod(
			moment().local().subtract(1, 'month').startOf('month').toDate(),
			moment().local().toDate()
		);
	};
}
