import { Button, Popconfirm, Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useContext, useRef } from 'react';
import { FileSearchOutlined, PrinterOutlined } from '@ant-design/icons';
import { useReactToPrint } from 'react-to-print';
import PrintElectronicDocument from '../../print/PrintElectronicDocument';
import { IDownloadAttachment } from '../../../app/models/electronicDocument/DownloadAttachment';
import { RootStoreContext } from '../../../app/stores/rootStore';
import Constants from '../../../app/constants/constants';
import { v4 as uuidv4 } from 'uuid';

interface IProps {
	closeModal: () => Promise<void>;
	openModal: (id: number | undefined) => Promise<void>;
}

const ViewMedicalReferralModal: React.FC<IProps> = ({
	closeModal,
	openModal,
}) => {
	const rootStore = useContext(RootStoreContext);
	const {
		checkMedicalReferral,
		setPerformMedicalReferral,
		performMedicalReferral,
		unperformMedicalReferral,
	} = rootStore.medicalReferralStore;

	const {
		electronicDocumentById,
		downloadAttachment,
	} = rootStore.electronicDocumentStore;

	const { loadingModal } = rootStore.loadersStore;

	const perform = async () => {
		let newModel = { ...checkMedicalReferral! };
		newModel.medicalReferralId = electronicDocumentById!.id;
		setPerformMedicalReferral(newModel);
		await performMedicalReferral(checkMedicalReferral!);
		await closeModal();
	};

	const unperform = async () => {
		await unperformMedicalReferral(electronicDocumentById!.id);
		await closeModal();
	};

	const download = async (
		patientUid: string,
		fileName: string,
		documentType: string
	) => {
		let model: IDownloadAttachment = {
			documentType: documentType,
			patientUid: patientUid,
			fileName: fileName,
		};

		await downloadAttachment(model);

		if (rootStore.electronicDocumentStore.attachmentDownloadResult) {
			const url = window.URL.createObjectURL(
				new Blob([
					rootStore.electronicDocumentStore.attachmentDownloadResult!,
				])
			);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `${model.documentType}.pdf`);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	};

	const componentRef = useRef(null);
	const handlePrint = useReactToPrint({
		content: () => componentRef.current!,
	});

	return (
		<Spin spinning={loadingModal}>
			<div>
				<h4> Данни за пациента: </h4>
				<div className='view-referral-div'>
					ЕГН: {electronicDocumentById?.patientUid}
				</div>
				<div className='view-referral-div'>
					Име: {electronicDocumentById?.patientName}
				</div>
				<div className='view-referral-div'>
					Имейл: {electronicDocumentById?.pacientEmail}
				</div>
				<br />
				<h4>Данни за назначилия лекар:</h4>
				<div className='view-referral-div'>
					РЗИ/ЛЗ:{' '}
					{electronicDocumentById?.medicalFacilityIssuerRziCode}/{' '}
					{electronicDocumentById?.medicalFacilityIssuerName}/{' '}
					{electronicDocumentById?.medicalFacilityIssuerCity}{' '}
				</div>
				<div className='view-referral-div'>
					УИН: {electronicDocumentById?.issuerUin}
				</div>
				<div className='view-referral-div'>
					Име: {electronicDocumentById?.issuerName}
				</div>
				<div className='view-referral-div'>
					Специалност: {electronicDocumentById?.issuerSpecialityName}
				</div>

				<br />
				<span
					style={{
						display:
							electronicDocumentById?.status ===
							Constants.MedicalReferralStatus.Performed
								? 'inherit'
								: 'none',
					}}>
					<h4>Данни за лекаря, запазил назначението:</h4>
					<div className='view-referral-div'>
						УИН: {electronicDocumentById?.performerUin}
					</div>
					<div className='view-referral-div'>
						Име: {electronicDocumentById?.performerName}
					</div>
					<div className='view-referral-div'>
						Специалност:{' '}
						{electronicDocumentById?.performerSpecialityName}
					</div>
					<br />
				</span>

				<h4> Данни за диагнозата: </h4>
				<div className='view-referral-div'>
					{electronicDocumentById?.mkbCode} -{' '}
					{electronicDocumentById?.mkbName}
				</div>
				<br />

				<span
					style={{
						display: electronicDocumentById?.toSpecialistSpecialityName
							? 'inherit'
							: 'none',
					}}>
					<h4>Специалност, към която се отнася: </h4>
					<div className='view-referral-div'>
						{electronicDocumentById?.toSpecialistSpecialityName}
					</div>
					<br />
				</span>

				<h4> Назначени дейности: </h4>

				<table
					style={{
						borderCollapse: 'collapse',
						textAlign: 'center',
						width: '100%',
						color: 'black',
						fontSize: 12,
						fontWeight: 600,
					}}>
					<thead>
						<tr style={{ border: '1px solid ' }}>
							<th style={{ border: '1px solid ', width: '10%' }}>
								Код по МКБ 9КМ / НЗОК
							</th>
							<th style={{ border: '1px solid ', width: '40%' }}>
								Дейност
							</th>
							<th style={{ border: '1px solid ', width: '20%' }}>
								Брой
							</th>
							<th style={{ border: '1px solid ', width: '30%' }}>
								Допълнителна информация
							</th>
						</tr>
					</thead>
					<tbody style={{ border: '1px solid ' }}>
						{electronicDocumentById?.details?.map((detail) => (
							<tr key={uuidv4()}>
								<td
									style={{
										border: '1px solid ',
										textAlign: 'center',
									}}>
									{detail.nhifCode}
								</td>
								<td
									style={{
										border: '1px solid ',
										textAlign: 'left',
									}}>
									{detail.name}
								</td>
								<td style={{ border: '1px solid ' }}>
									{detail.count}
								</td>
								<td style={{ border: '1px solid ' }}>
									{detail.description}
								</td>
							</tr>
						))}
					</tbody>
				</table>

				<br />

				<span
					style={{
						display:
							electronicDocumentById?.attachments.length === 0
								? 'none'
								: 'inherit',
					}}>
					<h4> Други документи: </h4>

					<table
						style={{
							borderCollapse: 'collapse',
							textAlign: 'center',
							width: '100%',
							color: 'black',
							fontSize: 12,
						}}>
						<thead>
							<tr style={{ border: '1px solid ' }}>
								<th
									style={{
										border: '1px solid ',
										width: '10%',
									}}>
									{' '}
									Тип на документ{' '}
								</th>
								<th
									style={{
										border: '1px solid ',
										width: '35%',
									}}>
									{' '}
									Съдържание тип{' '}
								</th>
								<th
									style={{
										border: '1px solid ',
										width: '35%',
									}}>
									Допълнителна информация
								</th>
								<th
									style={{
										border: '1px solid ',
										width: '10%',
									}}>
									Запазване
								</th>
							</tr>
						</thead>
						<tbody style={{ border: '1px solid ' }}>
							{electronicDocumentById?.attachments.map(
								(attachment) => (
									<tr key={uuidv4()}>
										<td
											style={{
												border: '1px solid ',
												textAlign: 'left',
											}}>
											{attachment.attachmentType}
										</td>
										<td
											style={{
												border: '1px solid ',
												textAlign: 'left',
											}}>
											{attachment.documentType}
										</td>

										<td
											style={{
												border: '1px solid ',
												textAlign: 'left',
											}}>
											{attachment.description}
										</td>
										<td
											style={{
												border: '1px solid ',
												textAlign: 'center',
											}}>
											<Button
												type='link'
												onClick={async () =>
													await download(
														electronicDocumentById!
															.patientUid,
														attachment.fileName,
														attachment.documentType
													)
												}>
												<FileSearchOutlined
													style={{
														color: '#e48800',
														fontSize: 20,
														fontWeight: 700,
													}}
												/>
											</Button>
										</td>
									</tr>
								)
							)}
						</tbody>
					</table>
				</span>
				<br />
				<span
					style={{
						display: electronicDocumentById?.medicalNotes
							? 'inherit'
							: 'none',
					}}>
					<h4> Пояснения: </h4>

					<div style={{ border: '1px solid', paddingLeft: 10 }}>
						{electronicDocumentById?.medicalNotes}
					</div>
				</span>
				<br />
				<br />
				<div style={{ display: 'none' }}>
					<PrintElectronicDocument ref={componentRef} />
				</div>
				<Button
					type='primary'
					className='btn-groupe'
					shape='round'
					style={{
						float: 'left',
						margin: 0,
						display:
							electronicDocumentById?.status ===
							Constants.MedicalReferralStatus.Performed
								? 'block'
								: 'none',
						backgroundColor: '#234254',
						borderRadius: 20,
					}}
					onClick={handlePrint}>
					<PrinterOutlined /> Принтирай
				</Button>
				<div
					style={{
						textAlign:
							electronicDocumentById?.status ===
							Constants.MedicalReferralStatus.Performed
								? 'right'
								: 'center',
					}}>
					<Popconfirm
						title={
							electronicDocumentById?.status ===
							Constants.MedicalReferralStatus.Performed
								? 'Отказване на назначението?'
								: 'Запазване на назначението?'
						}
						onConfirm={async () =>
							electronicDocumentById?.status ===
							Constants.MedicalReferralStatus.Performed
								? await unperform()
								: await perform()
						}>
						<Button
							type='primary'
							shape='round'
							className={
								electronicDocumentById?.status ===
								Constants.MedicalReferralStatus.Performed
									? 'btn-danger'
									: 'btn-primary'
							}
							htmlType='submit'>
							{electronicDocumentById?.status ===
							Constants.MedicalReferralStatus.Performed
								? 'Откажи медицинското назначение'
								: 'Запази медицинското назначение'}
						</Button>
					</Popconfirm>
				</div>
			</div>
		</Spin>
	);
};

export default observer(ViewMedicalReferralModal);
