import { IXmlImportResponse } from './../app/models/xmlImport/XmlImportRequest';
import axios, { AxiosResponse } from 'axios';
import { history } from '..';

import { IInsuranceStatusMainResponse } from './../app/models/insuranceStatusCheck/InuranceStatusMainResponse';
import { IInsuranceStatusRequest } from '../app/models/insuranceStatusCheck/InsuranceStatusRequest';
import { IInsuranceProduct } from '../app/models/insuranceStatusCheck/InsuranceProduct';
import { IInsuranceCompany } from '../app/models/insuranceStatusCheck/InsuranceCompany';

import {
	ICreateUser,
	IForCertificateAuthenticationUser,
} from './../app/models/userManagement/CreateUser';
import { IUpdateUser } from './../app/models/userManagement/UpdateUser';
import { IUser } from './../app/models/userManagement/User';

import { ISetUserPassword } from '../app/models/SetUserPassword';
import { ISetUserEmail } from '../app/models/SetUserEmail';

import { IDoctorByUinResponse } from '../app/models/electronicDocument/DoctorByUinResponse';
import { IPatientByEgnResponse } from '../app/models/electronicDocument/PatientByEgnResponse';
import { IBaseServices } from '../app/models/electronicDocument/BaseServices';
import { ISpeciality } from '../app/models/electronicDocument/Speciality';
import { IMkb } from '../app/models/electronicDocument/Mkb';
import { IDocumentType } from '../app/models/electronicDocument/DocumentType';

import {
	IBaseElectronicDocument,
	IElectronicDocument,
	IDetail,
	IUpdateElectronicDocument,
	IAttachment,
} from '../app/models/electronicDocument/ElectronicDocument';

import { IDynamicAttachmentMetadata } from '../app/models/electronicDocument/Attachment';
import { IDownloadAttachment } from '../app/models/electronicDocument/DownloadAttachment';
import { IPerformMedicalReferral } from '../app/models/electronicDocument/PerformMedicalReferral';
import { IAttachmentType } from '../app/models/electronicDocument/AttachmentType';
import { IMessage } from '../app/models/messages/Message';
import { INotification } from '../app/models/notifications/Notification';
import { IServiceCorrelation } from '../app/models/servicesCorrelations/ServiceCorrelation';
import { IServicePrice } from '../app/models/servicesCorrelations/ServicePrice';
import { ISettings } from '../app/models/settings/Settings';
import { IAutomaticSearchRequest } from '../app/models/electronicDocument/AutomaticSearchRequest';
import { IPredefinedServicesCorrelationsSearchRequest } from '../app/models/electronicDocument/PredefinedServicesCorrelationsSearchRequest';
import { IByPeriodSearch } from '../app/models/electronicDocument/ByPeriodSearch';
import { IClaimRequest } from '../app/models/claims/ClaimRequest';
import { IClaim } from '../app/models/claims/Claim';
import { IPriceListMain } from '../app/models/servicesCorrelations/PriceList';
import { IAuthorizationResponse } from '../app/models/AuthorizationResponse';
import { IHelpdeskRequest } from '../app/models/helpdesk/HelpdeskRequest';
import { IEnumResponse } from '../app/models/EnumResponse';

const mainAxios = axios.create({
	baseURL: process.env.REACT_APP_API_ADDRESS!,
});

const messagesAxios = axios.create({
	baseURL: process.env.REACT_APP_MESSANGER_API_ADDRESS!,
});

mainAxios.interceptors.request.use(
	(config) => {
		const token = window.sessionStorage.getItem('access_token');
		if (token) config.headers.Authorization = `Bearer ${token}`;
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

messagesAxios.interceptors.request.use(
	(config) => {
		const token = window.sessionStorage.getItem('access_token');
		if (token) config.headers.Authorization = `Bearer ${token}`;
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

mainAxios.interceptors.response.use(undefined, (error) => {
	if (error.message === 'Network Error' && !error.response) {
		// message.error('Network error - make sure API is running!');
		console.log('Network error - make sure API is running!');
	}
	const { status } = error.response;
	if (status === 404) {
		history.push('/');
	}
	if (status === 401) {
		// && headers['www-authenticate'] === 'Bearer error="invalid_token", error_description="The token is expired"'
		window.sessionStorage.removeItem('access_token');
		window.sessionStorage.removeItem('refresh_token');
		window.location.assign(process.env.REACT_APP_BLACKBOX_ADDRESS!);
	}
	if (status === 403) {
		window.sessionStorage.removeItem('access_token');
		window.location.assign(process.env.REACT_APP_BLACKBOX_ADDRESS!);
	}

	throw error.response;
});

const responseBody = (response: AxiosResponse) => response.data;

const requests = {
	get: (url: string) => mainAxios.get(url).then(responseBody),
	post: (url: string, body: {}) =>
		mainAxios.post(url, body).then(responseBody),
	postWithBlobResponse: (url: string, body: {}) =>
		mainAxios.post(url, body, { responseType: 'blob' }).then(responseBody),
	postWithoutBody: (url: string) => mainAxios.post(url).then(responseBody),
	put: (url: string, body: {}) => mainAxios.put(url, body).then(responseBody),
	putWithoutBody: (url: string) => mainAxios.put(url).then(responseBody),
	del: (url: string, body: {}) =>
		mainAxios.delete(url, body).then(responseBody),
};

const messagesRequests = {
	get: (url: string) => messagesAxios.get(url).then(responseBody),
	post: (url: string, body: {}) =>
		messagesAxios.post(url, body).then(responseBody),
};

const IdentityOperations = {
	authenticate: (key: string): Promise<IAuthorizationResponse> =>
		requests.post('/auth/login', { key: key }),
	refresh: (refreshToken: string): Promise<IAuthorizationResponse> =>
		requests.post('/auth/refresh', { refreshToken: refreshToken }),
	logout: (): Promise<void> => requests.post('/auth/logout', {}),
	checkChangePasswordState: (): Promise<boolean> =>
		requests.postWithoutBody('/auth/changepassword'),
	changePassword: (setUserPasswordModel: ISetUserPassword): Promise<number> =>
		requests.put('/auth/changepassword', setUserPasswordModel),
	checkChangeEmailState: (): Promise<string> =>
		requests.postWithoutBody('/auth/changeemail'),
	changeEmail: (setUserEmailModel: ISetUserEmail): Promise<number> =>
		requests.put('/auth/changeemail', setUserEmailModel),
};

const UserManagement = {
	getAll: (): Promise<IUser[]> =>
		requests.postWithoutBody('/UserManagement/All'),
	getById: (userId: number) =>
		requests.post('/UserManagement/GetById', { id: userId }),
	createForCertificateAuthenticationUser: (
		user: IForCertificateAuthenticationUser
	): Promise<boolean> =>
		requests.post(
			'/UserManagement/CreateForCertificateAuthentication',
			user
		),
	update: (user: IUpdateUser): Promise<boolean> =>
		requests.put('/UserManagement/Update', user),
	delete: (userId: number): Promise<boolean> =>
		requests.post('/UserManagement/Delete', { id: userId }),
	activate: (userId: number) =>
		requests.post('/UserManagement/ActivateUser', { id: userId }),
	deactivate: (userId: number) =>
		requests.post('/UserManagement/DeactivateUser', { id: userId }),
};

const InsuranceStatus = {
	check: (id: number): Promise<IInsuranceProduct> =>
		requests.post('/insurancestatus/check', { id: id }),
	getResponseHistory: (
		period: IByPeriodSearch
	): Promise<IInsuranceStatusMainResponse[]> =>
		requests.post('/insurancestatus/history', period),
	getCompanies: (): Promise<IInsuranceCompany[]> =>
		requests.get('/InsuranceStatus/InsuranceCompaniesList'),
	createRequest: (request: IInsuranceStatusRequest): Promise<boolean> =>
		requests.post('/insurancestatus/createrequest', request),
};

const MedicalRefferal = {
	getAllIssuedByPeriod: (
		period: IByPeriodSearch
	): Promise<IElectronicDocument[]> =>
		requests.post('/MedicalReferral/AllIssuedByPeriod', period),

	getAllPerformedByPeriod: (
		period: IByPeriodSearch
	): Promise<IElectronicDocument[]> =>
		requests.post('/MedicalReferral/AllPerformedByPeriod', period),

	create: (referral: FormData): Promise<number> =>
		requests.post('/MedicalReferral/Create', referral),

	edit: (referral: IUpdateElectronicDocument) =>
		requests.put('/MedicalReferral/Edit', referral),

	checkForPerform: (
		performRequest: IPerformMedicalReferral
	): Promise<IBaseElectronicDocument> =>
		requests.post('/MedicalReferral/CheckForPerform', performRequest),

	perform: (performRequest: IPerformMedicalReferral) =>
		requests.post('/MedicalReferral/Perform', performRequest),

	unperform: (referralId: number) =>
		requests.post('/MedicalReferral/Unperform', { id: referralId }),

	sendPatientEmail: (
		referralId: number,
		isEditRecordMode: boolean,
		isDeactivated: boolean
	) =>
		requests.post('/MedicalReferral/SendPatientEmail', {
			id: referralId,
			isEditRecordMode: isEditRecordMode,
			isDeactivated: isDeactivated,
		}),
};

const PriorApproval = {
	getAllByPeriod: (period: IByPeriodSearch): Promise<IElectronicDocument[]> =>
		requests.post('/PriorApproval/GetAllByPeriod', period),

	getAllOpenByPeriod: (
		period: IByPeriodSearch
	): Promise<IElectronicDocument[]> =>
		requests.post('/PriorApproval/GetAllOpenByPeriod', period),

	create: (referral: FormData): Promise<number> =>
		requests.post('/PriorApproval/Create', referral),

	edit: (referral: IUpdateElectronicDocument) =>
		requests.put('/PriorApproval/Edit', referral),

	getBackInOpenMode: (id: number, message: string): Promise<boolean> =>
		requests.post('/PriorApproval/GetBackInOpenMode', {
			id: id,
			message: message,
		}),
};

const AmbulatorySheets = {
	getAllByPeriod: (period: IByPeriodSearch): Promise<IElectronicDocument[]> =>
		requests.post('/AmbulatorySheets/GetAllByPeriod', period),

	getActiveByPeriod: (
		period: IByPeriodSearch
	): Promise<IElectronicDocument[]> =>
		requests.post('/AmbulatorySheets/GetActiveByPeriod', period),

	getForEditingByPeriod: (
		period: IByPeriodSearch
	): Promise<IElectronicDocument[]> =>
		requests.post('/AmbulatorySheets/GetForEditingByPeriod', period),

	create: (document: FormData): Promise<number> =>
		requests.post('/AmbulatorySheets/Create', document),

	edit: (document: IUpdateElectronicDocument) =>
		requests.put('/AmbulatorySheets/Edit', document),

	manuallyAttachToClaim: (claimId: number, electronicDocumentId: number) =>
		requests.put('/AmbulatorySheets/ManuallyAttachToClaim', {
			claimId: claimId,
			electronicDocumentId: electronicDocumentId,
		}),
};

const DeseaseHistory = {
	getAllByPeriod: (period: IByPeriodSearch): Promise<IElectronicDocument[]> =>
		requests.post('/DiseaseHistory/GetAllByPeriod', period),

	getActiveByPeriod: (
		period: IByPeriodSearch
	): Promise<IElectronicDocument[]> =>
		requests.post('/DiseaseHistory/GetActiveByPeriod', period),

	getForEditingByPeriod: (
		period: IByPeriodSearch
	): Promise<IElectronicDocument[]> =>
		requests.post('/DiseaseHistory/GetForEditingByPeriod', period),

	create: (document: FormData): Promise<number> =>
		requests.post('/DiseaseHistory/Create', document),

	edit: (document: IUpdateElectronicDocument) =>
		requests.put('/DiseaseHistory/Edit', document),

};

const LaboratoryResults = {
	getAllByPeriod: (period: IByPeriodSearch): Promise<IElectronicDocument[]> =>
		requests.post('/LaboratoryResults/GetAllByPeriod', period),

	getActiveByPeriod: (
		period: IByPeriodSearch
	): Promise<IElectronicDocument[]> =>
		requests.post('/LaboratoryResults/GetActiveByPeriod', period),

	getForEditingByPeriod: (
		period: IByPeriodSearch
	): Promise<IElectronicDocument[]> =>
		requests.post('/LaboratoryResults/GetForEditingByPeriod', period),

	getWithoutMedicalReferralByPeriod: (
		period: IByPeriodSearch
	): Promise<IElectronicDocument[]> =>
		requests.post(
			'/LaboratoryResults/GetWithoutMedicalReferralByPeriod',
			period
		),

	getWithoutLabResultsByPeriod: (
		period: IByPeriodSearch
	): Promise<IElectronicDocument[]> =>
		requests.post(
			'/LaboratoryResults/GetWithoutLabResultsByPeriod',
			period
		),

	getWithoutPriorApprovalByPeriod: (
		period: IByPeriodSearch
	): Promise<IElectronicDocument[]> =>
		requests.post(
			'/LaboratoryResults/GetWithoutPriorApprovalByPeriod',
			period
		),

	create: (document: FormData): Promise<number> =>
		requests.post('/LaboratoryResults/Create', document),

	edit: (document: IUpdateElectronicDocument) =>
		requests.put('/LaboratoryResults/Edit', document),
};

const Prevention = {
	getAllByPeriod: (period: IByPeriodSearch): Promise<IElectronicDocument[]> =>
		requests.post('/Prevention/GetAllByPeriod', period),

	getActiveByPeriod: (
		period: IByPeriodSearch
	): Promise<IElectronicDocument[]> =>
		requests.post('/Prevention/GetActiveByPeriod', period),

	getForEditingByPeriod: (
		period: IByPeriodSearch
	): Promise<IElectronicDocument[]> =>
		requests.post('/Prevention/GetForEditingByPeriod', period),

	create: (document: FormData): Promise<number> =>
		requests.post('/Prevention/Create', document),

	edit: (document: IUpdateElectronicDocument) =>
		requests.put('/Prevention/Edit', document),
};

const ElectronicDocument = {
	getPatientByEgn: (egn: string): Promise<IPatientByEgnResponse> =>
		requests.post('/ElectronicDocument/SearchForPatient', { egn: egn }),

	getDoctorByUin: (uinCode: string): Promise<IDoctorByUinResponse> =>
		requests.post('/ElectronicDocument/SearchForDoctor', {
			uinCode: uinCode,
		}),

	getBaseServices: (): Promise<IBaseServices[]> =>
		requests.get('/ElectronicDocument/GetBaseServices'),

	getBaseServicesForMdd: (): Promise<IBaseServices[]> =>
		requests.get('/ElectronicDocument/GetBaseServicesForMdd'),

	getMkbs: (): Promise<IMkb[]> => requests.get('/ElectronicDocument/GetMkbs'),

	getMkbByCode: (code: string): Promise<IMkb> =>
		requests.post('/ElectronicDocument/SearchForMkb', { code: code }),

	getSpecialities: (): Promise<ISpeciality[]> =>
		requests.get('/ElectronicDocument/GetSpecialities'),

	getDocumentTypes: (): Promise<IDocumentType[]> =>
		requests.get('/ElectronicDocument/GetDocumentTypes'),

	getAttachmentTypes: (): Promise<IAttachmentType[]> =>
		requests.get('/ElectronicDocument/GetAttachmentTypes'),

	getById: (id: number) =>
		requests.post('/ElectronicDocument/ById', { id: id }),

	createAttachment: (form: FormData): Promise<IAttachment> =>
		requests.post('/ElectronicDocument/CreateAttachment', form),

	updateAttachment: (
		attachment: IDynamicAttachmentMetadata
	): Promise<IAttachment> =>
		requests.put('/ElectronicDocument/UpdateAttachment', attachment),

	deleteAttachment: (id: number): Promise<boolean> =>
		requests.put('/ElectronicDocument/DeleteAttachment', { id: id }),

	downloadAttachment: (attachment: IDownloadAttachment): Promise<any> =>
		requests.postWithBlobResponse(
			'/ElectronicDocument/DownloadAttachment',
			attachment
		),

	downloadClaimDetailsToExcel: (claimId: number): Promise<any> =>
		requests.postWithBlobResponse(
			'/ElectronicDocument/DownloadClaimDetailsToExcel',
			{ id: claimId }
		),

	downloadClaimAttachmentsToExcel: (claimId: number): Promise<any> =>
		requests.postWithBlobResponse(
			'/ElectronicDocument/DownloadClaimAttachmentsToExcel',
			{ id: claimId }
		),

	createDetail: (detail: IDetail): Promise<number> =>
		requests.post('/ElectronicDocument/CreateDetail', detail),

	updateDetail: (detail: IDetail): Promise<boolean> =>
		requests.put('/ElectronicDocument/UpdateDetail', detail),

	deleteDetail: (id: number): Promise<boolean> =>
		requests.put('/ElectronicDocument/DeleteDetail', { id: id }),

	getElectronicDocumentsForPatient: (
		egn: string
	): Promise<IElectronicDocument[]> =>
		requests.post('/ElectronicDocument/GetElectronicDocumentsForPatient', {
			egn: egn,
		}),

	getAutomatedElectronicDocumentsForPatient: (
		model: IAutomaticSearchRequest
	): Promise<IElectronicDocument[]> =>
		requests.post(
			'/ElectronicDocument/GetAutomatedElectronicDocumentsForPatient',
			model
		),

	searchForPredefinedServiceCorrelations: (
		model: IPredefinedServicesCorrelationsSearchRequest
	): Promise<IDetail> =>
		requests.post(
			'/ElectronicDocument/SearchForPredefinedServiceCorrelation',
			model
		),
	searchForPredefinedServiceCorrelationsForPrevention: (
		model: IPredefinedServicesCorrelationsSearchRequest
	): Promise<IDetail> =>
		requests.post(
			'/ElectronicDocument/SearchForPredefinedServiceCorrelationForPrevention',
			model
		),
};

const Messages = {
	all: (id: number): Promise<IMessage[]> =>
		messagesRequests.post('/MessageApi/GetAllMessages', { id: id }),
};

const Notifications = {
	GetAllNotifications: (): Promise<INotification[]> =>
		messagesRequests.get('/NotificationApi/GetAllNotifications'),

	GetNotReadNotifications: (): Promise<INotification[]> =>
		messagesRequests.get('/NotificationApi/GetNotReadNotifications'),

	GetAllElectronicDocumentsWithUnreadNotifications: (): Promise<number[]> =>
		messagesRequests.get(
			'/NotificationApi/GetAllElectronicDocumentsWithUnreadNotifications'
		),
};

const ServicesCorrelations = {
	GetAll: (): Promise<IServiceCorrelation[]> =>
		requests.get(
			'/ServicesCorrelations/GetAllServicesCorrelationsWithPrices'
		),

	GetAllForMdd: (): Promise<IServiceCorrelation[]> =>
		requests.get(
			'/ServicesCorrelations/GetAllServicesCorrelationsWithPricesForMdd'
		),

	CreateServiceCorrelationWithPrices: (
		serviceCorrelationWithPrices: IServiceCorrelation
	): Promise<number> =>
		requests.post(
			'/ServicesCorrelations/CreateServiceCorrelationWithPrices',
			serviceCorrelationWithPrices
		),

	UpdateServiceCorrelation: (
		serviceCorrelation: IServiceCorrelation
	): Promise<boolean> =>
		requests.put(
			'/ServicesCorrelations/UpdateServiceCorrelation',
			serviceCorrelation
		),

	DeleteServiceCorrelation: (id: number): Promise<boolean> =>
		requests.put('/ServicesCorrelations/DeleteServiceCorrelation', {
			id: id,
		}),

	CreatePrice: (servicePrice: IServicePrice): Promise<number> =>
		requests.post('/ServicesCorrelations/CreatePrice', servicePrice),

	UpdatePrice: (servicePrice: IServicePrice): Promise<boolean> =>
		requests.put('/ServicesCorrelations/UpdatePrice', servicePrice),

	DeletePrice: (id: number): Promise<boolean> =>
		requests.put('/ServicesCorrelations/DeletePrice', { id: id }),

	generatePriceList: (id: number): Promise<IPriceListMain> =>
		requests.post('/ServicesCorrelations/GeneratePriceList', { id: id }),
};

const Settings = {
	GetSettings: (): Promise<ISettings> =>
		requests.get('/Settings/GetSettings'),

	UpdateShowAmbulatorySheets: (value: boolean): Promise<boolean> =>
		requests.post('/Settings/UpdateShowAmbulatorySheets', { value: value }),

	UpdateShowHospitalityComponent: (value: boolean): Promise<boolean> =>
		requests.post('/Settings/UpdateShowHospitalityComponent', {
			value: value,
		}),

	UpdateShowMdd: (value: boolean): Promise<boolean> =>
		requests.post('/Settings/UpdateShowMdd', { value: value }),

	UpdateShowPreventionsComponent: (value: boolean): Promise<boolean> =>
		requests.post('/Settings/UpdateShowPreventionsComponent', {
			value: value,
		}),

	UpdateShowServicesFromShortListOnly: (value: boolean): Promise<boolean> =>
		requests.post('/Settings/UpdateShowServicesFromShortListOnly', {
			value: value,
		}),

	UpdateShowMddFromShortListOnly: (value: boolean): Promise<boolean> =>
		requests.post('/Settings/UpdateShowMddFromShortListOnly', {
			value: value,
		}),

	UpdateShowPreventionFromShortListOnly: (value: boolean): Promise<boolean> =>
		requests.post('/Settings/UpdateShowPreventionFromShortListOnly', {
			value: value,
		}),
};

const XmlImport = {
	getAllByPeriod: (period: Date): Promise<IXmlImportResponse[]> =>
		requests.post('/XmlImport/GetAllByPeriod', { date: period }),
	byId: (id: number): Promise<IXmlImportResponse> =>
		requests.post('/XmlImport/GetById', { id: id }),
	create: (formData: FormData): Promise<number> =>
		requests.post('/XmlImport/Create', formData),
	update: (formData: FormData): Promise<IXmlImportResponse> =>
		requests.put('/XmlImport/Update', formData),
	delete: (id: number): Promise<void> =>
		requests.put('/XmlImport/Delete', { id: id }),
	downloadXml: (xmlImportId: number): Promise<any> =>
		requests.postWithBlobResponse('/XmlImport/DownloadXml', {
			id: xmlImportId,
		}),
};

const Claim = {
	getAllByPeriod: (period: Date): Promise<IClaim[]> =>
		requests.post('/Claims/GetAllByPeriod', { date: period }),
	byId: (id: number): Promise<IClaim> =>
		requests.post('/Claims/GetById', { id: id }),
	create: (request: IClaimRequest): Promise<boolean> =>
		requests.post('/Claims/Create', request),
	delete: (id: number): Promise<boolean> =>
		requests.put('/Claims/Delete', { id: id }),
	setInProcessingStatus: (id: number): Promise<boolean> =>
		requests.put('/Claims/SetInProcessingStatus', { id: id }),
};

const Helpdesk = {
	getAllByPeriod: (period: IByPeriodSearch): Promise<IHelpdeskRequest[]> =>
		requests.post('/Helpdesk/GetAllByPeriod', period),
	byId: (id: number): Promise<IHelpdeskRequest> =>
		requests.post('/Helpdesk/GetById', { id: id }),
	create: (request: IHelpdeskRequest): Promise<IHelpdeskRequest> =>
		requests.post('/Helpdesk/Create', request),
	getCategories: (): Promise<IEnumResponse[]> =>
		requests.get('/Helpdesk/GetCategories'),
	getModules: (): Promise<IEnumResponse[]> =>
		requests.get('/Helpdesk/GetModules'),
};

export default {
	IdentityOperations,
	UserManagement,
	InsuranceStatus,
	MedicalRefferal,
	PriorApproval,
	AmbulatorySheets,
	ElectronicDocument,
	Messages,
	Notifications,
	ServicesCorrelations,
	Settings,
	LaboratoryResults,
	Prevention,
	XmlImport,
	Claim,
	Helpdesk,
	DeseaseHistory
};
