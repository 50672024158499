import React, { useState, useContext } from 'react';
import { Input, InputNumber, Form, TreeSelect, Select } from 'antd';
import { TreeNode } from 'antd/lib/tree-select';
import { FormInstance } from 'antd/lib/form';
import { observer } from 'mobx-react-lite';
import { toJS } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import Constants from '../../../../app/constants/constants';
import { RootStoreContext } from '../../../../app/stores/rootStore';
import { IDetail } from '../../../../app/models/electronicDocument/ElectronicDocument';
import { IPredefinedServicesCorrelationsSearchRequest } from '../../../../app/models/electronicDocument/PredefinedServicesCorrelationsSearchRequest';
import { IServiceCorrelation } from '../../../../app/models/servicesCorrelations/ServiceCorrelation';

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
	editing: boolean;
	dataIndex: string;
	title: any;
	inputType: 'number' | 'text';
	record: IDetail;
	index: number;
	children: React.ReactNode;
	formTmp: FormInstance;
	passedForm: FormInstance;
	focused: boolean;
}

const DeseaseHistoryDetailsCell: React.FC<EditableCellProps> = ({
	editing,
	dataIndex,
	title,
	inputType,
	record,
	index,
	children,
	formTmp,
	passedForm,
	focused,
	...restProps
}) => {
	const [value, setValue] = useState(undefined);

	const rootStore = useContext(RootStoreContext);
	const {
		detailsOriginData,
		setDetailsOriginData,
		detailsEditMode,
		setIsDetailsFormTouched,
		isDetailsFormTouched,
		searchForPredefinedServiceCorrelation,
		electronicDocumentById,
	} = rootStore.electronicDocumentStore;

	const { baseServices } = rootStore.operationsStore;
	const { settings } = rootStore.settingsStore;

	const { Option } = Select;

	// const calculateTotalAmountForCurrentDetail = (countElement: any) => {
	// 	let countValue = countElement.target.value;
	// 	let newData = toJS(detailsOriginData);
	// 	let currentElementIndex = newData!.findIndex(
	// 		(el) => el.key === record.key
	// 	);

	// 	newData![currentElementIndex].count = countValue;
	// 	newData![currentElementIndex].totalAmount = (
	// 		parseInt(countValue) *
	// 		parseFloat(newData![currentElementIndex].price)
	// 	)
	// 		.toFixed(2)
	// 		.toString();
	// 	setDetailsOriginData(newData);
	// 	setIsDetailsFormTouched(true);
	// };

	const onSelectChange = async (value: any, option: any) => {
		let newData = toJS(detailsOriginData);
		let currentElementIndex = newData!.findIndex(
			(el) => el.key === record.key
		);

		newData![currentElementIndex].systemCode = option.systemCode;
		newData![currentElementIndex].code = option.code;
		newData![currentElementIndex].nhifCode = option.nhifCode;
		newData![currentElementIndex].name = value;
		newData![currentElementIndex].description =
			option.additionalInformation;
		formTmp.setFieldsValue({
			description: newData![currentElementIndex].description,
		});
		if (
			option.prices.filter(
				(el) =>
					el.specialityCode ===
					passedForm.getFieldValue('issuerSpecialityCode')
			).length > 0
		) {
			newData![currentElementIndex].price = option.prices.filter(
				(el) =>
					el.specialityCode ===
					passedForm.getFieldValue('issuerSpecialityCode')
			)[0].price;
		} else {
			newData![currentElementIndex].price = option.prices.filter(
				(el) => el.specialityCode.length === 0
			)[0].price;
		}

		formTmp.setFieldsValue({ price: newData![currentElementIndex].price });

		newData![currentElementIndex].totalAmount = (
			1 * parseFloat(newData![currentElementIndex].price)
		)
			.toFixed(2)
			.toString();

		setDetailsOriginData(newData);
		setIsDetailsFormTouched(true);
	};

	const onThreeSelectChange = async (
		treeSelectValue: any,
		label: any,
		extra: any
	) => {
		let newData = toJS(detailsOriginData);
		let currentElementIndex = newData!.findIndex(
			(el) => el.key === record.key
		);

		setValue(treeSelectValue);
		if (treeSelectValue) {
			let systemCode = extra.triggerNode.props.systemCode;

			let searchModel: IPredefinedServicesCorrelationsSearchRequest = {
				baseServiceSystemCode: systemCode,
				insuranceCompanyId: electronicDocumentById
					? electronicDocumentById!.insuranceCompanyId
					: passedForm.getFieldValue('companyName'),
				specialityCode: electronicDocumentById
					? electronicDocumentById!.issuerSpecialityCode
					: passedForm.getFieldValue('issuerSpecialityCode'),
			};

			await searchForPredefinedServiceCorrelation(searchModel);

			if (
				rootStore.electronicDocumentStore
					.serviceCorrelationSearchResult !== undefined
			) {
				let resToJs = toJS(
					rootStore.electronicDocumentStore
						.serviceCorrelationSearchResult
				);
				newData![currentElementIndex].systemCode = systemCode;
				newData![currentElementIndex].code = resToJs.code;
				newData![currentElementIndex].nhifCode = resToJs.nhifCode;
				newData![currentElementIndex].name = resToJs.name;
				newData![currentElementIndex].price = resToJs.price;

				formTmp.setFieldsValue({ price: resToJs.price });

				if (resToJs.price.length === 0) {
					newData![currentElementIndex].totalAmount = '';
				} else {
					newData![currentElementIndex].totalAmount = (
						resToJs.count * parseFloat(resToJs.price)
					)
						.toFixed(2)
						.toString();
				}
			} else {
				let code = extra.triggerNode.props.code;
				let nhifCode = extra.triggerNode.props.nhifCode;
				let name = treeSelectValue;

				newData![currentElementIndex].systemCode = systemCode;
				newData![currentElementIndex].code = code;
				newData![currentElementIndex].nhifCode = nhifCode;
				newData![currentElementIndex].name = name;
			}
		} else {
			newData![currentElementIndex].systemCode = '';
			newData![currentElementIndex].code = '';
			newData![currentElementIndex].nhifCode = '';
			newData![currentElementIndex].name = '';
		}

		setDetailsOriginData(newData);
		setIsDetailsFormTouched(true);
	};

	const inputNode =
		inputType === 'number' ? (
			<Form.Item style={{ margin: 0, width: '100%' }} name={dataIndex}>
				<InputNumber
					defaultValue={0}
					min={1}
					step={1}
					onMouseLeave={() => {
						if (!isDetailsFormTouched) {
							setIsDetailsFormTouched(true);
						}
					}}
				/>
			</Form.Item>
		) : title === 'Дейност' ? (
			!settings.showServicesFromShortListOnly ? (
				<Form.Item
					style={{ margin: 0, minWidth: 300 }}
					name={dataIndex}
					rules={[
						{
							required: true,
							message: Constants.FormMessages.RequiredField,
						},
					]}>
					<TreeSelect
						onMouseEnter={() => {
							if (
								passedForm.getFieldValue('companyName') !==
									undefined &&
								passedForm.getFieldValue(
									'issuerSpecialityCode'
								) !== undefined
							) {
								rootStore.deseaseHistoryStore.setIsLongListIsDisable(
									false
								);
							}
						}}
						autoFocus={
							detailsEditMode && detailsOriginData!.length > 1
						}
						disabled={
							rootStore.deseaseHistoryStore.isLongListIsDisable
						}
						showSearch
						style={{ width: '100%', minWidth: 300 }}
						dropdownStyle={{
							minWidth: '600px',
							maxHeight: 400,
							overflow: 'auto',
						}}
						value={value}
						allowClear
						onChange={(treeSelectValue, label, extra) =>
							onThreeSelectChange(treeSelectValue, label, extra)
						}
						treeNodeFilterProp='title'>
						{baseServices?.map((i) => (
							<TreeNode
								disabled={true}
								value={i.id}
								key={uuidv4()}
								title={
									i.nhifCode === null
										? i.name
										: `${i.name} (${i.nhifCode})`
								}>
								{i.baseServices.map((e) => (
									<TreeNode
										disabled={true}
										value={e.id}
										key={uuidv4()}
										title={
											e.nhifCode === null
												? e.name
												: `${e.name} (${e.nhifCode})`
										}>
										{e.baseServices.map((a) => (
											<TreeNode
												value={a.name}
												key={uuidv4()}
												nhifCode={a.nhifCode}
												systemCode={a.code}
												title={
													a.nhifCode === null
														? a.name
														: `${a.name} (${a.nhifCode})`
												}
											/>
										))}
									</TreeNode>
								))}
							</TreeNode>
						))}
					</TreeSelect>
				</Form.Item>
			) : (
				<Form.Item
					style={{ margin: 0, minWidth: 300 }}
					name={dataIndex}
					rules={[
						{
							required: true,
							message: Constants.FormMessages.RequiredField,
						},
					]}>
					<Select
						showSearch
						optionFilterProp='title'
						onMouseEnter={async () => {
							if (
								rootStore.deseaseHistoryStore
									.filteredServiceCorelations.length === 0
							) {
								formTmp.resetFields();
								await rootStore.deseaseHistoryStore.filterServiceCorelationsOnChangeCompanyName(
									passedForm.getFieldValue('companyName'),
									passedForm.getFieldValue(
										'issuerSpecialityCode'
									)
								);
							}
						}}
						onSelect={(value, option) =>
							onSelectChange(value, option)
						}
						disabled={
							rootStore.electronicDocumentStore
								.electronicDocumentById === undefined &&
							rootStore.deseaseHistoryStore
								.filteredServiceCorelations.length === 0
						}>
						{rootStore.deseaseHistoryStore.filteredServiceCorelations?.map(
							(serviceCorrelation: IServiceCorrelation) => (
								<Option
									key={uuidv4()}
									value={
										serviceCorrelation.baseServiceSystemCode.includes(
											'Z99_99'
										)
											? `${serviceCorrelation.baseServiceName} (${serviceCorrelation.code})`
											: serviceCorrelation.baseServiceName
									}
									code={serviceCorrelation.code}
									nhifCode={serviceCorrelation.nhifCode}
									systemCode={
										serviceCorrelation.baseServiceSystemCode
									}
									prices={toJS(
										serviceCorrelation.servicePrices
									)}
									additionalInformation={
										serviceCorrelation.additionalInformation
									}
									title={
										serviceCorrelation.baseServiceSystemCode.includes(
											'Z99_99'
										)
											? `${serviceCorrelation.baseServiceName} (${serviceCorrelation.additionalInformation})`
											: serviceCorrelation.nhifCode !==
											  null
											? `${serviceCorrelation.baseServiceName} (${serviceCorrelation.nhifCode})`
											: `${serviceCorrelation.baseServiceName}`
									}>
									{serviceCorrelation.baseServiceSystemCode.includes(
										'Z99_99'
									)
										? `${serviceCorrelation.baseServiceName} (${serviceCorrelation.additionalInformation})`
										: serviceCorrelation.nhifCode !== null
										? `${serviceCorrelation.baseServiceName} (${serviceCorrelation.nhifCode})`
										: `${serviceCorrelation.baseServiceName}`}
								</Option>
							)
						)}
					</Select>
				</Form.Item>
			)
		) : dataIndex === 'description' ? (
			<Form.Item
				name={dataIndex}
				style={{ margin: 0, minWidth: 300 }}
				rules={[
					{
						required:
							record && record.systemCode
								? record.systemCode.includes('Z99_99')
								: false,
						message: 'Въведете допълнителна информация!',
					},
				]}>
				<Input
					disabled={
						detailsOriginData[
							detailsOriginData.findIndex(
								(el) => el.key === record.key
							)
						].code?.length > 0 &&
						detailsOriginData[
							detailsOriginData.findIndex(
								(el) => el.key === record.key
							)
						].description?.length > 0
					}
					onChange={() => {
						if (!isDetailsFormTouched) {
							setIsDetailsFormTouched(true);
						}
					}}
				/>
			</Form.Item>
		) : dataIndex === 'price' ? (
			<Form.Item
				name={dataIndex}
				rules={[
					{
						required: true,
						message: ' ',
					},
					{
						pattern: new RegExp('[0-9]+(\\.[0-9][0-9]?)?'),
						message: 'Формат: 0.00',
					},
				]}>
				<Input
					onMouseLeave={() => {
						if (!isDetailsFormTouched) {
							setIsDetailsFormTouched(true);
						}
					}}
					placeholder='0.00'
					disabled={
						detailsOriginData[
							detailsOriginData.findIndex(
								(el) => el.key === record.key
							)
						].code?.length > 0 &&
						detailsOriginData[
							detailsOriginData.findIndex(
								(el) => el.key === record.key
							)
						].price !== '0.00'
					}
				/>
			</Form.Item>
		) : null;

	return (
		<td {...restProps}>
			{editing ? (
				<Form.Item name={dataIndex} style={{ margin: 0 }}>
					{inputNode}
				</Form.Item>
			) : (
				children
			)}
		</td>
	);
};

export default observer(DeseaseHistoryDetailsCell);
