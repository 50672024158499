import { action, configure } from 'mobx';
import ElectronicDocumentStore from './electronicDocumentStore';
import LoadersStore from './loadersStore';
import MedicalReferralStore from './medicalRefferalStore';
import PriorApprovalStore from './priorApprovalStore';
import OperationsStore from './operationsStore';
import { createContext } from 'react';
import InsuranceStatusStore from './insuranceStatusStore';
import UserManagementStore from './userManagementStore';
import IdentityStore from './identityStore';
import MessangerStore from './messangerStore';
import NotificationStore from './notificationStore';
import ServicesCorrelationsStore from './servicesCorrelationsStore';
import SettingsStore from './settingsStore';
import AmbulatorySheetsStore from './ambulatorySheetsStore';
import LaboratoryResultsStore from './laboratoryResultsStore';
import XmlImportStore from './xmlImportStore';
import ClaimStore from './claimStore';
import PreventionsStore from './preventionsStore';
import HelpdeskStore from './helpdeskStore';
import DeseaseHistoryStore from './deseaseHistoryStore';

configure({ enforceActions: 'always' });

export class RootStore {
	electronicDocumentStore: ElectronicDocumentStore;
	loadersStore: LoadersStore;
	medicalReferralStore: MedicalReferralStore;
	priorApprovalStore: PriorApprovalStore;
	operationsStore: OperationsStore;
	insuranceStatusStore: InsuranceStatusStore;
	userManagementStore: UserManagementStore;
	identityStore: IdentityStore;
	messangerStore: MessangerStore;
	notificationStore: NotificationStore;
	servicesCorrelationsStore: ServicesCorrelationsStore;
	settingsStore: SettingsStore;
	ambulatorySheetsStore: AmbulatorySheetsStore;
	laboratoryResultsStore: LaboratoryResultsStore;
	preventionsStore: PreventionsStore;
	xmlImportStore: XmlImportStore;
	claimStore: ClaimStore;
	helpdeskStore: HelpdeskStore;
	deseaseHistoryStore: DeseaseHistoryStore 

	constructor() {
		this.electronicDocumentStore = new ElectronicDocumentStore(this);
		this.loadersStore = new LoadersStore(this);
		this.medicalReferralStore = new MedicalReferralStore(this);
		this.priorApprovalStore = new PriorApprovalStore(this);
		this.operationsStore = new OperationsStore(this);
		this.insuranceStatusStore = new InsuranceStatusStore(this);
		this.userManagementStore = new UserManagementStore(this);
		this.identityStore = new IdentityStore(this);
		this.messangerStore = new MessangerStore(this);
		this.notificationStore = new NotificationStore(this);
		this.servicesCorrelationsStore = new ServicesCorrelationsStore(this);
		this.settingsStore = new SettingsStore(this);
		this.ambulatorySheetsStore = new AmbulatorySheetsStore(this);
		this.laboratoryResultsStore = new LaboratoryResultsStore(this);
		this.preventionsStore = new PreventionsStore(this);
		this.xmlImportStore = new XmlImportStore(this);
		this.claimStore = new ClaimStore(this);
		this.helpdeskStore = new HelpdeskStore(this);
		this.deseaseHistoryStore = new DeseaseHistoryStore(this);
	}

	@action setBaseConfiguration = async () => {
		if (!this.operationsStore.baseServices) {
			await this.operationsStore.getBaseServices();
		}
		if (!this.operationsStore.baseServicesForMdd) {
			await this.operationsStore.getBaseServicesForMdd();
		}

		if (!this.operationsStore.companies) {
			await this.operationsStore.getCompanies();
		}

		if (!this.operationsStore.documetType) {
			await this.operationsStore.getDocumetType();
		}

		if (!this.operationsStore.attachmentType) {
			await this.operationsStore.getAttachmentType();
		}

		if (!this.operationsStore.mkbs) {
			await this.operationsStore.getMkbs();
		}

		if (!this.operationsStore.specialities) {
			await this.operationsStore.getSpecialities();
		}
		if (
			!this.operationsStore.doctorByUin &&
			this.identityStore.currentUser &&
			this.identityStore.currentUser.uinCode
		) {
			await this.operationsStore.getDoctorByUin(
				this.identityStore.currentUser.uinCode
			);
		}
		if (!this.servicesCorrelationsStore.servicesCorrelations) {
			await this.servicesCorrelationsStore.getServicesCorrelations();
		}
		if (!this.settingsStore.settings) {
			await this.settingsStore.getSettings();
		}
		if (!this.operationsStore.helpdeskCategories) {
			await this.operationsStore.getHelpdeskCategories();
		}
		if (!this.operationsStore.helpdeskModules) {
			await this.operationsStore.getHelpdeskModules();
		}
	};
}

export const RootStoreContext = createContext(new RootStore());
