import { DatePicker, Form, Input, Select, Switch } from 'antd';
import React, { useContext } from 'react';
import { FormInstance } from 'antd/lib/form';
import { observer } from 'mobx-react-lite';
import Constants from '../../constants/constants';
import { RootStoreContext } from '../../stores/rootStore';
import { v4 as uuidv4 } from 'uuid';

export interface IProps {
	form: FormInstance;
	label: string;
}

const DocumentNumberAndDateFormItem: React.FC<IProps> = ({ form, label }) => {
	const rootStore = useContext(RootStoreContext);

	const { electronicDocumentById } = rootStore.electronicDocumentStore;

	const { companies } = rootStore.operationsStore;

	const {
		spliceAllFilteredServiceCorrelations,
	} = rootStore.ambulatorySheetsStore;

	const { Option } = Select;
	const documentNumberPadLeftWithZeros = (e: any) => {
		if (e.target.value !== undefined && e.target.value.length > 0) {
			form.setFieldsValue({
				documentNumber: e.target.value.padStart(6, '0'),
			});
		}
	};

	return (
		<Form.Item
			label={label}
			rules={[
				{
					required: true,
				},
			]}>
			<Form.Item
				name='documentNumber'
				className='docNumber-input'
				rules={[
					{
						required: true,
						message: Constants.FormMessages.RequiredField,
					},
					({ getFieldValue }) => ({
						validator(rule, value) {
							const expression = new RegExp('^[0-9]*$');
							if (expression.test(value)) {
								return Promise.resolve();
							}
							form.setFieldsValue({
								documentNumber: '',
							});
							return Promise.reject(
								'Номерът трябва да съдържа само цифри!'
							);
						},
					}),
					{
						max: 6,
						message: 'Номерът трябва да е 6 цифри!',
					},
				]}>
				<Input
					placeholder='Номер'
					onBlur={(e) => documentNumberPadLeftWithZeros(e)}
				/>
			</Form.Item>
			<Form.Item
				name='documentDate'
				className='docDate-input'
				rules={[
					{
						required: true,
						message: Constants.FormMessages.RequiredField,
					},
				]}>
				<DatePicker
					showTime={
						rootStore.electronicDocumentStore.typeOfDocument ===
							Constants.DocumentType.AmbSheet ||
						rootStore.electronicDocumentStore.typeOfDocument ===
							Constants.DocumentType.DeseaseHistory
							? { format: 'HH:mm' }
							: false
					}
					format={
						rootStore.electronicDocumentStore.typeOfDocument ===
							Constants.DocumentType.AmbSheet ||
						rootStore.electronicDocumentStore.typeOfDocument ===
							Constants.DocumentType.DeseaseHistory
							? Constants.Common.FullDate_Format_Without_Seconds
							: Constants.Common.ShortDate_Format
					}
				/>
			</Form.Item>
			<Form.Item name='companyName' className='amb-ins-comp-input'>
				<Select
					onChange={() => {
						if (
							rootStore.ambulatorySheetsStore
								.filteredServiceCorelations !== undefined ||
							rootStore.deseaseHistoryStore
								.filteredServiceCorelations !== undefined
						) {
							spliceAllFilteredServiceCorrelations();
						}
					}}
					showSearch
					optionFilterProp='title'
					placeholder='Застрахователна компания'
					disabled={
						(electronicDocumentById &&
							electronicDocumentById?.isActive === false) ||
						electronicDocumentById?.documentStatus !== undefined
					}>
					{companies?.map((company) => (
						<Option
							key={uuidv4()}
							value={company.id}
							title={company.name}>
							{company.name}
						</Option>
					))}
				</Select>
			</Form.Item>
			<Form.Item
				name='isPrimary'
				hidden={
					rootStore.electronicDocumentStore.typeOfDocument ===
						Constants.DocumentType.LabResults ||
					rootStore.electronicDocumentStore.typeOfDocument ===
						Constants.DocumentType.DeseaseHistory
				}
				style={{
					display:
						rootStore.electronicDocumentStore.typeOfDocument ===
							Constants.DocumentType.LabResults ||
						rootStore.electronicDocumentStore.typeOfDocument ===
							Constants.DocumentType.DeseaseHistory
							? 'none'
							: 'inline-block',
					marginLeft: 10,
				}}>
				<Switch
					defaultChecked={
						electronicDocumentById?.isPrimary
							? electronicDocumentById!.isPrimary
							: true
					}
					checkedChildren='Първичен'
					unCheckedChildren='Вторичен'
					onChange={(value: any) => {
						form.setFieldsValue({ isPrimary: value });
					}}></Switch>
			</Form.Item>
		</Form.Item>
	);
};

export default observer(DocumentNumberAndDateFormItem);
