import React, { Fragment, useContext, useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { Form, Button, Spin, notification } from 'antd';
import {
	IDetail,
	IAttachment,
} from '../../app/models/electronicDocument/ElectronicDocument';
import { PrinterOutlined } from '@ant-design/icons';
import PatientFormItem from '../../app/layout/FormItems/PatientFormItem';
import IsActiveFormItem from '../../app/layout/FormItems/IsActiveFormItem';
import { RootStoreContext } from '../../app/stores/rootStore';
import constants from '../../app/constants/constants';
import moment from 'moment';
import Enums from '../../app/constants/enums';
import AttachmentsFormItem from '../../app/layout/FormItems/AttachmentsFormItem';
import PreventionDateAndCompanyFormItem from '../../app/layout/FormItems/PreventionDateAndCompanyFormItem';
import PreventionsDetailsTable from './details/PreventionsDetailsTable';
import { toJS } from 'mobx';
import { useReactToPrint } from 'react-to-print';
import PrintAmbulatorySheet from '../print/PrintAmbulatorySheet';

const DocumentTypeEnum = Enums.DocumentTypeEnum;

interface IProps {
	closeModal: () => Promise<void>;
	openModal: (id: number | undefined) => Promise<void>;
}
const PreventionsModal: React.FC<IProps> = ({ openModal, closeModal }) => {
	const rootStore = useContext(RootStoreContext);
	const [form] = Form.useForm();

	const {
		electronicDocumentById,
		createElectronicDocument,
		editElectronicDocument,
		detailsOriginData,
		attachmentsOriginData,
		setIsFormTouched,
		setDetailsOriginDataFromElectronicDocument,
		setAttachmentsOriginDataFromElectronicDocument,
	} = rootStore.electronicDocumentStore;

	const { period } = rootStore.preventionsStore;
	const { loadingModal } = rootStore.loadersStore;

	const defaultValues = {
		id: electronicDocumentById?.id,
		documentNumber: electronicDocumentById?.documentNumber,
		documentDate: electronicDocumentById?.documentDate,
		companyName: electronicDocumentById
			? parseInt(electronicDocumentById?.insuranceCompanyId)
			: undefined,
		patientUid: electronicDocumentById?.patientUid,
		patientName: electronicDocumentById?.patientName,
		pacientEmail: electronicDocumentById?.pacientEmail,
		patientCard: electronicDocumentById?.patientCard,
		isActive: electronicDocumentById?.isActive,
		prventionDateRange: electronicDocumentById
			? [
					electronicDocumentById.preventionDateFrom,
					electronicDocumentById.preventionDateTo,
			  ]
			: [],
	};

	const prepareAndSubmitForm = async (
		values: any,
		detailsOriginData: IDetail[],
		attachmentsOriginData: IAttachment[]
	) => {
		const { ...electronicDocumentById } = values;

		if (!electronicDocumentById.id) {
			let newPreventionDocument = {
				...electronicDocumentById,
			};
			values.details = [] as IDetail[];
			values.attachments = [] as IAttachment[];

			detailsOriginData.forEach((el) => {
				values.details.push(el);
			});

			attachmentsOriginData.forEach((el) => {
				values.attachments.push(el);
			});

			newPreventionDocument.documentType = DocumentTypeEnum.Preventions;
			newPreventionDocument.details = values.details;
			newPreventionDocument.attachments = values.attachments;
			newPreventionDocument.preventionDateFrom = moment(
				values.preventionDateRange[0]
			).local();
			newPreventionDocument.preventionDateTo = moment(
				values.preventionDateRange[1]
			).local();

			let formData = new FormData();
			formData.set('request', JSON.stringify(newPreventionDocument));
			newPreventionDocument.attachments.forEach(
				(element: IAttachment) => {
					formData.append(`files`, element.file!);
				}
			);

			let lastRecordId = await createElectronicDocument(
				formData,
				DocumentTypeEnum.Preventions
			);
			if (lastRecordId !== undefined) {
				await rootStore.electronicDocumentStore.getElectronicDocumentById(
					lastRecordId
				);
				rootStore.electronicDocumentStore.setIsFormTouched(false);
				rootStore.electronicDocumentStore.setGetElDocsAgain(true);

				if (
					moment(
						rootStore.electronicDocumentStore.electronicDocumentById
							?.preventionDateFrom
					) < moment(period.dateFrom) ||
					moment(
						rootStore.electronicDocumentStore.electronicDocumentById
							?.preventionDateFrom
					) > moment(period.dateTo)
				) {
					notification.info({
						message:
							'Периодът на този документ е различен от периода, зададен във филтъра на таблицата. За да го видите трябва да смените настройката по период!',
					});
				}
			}
		} else {
			let newPreventionDocument = {
				...electronicDocumentById,
			};
			values.details = [] as IDetail[];
			values.attachments = [] as IAttachment[];

			detailsOriginData.forEach((el) => {
				values.details.push(toJS(el));
			});
			attachmentsOriginData.forEach((el) => {
				values.attachments.push(toJS(el));
			});

			newPreventionDocument.details = values.details;
			newPreventionDocument.attachments = values.attachments;

			if (Number.isInteger(newPreventionDocument.isActive)) {
				newPreventionDocument.isActive =
					newPreventionDocument.isActive === 1 ? true : false;
			}

			await editElectronicDocument(
				newPreventionDocument!,
				DocumentTypeEnum.Preventions
			);
			await rootStore.electronicDocumentStore.getElectronicDocumentById(
				newPreventionDocument.id
			);
			rootStore.electronicDocumentStore.setIsFormTouched(false);
			rootStore.electronicDocumentStore.setGetElDocsAgain(true);
		}
	};

	const onFormFieldsChange = () => {
		setIsFormTouched(true);
	};

	useEffect(() => {
		if (!detailsOriginData) {
			setDetailsOriginDataFromElectronicDocument(electronicDocumentById);
		}
		if (!attachmentsOriginData) {
			setAttachmentsOriginDataFromElectronicDocument(
				electronicDocumentById
			);
		}
	}, [detailsOriginData, attachmentsOriginData]);

	const componentRef = useRef(null);
	const handlePrint = useReactToPrint({
		content: () => componentRef.current!,
	});

	return (
		<Fragment>
			<Spin spinning={loadingModal}>
				<div
					style={{
						textAlign: 'center',
						display:
							electronicDocumentById?.isActive === false
								? 'inherit'
								: 'none',
						color: 'red',
						fontSize: '21px',
					}}>
					{electronicDocumentById?.isActive === false
						? 'Този документ е неактивен!'
						: ''}
				</div>
				<div
					style={{
						display:
							(electronicDocumentById?.claimProcessStatus ===
								constants.ClaimProcessStatus.ChangeRequested &&
								electronicDocumentById?.insuranceCompanyNotes) ||
							(electronicDocumentById?.claimProcessStatus ===
								constants.ClaimProcessStatus.Processed &&
								electronicDocumentById?.insuranceCompanyNotes)
								? 'inherit'
								: 'none',

						fontSize: '15px',
						color: 'red',
					}}>
					Резолюция / Забележки:{' '}
					{electronicDocumentById?.insuranceCompanyNotes}
				</div>
				<Form
					form={form}
					initialValues={defaultValues}
					key={electronicDocumentById?.id}
					onFieldsChange={onFormFieldsChange}
					onFinish={(values) =>
						prepareAndSubmitForm(
							values,
							detailsOriginData!,
							attachmentsOriginData!
						)
					}>
					{rootStore.electronicDocumentStore
						.electronicDocumentById === undefined ? (
						<PreventionDateAndCompanyFormItem form={form} />
					) : null}

					<PatientFormItem form={form} />

					<PreventionsDetailsTable
						passedForm={form}
						openModal={openModal}
						closeModal={closeModal}
					/>
					<AttachmentsFormItem
						form={form}
						openModal={openModal}
						closeModal={closeModal}
					/>
					<IsActiveFormItem form={form} />

					<Form.Item name='id'>
						<div style={{ display: 'none' }}>
							<PrintAmbulatorySheet ref={componentRef} />
						</div>
						<Button
							key='print'
							type='primary'
							className='btn-groupe'
							shape='round'
							style={{
								float: 'left',
								margin: 0,
								display:
									!electronicDocumentById ||
									electronicDocumentById?.isActive === false
										? 'none'
										: 'block',
								backgroundColor: '#234254',
								borderRadius: 20,
							}}
							onClick={handlePrint}>
							<PrinterOutlined /> Принтирай
						</Button>
						<Button
							onMouseEnter={(e) =>
								form.setFieldsValue({
									patientName: form
										.getFieldValue('patientName')!
										.trim(),
								})
							}
							disabled={
								electronicDocumentById?.isActive === false ||
								rootStore.electronicDocumentStore
									.detailsEditMode === true ||
								rootStore.electronicDocumentStore
									.isFormTouched === false
							}
							type='primary'
							shape='round'
							className='btn-primary submit-referral-btn'
							htmlType='submit'
							style={{
								display:
									electronicDocumentById?.isActive === false
										? 'none'
										: 'block',
							}}>
							{electronicDocumentById
								? 'Запази промените'
								: 'Създай документ'}
						</Button>
					</Form.Item>
				</Form>
			</Spin>
		</Fragment>
	);
};

export default observer(PreventionsModal);
