import { action, observable, runInAction } from 'mobx';
import moment from 'moment';
import agent from '../../api/agent';
import { IByPeriodSearch } from '../models/electronicDocument/ByPeriodSearch';
import { IHelpdeskRequest } from '../models/helpdesk/HelpdeskRequest';
import { RootStore } from './rootStore';

export default class HelpdeskStore {
	rootStore: RootStore;

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
	}

	@observable allHelpdeskRequests: IHelpdeskRequest[] | undefined;
	@observable helpdeskById: IHelpdeskRequest | undefined;

	@observable period: IByPeriodSearch = {
		dateFrom: moment().startOf('month').local().toDate(),
		dateTo: moment().endOf('month').local().toDate(),
	};

	@observable isFormTouched = false;

	@action setIsFormTouched = (value: boolean) => {
		this.isFormTouched = value;
	};

	@action setPeriod = (dateFrom: Date, dateTo: Date) => {
		this.period.dateFrom = dateFrom;
		this.period.dateTo = dateTo;
	};

	@action getAllByPeriod = async (period: IByPeriodSearch) => {
		this.rootStore.loadersStore.setLoadingAllTables(true);
		try {
			let res = await agent.Helpdesk.getAllByPeriod(period);
			runInAction(() => {
				this.allHelpdeskRequests = res;
				this.rootStore.loadersStore.setLoadingAllTables(false);
			});
		} catch (error) {
			this.rootStore.loadersStore.setLoadingAllTables(false);
		}
	};

	@action getById = async (id: number | undefined) => {
		if (id) {
			this.rootStore.loadersStore.setLoadingAllTables(true);
			try {
				let res = await agent.Helpdesk.byId(id);
				runInAction(() => {
					this.helpdeskById = res;
					this.rootStore.loadersStore.setLoadingAllTables(false);
				});
			} catch (error) {
				this.rootStore.loadersStore.setLoadingAllTables(false);
			}
		} else {
			this.helpdeskById = undefined;
		}
	};

	@action create = async (values: IHelpdeskRequest) => {
		this.rootStore.loadersStore.setLoadingModal(true);
		try {
			let createdResult = await agent.Helpdesk.create(values);
			this.rootStore.loadersStore.setLoadingModal(false);
			return createdResult;
		} catch (error) {
			this.rootStore.loadersStore.setLoadingModal(false);
		}
	};

	@action setHelpdeskRequest = (helpdeskRequest: IHelpdeskRequest) => {
		this.helpdeskById = helpdeskRequest;
	};

	@action removeAllHelpdeskRequest = () => {
		this.allHelpdeskRequests = undefined;
		this.setPeriod(
			moment().startOf('month').local().toDate(),
			moment().endOf('month').local().toDate()
		);
	};
}
