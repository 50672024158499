import React, { useContext } from 'react';
import { Form, Input } from 'antd';
import { RootStoreContext } from '../../stores/rootStore';
import { observer } from 'mobx-react-lite';
import Constants from '../../constants/constants';
import { FormInstance } from 'antd/lib/form';

export interface IProps {
	form: FormInstance;
}

const PatientHealthStatusFormItem: React.FC<IProps> = ({ form }) => {
	const rootStore = useContext(RootStoreContext);
	const {
		electronicDocumentById,
		attachmentsOriginData,
	} = rootStore.electronicDocumentStore;

	return (
		<Form.Item
			style={{ display: 'block' }}
			name='patientHealthStatus'
			label='Статус:'
			rules={[
				{
					required:
						attachmentsOriginData?.findIndex(
							(el) =>
								el.documentType ===
								Constants.DocumentType.AmbSheet
						) > -1
							? false
							: true,
					message: Constants.FormMessages.RequiredField,
				},
			]}>
			<Input.TextArea
				disabled={
					electronicDocumentById?.isActive === false ||
					electronicDocumentById?.isAutomatic ===
						Constants.ExaminationImportType.Automatic ||
					electronicDocumentById?.isAttachedToClaim
				}
			/>
		</Form.Item>
	);
};

export default observer(PatientHealthStatusFormItem);
