import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { Input, Select, Space, Table } from 'antd';
import { RootStoreContext } from '../../app/stores/rootStore';
import { EyeTwoTone } from '@ant-design/icons';

const ProceduresList: React.FC = () => {
	const rootStore = useContext(RootStoreContext);
	const {
		baseServices,
		baseServicesForProceduresList,
		setServiceCategory,
		setSearchTerm,
		filterBaseServicesForProceduresList,
		resetBaseServicesForProcedureList,
	} = rootStore.operationsStore;

	const { Option } = Select;
	const [rowClassName, setRowClassName] = useState(
		'ant-table-row ant-table-row-level-0'
	);
	const [rowKey, setRowKey] = useState();

	useEffect(() => {
		return () => {
			setServiceCategory('NH');
			setSearchTerm('');
			resetBaseServicesForProcedureList();
		};
	}, []);

	const columns = [
		{
			title: '',
			key: 'id',
			dataIndex: '',
			width: '3%',
			render: (_text: any, row: any, index: any) => (
				<div
					className='table-div-centered eye-icon'
					onClick={() => [
						setRowClassName('ant-table-row-selected'),
						setRowKey(row.key),
					]}>
					{row.baseServices ? (
						row.baseServices.length !== 0 ? (
							<EyeTwoTone twoToneColor='darkgreen' />
						) : (
							''
						)
					) : (
						''
					)}
				</div>
			),
		},
		{
			title: 'Код по МКБ 9КМ / НЗОК',
			width: '10%',
			dataIndex: 'nhifCode',
			key: 'nhifCode',
			render: (_text: any, row: any, index: any) => (
				<div className='table-div-centered'>{row.nhifCode}</div>
			),
		},
		{
			title: 'Системен код',
			width: '10%',
			dataIndex: 'code',
			key: 'code',
			render: (_text: any, row: any, index: any) => (
				<div className='table-div-centered'>{row.code}</div>
			),
		},
		{
			title: ' Дейност',
			width: '77%',
			dataIndex: 'name',
			key: 'name',
		},
	];

	return (
		<div className='home-grid-col-3'>
			<Space className='space-table-head'>
				<Select
					style={{ width: 500 }}
					defaultValue={'NH'}
					onChange={(value) => {
						setServiceCategory(value);
						//resetBaseServicesForProcedureList();
					}}>
					{baseServices?.map((a) => (
						<Option value={a.code}>{a.name}</Option>
					))}
				</Select>{' '}
				<Input.Search
					className='search-input'
					placeholder='Търсене...'
					enterButton
					onSearch={filterBaseServicesForProceduresList}
				/>
			</Space>
			<Table
				scroll={{ y: 'calc(100vh - 206px)' }}
				className='procedures-table'
				columns={columns}
				dataSource={baseServicesForProceduresList}
				pagination={false}
				rowKey='id'
				expandable={{
					childrenColumnName: 'baseServices',
					expandRowByClick: true,
					expandIconColumnIndex: -1,
				}}
				onRow={(record, index) => {
					return {
						onClick: () => {
							setRowClassName(
								'ant-table-row ant-table-row-level-0 ant-table-row-selected'
							);
							setRowKey(record.id.toString());
						},
					};
				}}
				rowClassName={(_record, index) => {
					return _record.id.toString() === rowKey ? rowClassName : '';
				}}
			/>
		</div>
	);
};

export default observer(ProceduresList);
