import React, { Fragment, useContext } from 'react';
import { Row } from 'antd';
import {
	SolutionOutlined,
	FileDoneOutlined,
	SettingOutlined,
	ContainerOutlined,
	FileUnknownOutlined,
	UsergroupAddOutlined,
	AuditOutlined,
	ExperimentOutlined,
	UploadOutlined,
	ProfileOutlined,
	ReconciliationOutlined,
	DesktopOutlined,
	FileSearchOutlined
} from '@ant-design/icons';
import { RootStoreContext } from '../../app/stores/rootStore';
import HomeGridElement from './HomeGridElement';
import { v4 as uuidv4 } from 'uuid';

const Home: React.FC = () => {
	const rootStore = useContext(RootStoreContext);
	const { currentUser } = rootStore.identityStore;

	const { settings } = rootStore.settingsStore;

	return (
		<Fragment>
			<Row gutter={[32, 24]} justify='center'>
				<HomeGridElement
					link='/status'
					icon={<SolutionOutlined />}
					title='Проверка на застрахователен статус'
					style={{}}
					key={uuidv4()}
				/>
				<HomeGridElement
					link='/referral'
					icon={<FileDoneOutlined />}
					title='Медицински назначения'
					style={{}}
					key={uuidv4()}
				/>
				<HomeGridElement
					link='/priorApproval'
					icon={<FileUnknownOutlined />}
					title='Искане за предварително одобрение'
					style={{}}
					key={uuidv4()}
				/>
				<HomeGridElement
					link='/ambulatorySheets'
					icon={<AuditOutlined />}
					title='Амбулаторни листове'
					style={{
						display: settings?.showAmbulatorySheetsComponent
							? 'block'
							: 'none',
					}}
					key={uuidv4()}
				/>
				<HomeGridElement
					link='/laboratoryResults'
					icon={<ExperimentOutlined />}
					title='Медико-диагностична дейност'
					style={{
						display: settings?.showMddComponent ? 'block' : 'none',
					}}
					key={uuidv4()}
				/>
				<HomeGridElement
					link='/diseaseHistory'
					icon={<FileSearchOutlined />}
					title='История на заболяването'
					style={{
						display: settings?.showHospitalityComponent
							? 'block'
							: 'none',
					}}
					key={uuidv4()}
				/>
				<HomeGridElement
					link='/preventions'
					icon={<ReconciliationOutlined />}
					title='Профилактична дейност'
					style={{
						display: settings?.showPreventionsComponent
							? 'block'
							: 'none',
					}}
					key={uuidv4()}
				/>
				<HomeGridElement
					link='/xmlUpload'
					icon={<UploadOutlined />}
					title='Качване на XML'
					style={{}}
					key={uuidv4()}
				/>
				<HomeGridElement
					link='/claims'
					icon={<ContainerOutlined />}
					title='Искове'
					style={{}}
					key={uuidv4()}
				/>

				<HomeGridElement
					link='/settings'
					icon={<SettingOutlined />}
					title='Общи настройки'
					style={{}}
					key={uuidv4()}
				/>
				<HomeGridElement
					link='/procedures'
					icon={<ProfileOutlined />}
					title='Списък с процедури'
					style={{}}
					key={uuidv4()}
				/>
				<HomeGridElement
					link='/helpdesk'
					icon={<DesktopOutlined />}
					title='Помощен център'
					style={{}}
					key={uuidv4()}
				/>
				<HomeGridElement
					link='/accounts'
					icon={<UsergroupAddOutlined />}
					title='Управление на потребители'
					style={{
						display:
							currentUser?.manager?.length > 0 ? 'block' : 'none',
					}}
					key={uuidv4()}
				/>
				{/* <HomeGridElement
					link="/connection"
					icon={<CustomerServiceOutlined />}
					title="Връзка със ЗК"
					style={{}}
					key={uuidv4()}
				/> */}
			</Row>
		</Fragment>
	);
};
export default Home;
