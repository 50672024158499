import { Modal, Table } from 'antd';
import React, { useContext, useState } from 'react';
import Moment from 'react-moment';
import constants from '../../app/constants/constants';
import { RootStoreContext } from '../../app/stores/rootStore';
import {
	FileSearchOutlined,
	CloseCircleOutlined,
	CheckCircleOutlined,
} from '@ant-design/icons';
import AmbulatoryDocumentPreview from '../../app/layout/AmbulatoryDocumentPreview';

interface IProps {
	closeModal: () => Promise<void>;
}

const DocumtentsInClaimTable: React.FC<IProps> = ({ closeModal }) => {
	const rootStore = useContext(RootStoreContext);
	const { claimById } = rootStore.claimStore;
	const { getElectronicDocumentById } = rootStore.electronicDocumentStore;

	const [showAmbulatorySheetModal, setShowAmbulatorySheetModal] = useState(
		false
	);
	const [rowClassName, setRowClassName] = useState(
		'ant-table-row ant-table-row-level-0'
	);
	const [rowKey, setRowKey] = useState();

	const previewAmbulatorySheet = async (id: number) => {
		if (id) {
			await getElectronicDocumentById(id);
		}

		setShowAmbulatorySheetModal(true);
	};

	const columsAmbSheets = [
		{
			title: '№',
			dataIndex: 'documentNumber',
			key: 'documentNumber',
			with: '',
			align: 'center' as 'center',
		},
		{
			title: 'От дата',
			dataIndex: 'documentDate',
			key: '',
			with: '',
			align: 'center' as 'center',
			render: (text: any, row: any, index: any) => (
				<Moment
					format={constants.Common.FullDate_Format_Without_Seconds}>
					{text}
				</Moment>
			),
		},
		{
			title: 'Вид',
			dataIndex:
				claimById?.examinationType === '1' ? 'isPrimaryText' : '',
			key: claimById?.examinationType === '1' ? 'isPrimaryText' : '',
			align: 'center' as 'center',
		},
		{
			title: 'УИН',
			dataIndex: 'issuerUin',
			key: 'issuerUin',
			with: '',
			align: 'center' as 'center',
		},
		{
			title: 'Лекар',
			dataIndex: 'issuerName',
			key: 'issuerName',
			with: '',
			align: 'center' as 'center',
		},
		{
			title: 'Спец.',
			dataIndex: 'issuerSpecialityCode',
			key: 'issuerSpecialityCode',
			with: '',
			align: 'center' as 'center',
		},
		{
			title: 'ЕГН',
			dataIndex: 'patientUid',
			key: 'patientUid',
			with: '',
			align: 'center' as 'center',
		},
		{
			title: 'Име пациент',
			dataIndex: 'patientName',
			key: 'patientName',
			with: '',
			align: 'center' as 'center',
		},
		{
			title: '№ карта',
			dataIndex: 'patientCard',
			key: 'patientCard',
			with: '',
			align: 'center' as 'center',
		},
		{
			title: 'МКБ код',
			dataIndex: 'mkbCode',
			key: 'mkbCode',
			with: '',
			align: 'center' as 'center',
		},
		{
			title: 'МН',
			dataIndex: 'isMedicalReferralAttached',
			key: 'isMedicalReferralAttached',
			with: '',
			align: 'center' as 'center',
			render: (text: any, row: any, index: any) => (
				<div
					style={{
						color: text ? 'green' : 'red',
						fontSize: 16,
					}}>
					{text ? <CheckCircleOutlined /> : <CloseCircleOutlined />}
				</div>
			),
		},
		{
			title: 'ИПО',
			dataIndex: 'isPriorApprovalAttached',
			key: 'isPriorApprovalAttached',
			with: '',
			align: 'center' as 'center',
			render: (text: any, row: any, index: any) => (
				<div
					style={{
						color: text ? 'green' : 'red',
						fontSize: 16,
					}}>
					{text ? <CheckCircleOutlined /> : <CloseCircleOutlined />}
				</div>
			),
		},
		{
			title: 'Сума',
			dataIndex: 'totalAmount',
			key: 'totalAmount',
			with: '',
			align: 'center' as 'center',
		},
		{
			title: 'Отказ. дейн.',
			dataIndex: 'notPayableDetailsCount',
			key: 'notPayableDetailsCount',
			with: '',
			align: 'center' as 'center',
			render: (text: any, row: any, index: any) => (
				<div style={{ color: ' red' }}>
					{' '}
					{claimById?.status ===
						constants.ClaimProcessStatus.Processed ||
					claimById?.status ===
						constants.ClaimProcessStatus.ChangeRequested
						? text
						: null}{' '}
				</div>
			),
		},
		{
			title: 'Сума (нова)',
			dataIndex: 'payableAmount',
			key: 'payableAmount',
			with: '',
			align: 'center' as 'center',
			render: (text: any, row: any, index: any) => (
				<div
					style={{
						color: text !== row.totalAmount ? ' red' : 'black',
					}}>
					{' '}
					{claimById?.status ===
						constants.ClaimProcessStatus.Processed ||
					claimById?.status ===
						constants.ClaimProcessStatus.ChangeRequested
						? text
						: null}{' '}
				</div>
			),
		},
		{
			title: 'Преглед',
			dataIndex: 'id',
			key: 'id',
			with: '',
			align: 'center' as 'center',
			render: (text: any, row: any, index: any) => (
				<FileSearchOutlined
					onClick={() => previewAmbulatorySheet(row?.id)}
					style={{
						color: '#e48800',
						fontSize: 23,
						fontWeight: 700,
					}}
				/>
			),
		},
		{
			title: '',
			dataIndex: 'isForEditing',
			key: 'isForEditing',
			with: '',
			align: 'center' as 'center',
			render: (text: any, row: any, index: any) => (
				<div style={{ color: ' red' }}>
					{' '}
					{claimById?.status ===
						constants.ClaimProcessStatus.Processed ||
					claimById?.status ===
						constants.ClaimProcessStatus.ChangeRequested
						? row?.isForEditing
							? 'За кор.'
							: ' '
						: null}{' '}
				</div>
			),
		},
	];
	const columsMdd = [
		{
			title: '№',
			dataIndex: 'documentNumber',
			key: 'documentNumber',
			align: 'center' as 'center',
		},
		{
			title: 'От дата',
			dataIndex: 'documentDate',
			key: 'documentDate',
			align: 'center' as 'center',
			render: (text: any, row: any, index: any) => (
				<Moment format={constants.Common.ShortDate_Format}>
					{text}
				</Moment>
			),
		},
		{
			title: 'УИН',
			dataIndex: 'issuerUin',
			key: 'issuerUin',
			align: 'center' as 'center',
		},
		{
			title: 'Лекар',
			dataIndex: 'issuerName',
			key: 'issuerName',
			align: 'center' as 'center',
		},
		{
			title: 'Спец.',
			dataIndex: 'issuerSpecialityCode',
			key: 'issuerSpecialityCode',
			align: 'center' as 'center',
		},
		{
			title: 'ЕГН',
			dataIndex: 'patientUid',
			key: 'patientUid',
			align: 'center' as 'center',
		},
		{
			title: 'Име пациент',
			dataIndex: 'patientName',
			key: 'patientName',
			align: 'center' as 'center',
		},
		{
			title: '№ карта',
			dataIndex: 'patientCard',
			key: 'patientCard',
			align: 'center' as 'center',
		},
		{
			title: 'МКБ код',
			dataIndex: 'mkbCode',
			key: 'mkbCode',
			align: 'center' as 'center',
		},
		{
			title: 'МН',
			dataIndex: 'isMedicalReferralAttached',
			key: 'isMedicalReferralAttached',
			align: 'center' as 'center',
			render: (text: any, row: any, index: any) => (
				<div
					style={{
						color: text ? 'green' : 'red',
						fontSize: 16,
					}}>
					{text ? <CheckCircleOutlined /> : <CloseCircleOutlined />}
				</div>
			),
		},
		{
			title: 'Рез.',
			dataIndex: 'isLabResultAttached',
			key: 'isLabResultAttached',
			align: 'center' as 'center',
			render: (text: any, row: any, index: any) => (
				<div
					style={{
						color: text ? 'green' : 'red',
						fontSize: 16,
					}}>
					{text ? <CheckCircleOutlined /> : <CloseCircleOutlined />}
				</div>
			),
		},
		{
			title: 'ИПО',
			dataIndex: 'isPriorApprovalAttached',
			key: 'isPriorApprovalAttached',
			align: 'center' as 'center',
			render: (text: any, row: any, index: any) => (
				<div
					style={{
						color: text ? 'green' : 'red',
						fontSize: 16,
					}}>
					{text ? <CheckCircleOutlined /> : <CloseCircleOutlined />}
				</div>
			),
		},
		{
			title: 'Сума',
			dataIndex: 'totalAmount',
			key: 'totalAmount',
			align: 'center' as 'center',
		},
		{
			title: 'Отказ. дейн.',
			dataIndex: 'notPayableDetailsCount',
			key: 'notPayableDetailsCount',
			align: 'center' as 'center',
			render: (text: any, row: any, index: any) => (
				<div style={{ color: ' red' }}>
					{' '}
					{claimById?.status ===
						constants.ClaimProcessStatus.Processed ||
					claimById?.status ===
						constants.ClaimProcessStatus.ChangeRequested
						? text
						: null}{' '}
				</div>
			),
		},
		{
			title: 'Сума (нова)',
			dataIndex: 'payableAmount',
			key: 'payableAmount',
			align: 'center' as 'center',
			render: (text: any, row: any, index: any) => (
				<div
					style={{
						color: text !== row.totalAmount ? ' red' : 'black',
					}}>
					{' '}
					{claimById?.status ===
						constants.ClaimProcessStatus.Processed ||
					claimById?.status ===
						constants.ClaimProcessStatus.ChangeRequested
						? text
						: null}{' '}
				</div>
			),
		},
		{
			title: 'Преглед',
			dataIndex: 'id',
			key: 'id',
			align: 'center' as 'center',
			render: (text: any, row: any, index: any) => (
				<FileSearchOutlined
					onClick={() => previewAmbulatorySheet(row?.id)}
					style={{
						color: '#e48800',
						fontSize: 23,
						fontWeight: 700,
					}}
				/>
			),
		},
		{
			title: '',
			dataIndex: 'isForEditing',
			key: 'isForEditing',
			align: 'center' as 'center',
			render: (text: any, row: any, index: any) => (
				<div style={{ color: ' red' }}>
					{' '}
					{claimById?.status ===
						constants.ClaimProcessStatus.Processed ||
					claimById?.status ===
						constants.ClaimProcessStatus.ChangeRequested
						? row?.isForEditing
							? 'За кор.'
							: ' '
						: null}{' '}
				</div>
			),
		},
	];

	const columsPrevention = [
		{
			title: '№',
			dataIndex: 'documentNumber',
			key: 'documentNumber',
			align: 'center' as 'center',
		},
		{
			title: 'Дата',
			dataIndex: 'preventionDateFrom',
			key: 'preventionDateFrom',
			render: (row: any, record: any) => (
				<div className='table-div-centered'>
					<Moment format={constants.Common.ShortDate_Format}>
						{record.preventionDateFrom}
					</Moment>{' '}
					-{' '}
					<Moment format={constants.Common.ShortDate_Format}>
						{record.preventionDateTo}
					</Moment>
				</div>
			),
		},
		{
			title: 'ЕГН',
			dataIndex: 'patientUid',
			key: 'patientUid',
			align: 'center' as 'center',
		},
		{
			title: 'Име пациент',
			dataIndex: 'patientName',
			key: 'patientName',
			align: 'center' as 'center',
		},
		{
			title: '№ карта',
			dataIndex: 'patientCard',
			key: 'patientCard',
			align: 'center' as 'center',
		},
		{
			title: 'Карта',
			dataIndex: 'isPreventionCardAttached',
			key: 'isPreventionCardAttached',
			align: 'center' as 'center',
			render: (text: any, row: any, index: any) => (
				<div
					style={{
						color: text ? 'green' : 'red',
						fontSize: 16,
					}}>
					{text ? <CheckCircleOutlined /> : <CloseCircleOutlined />}
				</div>
			),
		},
		{
			title: 'Рез.',
			dataIndex: 'isLabResultAttached',
			key: 'isLabResultAttached',
			align: 'center' as 'center',
			render: (text: any, row: any, index: any) => (
				<div
					style={{
						color: text ? 'green' : 'red',
						fontSize: 16,
					}}>
					{text ? <CheckCircleOutlined /> : <CloseCircleOutlined />}
				</div>
			),
		},
		{
			title: 'Сума',
			dataIndex: 'totalAmount',
			key: 'totalAmount',
			align: 'center' as 'center',
		},
		{
			title: 'Отказ. дейн.',
			dataIndex: 'notPayableDetailsCount',
			key: 'notPayableDetailsCount',
			align: 'center' as 'center',
			render: (text: any, row: any, index: any) => (
				<div style={{ color: ' red' }}>
					{' '}
					{claimById?.status ===
						constants.ClaimProcessStatus.Processed ||
					claimById?.status ===
						constants.ClaimProcessStatus.ChangeRequested
						? text
						: null}{' '}
				</div>
			),
		},
		{
			title: 'Сума (нова)',
			dataIndex: 'payableAmount',
			key: 'payableAmount',
			align: 'center' as 'center',
			render: (text: any, row: any, index: any) => (
				<div
					style={{
						color: text !== row.totalAmount ? ' red' : 'black',
					}}>
					{' '}
					{claimById?.status ===
						constants.ClaimProcessStatus.Processed ||
					claimById?.status ===
						constants.ClaimProcessStatus.ChangeRequested
						? text
						: null}{' '}
				</div>
			),
		},
		{
			title: 'Преглед',
			dataIndex: 'id',
			key: 'id',
			align: 'center' as 'center',
			render: (text: any, row: any, index: any) => (
				<FileSearchOutlined
					onClick={() => previewAmbulatorySheet(row?.id)}
					style={{
						color: '#e48800',
						fontSize: 23,
						fontWeight: 700,
					}}
				/>
			),
		},
		{
			title: '',
			dataIndex: 'isForEditing',
			key: 'isForEditing',
			align: 'center' as 'center',
			render: (text: any, row: any, index: any) => (
				<div style={{ color: ' red' }}>
					{' '}
					{claimById?.status ===
						constants.ClaimProcessStatus.Processed ||
					claimById?.status ===
						constants.ClaimProcessStatus.ChangeRequested
						? row?.isForEditing
							? 'За кор.'
							: ' '
						: null}{' '}
				</div>
			),
		},
	];

	return (
		<div>
			<Table
				onRow={(record, index) => {
					return {
						onClick: () => {
							setRowClassName(
								'ant-table-row ant-table-row-level-0 ant-table-row-selected'
							);
							setRowKey(record.id);
						},
					};
				}}
				rowClassName={(_record, index) => {
					return _record.id === rowKey ? rowClassName : '';
				}}
				rowKey={rowKey}
				className='elDocDetailsTable claim-table-striped'
				columns={
					claimById?.examinationType === '1' || claimById?.examinationType === '4' 
						? columsAmbSheets
						: claimById?.examinationType === '2'
						? columsMdd
						: columsPrevention
				}
				dataSource={claimById?.electronicDocuments}
				pagination={{
					size: 'small',
				}}
			/>
			<div
				style={{
					textAlign: 'end',
					color: 'black',
					marginRight: '5%',
				}}>
				<strong>
					Брой: {claimById?.electronicDocuments.length} Общо:{' '}
					{claimById?.totalAmount}{' '}
					<span
						style={{
							display:
								claimById?.status ===
									constants.ClaimProcessStatus.Processed ||
								claimById?.status ===
									constants.ClaimProcessStatus.ChangeRequested
									? ''
									: 'none',
						}}>
						/ Общо(ново): {claimById?.payableAmount}{' '}
					</span>
				</strong>
			</div>
			<Modal
				className='appointment-modal'
				title={'Преглед на електронен документ'}
				centered
				maskClosable={false}
				transitionName='none'
				maskTransitionName='none'
				onCancel={() => setShowAmbulatorySheetModal(false)}
				visible={showAmbulatorySheetModal}
				footer={false}
				destroyOnClose={true}>
				{' '}
				<AmbulatoryDocumentPreview />
			</Modal>
		</div>
	);
};

export default DocumtentsInClaimTable;
