import React, { useState, Fragment, useContext, useEffect } from 'react';
import { Drawer, Menu, Avatar, Button } from 'antd';
import { NavBar, Icon } from 'antd-mobile';
import {
	LayoutOutlined,
	ContainerOutlined,
	NotificationOutlined,
	UserOutlined,
	LogoutOutlined,
	UsergroupAddOutlined,
	CustomerServiceOutlined,
	ProfileOutlined,
	SettingOutlined,
} from '@ant-design/icons';
import { Link, NavLink } from 'react-router-dom';
import SubMenu from 'antd/lib/menu/SubMenu';
import { useLocation } from 'react-router-dom';
import { RootStoreContext } from '../../../app/stores/rootStore';
import { HubConnectionState } from '@microsoft/signalr';

const DrawerBar: React.FC = () => {
	const [open, setOpen] = useState(false);

	const rootStore = useContext(RootStoreContext);
	const { currentUser, logout, accessToken } = rootStore.identityStore;

	const {
		createNotificationHubConnection,
		notificationHubConnection,
	} = rootStore.notificationStore;
	const {
		createMessangerHubConnection,
		messangerHubConnection,
	} = rootStore.messangerStore;

	useEffect(() => {
		if (rootStore.identityStore.currentUser) {
			if (
				notificationHubConnection?.state !==
				HubConnectionState.Connected
			) {
				createNotificationHubConnection();
			}
			if (
				messangerHubConnection?.state !== HubConnectionState.Connected
			) {
				createMessangerHubConnection();
			}
		}
	}, [createNotificationHubConnection, rootStore.identityStore.currentUser]);

	const onOpenChange = () => {
		setOpen(!open);
	};

	const location = useLocation();

	return (
		<div>
			{accessToken ? (
				<Fragment>
					<NavBar
						icon={<Icon type='ellipsis' />}
						onLeftClick={onOpenChange}
						rightContent={[
							<Button
								className='btn-primary'
								shape='round'
								icon={<LogoutOutlined />}
								onClick={async () => await logout()}></Button>,
						]}>
						<Link to='/profile'>
							<span className='ant-dropdown-link'>
								{currentUser?.userName}
								<Avatar
									className='navBar-avatar'
									icon={<UserOutlined />}
								/>
							</span>
						</Link>
					</NavBar>

					<Drawer
						placement='left'
						closable={false}
						onClose={onOpenChange}
						visible={open}>
						<Menu
							defaultOpenKeys={['sub1']}
							mode='inline'
							theme='dark'
							className='sidebar'
							defaultSelectedKeys={['/']}
							selectedKeys={[location.pathname]}>
							<Menu.Item
								key='/'
								icon={<LayoutOutlined />}
								onClick={onOpenChange}>
								Начало <NavLink to='/' />
							</Menu.Item>

							<SubMenu
								key='sub1'
								icon={<ContainerOutlined />}
								title='Дейности'>
								<Menu.Item key='/status'>
									Проверка на застрахователен статус{' '}
									<NavLink
										to='/status'
										onClick={onOpenChange}
									/>
								</Menu.Item>
								<Menu.Item key='/referral'>
									Медицински назначения{' '}
									<NavLink
										to='/referral'
										onClick={onOpenChange}
									/>
								</Menu.Item>
								<Menu.Item key='/priorApproval'>
									ИПО
									<NavLink
										to='/priorApproval'
										onClick={onOpenChange}
									/>
								</Menu.Item>
								<Menu.Item key='/ambulatorySheets'>
									Амбулаторни листове{' '}
									<NavLink
										to='/ambulatorySheets'
										onClick={onOpenChange}
									/>
								</Menu.Item>
								<Menu.Item key='/laboratoryResults'>
									МДД
									<NavLink to='/laboratoryResults' />
								</Menu.Item>
								<Menu.Item key='/diseaseHistory'>
									История на заболяването{' '}
									<NavLink
										to='/diseaseHistory'
										onClick={onOpenChange}
									/>
								</Menu.Item>
								<Menu.Item key='/preventions'>
									Профилактична дейност
									<NavLink to='/preventions' />
								</Menu.Item>
								<Menu.Item key='/xmlUpload'>
									XML-и <NavLink to='/xmlUpload' />
								</Menu.Item>
								<Menu.Item key='/claims'>
									Искове
									<NavLink
										to='/claims'
										onClick={onOpenChange}
									/>
								</Menu.Item>
							</SubMenu>
							<Menu.Item
								style={{
									display:
										currentUser?.manager?.length > 0
											? 'block'
											: 'none',
								}}
								key='/accounts'
								icon={<UsergroupAddOutlined />}
								onClick={onOpenChange}>
								Потребители <NavLink to='/accounts' />
							</Menu.Item>
							<Menu.Item
								key='/profile'
								icon={<UserOutlined />}
								onClick={onOpenChange}>
								Профил <NavLink to='/profile' />
							</Menu.Item>
							<Menu.Item
								key='/notifications'
								icon={<NotificationOutlined />}
								onClick={onOpenChange}>
								Известия <NavLink to='/notifications' />
							</Menu.Item>

							<Menu.Item
								key='/settings'
								icon={<SettingOutlined />}>
								Общи настройки
								<NavLink
									to='/settings'
									onClick={onOpenChange}
								/>
							</Menu.Item>
							<Menu.Item
								key='/procedures'
								icon={<ProfileOutlined />}>
								Списък с процедури
								<NavLink to='/procedures' />
							</Menu.Item>

							<Menu.Item
								key='/connection'
								icon={<CustomerServiceOutlined />}
								onClick={onOpenChange}>
								Връзка с ЗК <NavLink to='/connection' />
							</Menu.Item>
						</Menu>
					</Drawer>
				</Fragment>
			) : (
				<div style={{ display: 'none' }}></div>
			)}
		</div>
	);
};
export default DrawerBar;
