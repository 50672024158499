import { Button, Form, Select, Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { RootStoreContext } from '../../app/stores/rootStore';
import { v4 as uuidv4 } from 'uuid';
import { IClaimRequest } from '../../app/models/claims/ClaimRequest';

interface IProps {
	closeModal: () => Promise<void>;
}

const PrepareClaimsModal: React.FC<IProps> = ({ closeModal }) => {
	const [form] = Form.useForm();
	const rootStore = useContext(RootStoreContext);

	const { period, createClaim, getAllClaims } = rootStore.claimStore;

	const { companies } = rootStore.operationsStore;

	const examinations = [
		{ value: 1, label: 'Амбулаторна' },
		{ value: 2, label: 'МДД' },
		{ value: 3, label: 'Профилактична' },
		{ value: 4, label: 'Болнична' },
	];

	const { Option } = Select;

	const createClaimOnFormSubmit = async (values) => {
		let requestModel: IClaimRequest = {
			examinationType: values.examinationType,
			insuranceCompanyId: values.insuranceCompanyName,
			period: period,
		};

		await createClaim(requestModel);
		await getAllClaims(rootStore.claimStore.period);
		await closeModal();
	};

	return (
		<Spin spinning={rootStore.loadersStore.loadingModal}>
			<Form
				form={form}
				layout='vertical'
				onFinish={(values) => createClaimOnFormSubmit(values)}>
				<Form.Item
					label='Застрахователна компания'
					name='insuranceCompanyName'>
					<Select
						showSearch
						optionFilterProp='title'
						placeholder='Застрахователна компания'>
						{companies?.map((company) => (
							<Option
								key={uuidv4()}
								value={company.id}
								title={company.name}>
								{company.name}
							</Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item label='Тип на дейността' name='examinationType'>
					<Select
						showSearch
						optionFilterProp='title'
						placeholder='Тип на дейността'>
						{examinations?.map((examination) => (
							<Option
								key={uuidv4()}
								value={examination.value}
								title={examination.label}>
								{examination.label}
							</Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item>
					<Button
						className='btn-primary'
						type='primary'
						htmlType='submit'>
						Създай
					</Button>
				</Form.Item>
			</Form>
		</Spin>
	);
};

export default observer(PrepareClaimsModal);
