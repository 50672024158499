import React, { Fragment } from 'react';
import { observer } from 'mobx-react-lite';
import { Row, Col, Statistic } from 'antd';
import { FileAddOutlined, FileSearchOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import Avatar from 'antd/lib/avatar/avatar';

const MedicalReferral: React.FC = () => {
	return (
		<Fragment>
			<Row gutter={[32, 24]} justify='center'>
				<Col xs={24} sm={12} md={12} lg={12} xl={6}>
					<Link to='/referral/create'>
						<div>
							<div className='home-grid-col-1'>
								<Avatar
									className='home-grid-avatar'
									size={64}
									icon={<FileAddOutlined />}
								/>

								<Statistic
									title='Създаване на медицинско назначение '
									value={' '}
								/>
							</div>
						</div>
					</Link>
				</Col>

				<Col xs={24} sm={12} md={12} lg={12} xl={6}>
					<div>
						<Link to='/referral/check'>
							<div className='home-grid-col-1'>
								<Avatar
									className='home-grid-avatar'
									size={64}
									icon={<FileSearchOutlined />}
								/>
								<Statistic
									title='Запазване на медицинско назначение'
									value={' '}
								/>
							</div>
						</Link>
					</div>
				</Col>
			</Row>
		</Fragment>
	);
};

export default observer(MedicalReferral);
