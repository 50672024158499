import React, { Fragment } from 'react';
import { observer } from 'mobx-react-lite';
import { Row, Col, Typography } from 'antd';
import InsuranceStatusForm from './InsuranceStatusForm';
import InsuranceStatusTable from './InsuranceStatusTable';

const InsuranceStatus: React.FC = () => {
	const { Title } = Typography;

	return (
		<Fragment>
			<Row gutter={[24, 24]} justify='center'>
				<Col xs={24} sm={24} md={24} lg={24} xl={7}>
					<div>
						<div className='status-grid-col-1'>
							<Title level={3}>Проверка на статус</Title>
							<InsuranceStatusForm />
						</div>
					</div>
				</Col>

				<Col xs={24} sm={24} md={24} lg={24} xl={16}>
					<div>
						<div className='status-grid-col'>
							<Title level={3}>Последни статуси</Title>
							<InsuranceStatusTable />
						</div>
					</div>
				</Col>
			</Row>
		</Fragment>
	);
};
export default observer(InsuranceStatus);
