import { Form, Input, Select } from 'antd';
import { FormInstance } from 'antd/lib/form/Form';
import React, { useContext, useEffect, useState } from 'react';
import { Picker, List } from 'antd-mobile';
import { RootStoreContext } from '../../stores/rootStore';
import { observer } from 'mobx-react-lite';
import Constants from '../../constants/constants';
import { v4 as uuidv4 } from 'uuid';

export interface IProps {
	form: FormInstance;
}
const MkbsFormItem: React.FC<IProps> = ({ form }) => {
	const rootStore = useContext(RootStoreContext);
	const { electronicDocumentById } = rootStore.electronicDocumentStore;
	const { mkbs } = rootStore.operationsStore;

	const { Option } = Select;

	const [isMobile, setIsMobile] = useState(false);

	const isFormEditingDisabled =
		electronicDocumentById?.documentStatus &&
		electronicDocumentById?.documentStatus !==
			Constants.DocumentProcessStatus.Registered;

	const handleWindowResize = () => {
		setIsMobile(window.innerWidth < 770);
	};

	useEffect(() => {
		handleWindowResize();
		window.addEventListener('resize', handleWindowResize);
	}, []);

	const searchForMkb = (code: string) => {
		let mkb = mkbs!.find((el) => el.code === code);
		form.setFieldsValue({
			mkbName: mkb?.name,
		});
	};

	const dataMkbs = mkbs?.map((p) => ({
		value: p.code,
		label: ` ${p.code} - ${p.name}`,
		children: [],
	}));

	return (
		<Form.Item
			label='Диагноза: '
			rules={[
				{
					required: true,
				},
			]}>
			<Form.Item
				name='mkbCode'
				className='mkbCode-input'
				rules={[
					{
						required: true,
						message: Constants.FormMessages.RequiredField,
					},
					({ getFieldValue }) => ({
						validator(rule, value) {
							if (value.length === 3 || value.length === 5) {
								return Promise.resolve();
							}
							return Promise.reject(
								'Кодът трябва да е 3 или 5 символа!'
							);
						},
					}),
				]}>
				<Input
					placeholder='МКБ код'
					onChange={(e: any) => {
						if (e.target.value.length === 5) {
							searchForMkb(e.target.value);
						} else if (e.target.value.length !== 3) {
							form.setFieldsValue({
								mkbName: '',
							});
						}
					}}
					onBlur={(e: any) => {
						if (e.target.value.length === 3) {
							searchForMkb(e.target.value);
						} else if (e.target.value.length !== 5) {
							form.setFieldsValue({
								mkbName: '',
							});
						}
					}}
				/>
			</Form.Item>
			<Form.Item
				name='mkbName'
				className='mkbName-input'
				rules={[
					{
						required: true,
						message: Constants.FormMessages.RequiredField,
					},
					({ isFieldsTouched }) => ({
						validator(rule, value) {
							if (isFieldsTouched() === false) {
								return Promise.reject();
							}
							return Promise.resolve();
						},
					}),
				]}>
				{!isMobile ? (
					<Select
						showSearch
						optionFilterProp='title'
						placeholder='МКБ код и наименование'
						disabled={
							electronicDocumentById?.status ===
								Constants.MedicalReferralStatus.Performed ||
							electronicDocumentById?.isActive === false ||
							isFormEditingDisabled ||
							electronicDocumentById?.isAutomatic ===
								Constants.ExaminationImportType.Automatic ||
							electronicDocumentById?.isAttachedToClaim
						}
						onSelect={(option: any) => {
							form.setFieldsValue({
								mkbCode: option,
							});
						}}>
						{mkbs?.map((mkb: any) => (
							<Option
								key={uuidv4()}
								value={mkb.code}
								title={mkb.code + '-' + mkb.name}>
								{mkb.code + '-' + mkb.name}
							</Option>
						))}
					</Select>
				) : (
					<Picker
						data={dataMkbs!}
						cols={1}
						extra={
							electronicDocumentById
								? electronicDocumentById.mkbName
								: 'Избери'
						}
						okText='Избери'
						dismissText=' Обратно'
						disabled={
							electronicDocumentById?.status ===
								Constants.MedicalReferralStatus.Performed ||
							electronicDocumentById?.isActive === false ||
							isFormEditingDisabled ||
							electronicDocumentById?.isAutomatic ===
								Constants.ExaminationImportType.Automatic ||
							electronicDocumentById?.isAttachedToClaim
						}>
						<List.Item key={uuidv4()} arrow='horizontal' extra=''>
							{' '}
						</List.Item>
					</Picker>
				)}
			</Form.Item>
		</Form.Item>
	);
};

export default observer(MkbsFormItem);
