import { Button, Form, Popconfirm, Table, Tooltip } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import AmbSheetsDetailsCell from './AmbSheetsDetailsCell';
import {
	FormOutlined,
	DeleteOutlined,
	CloseOutlined,
	CheckCircleOutlined,
} from '@ant-design/icons';
import { toJS } from 'mobx';
import { IDetail } from '../../../../app/models/electronicDocument/ElectronicDocument';
import { RootStoreContext } from '../../../../app/stores/rootStore';
import Constants from '../../../../app/constants/constants';
interface IProps {
	passedForm: FormInstance;
	closeModal: () => Promise<void>;
	openModal: (id: number | undefined) => Promise<void>;
}

const AmbSheetsDetailsTable: React.FC<IProps> = ({
	passedForm,
	openModal,
	closeModal,
}) => {
	const rootStore = useContext(RootStoreContext);

	const {
		detailsOriginData,
		electronicDocumentById,
		setDetailsOriginData,
		setDetailsEditMode,
		createDetail,
		updateDetail,
		deleteDetail,
		isDetailsFormTouched,
		setIsDetailsFormTouched,
		attachmentsOriginData,
		setAttachmentsOriginData,
		setAttachmentTableVisible,
	} = rootStore.electronicDocumentStore;

	const { loadingAllTable } = rootStore.loadersStore;
	const {
		detailsCount,
		setCount,
		detailsEditingKey,
		setEditingKey,
		setAttachCount,
	} = rootStore.ambulatorySheetsStore;

	let [formA] = Form.useForm(rootStore.ambulatorySheetsStore.detailsForm);
	const [temporaryData, setTemporaryData] = useState([] as IDetail[]);

	const [rowClassName, setRowClassName] = useState(
		'ant-table-row ant-table-row-level-0'
	);
	const [rowKey, setRowKey] = useState();
	const [editMode, setEditMode] = useState(false);

	const isEditing = (record: IDetail) => record.key === detailsEditingKey;

	useEffect(() => {
		setCount(
			electronicDocumentById
				? electronicDocumentById.details.length + 1
				: 2
		);
		setEditingKey(electronicDocumentById ? '' : '1');
	}, []);

	const edit = (record: IDetail) => {
		setEditMode(true);
		if (electronicDocumentById) {
			setTemporaryData(electronicDocumentById!.details);
		} else {
			setTemporaryData(detailsOriginData!);
		}
		setDetailsEditMode(true);
		formA.setFieldsValue({ ...record });
		setEditingKey(record.key);
		setIsDetailsFormTouched(false);
	};

	const handleAdd = () => {
		setDetailsEditMode(true);
		setTemporaryData(detailsOriginData!);
		const newData = [
			...detailsOriginData!,
			{
				key: detailsCount.toString(),
				systemCode: '',
				code: '',
				nhifCode: '',
				name: '',
				count: 1,
				description: '',
				toMainId: 0,
				id: 0,
				lastModifiedByUsername: ' ',
				lastModifiedOn: null,
				price: '',
				totalAmount: '0.00',
				isPayable: true,
				specialityCode: '',
				specialityName: '',
			},
		];
		setDetailsOriginData(newData);
		formA.resetFields();
		if (electronicDocumentById) {
			setCount(newData!.length + 1);
		} else {
			setCount(detailsCount + 1);
		}

		setEditingKey(detailsCount.toString());
		setIsDetailsFormTouched(false);
	};

	const handleCancel = (record: IDetail) => {
		let newData = toJS(temporaryData);
		for (let index = 0; index < newData.length; index++) {
			newData[index].key = (index + 1).toString();
		}
		setCount(newData!.length + 1);
		setDetailsOriginData(newData);
		setEditingKey('');
		setDetailsEditMode(false);
		setEditMode(false);
		setIsDetailsFormTouched(false);
	};

	const save = async (key: React.Key) => {
		try {
			const row = (await formA.validateFields()) as IDetail;
			row.count = row.count ? parseFloat(row.count.toString()) : 1;
			const newData = toJS(detailsOriginData);

			const index = newData!.findIndex((item) => key === item.key);
			if (electronicDocumentById) {
				const item = newData![index];
				item.name = row.name;
				item.description = row.description;
				item.count = row.count;
				item.price = row.price;

				item.toMainId = electronicDocumentById.id;
				if (editMode) {
					await updateDetail(item);
				} else {
					await createDetail(item);
				}
			} else {
				newData![index].name = row.name;
				newData![index].description = row.description;
				newData![index].count = row.count;
				setDetailsOriginData(newData);
				setEditMode(false);
			}
			setEditingKey('');
			setDetailsEditMode(false);
			setIsDetailsFormTouched(false);
			setEditMode(false);
			if (!electronicDocumentById && attachmentsOriginData?.length > 0) {
				setAttachmentsOriginData([]);
				setAttachCount(1);
				setAttachmentTableVisible(false);
			}
		} catch (errInfo) {
			console.log('Validate Failed:', errInfo);
		}
	};

	const handleDelete = async (key: any) => {
		let newData = toJS(detailsOriginData)!.filter(
			(item) => item.key !== key
		);
		for (let index = 0; index < newData.length; index++) {
			newData[index].key = (index + 1).toString();
		}

		if (electronicDocumentById) {
			let currentDetail = toJS(detailsOriginData)!.filter(
				(item) => item.key === key
			)[0];
			await deleteDetail(currentDetail.id);
			setEditingKey('');
			setDetailsEditMode(false);
			setIsDetailsFormTouched(false);
			setCount(detailsCount - 1);
		} else {
			setDetailsOriginData(newData);
			setCount(detailsCount - 1);
		}
		if (!electronicDocumentById && attachmentsOriginData?.length > 0) {
			setAttachmentsOriginData([]);
			setAttachCount(1);
			setAttachmentTableVisible(false);
		}
		setIsDetailsFormTouched(false);
	};

	const onSaveButtonMouseEnter = async (record) => {
		let inputPriceValue = formA.getFieldValue('price');
		let inputCountValue = formA.getFieldValue('count')
			? formA.getFieldValue('count')
			: 1;
		let priceRegex = new RegExp('[0-9]+(\\.[0-9][0-9]?)?');
		if (
			(inputPriceValue?.length > 0 || inputCountValue.lenght > 0) &&
			inputPriceValue.match(priceRegex)
		) {
			let formattedPrice = parseFloat(inputPriceValue)
				.toFixed(2)
				.toString();

			let newData = toJS(detailsOriginData);
			let currentElementIndex = newData!.findIndex(
				(el) => el.key === record.key
			);

			formA.setFieldsValue({ price: formattedPrice });
			formA.setFieldsValue({ count: parseFloat(inputCountValue) });

			newData![currentElementIndex].price = formattedPrice;
			newData![currentElementIndex].count = parseFloat(inputCountValue);
			newData![currentElementIndex].totalAmount = (
				parseInt(inputCountValue) *
				parseFloat(newData![currentElementIndex].price)
			)
				.toFixed(2)
				.toString();

			setDetailsOriginData(newData);
			setIsDetailsFormTouched(true);
		}
	};

	const columns = [
		{
			dataIndex: 'systemCode',
			className: 'hide-col',
			width: '0%',
		},
		{
			title: 'No',
			dataIndex: 'key',
			width: '4%',
			render: (row: any, record: any) => {
				return <div className='table-div-centered'>{record.key}</div>;
			},
		},
		{
			title: 'Дейност',
			dataIndex: 'name',
			width: '30%',
			editable: true,
			render: (row: any, record: any) => {
				return <div style={{ minWidth: '300px' }}>{record.name}</div>;
			},
		},
		{
			title: 'Допълнителна информация',
			dataIndex: 'description',
			width: '25%',
			editable: true,
			render: (row: any, record: any) => {
				return (
					<div style={{ minWidth: '200px' }}>
						{record.description}
					</div>
				);
			},
		},

		{
			title: ' Код по МКБ 9КМ / НЗОК',
			dataIndex: 'nhifCode',
			width: '7%',
			render: (row: any, record: any) => {
				return (
					<div className='table-div-centered'>{record.nhifCode}</div>
				);
			},
		},
		{
			title: ' Код ЛЗ',
			dataIndex: 'code',
			width: '7%',
			render: (row: any, record: any) => {
				return <div className='table-div-centered'>{record.code}</div>;
			},
		},

		{
			title: 'Брой',
			dataIndex: 'count',
			width: '7%',
			editable: true,
			render: (row: any, record: any) => {
				return <div className='table-div-centered'>{record.count}</div>;
			},
		},
		{
			title: ' Ед. цена',
			dataIndex: 'price',
			width: '7%',
			editable: true,
			render: (row: any, record: any) => {
				return <div style={{ float: 'right' }}>{record.price}</div>;
			},
		},
		{
			title: 'Сума',
			dataIndex: 'totalAmount',
			width: '7%',
			render: (row: any, record: any) => {
				return (
					<div style={{ float: 'right' }}>
						{isNaN(record.totalAmount)
							? '0.00'
							: record.totalAmount}
					</div>
				);
			},
		},
		{
			title: '',
			dataIndex: 'operation',
			className:
				electronicDocumentById?.claimProcessStatus ===
					Constants.ClaimProcessStatus.ChangeRequested ||
				electronicDocumentById?.claimProcessStatus ===
					Constants.ClaimProcessStatus.Processed
					? 'hide-col'
					: '',
			width:
				electronicDocumentById?.claimProcessStatus ===
					Constants.ClaimProcessStatus.ChangeRequested ||
				electronicDocumentById?.claimProcessStatus ===
					Constants.ClaimProcessStatus.Processed
					? '0%'
					: '',
			render: (_: any, record: IDetail) => {
				const editable = isEditing(record);
				return editable ? (
					<div className='table-div-centered'>
						<Tooltip
							title='Запази'
							destroyTooltipOnHide={
								electronicDocumentById?.isActive === false ||
								electronicDocumentById?.isAutomatic ===
									Constants.ExaminationImportType.Automatic ||
								electronicDocumentById?.isAttachedToClaim ||
								!isDetailsFormTouched ||
								record.systemCode.length === 0
							}>
							<CheckCircleOutlined
								onClick={() => save(record.key)}
								onMouseEnter={() =>
									onSaveButtonMouseEnter(record)
								}
								hidden={
									electronicDocumentById?.isActive ===
										false ||
									electronicDocumentById?.isAutomatic ===
										Constants.ExaminationImportType
											.Automatic ||
									electronicDocumentById?.isAttachedToClaim
								}
								disabled={
									!isDetailsFormTouched ||
									record.systemCode.length === 0
								}
								style={{
									fontSize: 16,
									fontWeight: 700,
									color:
										!isDetailsFormTouched ||
										record.systemCode.length === 0 ||
										record.price.length === 0 ||
										record.price === '0.00'
											? 'grey'
											: 'green',
								}}
							/>
						</Tooltip>
					</div>
				) : (
					<div className='table-div-centered'>
						<Tooltip
							title='Редактирай'
							destroyTooltipOnHide={
								detailsEditingKey !== '' ||
								electronicDocumentById?.isActive === false ||
								electronicDocumentById?.isAutomatic ===
									Constants.ExaminationImportType.Automatic ||
								electronicDocumentById?.isAttachedToClaim
							}>
							<FormOutlined
								disabled={detailsEditingKey !== ''}
								onClick={() => edit(record)}
								hidden={
									electronicDocumentById?.isActive ===
										false ||
									electronicDocumentById?.isAutomatic ===
										Constants.ExaminationImportType
											.Automatic ||
									electronicDocumentById?.isAttachedToClaim
								}
								style={{
									fontSize: 16,
									fontWeight: 700,
									color:
										detailsEditingKey !== ''
											? 'grey'
											: 'blue',
								}}
							/>
						</Tooltip>
					</div>
				);
			},
		},
		{
			title: '',
			dataIndex: 'operation',
			className:
				electronicDocumentById?.claimProcessStatus ===
					Constants.ClaimProcessStatus.ChangeRequested ||
				electronicDocumentById?.claimProcessStatus ===
					Constants.ClaimProcessStatus.Processed
					? 'hide-col'
					: '',
			width:
				electronicDocumentById?.claimProcessStatus ===
					Constants.ClaimProcessStatus.ChangeRequested ||
				electronicDocumentById?.claimProcessStatus ===
					Constants.ClaimProcessStatus.Processed
					? '0%'
					: ' ',
			render: (_: any, record: IDetail) => {
				const editable = isEditing(record);
				return editable ? (
					<div
						className='table-div-centered'
						style={{
							display:
								(detailsOriginData?.length === 1 &&
									!electronicDocumentById) ||
								detailsOriginData![0].name === ''
									? 'none'
									: 'block',
						}}>
						<Tooltip title='Отказ'>
							<CloseOutlined
								onClick={() => handleCancel(record)}
								hidden={
									electronicDocumentById?.isActive ===
										false ||
									electronicDocumentById?.isAutomatic ===
										Constants.ExaminationImportType
											.Automatic ||
									electronicDocumentById?.isAttachedToClaim
								}
								style={{
									color: 'red',
									fontSize: 16,
									fontWeight: 700,
								}}
							/>
						</Tooltip>
					</div>
				) : detailsOriginData!.length >= 1 ? (
					<div className='table-div-centered'>
						<Popconfirm
							title='Сигурни ли сте, че искате да изтриете реда?'
							onConfirm={() => handleDelete(record.key)}
							disabled={
								detailsEditingKey !== '' ||
								detailsOriginData?.length === 1
							}>
							<Tooltip
								title='Изтриване'
								destroyTooltipOnHide={
									detailsEditingKey !== '' ||
									detailsOriginData?.length === 1 ||
									electronicDocumentById?.isActive ===
										false ||
									electronicDocumentById?.isAutomatic ===
										Constants.ExaminationImportType
											.Automatic ||
									electronicDocumentById?.isAttachedToClaim
								}>
								<DeleteOutlined
									disabled={
										detailsEditingKey !== '' ||
										detailsOriginData?.length === 1
									}
									hidden={
										electronicDocumentById?.isActive ===
											false ||
										electronicDocumentById?.isAutomatic ===
											Constants.ExaminationImportType
												.Automatic ||
										electronicDocumentById?.isAttachedToClaim
									}
									style={{
										color:
											detailsEditingKey !== '' ||
											detailsOriginData?.length === 1
												? 'grey'
												: 'red',
										fontSize: 16,
										fontWeight: 700,
									}}
								/>
							</Tooltip>
						</Popconfirm>
					</div>
				) : null;
			},
		},
		{
			dataIndex: 'isPayable',
			className:
				electronicDocumentById?.claimProcessStatus ===
					Constants.ClaimProcessStatus.ChangeRequested ||
				electronicDocumentById?.claimProcessStatus ===
					Constants.ClaimProcessStatus.Processed
					? ''
					: 'hide-col',
			width:
				electronicDocumentById?.claimProcessStatus ===
					Constants.ClaimProcessStatus.ChangeRequested ||
				electronicDocumentById?.claimProcessStatus ===
					Constants.ClaimProcessStatus.Processed
					? '6%'
					: '0%',
			render: (_: any, record: IDetail) => (
				<div className='table-div-centered' style={{ color: 'red' }}>
					{record.isPayable ? '' : 'Отказана'}
				</div>
			),
		},
	];

	const mergedColumns = columns.map((col) => {
		if (!col.editable) {
			return col;
		}
		return {
			...col,
			onCell: (record: any) => ({
				record,
				inputType: col.dataIndex === 'count' ? 'number' : 'text',
				dataIndex: col.dataIndex,
				title: col.title,
				editing: isEditing(record),
				formTmp: formA,
				passedForm: passedForm,
			}),
		};
	});
	return (
		<Form.Item
			name='details'
			style={{ display: 'block', marginTop: 14 }}
			label='Дейности:'>
			<Form form={formA} component={false}>
				<Button
					onClick={() => {
						handleAdd();
					}}
					type='primary'
					className='add-detail-btn'
					disabled={detailsEditingKey !== ''}
					hidden={
						electronicDocumentById?.isActive === false ||
						electronicDocumentById?.isAutomatic ===
							Constants.ExaminationImportType.Automatic ||
						electronicDocumentById?.isAttachedToClaim
					}>
					Добави нова дейност
				</Button>

				<Table
					onRow={(record, index) => {
						return {
							onDoubleClick: () => {
								setRowClassName(
									'ant-table-row ant-table-row-level-0 ant-table-row-selected'
								);
								setRowKey(record.key);
							},
						};
					}}
					components={{
						body: {
							cell: AmbSheetsDetailsCell,
						},
					}}
					bordered
					loading={loadingAllTable}
					dataSource={detailsOriginData}
					columns={mergedColumns}
					pagination={false}
					rowClassName={(_record, index) => {
						return _record.key === rowKey ? rowClassName : '';
					}}
					footer={(records: IDetail[]) => (
						<div className='detail-table-footer'>
							<div>
								Общо:{' '}
								{records !== undefined &&
								records.length !== 0 &&
								records[0].price.length > 0
									? !isNaN(
											records
												?.map((a) =>
													parseFloat(a.totalAmount)
												)
												.reduce((a, b) => a + b)
									  )
										? records
												?.map((a) =>
													parseFloat(a.totalAmount)
												)
												.reduce((a, b) => a + b)
												.toFixed(2)
										: (0.0).toFixed(2)
									: (0.0).toFixed(2)}{' '}
								<span
									style={{
										display:
											electronicDocumentById?.claimProcessStatus ===
												Constants.ClaimProcessStatus
													.ChangeRequested ||
											electronicDocumentById?.claimProcessStatus ===
												Constants.ClaimProcessStatus
													.Processed
												? 'auto'
												: 'none',
									}}>
									/ Платимо:{' '}
									{electronicDocumentById?.payableAmount}
								</span>
							</div>
						</div>
					)}
				/>
			</Form>
		</Form.Item>
	);
};
export default observer(AmbSheetsDetailsTable);
