import { Form, Switch } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { observer } from 'mobx-react-lite';
import React, { Fragment, useContext } from 'react';
import constants from '../../constants/constants';
import { RootStoreContext } from '../../stores/rootStore';
export interface IProps {
	form: FormInstance;
}
const IsPrimaryFormItem: React.FC<IProps> = ({ form }) => {
	const rootStore = useContext(RootStoreContext);

	const { electronicDocumentById } = rootStore.electronicDocumentStore;
	return (
		<Fragment>
			<Form.Item name='isPrimary' style={{ display: 'inline-block' }}>
				<Switch
					disabled={
						electronicDocumentById?.isActive === false ||
						electronicDocumentById?.isAutomatic ===
							constants.ExaminationImportType.Automatic ||
						(electronicDocumentById !== undefined &&
							electronicDocumentById?.isAttachedToClaim &&
							electronicDocumentById?.claimProcessStatus !== '')
					}
					defaultChecked={electronicDocumentById?.isPrimary}
					checkedChildren='Първичен'
					unCheckedChildren='Вторичен'
					onChange={(value: any) => {
						form.setFieldsValue({ isPrimary: value });
					}}></Switch>
			</Form.Item>
			<Form.Item
				name='shouldAttachToClaim'
				style={{
					display:
						electronicDocumentById?.issuerSpecialityCode === '23' &&
						electronicDocumentById?.isPrimary
							? 'inline-block'
							: 'none',
				}}>
				<Switch
					style={{
						display:
							electronicDocumentById?.issuerSpecialityCode ===
								'23' && electronicDocumentById?.isPrimary
								? ''
								: 'none',
						marginLeft: 10,
					}}
					disabled={
						electronicDocumentById?.isActive === false ||
						electronicDocumentById?.isAutomatic ===
							constants.ExaminationImportType.Automatic ||
						(electronicDocumentById !== undefined &&
							electronicDocumentById?.isAttachedToClaim &&
							electronicDocumentById?.claimProcessStatus !== '')
					}
					defaultChecked={electronicDocumentById?.shouldAttachToClaim}
					checkedChildren='Позволено самостоятелно закачане към иск'
					unCheckedChildren='Забранено самостоятелно закачане към иск'
					onChange={(value: any) => {
						form.setFieldsValue({ shouldAttachToClaim: value });
					}}></Switch>
			</Form.Item>
		</Fragment>
	);
};

export default observer(IsPrimaryFormItem);
