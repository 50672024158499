import React, { useContext, useRef } from 'react';
import { Input, Form, notification, Tooltip } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { observer } from 'mobx-react-lite';
import { UploadOutlined } from '@ant-design/icons';
import { toJS } from 'mobx';
import Constants from '../../../../app/constants/constants';
import { IAttachment } from '../../../../app/models/electronicDocument/ElectronicDocument';
import { RootStoreContext } from '../../../../app/stores/rootStore';
import DocumentTypeItem from '../../../../app/layout/FormItems/AttachmentsFormItem/AttachmetsTableItems/DocumentTypeItem';
import AttachmentTypeItem from '../../../../app/layout/FormItems/AttachmentsFormItem/AttachmetsTableItems/AttachmentTypeItem';

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
	editing: boolean;
	dataIndex: string;
	title: any;
	inputType: 'number' | 'text';
	record: IAttachment;
	index: number;
	children: React.ReactNode;
	formTmp: FormInstance;
	passedForm: FormInstance;
}

const AmbSheetsAttachmentsCell: React.FC<EditableCellProps> = ({
	editing,
	dataIndex,
	title,
	inputType,
	record,
	index,
	children,
	formTmp,
	passedForm,
	...restProps
}) => {
	const rootStore = useContext(RootStoreContext);
	const {
		attachmentsOriginData,
		setAttachmentsOriginData,
		isElectronicDocumentAttaching,
		setIsUploadFormTouched,
	} = rootStore.electronicDocumentStore;

	const fileLabelRef = useRef(null);

	const onFileUploadChange = (e: any) => {
		if (e.target.files.length > 0) {
			let file = e.target.files[0] as File;

			const isLt2M = file.size / 1024 / 1024 < 2;
			const isPdf = file.type === 'application/pdf';

			if (!isPdf) {
				notification.error({
					message:
						'Прикачените файлове могат да са само в PDF формат!',
					className: 'success-messages',
					duration: 5,
				});

				return;
			}
			if (!isLt2M) {
				notification.error({
					message: 'Максималната големина на файла може да е 2МВ!',
					className: 'success-messages',
					duration: 5,
				});

				return;
			}

			let newData = toJS(attachmentsOriginData);
			let currentElementIndex = newData!.findIndex(
				(el) => el.key === record.key
			);

			newData![currentElementIndex].file = file;
			newData![currentElementIndex].fileName = file.name;
			setAttachmentsOriginData(newData);
		}
	};

	const inputNode =
		dataIndex === 'fileName' ? (
			<Form.Item
				style={{ margin: 0 }}
				name={dataIndex}
				rules={[
					{
						required: isElectronicDocumentAttaching ? false : true,
						message: Constants.FormMessages.RequiredField,
					},
				]}>
				<label
					htmlFor='file'
					className='table-div-centered'
					id='file_label'
					ref={fileLabelRef}
					style={{
						color:
							record.fileName && record.fileName.length > 0
								? 'green'
								: 'inherit',
						pointerEvents:
							record.fileName && record.fileName.length === 0
								? 'auto'
								: 'none',
					}}>
					{record.fileName && record.fileName.length > 0 ? (
						'Наличен е файл'
					) : (
						<div className='table-div-centered'>
							<Tooltip title='Избери документ'>
								<UploadOutlined
									style={{
										color: '#e48800',
										fontSize: 23,
										fontWeight: 700,
									}}
								/>
							</Tooltip>
						</div>
					)}
				</label>
				<input
					id='file'
					style={{ display: 'none' }}
					type='file'
					accept='.pdf'
					onChange={(e: any) => onFileUploadChange(e)}></input>
			</Form.Item>
		) : dataIndex === 'documentType' ? (
			<DocumentTypeItem
				dataIndex={dataIndex}
				formTmp={formTmp}
				record={record}
			/>
		) : dataIndex === 'attachmentType' ? (
			<AttachmentTypeItem
				dataIndex={dataIndex}
				formTmp={formTmp}
				record={record}
			/>
		) : dataIndex === 'description' ? (
			<Form.Item
				name={dataIndex}
				style={{ margin: 0, minWidth: 300 }}
				rules={[
					{
						required:
							record && record.documentType
								? parseInt(record.documentType) === 9
								: false,
						message: 'Въведете допълнителна информация!',
					},
				]}>
				<Input
					placeholder='Допълнителна информация'
					onBlur={(e) => {
						e.preventDefault();
						setIsUploadFormTouched(true);
						e.target.focus();
					}}
				/>
			</Form.Item>
		) : null;

	return (
		<td {...restProps}>
			{editing ? (
				<Form.Item name={dataIndex} style={{ margin: 0 }}>
					{inputNode}
				</Form.Item>
			) : (
				children
			)}
		</td>
	);
};

export default observer(AmbSheetsAttachmentsCell);
