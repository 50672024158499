import React, { useState, useContext, useEffect } from 'react';
import { Table, Form, Button, Popconfirm, Tooltip, Modal } from 'antd';
import { observer } from 'mobx-react-lite';
import { FormInstance } from 'antd/lib/form';
import {
	FormOutlined,
	DeleteOutlined,
	CloseOutlined,
	CheckCircleOutlined,
	FileSearchOutlined,
} from '@ant-design/icons';
import { toJS } from 'mobx';
import Enums from '../../../../app/constants/enums';
import Moment from 'react-moment';
import { RootStoreContext } from '../../../../app/stores/rootStore';
import { IAttachment } from '../../../../app/models/electronicDocument/ElectronicDocument';
import { IDynamicAttachmentMetadata } from '../../../../app/models/electronicDocument/Attachment';
import { IDownloadAttachment } from '../../../../app/models/electronicDocument/DownloadAttachment';
import Constants from '../../../../app/constants/constants';
import AmbSheetsAttachmentsCell from './AmbSheetsAttachmentsCell';
import ElectonicDocumentPreveiw from '../../../../app/layout/ElectonicDocumentPreveiw';
import { IAutomaticSearchRequest } from '../../../../app/models/electronicDocument/AutomaticSearchRequest';
import moment from 'moment';

interface IProps {
	passedForm: FormInstance;
	closeModal: () => Promise<void>;
	openModal: (id: number | undefined) => Promise<void>;
}

const AmbSheetsAttachmentsTable: React.FC<IProps> = ({
	openModal,
	closeModal,
	passedForm,
}) => {
	const rootStore = useContext(RootStoreContext);
	const { loadingAllTable } = rootStore.loadersStore;

	const {
		attachmentsOriginData,
		electronicDocumentById,
		setAttachmentsOriginData,
		setUploadEditMode,
		downloadAttachment,
		deleteAttachment,
		createAttachment,
		updateAttachment,
		isUploadFormTouched,
		setIsUploadFormTouched,
		isElectronicDocumentAttaching,
		getElectronicSubDocumentById,
		setIsElectronicDocumentAttaching,
		detailsOriginData,
		automaticallySearchForElectronicDocumentsForAbulatorySheetsAndMdd,
		setAttachmentTableVisible,
		detailsEditMode,
	} = rootStore.electronicDocumentStore;

	const {
		attachCount,
		attachEditingKey,
		attachForm,
		setAttachCount,
		setAttachEditingKey,
	} = rootStore.ambulatorySheetsStore;

	const [form] = Form.useForm(attachForm);
	const [temporaryData, setTemporaryData] = useState([] as IAttachment[]);
	const [rowKey, setRowKey] = useState('');
	const [rowClassName, setRowClassName] = useState(
		'ant-table-row ant-table-row-level-0'
	);
	const [show, setShow] = useState(false);

	useEffect(() => {
		setAttachCount(
			electronicDocumentById &&
				electronicDocumentById.attachments.length > 0
				? electronicDocumentById.attachments.length + 1
				: attachmentsOriginData !== undefined &&
				  attachmentsOriginData.length !== undefined &&
				  attachmentsOriginData.length > 1
				? attachmentsOriginData.length + 1
				: 2
		);
		setAttachEditingKey(
			electronicDocumentById &&
				electronicDocumentById.attachments.length > 0
				? ''
				: '1'
		);
	}, []);

	const emptyRowInitialData = (key: string) => {
		return {
			key: key,
			id: 0,
			documentType: '',
			attachmentType: Enums.AttachmentTypeEnum.PhysicalCopy.toString(),
			eDocumentNumber: '',
			eDocumentDate: undefined,
			eDocumentDoctorName: '',
			fileName: '',
			description: '',
			file: undefined,
			toAttachmentId: 0,
			lastModifiedByUsername: ' ',
			lastModifiedOn: null,
		};
	};

	const isEditing = (record: IAttachment) => record.key === attachEditingKey;

	const handleAdd = () => {
		setUploadEditMode(false);
		setTemporaryData(attachmentsOriginData!);
		const newData = [
			...attachmentsOriginData!,
			emptyRowInitialData(attachCount.toString()),
		];
		setAttachmentsOriginData(newData);
		form.resetFields();
		if (electronicDocumentById) {
			setAttachCount(newData!.length + 1);
		} else {
			setAttachCount(attachCount + 1);
		}

		setAttachEditingKey(attachCount.toString());
		setIsUploadFormTouched(false);
	};

	const automaticElectronicDocumentsImport = async () => {
		let model: IAutomaticSearchRequest = {
			insuranceCompanyId: passedForm.getFieldValue('companyName'),
			patientIdentifier: passedForm.getFieldValue('patientUid'),
			performerDoctorUin: passedForm.getFieldValue('issuerUin'),
			documentDate: moment(passedForm.getFieldValue('documentDate'))
				.local()
				.local()
				.toDate(),
			baseServicesSystemCodes: [],
			electronicDocumentMainId: electronicDocumentById?.id,
		};

		detailsOriginData.forEach((element) => {
			model.baseServicesSystemCodes.push(element.systemCode);
		});

		await automaticallySearchForElectronicDocumentsForAbulatorySheetsAndMdd(
			model
		);

		if (
			rootStore.electronicDocumentStore
				.electronicDocumentsAutomaticSearchResult !== undefined &&
			rootStore.electronicDocumentStore
				.electronicDocumentsAutomaticSearchResult.length !== 0
		) {
			const newData = toJS(attachmentsOriginData);
			let automaticDocumentsToJs = toJS(
				rootStore.electronicDocumentStore
					.electronicDocumentsAutomaticSearchResult
			);
			newData.splice(0, 1);
			for (let i = 0; i < automaticDocumentsToJs.length; i++) {
				newData.push({
					id: automaticDocumentsToJs[i].id,
					key: (i + 1).toString(),
					attachmentType: Constants.AttachmentType.ElectronicDocument,
					documentType: automaticDocumentsToJs[
						i
					].documentType.toString(),
					eDocumentNumber: automaticDocumentsToJs[i].documentNumber,
					eDocumentDate: automaticDocumentsToJs[i].documentDate,
					eDocumentDoctorName:
						automaticDocumentsToJs[i].documentType ===
						Enums.DocumentTypeEnum.MedicalReferral
							? automaticDocumentsToJs[i]?.performerName
							: automaticDocumentsToJs[i]?.issuerName,
					fileName: '',
					description: '',
					file: undefined,
					toAttachmentId: automaticDocumentsToJs[i].id,
					lastModifiedByUsername:
						automaticDocumentsToJs[i].lastModifiedByUsername,
					lastModifiedOn: automaticDocumentsToJs[i].lastModifiedOn,
				});
			}
			setAttachCount(automaticDocumentsToJs.length + 1);
			setAttachmentsOriginData(newData);
			setAttachEditingKey('');
			setUploadEditMode(true);
			setAttachmentTableVisible(true);
			// if (electronicDocumentById !== undefined) {
			// 	await closeModal();
			// 	await openModal(electronicDocumentById?.id);
			// }
		} else {
			setAttachmentTableVisible(true);
			setAttachCount(2);
			setAttachEditingKey('1');
			setUploadEditMode(true);
			setAttachmentsOriginData([
				{
					key: '1',
					id: 0,
					documentType: '',
					attachmentType: '',
					eDocumentNumber: '',
					eDocumentDate: undefined,
					eDocumentDoctorName: '',
					fileName: '',
					description: '',
					file: undefined,
					toAttachmentId: 0,
					lastModifiedByUsername: ' ',
					lastModifiedOn: null,
				},
			]);
		}
	};

	const edit = (record: IAttachment) => {
		setIsElectronicDocumentAttaching(false);
		setUploadEditMode(false);

		if (electronicDocumentById) {
			setTemporaryData(electronicDocumentById!.attachments);
		} else {
			setTemporaryData(attachmentsOriginData!);
		}
		form.setFieldsValue({ ...record });
		setAttachEditingKey(record.key);
		setIsUploadFormTouched(false);
	};

	const handleCancel = (record: IAttachment) => {
		if (temporaryData.length === 0) {
			const newData = [emptyRowInitialData('1')];

			record = newData[0];
			setAttachmentsOriginData(newData);
			setAttachEditingKey('1');
			setUploadEditMode(false);

			form.resetFields();
		} else {
			let newData = toJS(temporaryData);
			for (let index = 0; index < newData.length; index++) {
				newData[index].key = (index + 1).toString();
			}
			setAttachCount(newData!.length + 1);
			setAttachmentsOriginData(newData);
			setAttachEditingKey('');
			setUploadEditMode(true);
		}

		setIsUploadFormTouched(false);
	};

	const save = async (key: React.Key) => {
		try {
			const row = (await form.validateFields()) as IAttachment;
			const newData = toJS(attachmentsOriginData);

			const index = newData!.findIndex((item) => key === item.key);
			if (electronicDocumentById) {
				const item = newData![index];
				item.documentType = row.documentType;
				item.attachmentType =
					row.attachmentType ??
					Enums.AttachmentTypeEnum.PhysicalCopy.toString();

				if (!isElectronicDocumentAttaching) {
					item.fileName = row.fileName.replace(/C:\\fakepath\\/i, '');
					item.description = row.description;
				}

				if (
					temporaryData[index] &&
					(temporaryData[index].documentType !== item.documentType ||
						temporaryData[index].description !== item.description)
				) {
					//Edit mode
					let updatedAttachment: IDynamicAttachmentMetadata = {
						key: item.key,
						parentRecordId: electronicDocumentById.id,
						description: item.description,
						documentType: item.documentType,
						attachmentRecordId: item.id,
						attachmentType: item.attachmentType,
						toAttachmentId: 0,
					};

					await updateAttachment(updatedAttachment, item.key);
				} else {
					let formData = new FormData();
					let newAttachment: IDynamicAttachmentMetadata = {
						key: item.key,
						parentRecordId: electronicDocumentById.id,
						description: item.description,
						documentType: item.documentType,
						attachmentRecordId: 0,
						attachmentType: item.attachmentType,
						toAttachmentId: item.toAttachmentId,
					};
					formData.set('request', JSON.stringify(newAttachment));

					if (!isElectronicDocumentAttaching) {
						formData.append(`file`, item.file!);
					}

					await createAttachment(formData, item.key);
				}
			} else {
				if (!isElectronicDocumentAttaching) {
					newData![index].documentType = row.documentType;
					newData![index].attachmentType = row.attachmentType;
					newData![index].description = row.description;
					newData![index].fileName = row.fileName.replace(
						/C:\\fakepath\\/i,
						''
					);
				}
				setAttachmentsOriginData(newData);
			}
			setAttachEditingKey('');
			setIsUploadFormTouched(false);
		} catch (errInfo) {}
	};

	const handleDelete = async (key: any) => {
		let newData = toJS(attachmentsOriginData)!.filter(
			(item) => item.key !== key
		);
		for (let index = 0; index < newData.length; index++) {
			newData[index].key = (index + 1).toString();
		}

		if (electronicDocumentById) {
			let currentAttachment = toJS(attachmentsOriginData)!.filter(
				(item) => item.key === key
			);
			await deleteAttachment(currentAttachment[0].id);
			setUploadEditMode(true);
			setAttachCount(attachCount - 1);
		} else {
			setAttachmentsOriginData(newData);
			setAttachCount(attachCount - 1);
		}

		if (attachmentsOriginData!.length === 1) {
			const newData = [emptyRowInitialData('1')];
			setAttachmentsOriginData(newData);
			setAttachEditingKey('1');
			setUploadEditMode(false);
		}
		setIsUploadFormTouched(false);
	};

	const preview = async (id: number) => {
		if (id) {
			await getElectronicSubDocumentById(id);
		}

		setShow(true);
	};

	const download = async (
		patientUid: string,
		fileName: string,
		documentType: string
	) => {
		let model: IDownloadAttachment = {
			documentType: documentType,
			patientUid: patientUid,
			fileName: fileName,
		};

		await downloadAttachment(model);

		if (rootStore.electronicDocumentStore.attachmentDownloadResult) {
			const url = window.URL.createObjectURL(
				new Blob([
					rootStore.electronicDocumentStore.attachmentDownloadResult!,
				])
			);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `${model.documentType}.pdf`);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	};

	const closeSubModal = async () => {
		setShow(false);
	};

	const validate =
		electronicDocumentById?.isActive === false ||
		(electronicDocumentById?.isAttachedToClaim &&
			electronicDocumentById?.claimProcessStatus !==
				Constants.ClaimProcessStatus.ChangeRequested) ||
		(electronicDocumentById?.claimProcessStatus ===
			Constants.ClaimProcessStatus.ChangeRequested &&
			electronicDocumentById?.isForEditing === false);

	const noEditingKey =
		attachEditingKey !== '' || attachmentsOriginData?.length === 0;

	const columns = [
		{
			title: 'No',
			dataIndex: 'key',
			key: 'key',
			width: '4%',
			render: (row: any, record: any) => {
				return <div className='table-div-centered'>{record.key}</div>;
			},
		},
		{
			dataIndex: 'id',
			key: 'id',
			className: 'hide-col',
		},
		{
			title: 'Тип документ',
			dataIndex: 'attachmentType',
			key: 'attachmentType',
			editable: true,
			render: (row: any, record: any) => {
				return (
					<div
						className='table-div-centered'
						style={{ minWidth: '100px' }}>
						{record.attachmentType}
					</div>
				);
			},
		},
		{
			dataIndex: 'toAttachmentId',
			key: 'toAttachmentId',
			className: 'hide-col',
		},
		{
			title: 'Съдържание тип',
			dataIndex: 'documentType',
			key: 'documentType',
			editable: true,
			render: (row: any, record: any) => {
				return (
					<div style={{ minWidth: '200px' }}>
						{record.documentType}
					</div>
				);
			},
		},
		{
			title: 'Номер на документ',
			dataIndex: 'eDocumentNumber',
			key: 'eDocumentNumber',

			editable: false,
			render: (row: any, record: any) => {
				return (
					<div className='table-div-centered'>
						{record.eDocumentNumber}
					</div>
				);
			},
		},
		{
			title: 'Дата на документ',
			dataIndex: 'eDocumentDate',
			key: 'eDocumentDate',
			editable: false,
			render: (row: any, record: any) => {
				return (
					<div className='table-div-centered'>
						{record.eDocumentDate ? (
							<Moment format={Constants.Common.ShortDate_Format}>
								{record.eDocumentDate}
							</Moment>
						) : null}
					</div>
				);
			},
		},

		{
			dataIndex: 'file',
			key: 'file',
			className: 'hide-col',
		},
		{
			title: 'Допълнителна информация / Лекар',
			dataIndex: !isElectronicDocumentAttaching
				? 'description'
				: 'eDocumentDoctorName',
			key: !isElectronicDocumentAttaching
				? 'description'
				: 'eDocumentDoctorName',
			editable: !isElectronicDocumentAttaching ? true : false,
			render: (row: any, record: any) => {
				return (
					<div style={{ minWidth: '200px' }}>
						{record.description
							? record.description
							: record.eDocumentDoctorName
							? record.eDocumentDoctorName
							: ''}
					</div>
				);
			},
		},
		{
			title: 'Преглед / Запазване',
			dataIndex: 'fileName',
			key: 'fileName',
			width: validate ? '5%' : '',
			editable: !isElectronicDocumentAttaching ? true : false,
			render: (row: any, record: any) => {
				return record.id ? (
					<div className='table-div-centered'>
						<FileSearchOutlined
							onClick={() =>
								record.attachmentType ===
								Constants.AttachmentType.PhysicalCopy
									? download(
											passedForm.getFieldValue(
												'patientUid'
											),
											record.fileName,
											record.documentType
									  )
									: preview(record!.toAttachmentId)
							}
							style={{
								color: '#e48800',
								fontSize: 16,
								fontWeight: 700,
							}}
						/>
					</div>
				) : (
					''
				);
			},
		},
		{
			title: '',
			dataIndex: 'operation',
			className: validate ? 'hide-col' : '',
			width: validate ? '0%' : '',
			render: (_: any, record: IAttachment) => {
				const editable = isEditing(record);
				return editable ? (
					<div className='table-div-centered'>
						<Tooltip
							title='Запази'
							destroyTooltipOnHide={!isUploadFormTouched}>
							<CheckCircleOutlined
								disabled={!isUploadFormTouched}
								onClick={() => save(record.key)}
								style={{
									color: !isUploadFormTouched
										? 'grey'
										: 'green',
									fontSize: 16,
									fontWeight: 700,
								}}
							/>
						</Tooltip>
					</div>
				) : (
					<div className='table-div-centered'>
						<Tooltip
							title='Редактирай'
							destroyTooltipOnHide={
								attachEditingKey !== '' ||
								record.attachmentType ===
									Constants.AttachmentType.ElectronicDocument
							}>
							<FormOutlined
								onClick={() => {
									edit(record);
									setRowKey('');
								}}
								disabled={attachEditingKey !== ''}
								hidden={
									record.attachmentType ===
									Constants.AttachmentType.ElectronicDocument
								}
								style={{
									color:
										attachEditingKey !== ''
											? 'grey'
											: 'blue',
									fontSize: 16,
									fontWeight: 700,
								}}
							/>
						</Tooltip>
					</div>
				);
			},
		},
		{
			title: '',
			dataIndex: 'operation',
			className: validate ? 'hide-col' : '',
			width: validate ? '0%' : '',
			render: (text: any, record: IAttachment) => {
				const editable = isEditing(record);
				return editable ? (
					<div
						className='table-div-centered'
						style={{
							display:
								attachmentsOriginData?.length === 0 &&
								!electronicDocumentById
									? 'none'
									: 'block',
						}}>
						<Tooltip title='Отказ'>
							<CloseOutlined
								onClick={() => {
									handleCancel(record);
								}}
								style={{
									color: 'red',
									fontSize: 16,
									fontWeight: 700,
								}}
							/>
						</Tooltip>
					</div>
				) : (
					<div className='table-div-centered'>
						<Popconfirm
							title='Сигурни ли сте, че искате да изтриете реда?'
							onConfirm={() => handleDelete(record.key)}
							disabled={noEditingKey}>
							<Tooltip title='Изтриване'>
								<DeleteOutlined
									disabled={noEditingKey}
									style={{
										color: noEditingKey ? 'grey' : 'red',
										fontSize: 16,
										fontWeight: 700,
									}}
								/>
							</Tooltip>
						</Popconfirm>
					</div>
				);
			},
		},
	];

	const mergedColumns = columns.map((col) => {
		if (!col.editable) {
			return col;
		}
		return {
			...col,
			onCell: (record: IAttachment) => ({
				record,
				inputType: col.dataIndex === 'count' ? 'number' : 'text',
				dataIndex: col.dataIndex,
				title: col.title,
				editing: isEditing(record),
				formTmp: form,
				passedForm: passedForm,
			}),
		};
	});

	return (
		<Form.Item
			className={
				rootStore.electronicDocumentStore.uploadEditMode === true
					? ' '
					: 'upload-form-item'
			}
			name='attachments'
			style={{ display: 'block' }}
			label='Други документи:'>
			<Form form={form} component={false}>
				<Button
					className='add-file-btn'
					onClick={async () => {
						await automaticElectronicDocumentsImport();
					}}
					style={{
						borderRadius: 20,
					}}
					type='primary'
					hidden={
						rootStore.electronicDocumentStore
							.attachmentTableVisible === true || validate
					}
					disabled={
						passedForm.getFieldValue('companyName') === undefined ||
						passedForm.getFieldValue('patientUid') === undefined ||
						passedForm.getFieldValue('issuerUin') === undefined ||
						detailsEditMode ||
						(passedForm.getFieldValue('companyName') !==
							undefined &&
							passedForm.getFieldValue('companyName')!.length ===
								0) ||
						(passedForm.getFieldValue('patientUid') !== undefined &&
							passedForm.getFieldValue('patientUid')!.length ===
								0) ||
						(passedForm.getFieldValue('issuerUin') !== undefined &&
							passedForm.getFieldValue('issuerUin')!.length ===
								0) ||
						(detailsOriginData !== undefined &&
							detailsOriginData[0]!.systemCode === '')
					}>
					Добави документи
				</Button>
				<Button
					className='add-file-btn'
					onClick={() => {
						handleAdd();
					}}
					type='primary'
					hidden={
						rootStore.electronicDocumentStore
							.attachmentTableVisible === false || validate
					}
					style={{
						borderRadius: 20,
					}}
					disabled={attachEditingKey !== ''}>
					Добави нов документ
				</Button>

				<Table
					onRow={(record, index) => {
						return {
							onDoubleClick: () => {
								setRowClassName(
									'ant-table-row ant-table-row-level-0 ant-table-row-selected'
								);
								setRowKey(record.key);
							},
						};
					}}
					components={{
						body: {
							cell: AmbSheetsAttachmentsCell,
						},
					}}
					style={{
						display:
							rootStore.electronicDocumentStore
								.attachmentTableVisible === false
								? 'none'
								: 'inherit',
					}}
					loading={loadingAllTable}
					dataSource={attachmentsOriginData}
					columns={mergedColumns}
					pagination={false}
					rowClassName={(_record, index) => {
						return _record.key === rowKey ? rowClassName : '';
					}}
				/>
				<Modal
					className='appointment-modal'
					title={'Преглед на електронен документ'}
					centered
					maskClosable={false}
					transitionName='none'
					maskTransitionName='none'
					onCancel={() => closeSubModal()}
					visible={show}
					footer={false}
					destroyOnClose={true}>
					{' '}
					<ElectonicDocumentPreveiw />
				</Modal>
			</Form>
		</Form.Item>
	);
};

export default observer(AmbSheetsAttachmentsTable);
