import React, { useState, useContext, Fragment } from 'react';
import { Table, Form, Button, Popconfirm, Tooltip, Popover } from 'antd';
import { IDetail } from '../../../models/electronicDocument/ElectronicDocument';
import DetailsEditableCell from './DetailsEditableCell';
import { observer } from 'mobx-react-lite';
import { FormInstance } from 'antd/lib/form';
import {
	FormOutlined,
	DeleteOutlined,
	CloseOutlined,
	CheckCircleOutlined,
	InfoCircleOutlined,
} from '@ant-design/icons';
import { RootStoreContext } from '../../../stores/rootStore';
import { toJS } from 'mobx';
import Constants from '../../../constants/constants';
import Moment from 'react-moment';

interface IProps {
	passedForm: FormInstance;
	closeModal: () => Promise<void>;
	openModal: (id: number | undefined) => Promise<void>;
}

const DetailsEditableTable: React.FC<IProps> = ({ openModal, closeModal }) => {
	const rootStore = useContext(RootStoreContext);
	const { setActivateSendEmailButton } = rootStore.medicalReferralStore;

	const {
		detailsOriginData,
		electronicDocumentById,
		setDetailsOriginData,
		setDetailsEditMode,
		createDetail,
		updateDetail,
		deleteDetail,
		isDetailsFormTouched,
		setIsDetailsFormTouched,
	} = rootStore.electronicDocumentStore;

	const editButtonDisabled =
		electronicDocumentById?.documentStatus &&
		electronicDocumentById.documentStatus !==
			Constants.DocumentProcessStatus.Registered;

	const [form] = Form.useForm();
	const [temporaryData, setTemporaryData] = useState([] as IDetail[]);
	const [count, setCount] = useState(
		electronicDocumentById ? electronicDocumentById.details.length + 1 : 2
	);
	const [editingKey, setEditingKey] = useState(
		electronicDocumentById ? '' : '1'
	);
	const [rowClassName, setRowClassName] = useState(
		'ant-table-row ant-table-row-level-0'
	);
	const [rowKey, setRowKey] = useState();

	const [editMode, setEditMode] = useState(false);

	const isEditing = (record: IDetail) => record.key === editingKey;

	const edit = (record: IDetail) => {
		setEditMode(true);
		if (electronicDocumentById) {
			setTemporaryData(electronicDocumentById!.details);
		} else {
			setTemporaryData(detailsOriginData!);
		}
		setDetailsEditMode(true);
		form.setFieldsValue({ ...record });
		setEditingKey(record.key);
		setIsDetailsFormTouched(false);
	};

	const handleAdd = () => {
		setDetailsEditMode(true);
		setTemporaryData(detailsOriginData!);
		const newData = [
			...detailsOriginData!,
			{
				key: count.toString(),
				systemCode: '',
				code: '',
				nhifCode: '',
				name: '',
				count: 1,
				description: '',
				toMainId: 0,
				id: 0,
				lastModifiedByUsername: ' ',
				lastModifiedOn: null,
				price: '',
				totalAmount: '',
				isPayable: true,
				specialityCode: '',
				specialityName: '',
			},
		];
		setDetailsOriginData(newData);
		form.resetFields();
		if (electronicDocumentById) {
			setCount(newData!.length + 1);
		} else {
			setCount(count + 1);
		}

		setEditingKey(count.toString());
		setIsDetailsFormTouched(false);
	};

	const handleCancel = (record: IDetail) => {
		let newData = toJS(temporaryData);
		for (let index = 0; index < newData.length; index++) {
			newData[index].key = (index + 1).toString();
		}
		setCount(newData!.length + 1);
		setDetailsOriginData(newData);
		setEditingKey('');
		setDetailsEditMode(false);
		setEditMode(false);
		setIsDetailsFormTouched(false);
	};

	const save = async (key: React.Key) => {
		try {
			const row = (await form.validateFields()) as IDetail;
			row.count = row.count ? parseFloat(row.count.toString()) : 1;
			const newData = toJS(detailsOriginData);

			const index = newData!.findIndex((item) => key === item.key);
			if (electronicDocumentById) {
				const item = newData![index];
				item.name = row.name;
				item.description = row.description;
				item.count = row.count;

				item.toMainId = electronicDocumentById.id;
				if (editMode) {
					await updateDetail(item);
					setActivateSendEmailButton(true);
				} else {
					await createDetail(item);
					setActivateSendEmailButton(true);
				}
			} else {
				newData![index].name = row.name;
				newData![index].description = row.description;
				newData![index].count = row.count;
				setDetailsOriginData(newData);
				setEditMode(false);
			}
			setDetailsEditMode(false);
			setEditingKey('');
			setEditMode(false);
		} catch (errInfo) {
			console.log('Validate Failed:', errInfo);
		}
	};

	const handleDelete = async (key: any) => {
		let newData = toJS(detailsOriginData)!.filter(
			(item) => item.key !== key
		);
		for (let index = 0; index < newData.length; index++) {
			newData[index].key = (index + 1).toString();
		}

		if (electronicDocumentById) {
			let currentDetail = toJS(detailsOriginData)!.filter(
				(item) => item.key === key
			)[0];
			await deleteDetail(currentDetail.id);
			setActivateSendEmailButton(true);
			setEditingKey('');
			setDetailsEditMode(false);
			setCount(count - 1);
		} else {
			setDetailsOriginData(newData);
			setCount(count - 1);
		}
		setIsDetailsFormTouched(false);
	};

	const contentInfo = (row: any) =>
		row.lastModifiedOn ? (
			<Fragment>
				<div>
					{' '}
					Записът е създаден на:{' '}
					<Moment format={Constants.Common.FullDate_Format}>
						{row.createdOn}
					</Moment>
					, от потребител: {row.lastModifiedByUsername}{' '}
				</div>
				<div>
					Последна редакция на дата:{' '}
					<Moment format={Constants.Common.FullDate_Format}>
						{row.lastModifiedOn}
					</Moment>
					, от потребител: {row.lastModifiedByUsername}
				</div>
			</Fragment>
		) : null;

	const disableOption =
		electronicDocumentById?.isActive === false ||
		electronicDocumentById?.status ===
			Constants.MedicalReferralStatus.Performed ||
		electronicDocumentById?.documentStatus ===
			Constants.DocumentProcessStatus.Open ||
		electronicDocumentById?.documentStatus ===
			Constants.DocumentProcessStatus.Approved ||
		electronicDocumentById?.documentStatus ===
			Constants.DocumentProcessStatus.Rejected;

	const disableOptionNoEditingKey =
		editingKey !== '' ||
		detailsOriginData?.length === 1 ||
		editButtonDisabled ||
		(electronicDocumentById &&
			electronicDocumentById?.documentType.toString() ===
				Constants.DocumentType.PriorApproval &&
			electronicDocumentById?.documentStatus !==
				Constants.DocumentProcessStatus.Registered);

	const columns = [
		{
			dataIndex: 'id',
			className: 'hide-col',
		},
		{
			title: 'No',
			dataIndex: 'key',
			width: '4%',
			render: (row: any, record: IDetail) => {
				return <div className='table-div-centered'>{record.key}</div>;
			},
		},

		{
			dataIndex: 'systemCode',
			className: 'hide-col',
		},
		{
			title: ' Код по МКБ 9КМ / НЗОК',
			dataIndex: 'nhifCode',
			width: '10%',
			render: (row: any, record: IDetail) => {
				return (
					<div className='table-div-centered'>{record.nhifCode}</div>
				);
			},
		},
		{
			title: 'Дейност',
			dataIndex: 'name',
			width: '40%',
			editable: true,
			render: (row: any, record: IDetail) => {
				return <div style={{ minWidth: '300px' }}>{record.name}</div>;
			},
		},
		{
			title: 'Допълнителна информация',
			dataIndex: 'description',
			width:
				electronicDocumentById?.isActive === false ||
				electronicDocumentById?.status ===
					Constants.MedicalReferralStatus.Performed
					? '36%'
					: '30%',
			editable: true,
			render: (row: any, record: IDetail) => {
				return (
					<div style={{ minWidth: '200px' }}>
						{record.description}
					</div>
				);
			},
		},
		{
			title: 'Брой',
			dataIndex: 'count',
			width: '10%',
			editable: true,
			render: (row: any, record: IDetail) => {
				return <div className='table-div-centered'>{record.count}</div>;
			},
		},
		{
			title: '',
			dataIndex: 'operation',
			className: disableOption ? 'hide-col' : '',
			width: disableOption ? '0%' : '',
			render: (_: any, record: IDetail) => {
				const editable = isEditing(record);
				return editable ? (
					<div className='table-div-centered'>
						<Tooltip
							title='Запази'
							destroyTooltipOnHide={
								!isDetailsFormTouched || record.code === ''
							}>
							<CheckCircleOutlined
								onClick={() => save(record.key)}
								disabled={
									!isDetailsFormTouched || record.code === ''
								}
								style={{
									color:
										!isDetailsFormTouched ||
										record.code === ''
											? 'grey'
											: 'green',
									fontSize: 16,
									fontWeight: 700,
								}}
							/>
						</Tooltip>
					</div>
				) : (
					<div className='table-div-centered'>
						<Tooltip
							title='Редактирай'
							destroyTooltipOnHide={
								editingKey !== '' ||
								editButtonDisabled ||
								(electronicDocumentById &&
									electronicDocumentById?.documentType.toString() ===
										Constants.DocumentType.PriorApproval &&
									electronicDocumentById?.documentStatus !==
										Constants.DocumentProcessStatus
											.Registered) ||
								electronicDocumentById?.status ===
									Constants.MedicalReferralStatus.Performed
							}>
							<FormOutlined
								onClick={() => edit(record)}
								hidden={
									electronicDocumentById?.status ===
										Constants.MedicalReferralStatus
											.Performed ||
									editingKey !== '' ||
									editButtonDisabled ||
									(electronicDocumentById &&
										electronicDocumentById?.documentType.toString() ===
											Constants.DocumentType
												.PriorApproval &&
										electronicDocumentById?.documentStatus !==
											Constants.DocumentProcessStatus
												.Registered)
								}
								style={{
									color:
										editingKey !== '' ||
										editButtonDisabled ||
										(electronicDocumentById &&
											electronicDocumentById?.documentType.toString() ===
												Constants.DocumentType
													.PriorApproval &&
											electronicDocumentById?.documentStatus !==
												Constants.DocumentProcessStatus
													.Registered)
											? 'grey'
											: 'blue',
									fontSize: 16,
									fontWeight: 700,
								}}
							/>
						</Tooltip>
					</div>
				);
			},
		},
		{
			title: '',
			dataIndex: 'operation',
			className: disableOption ? 'hide-col' : '',
			width: disableOption ? '0%' : '',
			render: (text: any, record: IDetail) => {
				const editable = isEditing(record);
				return editable ? (
					<div
						className='table-div-centered'
						style={{
							display:
								(detailsOriginData?.length === 1 &&
									!electronicDocumentById) ||
								detailsOriginData![0].name === ''
									? 'none'
									: 'block',
						}}>
						<Tooltip title='Отказ'>
							<CloseOutlined
								onClick={() => handleCancel(record)}
								style={{
									color: 'red',
									fontSize: 16,
									fontWeight: 700,
								}}
							/>
						</Tooltip>
					</div>
				) : detailsOriginData!.length >= 1 ? (
					<div className='table-div-centered'>
						<Popconfirm
							title='Сигурни ли сте, че искате да изтриете реда?'
							onConfirm={() => handleDelete(record.key)}
							disabled={
								editingKey !== '' ||
								detailsOriginData?.length === 1 ||
								editButtonDisabled
							}>
							<Tooltip title='Изтриване'>
								<DeleteOutlined
									hidden={
										electronicDocumentById?.status ===
											Constants.MedicalReferralStatus
												.Performed ||
										disableOptionNoEditingKey
									}
									style={{
										color: disableOptionNoEditingKey
											? 'grey'
											: 'red',
										fontSize: 16,
										fontWeight: 700,
									}}
								/>
							</Tooltip>
						</Popconfirm>
					</div>
				) : null;
			},
		},
		{
			title: '',
			dataIndex: '',
			key: '',
			width: '1%',

			render: (text: any, row: any, index: any) => (
				<div className='table-div-centered'>
					<Popover
						content={contentInfo(row)}
						trigger='hover'
						placement='topRight'
						style={{ fontSize: 18 }}>
						<InfoCircleOutlined />
					</Popover>
				</div>
			),
		},
	];

	const mergedColumns = columns.map((col) => {
		if (!col.editable) {
			return col;
		}
		return {
			...col,
			onCell: (record: IDetail) => ({
				record,
				inputType: col.dataIndex === 'count' ? 'number' : 'text',
				dataIndex: col.dataIndex,
				title: col.title,
				editing: isEditing(record),
			}),
		};
	});
	return (
		<Form form={form} component={false}>
			<Button
				onClick={() => {
					handleAdd();
				}}
				type='primary'
				className='add-detail-btn'
				disabled={editingKey !== ''}
				hidden={disableOption}>
				Добави нова дейност
			</Button>

			<Table
				onRow={(record, index) => {
					return {
						onDoubleClick: () => {
							setRowClassName(
								'ant-table-row ant-table-row-level-0 ant-table-row-selected'
							);
							setRowKey(record.key);
						},
					};
				}}
				components={{
					body: {
						cell: DetailsEditableCell,
					},
				}}
				bordered
				dataSource={rootStore.electronicDocumentStore.detailsOriginData}
				columns={mergedColumns}
				pagination={false}
				rowClassName={(_record, index) => {
					return _record.key === rowKey ? rowClassName : '';
				}}
			/>
		</Form>
	);
};

export default observer(DetailsEditableTable);
