import { Button, Form, Input, Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { Fragment, useContext } from 'react';
import { RootStoreContext } from '../../app/stores/rootStore';

interface IProps {
	closeForCertificateAuthenticationModal: () => Promise<void>;
}

const AccountManagerForCertificateAuthenticationModal: React.FC<IProps> = ({
	closeForCertificateAuthenticationModal,
}) => {
	const rootStore = useContext(RootStoreContext);
	const {
		getAllUsers,
		createForCertificateAuthenticationUser,
		loadingInitial,
	} = rootStore.userManagementStore;

	const [form] = Form.useForm();

	const handleFormSubmit = async (values: any) => {
		let newUser = {
			...values,
		};

		await createForCertificateAuthenticationUser(newUser);
		await closeForCertificateAuthenticationModal();
		await getAllUsers();
	};

	return (
		<Fragment>
			<Spin tip='Зареждане...' spinning={loadingInitial}>
				<div>
					След въвеждане на ЕГН потребителят ще има възможност с{' '}
					<strong>персонален КЕП</strong> да активира профила си и да
					попълни нужната информация. Ще имате възможност да изтриете
					потребителя преди да е минал в състояние "Активен." След
					това ще можете само да сменяте състоянието му.
				</div>
				<br />
				<Form
					layout={'vertical'}
					form={form}
					onFinish={handleFormSubmit}>
					<Form.Item
						name='Egn'
						label='ЕГН (за вход със персонален КЕП)'
						rules={[
							({ getFieldValue }) => ({
								validator(rule, value) {
									const expression = new RegExp('^[0-9]*$');
									if (value && value.length > 0) {
										if (
											expression.test(value) &&
											value.length === 10
										) {
											return Promise.resolve();
										}
										return Promise.reject(
											'ЕГН или ЛНЧ трябва да съдържа само цифри (10)!'
										);
									} else {
										return Promise.resolve();
									}
								},
							}),
						]}>
						<Input />
					</Form.Item>
					<Form.Item name='Id'>
						<Button
							type='primary'
							shape='round'
							className='btn-primary'
							htmlType='submit'>
							Създаване на потребител
						</Button>
					</Form.Item>
				</Form>
			</Spin>
		</Fragment>
	);
};

export default observer(AccountManagerForCertificateAuthenticationModal);
