import React, { useState, useContext, Fragment } from 'react';
import {
	Table,
	Form,
	Button,
	Popconfirm,
	Tooltip,
	Modal,
	Popover,
	notification,
} from 'antd';
import { IAttachment } from '../../../models/electronicDocument/ElectronicDocument';
import UploadEditableCell from './UploadEditableCell';
import { observer } from 'mobx-react-lite';
import { FormInstance } from 'antd/lib/form';
import { IDynamicAttachmentMetadata } from '../../../models/electronicDocument/Attachment';
import {
	FormOutlined,
	DeleteOutlined,
	CloseOutlined,
	CheckCircleOutlined,
	FileSearchOutlined,
	InfoCircleOutlined,
} from '@ant-design/icons';
import { IDownloadAttachment } from '../../../models/electronicDocument/DownloadAttachment';
import { RootStoreContext } from '../../../stores/rootStore';
import { toJS } from 'mobx';
import Enums from '../../../../app/constants/enums';
import Moment from 'react-moment';
import ElectonicDocumentPreveiw from '../../ElectonicDocumentPreveiw';
import Constants from '../../../constants/constants';

interface IProps {
	passedForm: FormInstance;
	closeModal: () => Promise<void>;
	openModal: (id: number | undefined) => Promise<void>;
}

const UploadEditableTable: React.FC<IProps> = ({ openModal, closeModal }) => {
	const rootStore = useContext(RootStoreContext);
	const { setActivateSendEmailButton } = rootStore.medicalReferralStore;

	const {
		attachmentsOriginData,
		electronicDocumentById,
		setAttachmentsOriginData,
		setUploadEditMode,
		downloadAttachment,
		deleteAttachment,
		createAttachment,
		updateAttachment,
		isUploadFormTouched,
		setIsUploadFormTouched,
		isElectronicDocumentAttaching,
		getElectronicSubDocumentById,
		setIsElectronicDocumentAttaching,
	} = rootStore.electronicDocumentStore;

	const [form] = Form.useForm();
	const [temporaryData, setTemporaryData] = useState([] as IAttachment[]);
	const [count, setCount] = useState(
		electronicDocumentById && electronicDocumentById.attachments.length > 0
			? electronicDocumentById.attachments.length + 1
			: 2
	);
	const [editingKey, setEditingKey] = useState(
		electronicDocumentById && electronicDocumentById.attachments.length > 0
			? ''
			: '1'
	);
	const [rowKey, setRowKey] = useState('');
	const [rowClassName, setRowClassName] = useState(
		'ant-table-row ant-table-row-level-0'
	);
	const [show, setShow] = useState(false);

	const emptyRowInitialData = (key: string) => {
		return {
			key: key,
			id: 0,
			documentType: '',
			attachmentType: Enums.AttachmentTypeEnum.PhysicalCopy.toString(),
			eDocumentNumber: '',
			eDocumentDate: undefined,
			eDocumentDoctorName: '',
			fileName: '',
			description: '',
			file: undefined,
			toAttachmentId: 0,
			lastModifiedByUsername: ' ',
			lastModifiedOn: null,
		};
	};

	const isEditing = (record: IAttachment) => record.key === editingKey;

	const edit = (record: IAttachment) => {
		setIsElectronicDocumentAttaching(false);
		setUploadEditMode(false);

		if (electronicDocumentById) {
			setTemporaryData(electronicDocumentById!.attachments);
		} else {
			setTemporaryData(attachmentsOriginData!);
		}
		form.setFieldsValue({ ...record });
		setEditingKey(record.key);
		setIsUploadFormTouched(false);
	};

	const handleAdd = () => {
		setUploadEditMode(false);
		setTemporaryData(attachmentsOriginData!);
		const newData = [
			...attachmentsOriginData!,
			emptyRowInitialData(count.toString()),
		];
		setAttachmentsOriginData(newData);
		form.resetFields();
		if (electronicDocumentById) {
			setCount(newData!.length + 1);
		} else {
			setCount(count + 1);
		}

		setEditingKey(count.toString());
		setIsUploadFormTouched(false);
	};

	const handleCancel = (record: IAttachment) => {
		if (temporaryData.length === 0) {
			const newData = [emptyRowInitialData('1')];

			record = newData[0];
			setAttachmentsOriginData(newData);
			setEditingKey('1');
			setUploadEditMode(false);

			form.resetFields();
		} else {
			let newData = toJS(temporaryData);
			for (let index = 0; index < newData.length; index++) {
				newData[index].key = (index + 1).toString();
			}
			setCount(newData!.length + 1);
			setAttachmentsOriginData(newData);
			setEditingKey('');
			setUploadEditMode(true);
		}

		setIsUploadFormTouched(false);
	};

	const save = async (key: React.Key) => {
		try {
			const row = (await form.validateFields()) as IAttachment;
			const newData = toJS(attachmentsOriginData);

			const index = newData!.findIndex((item) => key === item.key);
			if (electronicDocumentById) {
				const item = newData![index];
				item.documentType = row.documentType;
				item.attachmentType =
					row.attachmentType ??
					Enums.AttachmentTypeEnum.PhysicalCopy.toString();

				if (!isElectronicDocumentAttaching) {
					item.fileName = row.fileName.replace(/C:\\fakepath\\/i, '');
					item.description = row.description;
				}

				if (
					temporaryData[index] &&
					(temporaryData[index].documentType !== item.documentType ||
						temporaryData[index].description !== item.description)
				) {
					//Edit mode
					let updatedAttachment: IDynamicAttachmentMetadata = {
						key: item.key,
						parentRecordId: electronicDocumentById.id,
						description: item.description,
						documentType: item.documentType,
						attachmentRecordId: item.id,
						attachmentType: item.attachmentType,
						toAttachmentId: 0,
					};

					await updateAttachment(updatedAttachment, item.key);
					setActivateSendEmailButton(true);
				} else {
					let formData = new FormData();
					let newAttachment: IDynamicAttachmentMetadata = {
						key: item.key,
						parentRecordId: electronicDocumentById.id,
						description: item.description,
						documentType: item.documentType,
						attachmentRecordId: 0,
						attachmentType: item.attachmentType,
						toAttachmentId: item.toAttachmentId,
					};
					formData.set('request', JSON.stringify(newAttachment));

					if (!isElectronicDocumentAttaching) {
						formData.append(`file`, item.file!);
					}

					await createAttachment(formData, item.key);
					setActivateSendEmailButton(true);
				}
			} else {
				if (!isElectronicDocumentAttaching) {
					newData![index].documentType = row.documentType;
					newData![index].attachmentType = row.attachmentType;
					newData![index].description = row.description;
					newData![index].fileName = row.fileName.replace(
						/C:\\fakepath\\/i,
						''
					);
				}
				setAttachmentsOriginData(newData);
			}
			setEditingKey('');
			setUploadEditMode(true);
			setIsUploadFormTouched(false);
		} catch (errInfo) {
			notification.error({
				message: 'Възникна грешка!',
				className: 'success-messages',
				duration: 5,
			});
		}
	};

	const handleDelete = async (key: any) => {
		let newData = toJS(attachmentsOriginData)!.filter(
			(item) => item.key !== key
		);
		for (let index = 0; index < newData.length; index++) {
			newData[index].key = (index + 1).toString();
		}

		if (electronicDocumentById) {
			let currentAttachment = toJS(attachmentsOriginData)!.filter(
				(item) => item.key === key
			);
			await deleteAttachment(currentAttachment[0].id);
			setActivateSendEmailButton(true);
			setUploadEditMode(true);
			setCount(count - 1);
		} else {
			setAttachmentsOriginData(newData);
			setCount(count - 1);
		}

		if (attachmentsOriginData!.length === 1) {
			const newData = [emptyRowInitialData('1')];
			setAttachmentsOriginData(newData);
			setEditingKey('1');
			setUploadEditMode(false);
		}
		setIsUploadFormTouched(false);
	};

	const preview = async (id: number) => {
		if (id) {
			await getElectronicSubDocumentById(id);
		}
		setShow(true);
	};

	const download = async (
		patientUid: string,
		fileName: string,
		documentType: string
	) => {
		let model: IDownloadAttachment = {
			documentType: documentType,
			patientUid: patientUid,
			fileName: fileName,
		};

		await downloadAttachment(model);

		if (rootStore.electronicDocumentStore.attachmentDownloadResult) {
			const url = window.URL.createObjectURL(
				new Blob([
					rootStore.electronicDocumentStore.attachmentDownloadResult!,
				])
			);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `${model.documentType}.pdf`);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	};

	const closeSubModal = async () => {
		setShow(false);
	};

	const disableOption =
		electronicDocumentById?.isActive === false ||
		electronicDocumentById?.status ===
			Constants.MedicalReferralStatus.Performed ||
		electronicDocumentById?.documentStatus ===
			Constants.DocumentProcessStatus.Open ||
		electronicDocumentById?.documentStatus ===
			Constants.DocumentProcessStatus.Approved ||
		electronicDocumentById?.documentStatus ===
			Constants.DocumentProcessStatus.Rejected;

	const disableNoEditingKey =
		editingKey !== '' ||
		attachmentsOriginData?.length === 0 ||
		(electronicDocumentById &&
			electronicDocumentById?.documentType.toString() ===
				Constants.DocumentType.PriorApproval &&
			electronicDocumentById?.documentStatus !==
				Constants.DocumentProcessStatus.Registered);

	const contentInfo = (row: any) =>
		row.lastModifiedOn ? (
			<Fragment>
				<div>
					{' '}
					Записът е създаден на:{' '}
					<Moment format={Constants.Common.FullDate_Format}>
						{row.createdOn}
					</Moment>
					, от потребител: {row.lastModifiedByUsername}{' '}
				</div>
				<div>
					Последна редакция на дата:{' '}
					<Moment format={Constants.Common.FullDate_Format}>
						{row.lastModifiedOn}
					</Moment>
					, от потребител: {row.lastModifiedByUsername}
				</div>
			</Fragment>
		) : null;

	const columns = [
		{
			title: 'No',
			dataIndex: 'key',
			key: 'key',
			width: '4%',
			render: (row: any, record: any) => {
				return <div className='table-div-centered'>{record.key}</div>;
			},
		},
		{
			dataIndex: 'id',
			key: 'id',
			className: 'hide-col',
		},
		{
			title: 'Тип документ',
			dataIndex: 'attachmentType',
			key: 'attachmentType',
			editable: true,
			render: (row: any, record: any) => {
				return (
					<div
						className='table-div-centered'
						style={{ minWidth: '100px' }}>
						{record.attachmentType}
					</div>
				);
			},
		},
		{
			dataIndex: 'toAttachmentId',
			key: 'toAttachmentId',
			className: 'hide-col',
		},
		{
			title: 'Съдържание тип',
			dataIndex: 'documentType',
			key: 'documentType',
			editable: true,
			render: (row: any, record: any) => {
				return (
					<div style={{ minWidth: '200px' }}>
						{record.documentType}
					</div>
				);
			},
		},
		{
			title: 'Номер на документ',
			dataIndex: 'eDocumentNumber',
			key: 'eDocumentNumber',
			className:
				rootStore.electronicDocumentStore.typeOfDocument ===
					Constants.DocumentType.MedicalReferral ||
				rootStore.electronicDocumentStore.typeOfDocument ===
					Constants.DocumentType.Preventions
					? 'hide-col'
					: '',
			editable: false,
			render: (row: any, record: any) => {
				return (
					<div
						className='table-div-centered'
						style={{
							minWidth:
								rootStore.electronicDocumentStore
									.typeOfDocument ===
									Constants.DocumentType.MedicalReferral ||
								rootStore.electronicDocumentStore
									.typeOfDocument ===
									Constants.DocumentType.Prescription
									? '0px'
									: '100px',
						}}>
						{record.eDocumentNumber}
					</div>
				);
			},
		},
		{
			title: 'Дата на документ',
			dataIndex: 'eDocumentDate',
			key: 'eDocumentDate',
			className:
				rootStore.electronicDocumentStore.typeOfDocument ===
					Constants.DocumentType.MedicalReferral ||
				rootStore.electronicDocumentStore.typeOfDocument ===
					Constants.DocumentType.Preventions
					? 'hide-col'
					: '',
			editable: false,
			render: (row: any, record: any) => {
				return (
					<div
						className='table-div-centered'
						style={{
							minWidth:
								rootStore.electronicDocumentStore
									.typeOfDocument ===
									Constants.DocumentType.MedicalReferral ||
								rootStore.electronicDocumentStore
									.typeOfDocument ===
									Constants.DocumentType.Prescription
									? '0px'
									: '100px',
						}}>
						{record.eDocumentDate ? (
							<Moment format={Constants.Common.ShortDate_Format}>
								{record.eDocumentDate}
							</Moment>
						) : null}
					</div>
				);
			},
		},

		{
			dataIndex: 'file',
			key: 'file',
			className: 'hide-col',
		},
		{
			title: 'Допълнителна информация / Лекар',
			dataIndex: !isElectronicDocumentAttaching
				? 'description'
				: 'eDocumentDoctorName',
			key: !isElectronicDocumentAttaching
				? 'description'
				: 'eDocumentDoctorName',
			editable: !isElectronicDocumentAttaching ? true : false,
			render: (row: any, record: any) => {
				return (
					<div style={{ minWidth: '200px' }}>
						{record.description
							? record.description
							: record.eDocumentDoctorName
							? record.eDocumentDoctorName
							: ''}
					</div>
				);
			},
		},
		{
			title: 'Преглед / Запазване',
			dataIndex: 'fileName',
			key: 'fileName',
			width: electronicDocumentById?.isActive === false ? '5%' : '',
			editable: !isElectronicDocumentAttaching ? true : false,
			render: (row: any, record: any) => {
				return record.id && electronicDocumentById ? (
					<div className='table-div-centered'>
						<FileSearchOutlined
							onClick={() =>
								record.attachmentType ===
								Constants.AttachmentType.PhysicalCopy
									? download(
											electronicDocumentById!.patientUid,
											record.fileName,
											record.documentType
									  )
									: preview(record!.toAttachmentId)
							}
							style={{
								color: '#e48800',
								fontSize: 16,
								fontWeight: 700,
							}}
						/>
					</div>
				) : (
					''
				);
			},
		},
		{
			title: '',
			dataIndex: 'operation',
			className: disableOption ? 'hide-col' : '',
			width: disableOption ? '0%' : '',
			render: (_: any, record: IAttachment) => {
				const editable = isEditing(record);
				return editable ? (
					<div className='table-div-centered'>
						<Tooltip
							title='Запази'
							destroyTooltipOnHide={!isUploadFormTouched}>
							<CheckCircleOutlined
								disabled={!isUploadFormTouched}
								onClick={() => save(record.key)}
								style={{
									color: !isUploadFormTouched
										? 'grey'
										: 'green',
									fontSize: 16,
									fontWeight: 700,
								}}
							/>
						</Tooltip>
					</div>
				) : (
					<div className='table-div-centered'>
						<Tooltip
							title='Редактирай'
							destroyTooltipOnHide={
								editingKey !== '' ||
								record.attachmentType ===
									Constants.AttachmentType
										.ElectronicDocument ||
								(electronicDocumentById &&
									electronicDocumentById?.documentType.toString() ===
										Constants.DocumentType.PriorApproval &&
									electronicDocumentById?.documentStatus !==
										Constants.DocumentProcessStatus
											.Registered) ||
								electronicDocumentById?.status ===
									Constants.MedicalReferralStatus.Performed
							}>
							<FormOutlined
								onClick={() => {
									edit(record);
									setRowKey('');
								}}
								hidden={
									electronicDocumentById?.status ===
										Constants.MedicalReferralStatus
											.Performed ||
									editingKey !== '' ||
									record.attachmentType ===
										Constants.AttachmentType
											.ElectronicDocument ||
									(electronicDocumentById &&
										electronicDocumentById?.documentType.toString() ===
											Constants.DocumentType
												.PriorApproval &&
										electronicDocumentById?.documentStatus !==
											Constants.DocumentProcessStatus
												.Registered)
								}
								style={{
									color:
										editingKey !== '' ||
										(electronicDocumentById &&
											electronicDocumentById?.documentType.toString() ===
												Constants.DocumentType
													.PriorApproval &&
											electronicDocumentById?.documentStatus !==
												Constants.DocumentProcessStatus
													.Registered)
											? 'grey'
											: 'blue',
									fontSize: 16,
									fontWeight: 700,
								}}
							/>
						</Tooltip>
					</div>
				);
			},
		},
		{
			title: '',
			dataIndex: 'operation',
			className: disableOption ? 'hide-col' : '',
			width: disableOption ? '0%' : '',
			render: (text: any, record: IAttachment) => {
				const editable = isEditing(record);
				return editable ? (
					<div
						className='table-div-centered'
						style={{
							display:
								attachmentsOriginData?.length === 0 &&
								!electronicDocumentById
									? 'none'
									: 'block',
						}}
						onClick={() => {
							handleCancel(record);
						}}>
						<Tooltip title='Отказ'>
							<CloseOutlined
								style={{
									color: 'red',
									fontSize: 16,
									fontWeight: 700,
								}}
							/>
						</Tooltip>
					</div>
				) : (
					<div className='table-div-centered'>
						<Popconfirm
							title='Сигурни ли сте, че искате да изтриете реда?'
							onConfirm={() => handleDelete(record.key)}
							disabled={disableNoEditingKey}>
							<Tooltip title='Изтриване'>
								<DeleteOutlined
									hidden={
										electronicDocumentById?.status ===
											Constants.MedicalReferralStatus
												.Performed ||
										disableNoEditingKey
									}
									style={{
										color: disableNoEditingKey
											? 'grey'
											: 'red',
										fontSize: 16,
										fontWeight: 700,
									}}
								/>
							</Tooltip>
						</Popconfirm>
					</div>
				);
			},
		},
		{
			title: '',
			dataIndex: '',
			key: '',
			width: '1%',
			render: (_text: any, row: any, index: any) => (
				<div className='table-div-centered'>
					<Popover
						content={contentInfo(row)}
						trigger='hover'
						placement='topRight'
						style={{ fontSize: 18 }}>
						<InfoCircleOutlined />
					</Popover>
				</div>
			),
		},
	];

	const mergedColumns = columns.map((col) => {
		if (!col.editable) {
			return col;
		}
		return {
			...col,
			onCell: (record: IAttachment) => ({
				record,
				inputType: col.dataIndex === 'count' ? 'number' : 'text',
				dataIndex: col.dataIndex,
				title: col.title,
				editing: isEditing(record),
				formTmp: form,
			}),
		};
	});

	return (
		<Form form={form} component={false}>
			<Button
				onClick={() => {
					handleAdd();
				}}
				type='primary'
				className='add-file-btn'
				disabled={editingKey !== ''}
				hidden={disableOption}>
				Добави нов прикачен документ
			</Button>
			<Table
				onRow={(record, index) => {
					return {
						onDoubleClick: () => {
							setRowClassName(
								'ant-table-row ant-table-row-level-0 ant-table-row-selected'
							);
							setRowKey(record.key);
						},
					};
				}}
				components={{
					body: {
						cell: UploadEditableCell,
					},
				}}
				dataSource={attachmentsOriginData}
				columns={mergedColumns}
				pagination={false}
				rowClassName={(_record, index) => {
					return _record.key === rowKey ? rowClassName : '';
				}}
			/>
			<Modal
				className='appointment-modal'
				title={'Преглед на електронен документ'}
				centered
				maskClosable={false}
				transitionName='none'
				maskTransitionName='none'
				onCancel={() => closeSubModal()}
				visible={show}
				footer={false}
				destroyOnClose={true}>
				{' '}
				<ElectonicDocumentPreveiw />
			</Modal>
		</Form>
	);
};

export default observer(UploadEditableTable);
