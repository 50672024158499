import { observer } from 'mobx-react-lite';
import React from 'react';
import LaboratoryResultsTable from './LaboratoryResultsTable';

const LaboratoryResults: React.FC = () => {
	return (
		<div>
			<LaboratoryResultsTable />
		</div>
	);
};
export default observer(LaboratoryResults);
