import { notification } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { action, observable, runInAction, toJS } from 'mobx';
import moment from 'moment';
import agent from '../../api/agent';
import { IByPeriodSearch } from '../models/electronicDocument/ByPeriodSearch';
import { IElectronicDocument } from '../models/electronicDocument/ElectronicDocument';
import { IServiceCorrelation } from '../models/servicesCorrelations/ServiceCorrelation';
import { RootStore } from './rootStore';

export default class PreventionsStore {
	rootStore: RootStore;

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
	}

	@observable allPreventions: IElectronicDocument[] | undefined;
	@observable period: IByPeriodSearch = {
		dateFrom: moment().startOf('month').local().toDate(),
		dateTo: moment().endOf('month').local().toDate(),
	};

	@observable
	filteredServiceCorelationsForPreventions = [] as IServiceCorrelation[];

	@observable detailsCount: number;
	@observable detailsEditingKey: string;
	@observable detailsEditMode = false;
	@observable detailsForm: FormInstance;
	@observable isLongListIsDisable = true;

	@observable attachCount: number;
	@observable attachEditingKey: string;
	@observable attachForm: FormInstance;

	@observable filterType: string;

	@action setFilterType = (value: string) => {
		this.filterType = value;
	};

	@action setCount = (num: number) => {
		this.detailsCount = num;
	};

	@action setEditingKey = (val: string) => {
		this.detailsEditingKey = val;
	};

	@action setEditMode = (bool: boolean) => {
		this.detailsEditMode = bool;
	};

	@action setIsLongListIsDisable = (bool: boolean) => {
		this.isLongListIsDisable = bool;
	};

	@action setAttachCount = (num: number) => {
		this.attachCount = num;
	};

	@action setAttachEditingKey = (val: string) => {
		this.attachEditingKey = val;
	};

	@action getPreventionsByPeriod = async (period: IByPeriodSearch) => {
		this.rootStore.loadersStore.setLoadingAllTables(true);
		try {
			let res = [] as IElectronicDocument[];
			if (this.filterType !== undefined && this.filterType.length > 0) {
				switch (this.filterType) {
					case 'all':
						res = await agent.Prevention.getAllByPeriod(period);
						break;
					case 'active':
						res = await agent.Prevention.getActiveByPeriod(period);
						break;
					case 'editable':
						res = await agent.Prevention.getForEditingByPeriod(
							period
						);
						runInAction(() => {
							if (res && res.length > 0) {
								this.setPeriod(
									res[0].documentDate,
									moment().local().endOf('month').toDate()
								);
								notification.warning({
									message: 'Периодът беше сменен!',
									className: 'success-messages',
									duration: 5,
								});
							}
						});
						break;
				}
			} else {
				res = await agent.Prevention.getActiveByPeriod(period);
				this.setFilterType('active');
			}

			runInAction(() => {
				this.allPreventions = res;
				this.rootStore.electronicDocumentStore.setGetElDocsAgain(false);
				this.rootStore.loadersStore.setLoadingAllTables(false);
			});
		} catch (error) {
			notification.error({
				message: error.data,
				className: 'success-messages',
				duration: 5,
			});
			this.rootStore.loadersStore.setLoadingAllTables(false);
		}
	};

	@action resetDetailsOriginData = () => {
		if (
			this.rootStore.electronicDocumentStore.electronicDocumentById ===
			undefined
		) {
			if (
				this.rootStore.electronicDocumentStore.detailsOriginData !==
					undefined &&
				this.rootStore.electronicDocumentStore.detailsOriginData
					.length > 0
			) {
				this.rootStore.electronicDocumentStore.detailsOriginData = [
					{
						key: '1',
						code: '',
						systemCode: '',
						nhifCode: '',
						name: ' ',
						count: 1,
						description: '',
						id: 0,
						toMainId: 0,
						lastModifiedByUsername: ' ',
						lastModifiedOn: null,
						price: '0.00',
						totalAmount: '',
						isPayable: true,
						specialityCode: '',
						specialityName: '',
					},
				];
				this.rootStore.electronicDocumentStore.setDetailsEditMode(true);
				this.detailsForm?.resetFields();
				this.detailsForm?.setFieldsValue({
					price: '',
					name: '',
					description: '',
					specialityCode: '',
				});
				this.setEditMode(true);
				this.setEditingKey('1');
				this.setCount(2);
			}
		}
	};

	@action spliceAllFilteredServiceCorrelationsForPreventions = () => {
		// this.resetDetailsOriginData();
		this.filteredServiceCorelationsForPreventions.splice(
			0,
			this.filteredServiceCorelationsForPreventions.length
		);
	};

	@action filterServiceCorelationsOnChangeCompanyName = async (
		insuranceCompanyId: any,
		specialityCode: any
	) => {
		this.spliceAllFilteredServiceCorrelationsForPreventions();

		if (
			this.rootStore.servicesCorrelationsStore.servicesCorrelations ===
			undefined
		) {
			await this.rootStore.servicesCorrelationsStore.getServicesCorrelations();
		}

		let tempServiceCorrelationArray = toJS(
			this.rootStore.servicesCorrelationsStore.servicesCorrelations
		);

		if (
			specialityCode !== undefined &&
			specialityCode !== null &&
			specialityCode.length > 0
		) {
			tempServiceCorrelationArray?.forEach((sc) => {
				let modifiedPrices = sc.servicePrices.filter(
					(el) =>
						(el.insuranceCompanyId === insuranceCompanyId &&
							el.specialityCode === specialityCode &&
							el.prophylacticPrice?.length > 0) ||
						(el.insuranceCompanyId === insuranceCompanyId &&
							el.specialityCode.length === 0 &&
							el.prophylacticPrice?.length > 0)
				);
				if (modifiedPrices.length !== 0) {
					sc.servicePrices = modifiedPrices;
					runInAction(() => {
						this.filteredServiceCorelationsForPreventions.push(sc);
					});
				}
			});
		} else {
			tempServiceCorrelationArray?.forEach((sc) => {
				let modifiedPrices = sc.servicePrices.filter(
					(el) =>
						el.insuranceCompanyId === insuranceCompanyId &&
						el.prophylacticPrice?.length > 0 &&
						el.specialityCode.length === 0
				);
				if (modifiedPrices.length !== 0) {
					sc.servicePrices = modifiedPrices;
					runInAction(() => {
						this.filteredServiceCorelationsForPreventions.push(sc);
					});
				}
			});
		}
	};

	@action sortPreventions = (
		comparer: (a: any, b: any) => 1 | -1,
		sortDirection: string
	) => {
		if (sortDirection !== 'NONE') {
			let sorted = [...this.allPreventions].sort(comparer);
			this.allPreventions = sorted;
		}
	};

	@action setPeriod = (dateFrom: Date, dateTo: Date) => {
		this.period.dateFrom = dateFrom;
		this.period.dateTo = dateTo;
	};

	@action removeAllPreventions = () => {
		this.allPreventions = undefined;
		this.spliceAllFilteredServiceCorrelationsForPreventions();
		this.setPeriod(
			moment().startOf('month').local().toDate(),
			moment().endOf('month').local().toDate()
		);
	};
}
