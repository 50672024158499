import React, { useState, useContext, Fragment, useEffect } from 'react';
import { IElectronicDocument } from '../../../app/models/electronicDocument/ElectronicDocument';
import Moment from 'react-moment';
import { Button, Input, Table, Space } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { TablePaginationConfig } from 'antd/lib/table';
import { SyncOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react-lite';
import ViewMedicalReferralModal from './ViewMedicalReferralModal';
import { RootStoreContext } from '../../../app/stores/rootStore';
import Constants from '../../../app/constants/constants';
import { DateRangePicker } from 'rsuite';

const LastCheckedReferralTable: React.FC<TablePaginationConfig> = ({
	total,
}) => {
	const [show, setShow] = useState(false);
	const [rowClassName, setRowClassName] = useState(
		'ant-table-row ant-table-row-level-0'
	);

	const [filterTable, setFilterTable] = useState([] as IElectronicDocument[]);
	const [rowKey, setRowKey] = useState();

	const rootStore = useContext(RootStoreContext);

	const {
		setAllPerformedMedicalReferrals,
		allPerformedMedicalReferrals,
		performedPeriod,
		setPerformedPeriod,
	} = rootStore.medicalReferralStore;
	const { loadingAllTable } = rootStore.loadersStore;
	const { currentUser } = rootStore.identityStore;
	const { getDoctorByUin } = rootStore.operationsStore;

	const {
		getElectronicDocumentById,
		setCurrentSelectedElectronicDocument,
		setDetailsOriginDataFromElectronicDocument,
		setAttachmentsOriginDataFromElectronicDocument,
		setDetailsOriginData,
		setAttachmentsOriginData,
		electronicDocumentById,
		setTypeOfDocument,
	} = rootStore.electronicDocumentStore;

	const openModal = async (id: number | undefined) => {
		if (id) {
			await getElectronicDocumentById(id);
		}
		setTypeOfDocument(Constants.DocumentType.MedicalReferral);
		setShow(true);
	};

	const closeModal = async () => {
		setShow(false);
		setCurrentSelectedElectronicDocument(undefined);
		setDetailsOriginDataFromElectronicDocument(undefined);
		setAttachmentsOriginDataFromElectronicDocument(undefined);
		setDetailsOriginData(undefined);
		setAttachmentsOriginData(undefined);
		await setAllPerformedMedicalReferrals(performedPeriod);
		setTypeOfDocument(undefined);
	};

	useEffect(() => {
		return () => {
			if (!currentUser.uinCode || currentUser.uinCode.length === 0) {
				getDoctorByUin(undefined);
			}
		};
	}, []);

	const compareByAlph = (a: any, b: any) => {
		if (a > b) {
			return -1;
		}
		if (a < b) {
			return 1;
		}
		return 0;
	};

	const search = (value: any) => {
		if (value.length >= 1) {
			const tableFilterResult = data!.filter((o: any) =>
				Object.keys(o).some((k) =>
					String(o[k]).toLowerCase().includes(value.toLowerCase())
				)
			);
			setFilterTable(tableFilterResult);
		} else if (value.length === 0) {
			setFilterTable([] as IElectronicDocument[]);
		}
	};

	const data = allPerformedMedicalReferrals;

	const changeDatesRange = (dates: Date[]) => {
		setPerformedPeriod(dates[0], dates[1]);
		setFilterTable([] as IElectronicDocument[]);
		setAllPerformedMedicalReferrals(
			rootStore.medicalReferralStore.performedPeriod
		);
	};
	const columns = [
		{
			title: 'Документ №',
			dataIndex: 'documentNumber',
			key: 'documentNumber',
			width: '7%',
			sorter: true,
			align: 'center' as 'center',
		},
		{
			title: 'Дата на назначаване',
			dataIndex: 'createdOn',
			key: 'createdOn',
			width: '10%',
			align: 'center' as 'center',
			sorter: (a: any, b: any) => {
				return compareByAlph(a.createdOn, b.createdOn);
			},
			render: (_text: any, row: any, index: any) => (
				<Moment format={Constants.Common.FullDate_Format}>
					{row.createdOn}
				</Moment>
			),
		},
		{
			title: 'УИН на назначил',
			dataIndex: 'issuerUin',
			key: 'issuerUin',
			width: '10%',
			sorter: true,
			align: 'center' as 'center',
		},
		{
			title: 'Дата на запазване',
			dataIndex: 'usedOn',
			key: 'usedOn',
			width: '10%',
			align: 'center' as 'center',
			sorter: (a: any, b: any) => {
				return compareByAlph(a.usedOn, b.usedOn);
			},
			render: (_text: any, row: any, index: any) => (
				<Moment format={Constants.Common.FullDate_Format}>
					{row.usedOn}
				</Moment>
			),
		},
		{
			title: 'УИН на запазил ',
			dataIndex: 'performerUin',
			key: 'performerUin',
			width: '10%',
			align: 'center' as 'center',
			sorter: true,
		},
		{
			title: 'Име на запазилия лекар',
			dataIndex: 'performerName',
			key: 'performerName',
			width: '24%',
			align: 'center' as 'center',
			sorter: (a: any, b: any) => {
				return compareByAlph(a.performerName, b.performerName);
			},
		},
		{
			title: 'ЕГН на пациент',
			dataIndex: 'patientUid',
			key: 'patientUid',
			width: '12%',
			sorter: true,
			align: 'center' as 'center',
		},
		{
			title: 'Име на пациент',
			dataIndex: 'patientName',
			key: 'patientName',
			width: '20%',
			align: 'center' as 'center',
			sorter: (a: any, b: any) => {
				return compareByAlph(a.patientName, b.patientName);
			},
		},
	];

	const title = (
		<div>
			Mедицинско назначение № {electronicDocumentById?.documentNumber} от{' '}
			<Moment format={Constants.Common.FullDate_Format}>
				{electronicDocumentById?.createdOn}
			</Moment>{' '}
		</div>
	);

	return (
		<Fragment>
			<Space className='space-table-head'>
				<Button
					className='btn-groupe'
					style={{
						background: '#234254',
					}}
					type='primary'
					icon={<SyncOutlined />}
					onClick={async () =>
						await setAllPerformedMedicalReferrals(performedPeriod)
					}>
					Опресняване
				</Button>
				<DateRangePicker
					placeholder={['дд.мм.гггг', 'дд.мм.гггг']}
					cleanable={false}
					className='date-range-tables'
					format={Constants.Common.ShortDate_Format}
					value={[
						rootStore.medicalReferralStore.performedPeriod.dateFrom,
						rootStore.medicalReferralStore.performedPeriod.dateTo,
					]}
					onOk={(date: Date[]) => changeDatesRange(date)}
					locale={Constants.ReactSuiteCalendarLocale}
					size='xs'
					color='green'
					ranges={[]}
					isoWeek={true}
				/>
				<Input.Search
					className='search-input'
					placeholder='Търсене...'
					enterButton
					onSearch={search}
				/>
			</Space>
			<Table
				onRow={(record, index) => {
					return {
						onClick: () => {
							setRowClassName(
								'ant-table-row ant-table-row-level-0 ant-table-row-selected'
							);
							setRowKey(record.id);
						},
						onDoubleClick: async () => {
							await openModal(record.id);
						},
					};
				}}
				loading={loadingAllTable}
				columns={columns}
				dataSource={filterTable.length < 1 ? data : filterTable}
				locale={{ emptyText: 'Няма налични данни' }}
				sortDirections={['descend', 'ascend']}
				showSorterTooltip={false}
				pagination={{
					showSizeChanger: true,
					showQuickJumper: true,
					total: total,
					showTotal: (total) => `Брой резултати: ${total}`,
				}}
				rowClassName={(_record, index) => {
					return _record.id === rowKey ? rowClassName : '';
				}}
				rowKey={rowKey}
			/>

			<Modal
				className='appointment-modal'
				title={title}
				centered
				maskClosable={false}
				transitionName='none'
				maskTransitionName='none'
				onCancel={async () => await closeModal()}
				visible={show}
				footer={false}
				destroyOnClose={true}>
				{' '}
				<ViewMedicalReferralModal
					openModal={openModal}
					closeModal={closeModal}
				/>
			</Modal>
		</Fragment>
	);
};
export default observer(LastCheckedReferralTable);
