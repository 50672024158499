import React, { Fragment, useState, useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Input, Table, Space, Button, Modal, Popconfirm, Popover } from 'antd';
import { TablePaginationConfig } from 'antd/lib/table';
import {
	PlusOutlined,
	SyncOutlined,
	CloseOutlined,
	InfoCircleOutlined,
} from '@ant-design/icons';
import Title from 'antd/lib/typography/Title';
import CreateReferralModal from './CreateReferralModal';
import { IElectronicDocument } from '../../../app/models/electronicDocument/ElectronicDocument';
import Moment from 'react-moment';
import { RootStoreContext } from '../../../app/stores/rootStore';
import Constants from '../../../app/constants/constants';
import { DateRangePicker } from 'rsuite';

const CreateReferralTable: React.FC<TablePaginationConfig> = ({ total }) => {
	const [show, setShow] = useState(false);
	const [rowClassName, setRowClassName] = useState(
		'ant-table-row ant-table-row-level-0'
	);
	const [filterTable, setFilterTable] = useState([] as IElectronicDocument[]);
	const [rowKey, setRowKey] = useState();

	const rootStore = useContext(RootStoreContext);

	const {
		setAllIssuedMedicalReferrals,
		setActivateSendEmailButton,
		allIssuedMedicalRefferals,
		issuedPeriod,
		setIssuedPeriod,
		removeAllIssuedMedicalReferrals,
	} = rootStore.medicalReferralStore;

	const { loadingAllTable } = rootStore.loadersStore;
	const { getPatientByEgn, getDoctorByUin } = rootStore.operationsStore;

	const {
		getElectronicDocumentById,
		setCurrentSelectedElectronicDocument,
		setDetailsOriginDataFromElectronicDocument,
		setAttachmentsOriginDataFromElectronicDocument,
		setDetailsOriginData,
		setAttachmentsOriginData,
		electronicDocumentById,
		setDetailsEditMode,
		setUploadEditMode,
		setTypeOfDocument,
		isFormTouched,
		setIsFormTouched,
		setIsUploadFormTouched,
		setIsDetailsFormTouched,
	} = rootStore.electronicDocumentStore;

	const { currentUser } = rootStore.identityStore;

	const openModal = async (id: number | undefined) => {
		if (id) {
			await getElectronicDocumentById(id);

			if (
				rootStore.electronicDocumentStore.electronicDocumentById
					?.attachments &&
				rootStore.electronicDocumentStore.electronicDocumentById!
					.attachments?.length >= 1
			) {
				setUploadEditMode(true);
			} else {
				setUploadEditMode(false);
			}
		} else {
			setDetailsEditMode(true);
			setUploadEditMode(false);
			setDetailsOriginDataFromElectronicDocument(undefined);
			setAttachmentsOriginDataFromElectronicDocument(undefined);
			setDetailsOriginData(undefined);
			setAttachmentsOriginData(undefined);
			await getElectronicDocumentById(undefined);
		}
		setTypeOfDocument(Constants.DocumentType.MedicalReferral);
		setShow(true);
	};

	const closeModal = async () => {
		setShow(false);
		if (rootStore.electronicDocumentStore.getElDocsAgain) {
			await setAllIssuedMedicalReferrals(issuedPeriod);
			rootStore.electronicDocumentStore.setGetElDocsAgain(false);
		}

		setCurrentSelectedElectronicDocument(undefined);
		setDetailsOriginDataFromElectronicDocument(undefined);
		setAttachmentsOriginDataFromElectronicDocument(undefined);
		setDetailsOriginData(undefined);
		setAttachmentsOriginData(undefined);
		setDetailsEditMode(false);
		setIsFormTouched(false);
		setIsDetailsFormTouched(false);
		setIsUploadFormTouched(false);
		setActivateSendEmailButton(false);
		await getPatientByEgn(undefined);
		setTypeOfDocument(undefined);
		if (!currentUser.uinCode || currentUser.uinCode.length === 0) {
			await getDoctorByUin(undefined);
		}
	};

	const compareByAlph = (a: any, b: any) => {
		if (a > b) {
			return -1;
		}
		if (a < b) {
			return 1;
		}
		return 0;
	};

	const search = (value: any) => {
		if (value.length >= 1) {
			const tableFilterResult = data!.filter((o: any) =>
				Object.keys(o).some((k) =>
					String(o[k]).toLowerCase().includes(value.toLowerCase())
				)
			);
			setFilterTable(tableFilterResult);
		} else if (value.length === 0) {
			setFilterTable([] as IElectronicDocument[]);
		}
	};

	useEffect(() => {
		if (!allIssuedMedicalRefferals) {
			setAllIssuedMedicalReferrals(issuedPeriod);
		}

		return () => {
			removeAllIssuedMedicalReferrals();
		};
	}, []);

	const data = allIssuedMedicalRefferals;

	const changeDatesRange = (dates: Date[]) => {
		setIssuedPeriod(dates[0], dates[1]);
		setFilterTable([] as IElectronicDocument[]);
		setAllIssuedMedicalReferrals(
			rootStore.medicalReferralStore.issuedPeriod
		);
	};

	const contentInfo = (row: any) => (
		<div>
			Последна редакция на дата:{' '}
			<Moment format={Constants.Common.FullDate_Format}>
				{row.lastModifiedOn}
			</Moment>
			, от потребител: {row.lastModifiedByUsername}
		</div>
	);

	const columns = [
		{
			title: 'Документ №',
			dataIndex: 'documentNumber',
			key: 'documentNumber',
			width: '7%',
			align: 'center' as 'center',
		},
		{
			title: 'Дата',
			dataIndex: 'createdOn',
			key: 'createdOn',
			width: '15%',
			align: 'center' as 'center',
			render: (_text: any, row: any, index: any) => (
				<Moment format={Constants.Common.FullDate_Format}>
					{row.createdOn}
				</Moment>
			),
		},
		{
			title: 'УИН',
			dataIndex: 'issuerUin',
			key: 'issuerUin',
			width: '8%',
			align: 'center' as 'center',
			sorter: true,
		},
		{
			title: 'Име на лекар',
			dataIndex: 'issuerName',
			key: 'issuerName',
			width: '24%',
			align: 'center' as 'center',
			sorter: (a: any, b: any) => {
				return compareByAlph(a.issuerName, b.issuerName);
			},
		},
		{
			title: 'ЕГН на пациент',
			dataIndex: 'patientUid',
			key: 'patientUid',
			width: '8%',
			sorter: true,
			align: 'center' as 'center',
		},
		{
			title: 'Име на пациент',
			dataIndex: 'patientName',
			key: 'patientName',
			width: '20%',
			align: 'center' as 'center',
			sorter: (a: any, b: any) => {
				return compareByAlph(a.patientName, b.patientName);
			},
		},
		{
			title: 'Статус',
			dataIndex: 'status',
			key: 'status',
			width: '8%',
			align: 'center' as 'center',
			sorter: (a: any, b: any) => {
				return compareByAlph(a.status, b.status);
			},
			render: (_text: any, row: any, index: any) => (
				<span
					style={{
						color:
							row.status ===
							Constants.MedicalReferralStatus.NotPerformed
								? 'green'
								: 'red',
					}}>
					{row.status}
				</span>
			),
		},
		{
			title: 'Активен',
			dataIndex: 'isActive',
			key: 'isActive',
			width: '7%',
			align: 'center' as 'center',
			render: (_text: any, row: any, index: any) => (
				<span
					style={{
						color: row.isActive === false ? 'red' : 'green',
					}}>
					{row.isActive === true ? 'Дa' : 'Нe'}
				</span>
			),
		},
		{
			title: '',
			dataIndex: 'id',
			key: 'id',
			width: '2%',
			align: 'center' as 'center',
			render: (_text: any, row: any, index: any) => (
				<Popover
					content={contentInfo(row)}
					trigger='hover'
					placement='topRight'
					style={{ fontSize: 18 }}>
					<InfoCircleOutlined />
				</Popover>
			),
		},
	];

	const title =
		electronicDocumentById?.status ===
			Constants.MedicalReferralStatus.Performed ||
		electronicDocumentById?.isActive === false ? (
			<div>
				Mедицинско назначение № {electronicDocumentById?.documentNumber}{' '}
				от{' '}
				<Moment format={Constants.Common.FullDate_Format}>
					{electronicDocumentById?.createdOn}
				</Moment>{' '}
			</div>
		) : (
			<div>
				Редактиране на медицинско назначение №{' '}
				{electronicDocumentById?.documentNumber} от{' '}
				<Moment format={Constants.Common.FullDate_Format}>
					{electronicDocumentById?.createdOn}
				</Moment>{' '}
			</div>
		);

	return (
		<Fragment>
			<div className='home-grid-col-3'>
				<Title level={3}>Последни назначения</Title>

				<Space className='space-table-head'>
					<Button
						className='btn-groupe'
						style={{
							background:
								'radial-gradient(circle, rgba(30,134,17,0.908000700280112) 28%, rgba(27,180,32,0.9248074229691877) 100%)',
						}}
						type='primary'
						icon={<PlusOutlined />}
						onClick={async () => await openModal(undefined!)}>
						Създаване
					</Button>
					<Button
						className='btn-groupe'
						style={{
							background: '#234254',
						}}
						type='primary'
						icon={<SyncOutlined />}
						onClick={() =>
							setAllIssuedMedicalReferrals(issuedPeriod)
						}>
						Опресняване
					</Button>
				</Space>
				<Space className='space-table-head'>
					<DateRangePicker
						placeholder={['дд.мм.гггг', 'дд.мм.гггг']}
						cleanable={false}
						className='date-range-tables'
						format={Constants.Common.ShortDate_Format}
						value={[
							rootStore.medicalReferralStore.issuedPeriod
								.dateFrom,
							rootStore.medicalReferralStore.issuedPeriod.dateTo,
						]}
						onOk={(date: Date[]) => changeDatesRange(date)}
						locale={Constants.ReactSuiteCalendarLocale}
						size='xs'
						color='green'
						ranges={[]}
						isoWeek={true}
					/>

					<Input.Search
						className='search-input'
						placeholder='Търсене...'
						enterButton
						onSearch={search}
					/>
				</Space>

				<Table
					onRow={(record, index) => {
						return {
							onClick: () => {
								setRowClassName(
									'ant-table-row ant-table-row-level-0 ant-table-row-selected'
								);
								setRowKey(record.id);
							},
							onDoubleClick: async () => {
								await openModal(record.id);
							},
						};
					}}
					loading={loadingAllTable}
					columns={columns}
					dataSource={filterTable.length < 1 ? data : filterTable}
					locale={{ emptyText: 'Няма налични данни' }}
					sortDirections={['descend', 'ascend']}
					showSorterTooltip={false}
					pagination={{
						showSizeChanger: true,
						showQuickJumper: true,
						total: total,
						showTotal: (total) => `Брой резултати: ${total}`,
					}}
					rowClassName={(_record, index) => {
						return _record.id === rowKey ? rowClassName : '';
					}}
					rowKey={rowKey}
				/>

				<Modal
					key={
						electronicDocumentById?.id
							? electronicDocumentById!.id
							: undefined
					}
					className='appointment-modal'
					title={
						electronicDocumentById
							? title
							: 'Създаване на медицинско назначение'
					}
					centered
					maskClosable={false}
					transitionName='none'
					maskTransitionName='none'
					closeIcon={
						isFormTouched ? (
							<Popconfirm
								title='Сигурни ли сте, че искате да затворите прозореца. Вашите промени няма да бъдат запазени!'
								onConfirm={async () => await closeModal()}>
								<CloseOutlined />
							</Popconfirm>
						) : (
							<CloseOutlined onClick={closeModal} />
						)
					}
					visible={show}
					footer={false}
					destroyOnClose={true}>
					<CreateReferralModal
						closeModal={closeModal}
						openModal={openModal}
					/>
				</Modal>
			</div>
		</Fragment>
	);
};
export default observer(CreateReferralTable);
