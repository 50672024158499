import { Form, Switch } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { RootStoreContext } from '../../stores/rootStore';
import Constants from '../../constants/constants';

export interface IProps {
	form: FormInstance;
}

const IsImportantFormItem: React.FC<IProps> = ({ form }) => {
	const rootStore = useContext(RootStoreContext);
	const {
		electronicDocumentById,
		setIsFormTouched,
	} = rootStore.electronicDocumentStore;

	return (
		<Form.Item
			name='isImportant'
			label='Приоритет:'
			rules={[
				({ isFieldsTouched }) => ({
					validator(rule, value) {
						if (isFieldsTouched() === false) {
							return Promise.reject();
						}
						return Promise.resolve();
					},
				}),
			]}>
			<Switch
				disabled={
					(electronicDocumentById?.documentStatus &&
						electronicDocumentById?.documentStatus !==
							Constants.DocumentProcessStatus.Registered) ||
					electronicDocumentById?.isActive === false
				}
				defaultChecked={
					electronicDocumentById?.isImportant
						? electronicDocumentById?.isImportant
						: false
				}
				onChange={async (value: any) => {
					setIsFormTouched(true);
					form.setFieldsValue({ isImportant: value });
				}}
				checkedChildren='Спешно'
				unCheckedChildren='Нормално'></Switch>{' '}
			<span
				style={{
					display:
						(electronicDocumentById?.documentStatus &&
							electronicDocumentById?.documentStatus !==
								Constants.DocumentProcessStatus.Registered) ||
						electronicDocumentById?.isActive === false
							? 'none'
							: 'inline-block',
					fontSize: 16,
				}}>
				Моля, изберете спешно, само ако е необходимо бързо обработване!
			</span>
		</Form.Item>
	);
};

export default observer(IsImportantFormItem);
