import React, { useContext } from 'react';
import { Form, Input } from 'antd';
import { RootStoreContext } from '../../stores/rootStore';
import { observer } from 'mobx-react-lite';
import { FormInstance } from 'antd/lib/form';
import Constants from '../../constants/constants';

export interface IProps {
	form: FormInstance;
}

const PatientTherapyFormItem: React.FC<IProps> = ({ form }) => {
	const rootStore = useContext(RootStoreContext);
	const { electronicDocumentById } = rootStore.electronicDocumentStore;

	return (
		<Form.Item
			name='patientTherapy'
			label='Терапия:'
			style={{ display: 'block' }}>
			<Input.TextArea
				disabled={
					electronicDocumentById?.isActive === false ||
					electronicDocumentById?.isAutomatic ===
						Constants.ExaminationImportType.Automatic ||
					electronicDocumentById?.isAttachedToClaim
				}
			/>
		</Form.Item>
	);
};

export default observer(PatientTherapyFormItem);
