import { Input, Form } from 'antd';
import TreeSelect, { TreeNode } from 'antd/lib/tree-select';
import { toJS } from 'mobx';
import React, { useContext, useState } from 'react';
import { IServiceCorrelation } from '../../app/models/servicesCorrelations/ServiceCorrelation';
import { RootStoreContext } from '../../app/stores/rootStore';
import { FormInstance } from 'antd/lib/form';

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
	editing: boolean;
	dataIndex: string;
	title: any;
	inputType: 'number' | 'text';
	record: IServiceCorrelation;
	index: number;
	children: React.ReactNode;
	passedForm: FormInstance;
}

export const CorrelationsEditableCell: React.FC<EditableCellProps> = ({
	editing,
	dataIndex,
	title,
	inputType,
	record,
	index,
	children,
	passedForm,
	...restProps
}) => {
	const [value, setValue] = useState(undefined);

	const rootStore = useContext(RootStoreContext);
	const {
		servicesCorrelations,
		setServicesCorrelations,
	} = rootStore.servicesCorrelationsStore;

	const { baseServices } = rootStore.operationsStore;

	const onBaseServiceChange = (value: any, label: any, extra: any) => {
		value = value.split('%')[0];
		let newData = toJS(servicesCorrelations);
		let currentElementIndex = newData!.findIndex(
			(el) => el.key === record.key
		);

		if (value) {
			setValue(value);
			let code = extra.triggerNode.props.code;
			let nhifCode = extra.triggerNode.props.nhifCode;

			newData![currentElementIndex].baseServiceSystemCode = code;
			newData![currentElementIndex].nhifCode = nhifCode;
			newData![currentElementIndex].baseServiceName = value;
		} else {
			newData![currentElementIndex].baseServiceSystemCode = '';
			newData![currentElementIndex].nhifCode = '';
			newData![currentElementIndex].baseServiceName = '';
		}

		setServicesCorrelations(newData);
	};

	const onCodeDoubleClick = (event: any) => {
		let newData = toJS(servicesCorrelations);
		let currentElementIndex = newData!.findIndex(
			(el) => el.key === record.key
		);

		if (
			newData![currentElementIndex].baseServiceSystemCode.length > 0 &&
			newData![currentElementIndex].nhifCode?.length > 0
		) {
			newData![currentElementIndex].code = newData![
				currentElementIndex
			].nhifCode;
			passedForm.setFieldsValue({
				code: newData![currentElementIndex].nhifCode,
			});
			setServicesCorrelations(newData);
		}
	};

	const inputNode =
		dataIndex === 'baseServiceName' ? (
			<Form.Item
				style={{ margin: 0, minWidth: 200 }}
				name={dataIndex}
				rules={[
					{
						required: true,
						message: ' ',
					},
				]}>
				<TreeSelect
					autoFocus={true}
					showSearch
					dropdownStyle={{
						minWidth: '600px',
						maxHeight: 400,
						overflow: 'auto',
					}}
					value={value}
					allowClear={false}
					onChange={(value, label, extra) =>
						onBaseServiceChange(value, label, extra)
					}
					treeNodeFilterProp='title'>
					{baseServices?.map((i) => (
						<TreeNode
							disabled={true}
							value={i.id}
							key={i.id}
							title={
								i.nhifCode === null
									? i.name
									: `${i.name} (${i.nhifCode})`
							}>
							{i.baseServices.map((e) => (
								<TreeNode
									disabled={true}
									value={e.id}
									key={e.id}
									title={
										e.nhifCode === null
											? e.name
											: `${e.name} (${e.nhifCode})`
									}>
									{e.baseServices.map((a) => (
										<TreeNode
											value={`${a.name}%${a.id}`}
											key={`${a.name}%${a.id}`}
											nhifCode={a.nhifCode}
											code={a.code}
											title={
												a.nhifCode === null
													? a.name
													: `${a.name} (${a.nhifCode})`
											}
										/>
									))}
								</TreeNode>
							))}
						</TreeNode>
					))}
				</TreeSelect>
			</Form.Item>
		) : dataIndex === 'code' ? (
			<Form.Item
				name={dataIndex}
				rules={[
					{
						required: true,
						message: ' ',
					},
					({ getFieldValue }) => ({
						validator(rule, value) {
							const expression = new RegExp('^[A-Z0-9_.-]*$');
							if (expression.test(value)) {
								return Promise.resolve();
							}
							return Promise.reject('');
						},
					}),
				]}>
				<Input onDoubleClick={(event) => onCodeDoubleClick(event)} />
			</Form.Item>
		) : (
			<Form.Item
				name={dataIndex}
				rules={[
					{
						required:
							record && record.baseServiceSystemCode
								? record.baseServiceSystemCode.includes(
										'Z99_99'
								  )
								: false,
						message: ' ',
					},
				]}>
				<Input />
			</Form.Item>
		);

	return (
		<td {...restProps}>
			{editing ? (
				<Form.Item name={dataIndex} style={{ margin: 0 }}>
					{inputNode}
				</Form.Item>
			) : (
				children
			)}
		</td>
	);
};
