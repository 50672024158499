import { Row, Col, Switch } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { Fragment, useContext, useEffect } from 'react';
import { RootStoreContext } from '../../app/stores/rootStore';
import CommonSettings from './CommonSettings';
import ServicesCorrelationsEditableTable from './ServicesCorrelationsEditableTable';
import ServicesPricesEditableTable from './ServicesPricesEditableTable';

const ServicesCorrelationsMain: React.FC = () => {
	const rootStore = useContext(RootStoreContext);
	const {
		getServicesCorrelations,
		setPriceListForCurrentSelectedServiceCorrelation,
		servicesCorrelations,
		setCurrentSelectedServiceCorrelationId,
		currentSelectedServiceCorrelationId,
		serviceCorrelationsTableEditMode,
		removeServiceCorrelations,
	} = rootStore.servicesCorrelationsStore;

	const {
		commonSettingsVisible,
		setCommonSettingsVisible,
	} = rootStore.settingsStore;

	useEffect(() => {
		if (!rootStore.servicesCorrelationsStore?.servicesCorrelations) {
			getServicesCorrelations().then(() => {
				if (
					rootStore.servicesCorrelationsStore
						.servicesCorrelations[0] &&
					!serviceCorrelationsTableEditMode
				) {
					setCurrentSelectedServiceCorrelationId(
						rootStore.servicesCorrelationsStore
							.servicesCorrelations[0].id
					);
					setPriceListForCurrentSelectedServiceCorrelation(
						rootStore.servicesCorrelationsStore
							.servicesCorrelations[0].servicePrices
					);
				}
			});
		} else if (currentSelectedServiceCorrelationId !== undefined) {
			setPriceListForCurrentSelectedServiceCorrelation(
				servicesCorrelations[
					servicesCorrelations.findIndex(
						(i) => i.id === currentSelectedServiceCorrelationId
					)
				]!.servicePrices
			);
		} else if (
			servicesCorrelations[0] &&
			!serviceCorrelationsTableEditMode
		) {
			setCurrentSelectedServiceCorrelationId(servicesCorrelations[0].id);
			setPriceListForCurrentSelectedServiceCorrelation(
				servicesCorrelations[0].servicePrices
			);
		}
		return () => {
			setCurrentSelectedServiceCorrelationId(undefined);
			setPriceListForCurrentSelectedServiceCorrelation(undefined);
			removeServiceCorrelations();
		};
	}, []);

	return (
		<Fragment>
			<div style={{ marginBottom: 10, marginTop: '-7px' }}>
				<Switch
					unCheckedChildren='Настройки'
					defaultChecked={commonSettingsVisible}
					onChange={() =>
						setCommonSettingsVisible(!commonSettingsVisible)
					}
				/>
			</div>
			<div hidden={!commonSettingsVisible} style={{ marginBottom: 10 }}>
				<div className='status-grid-col'>
					<CommonSettings />
				</div>
			</div>
			<Row gutter={[24, 8]} justify='center'>
				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<div>
						<div className='status-grid-col'>
							<ServicesCorrelationsEditableTable />
						</div>
					</div>
				</Col>

				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<div>
						<div className='status-grid-col'>
							<ServicesPricesEditableTable />
						</div>
					</div>
				</Col>
			</Row>
		</Fragment>
	);
};

export default observer(ServicesCorrelationsMain);
