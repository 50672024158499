import { Form } from 'antd';
import { FormInstance } from 'antd/lib/form';
import React, { useContext } from 'react';
import DetailsEditableTable from '../FormItems/DetailsFormItem/DetailsEditableTable';
import { RootStoreContext } from '../../stores/rootStore';
import { observer } from 'mobx-react-lite';
import Constants from '../../constants/constants';

export interface IProps {
	form: FormInstance;
	openModal: (id: number | undefined) => Promise<void>;
	closeModal: () => Promise<void>;
}
const DetailsFormItem: React.FC<IProps> = ({ form, openModal, closeModal }) => {
	const rootStore = useContext(RootStoreContext);
	const { electronicDocumentById } = rootStore.electronicDocumentStore;

	return (
		<Form.Item
			name='details'
			style={{ display: 'block' }}
			label={
				electronicDocumentById?.status ===
					Constants.MedicalReferralStatus.Performed ||
				electronicDocumentById?.isActive === false
					? 'Назначени дейности'
					: 'Избор на дейности:'
			}>
			<DetailsEditableTable
				passedForm={form}
				openModal={openModal}
				closeModal={closeModal}
			/>
		</Form.Item>
	);
};

export default observer(DetailsFormItem);
