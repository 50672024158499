import React, { Fragment, useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
	Button,
	Input,
	Modal,
	Popconfirm,
	Table,
	Popover,
	Switch,
	Tooltip,
	Space,
} from 'antd';
import Title from 'antd/lib/typography/Title';
import {
	PlusOutlined,
	SyncOutlined,
	CloseOutlined,
	InfoCircleOutlined,
	WechatOutlined,
} from '@ant-design/icons';
import PriorApprovalModal from './PriorApprovalModal';
import { RootStoreContext } from '../../app/stores/rootStore';
import Moment from 'react-moment';
import { TablePaginationConfig } from 'antd/lib/table';
import { IElectronicDocument } from '../../app/models/electronicDocument/ElectronicDocument';
import Constants from '../../app/constants/constants';
import {
	addResponseMessage,
	addUserMessage,
	dropMessages,
	renderCustomComponent,
	setBadgeCount,
} from 'react-chat-widget';
import CurrentDateChat from '../../app/layout/CustomComponents/CurrentDateChat';
import { DateRangePicker } from 'rsuite';

const PriorApprovalTable: React.FC<TablePaginationConfig> = ({ total }) => {
	const [show, setShow] = useState(false);
	const [rowClassName, setRowClassName] = useState(
		'ant-table-row ant-table-row-level-0'
	);

	const rootStore = useContext(RootStoreContext);

	const {
		electronicDocumentById,
		setUploadEditMode,
		setIsElectronicDocumentAttaching,
		isFormTouched,
		getElectronicDocumentById,
		setDetailsEditMode,
		setDetailsOriginDataFromElectronicDocument,
		setAttachmentsOriginDataFromElectronicDocument,
		setDetailsOriginData,
		setAttachmentsOriginData,
		setTypeOfDocument,
		setCurrentSelectedElectronicDocument,
		setIsFormTouched,
		setIsUploadFormTouched,
	} = rootStore.electronicDocumentStore;

	const {
		setAllPriorApprovalsByPeriod,
		allPriorApprovals,
		allPriorApprovalsWithUnreadMessages,
		getAllPriorApprovalsWithUnreadMessages,
		setPeriod,
		period,
		removeAllPriorApprovals,
		setFilterType,
	} = rootStore.priorApprovalStore;
	const {
		getPatientByEgn,
		getElectronicDocumentsForPatient,
	} = rootStore.operationsStore;

	const {
		removeSelfNotificationForUnreadMessages,
	} = rootStore.notificationStore;

	const { markAsRead, clearTemporaryMessageQueue } = rootStore.messangerStore;

	const { loadingAllTable } = rootStore.loadersStore;

	const {
		removeMessages,
		addToMessangerHubGroup,
		removeFromMessangerHubGroup,
	} = rootStore.messangerStore;

	const [filterTable, setFilterTable] = useState([]);
	const [rowKey, setRowKey] = useState();

	const openModal = async (id: number | undefined) => {
		setTypeOfDocument(Constants.DocumentType.PriorApproval);
		if (id) {
			await getElectronicDocumentById(id).then(async () => {
				if (rootStore.electronicDocumentStore.electronicDocumentById) {
					await getElectronicDocumentsForPatient(
						rootStore.electronicDocumentStore.electronicDocumentById
							.patientUid
					);
					//await getAllMessages(id);
					await addToMessangerHubGroup(id);
					rootStore.messangerStore.messages?.forEach((element) => {
						if (!element.isInsuranceCompanyMessage) {
							addUserMessage(element.content);
							renderCustomComponent(CurrentDateChat, {
								date: element.createdOn,
								className: 'rcw-client',
							});
						} else {
							addResponseMessage(element.content);
							renderCustomComponent(CurrentDateChat, {
								date: element.createdOn,
								className: 'rcw-response',
							});
						}

						setBadgeCount(0);
					});

					if (
						rootStore.electronicDocumentStore.electronicDocumentById
							?.attachments &&
						rootStore.electronicDocumentStore
							.electronicDocumentById!.attachments?.length >= 1
					) {
						setUploadEditMode(true);
					} else {
						setUploadEditMode(false);
					}
				}
			});
		} else {
			setDetailsEditMode(true);
			setUploadEditMode(false);
			setDetailsOriginDataFromElectronicDocument(undefined);
			setAttachmentsOriginDataFromElectronicDocument(undefined);
			setDetailsOriginData(undefined);
			setAttachmentsOriginData(undefined);
			await getElectronicDocumentById(undefined);
			await getElectronicDocumentsForPatient(undefined);
			removeMessages();
		}
		setTypeOfDocument(Constants.DocumentType.PriorApproval);
		setShow(true);
	};

	const closeModal = async () => {
		setShow(false);
		if (rootStore.electronicDocumentStore.getElDocsAgain) {
			await setAllPriorApprovalsByPeriod(period);
		}

		if (rootStore.electronicDocumentStore.electronicDocumentById) {
			await markAsRead(
				true,
				rootStore.electronicDocumentStore.electronicDocumentById!.id
			);
			await removeSelfNotificationForUnreadMessages(
				parseInt(rootStore.identityStore.currentUser!.userId),
				rootStore.electronicDocumentStore.electronicDocumentById!.id
			);

			dropMessages();
			clearTemporaryMessageQueue();

			await removeFromMessangerHubGroup(electronicDocumentById!.id);
		}

		await getElectronicDocumentById(undefined);
		setUploadEditMode(true);
		await getElectronicDocumentsForPatient(undefined);
		setCurrentSelectedElectronicDocument(undefined);
		setDetailsOriginDataFromElectronicDocument(undefined);
		setAttachmentsOriginDataFromElectronicDocument(undefined);
		setDetailsOriginData(undefined);
		setAttachmentsOriginData(undefined);
		setDetailsEditMode(false);
		setIsFormTouched(false);
		setIsUploadFormTouched(false);
		await getPatientByEgn(undefined);
		setTypeOfDocument(undefined);
		setIsElectronicDocumentAttaching(false);
		removeMessages();
	};

	const compareByAlph = (a: any, b: any) => {
		if (a > b) {
			return -1;
		}
		if (a < b) {
			return 1;
		}
		return 0;
	};

	const search = (value: any) => {
		if (value.length >= 1) {
			const tableFilterResult = data!.filter((o: any) =>
				Object.keys(o).some((k) =>
					String(o[k]).toLowerCase().includes(value.toLowerCase())
				)
			);
			setFilterTable(tableFilterResult);
		} else if (value.length === 0) {
			setFilterTable([] as IElectronicDocument[]);
		}
	};

	const title =
		electronicDocumentById?.status ===
			Constants.MedicalReferralStatus.Performed ||
		electronicDocumentById?.isActive === false ||
		(electronicDocumentById?.documentStatus !==
			Constants.DocumentProcessStatus.Registered &&
			electronicDocumentById?.documentStatus !==
				Constants.DocumentProcessStatus.OpenForModification) ? (
			<div>
				ИПО № {electronicDocumentById?.documentNumber} от{' '}
				<Moment format={Constants.Common.FullDate_Format}>
					{electronicDocumentById?.createdOn}
				</Moment>{' '}
			</div>
		) : (
			<div>
				Редактиране на ИПО № {electronicDocumentById?.documentNumber} от{' '}
				<Moment format={Constants.Common.FullDate_Format}>
					{electronicDocumentById?.createdOn}
				</Moment>{' '}
			</div>
		);

	useEffect(() => {
		if (!allPriorApprovals) {
			setAllPriorApprovalsByPeriod(period);
		}

		if (!allPriorApprovalsWithUnreadMessages) {
			getAllPriorApprovalsWithUnreadMessages();
		}
		return () => {
			removeAllPriorApprovals();
		};
	}, []);

	const data = allPriorApprovals;

	const contentInfo = (row: any) => (
		<div>
			Последна редакция на дата:{' '}
			<Moment format={Constants.Common.FullDate_Format}>
				{row.lastModifiedOn}
			</Moment>
			, от потребител: {row.lastModifiedByUsername}
		</div>
	);

	const changeDatesRange = (dates: Date[]) => {
		setPeriod(dates[0], dates[1]);
		setFilterTable([] as IElectronicDocument[]);
		setAllPriorApprovalsByPeriod(rootStore.priorApprovalStore.period);
	};

	const columns = [
		{
			title: 'Документ №',
			dataIndex: 'documentNumber',
			key: 'documentNumber',
			width: '7%',
			align: 'center' as 'center',
		},
		{
			title: 'Приоритет',
			dataIndex: 'isImportant',
			key: 'isImportant',
			width: '7%',
			align: 'center' as 'center',
			sorter: (a: any, b: any) => {
				return compareByAlph(a.isImportant, b.isImportant);
			},
			render: (_text: any, row: any, index: any) => (
				<div>{row.isImportant === false ? 'Обикновен' : ' Спешен'}</div>
			),
		},
		{
			title: 'Дата',
			dataIndex: 'createdOn',
			key: 'createdOn',
			width: '10%',
			align: 'center' as 'center',
			sorter: (a: any, b: any) => {
				return compareByAlph(a.createdOn, b.createdOn);
			},
			render: (_text: any, row: any, index: any) => (
				<Moment format={Constants.Common.FullDate_Format}>
					{row.createdOn}
				</Moment>
			),
		},
		{
			title: 'Застрахователна компания',
			dataIndex: 'insuranceCompanyName',
			key: 'insuranceCompanyName',
			width: '10%',
			align: 'center' as 'center',
			sorter: (a: any, b: any) => {
				return compareByAlph(
					a.insuranceCompanyName,
					b.insuranceCompanyName
				);
			},
		},
		{
			title: 'ЕГН на пациент',
			dataIndex: 'patientUid',
			key: 'patientUid',
			width: '12%',
			sorter: true,
			align: 'center' as 'center',
		},
		{
			title: 'Име на пациент',
			dataIndex: 'patientName',
			key: 'patientName',
			align: 'center' as 'center',

			width: '20%',
			sorter: (a: any, b: any) => {
				return compareByAlph(a.patientName, b.patientName);
			},
		},
		{
			title: 'Статус',
			dataIndex: 'documentStatus',
			key: 'documentStatus',
			width: '8%',
			align: 'center' as 'center',
			sorter: (a: any, b: any) => {
				return compareByAlph(a.documentStatus, b.documentStatus);
			},
			render: (_text: any, row: any, index: any) => (
				<span
					style={{
						color:
							row.documentStatus ===
							Constants.DocumentProcessStatus.Open
								? 'blue'
								: row.documentStatus ===
								  Constants.DocumentProcessStatus
										.OpenForModification
								? '#FF5722'
								: row.documentStatus ===
								  Constants.DocumentProcessStatus.Approved
								? 'green'
								: row.documentStatus ===
								  Constants.DocumentProcessStatus.Rejected
								? 'red'
								: 'inherit',
					}}>
					{row.documentStatus}
				</span>
			),
		},
		{
			title: 'Активен',
			dataIndex: 'isActive',
			key: 'isActive',
			width: '5%',
			align: 'center' as 'center',
			render: (_text: any, row: any, index: any) => (
				<span
					style={{
						color:
							row.isActive === false
								? 'red'
								: row.status ===
								  Constants.MedicalReferralStatus.Performed
								? 'auto'
								: 'green',
					}}>
					{row.isActive === true ? 'Дa' : 'Нe'}
				</span>
			),
		},
		{
			title: '',
			dataIndex: '',
			key: '',
			width: '1%',
			align: 'center' as 'center',
			render: (_text: any, row: any, index: any) => (
				<Popover
					content={contentInfo(row)}
					trigger='hover'
					placement='topRight'
					style={{ fontSize: 18 }}>
					<InfoCircleOutlined />
				</Popover>
			),
		},
		{
			title: '',
			dataIndex: 'unreadMessages',
			key: 'unreadMessages',
			width: '1%',
			align: 'center' as 'center',
			render: (_text: any, row: any, index: any) => (
				<div
					style={{
						display:
							rootStore.priorApprovalStore
								.allPriorApprovalsWithUnreadMessages &&
							rootStore.priorApprovalStore
								.allPriorApprovalsWithUnreadMessages!.length >
								0 &&
							rootStore.priorApprovalStore.allPriorApprovalsWithUnreadMessages!.findIndex(
								(el) => el === row.id
							) > -1
								? 'inherit'
								: 'none',
					}}>
					<Tooltip title={'Имате непрочетени съобщения'}>
						<WechatOutlined
							style={{ color: '#a03400', fontSize: 27 }}
						/>
					</Tooltip>
				</div>
			),
		},
	];

	return (
		<Fragment>
			<div className='home-grid-col-3'>
				<Title level={3}>
					Последни искания за предварително одобрение
				</Title>
				<Space className='space-table-head'>
					<Button
						className='btn-groupe'
						style={{
							background:
								'radial-gradient(circle, rgba(30,134,17,0.908000700280112) 28%, rgba(27,180,32,0.9248074229691877) 100%)',
						}}
						type='primary'
						icon={<PlusOutlined />}
						onClick={async () => await openModal(undefined!)}>
						Създаване
					</Button>

					<Button
						className='btn-groupe'
						style={{
							background: '#234254',
						}}
						type='primary'
						icon={<SyncOutlined />}
						onClick={() => {
							setAllPriorApprovalsByPeriod(period);
						}}>
						Опресняване
					</Button>

					<Switch
						className='switch-in-tables'
						defaultChecked={true}
						onChange={(value: any) => {
							setFilterType(value);
							setAllPriorApprovalsByPeriod(period);
						}}
						checkedChildren='Всички'
						unCheckedChildren='Само отворените'
					/>
				</Space>
				<Space className='space-table-head'>
					<DateRangePicker
						placeholder={['дд.мм.гггг', 'дд.мм.гггг']}
						cleanable={false}
						className='date-range-tables'
						format={Constants.Common.ShortDate_Format}
						value={[
							rootStore.priorApprovalStore.period.dateFrom,
							rootStore.priorApprovalStore.period.dateTo,
						]}
						onOk={(date: Date[]) => changeDatesRange(date)}
						locale={Constants.ReactSuiteCalendarLocale}
						size='xs'
						color='green'
						ranges={[]}
						isoWeek={true}
					/>
					<Input.Search
						className='search-input'
						placeholder='Търсене...'
						enterButton
						onSearch={search}
					/>
				</Space>

				<Table
					onRow={(record, index) => {
						return {
							onClick: () => {
								setRowClassName(
									'ant-table-row ant-table-row-level-0 ant-table-row-selected'
								);
								setRowKey(record.id);
							},
							onDoubleClick: async () => {
								await openModal(record.id);
							},
						};
					}}
					loading={loadingAllTable}
					columns={columns}
					dataSource={filterTable.length < 1 ? data : filterTable}
					locale={{ emptyText: 'Няма налични данни' }}
					sortDirections={['descend', 'ascend']}
					showSorterTooltip={false}
					pagination={{
						showSizeChanger: true,
						showQuickJumper: true,
						total: total,
						showTotal: (total) => `Брой резултати: ${total}`,
					}}
					rowClassName={(_record, index) => {
						return _record.id === rowKey ? rowClassName : '';
					}}
					rowKey={rowKey}
				/>

				<Modal
					className='appointment-modal prior-approval-modal'
					title={
						electronicDocumentById
							? title
							: 'Създаване на искане за предварително одобрение'
					}
					centered
					maskClosable={false}
					transitionName='none'
					maskTransitionName='none'
					closeIcon={
						isFormTouched ? (
							<Popconfirm
								title='Сигурни ли сте, че искате да затворите прозореца. Вашите промени няма да бъдат запазени!'
								onConfirm={async () => await closeModal()}>
								<CloseOutlined />
							</Popconfirm>
						) : (
							<CloseOutlined
								onClick={async () => await closeModal()}
							/>
						)
					}
					visible={show}
					footer={false}
					destroyOnClose={true}>
					<PriorApprovalModal
						closeModal={closeModal}
						openModal={openModal}
					/>
				</Modal>
			</div>
		</Fragment>
	);
};

export default observer(PriorApprovalTable);
