import React, { Fragment, useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Row, Col, Typography } from 'antd';
import CheckForReferralForm from './CheckForReferralForm';
import LastCheckedReferralTable from './LastCheckedReferralTable';
import { RootStoreContext } from '../../../app/stores/rootStore';

const ViewReferral: React.FC = () => {
	const { Title } = Typography;
	const rootStore = useContext(RootStoreContext);
	const {
		setAllPerformedMedicalReferrals,
		performedPeriod,
		removeAllPerformedMedicalReferrals,
		allPerformedMedicalReferrals,
	} = rootStore.medicalReferralStore;

	useEffect(() => {
		if (!allPerformedMedicalReferrals) {
			setAllPerformedMedicalReferrals(performedPeriod);
		}

		return () => {
			removeAllPerformedMedicalReferrals();
		};
	}, []);

	return (
		<Fragment>
			<Row gutter={[24, 8]} justify={'center'}>
				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<div>
						<div
							className='status-grid-col'
							style={{ padding: '10px 30px' }}>
							<Title level={3}>
								Проверка за медицинско назначение
							</Title>
							<CheckForReferralForm />
						</div>
					</div>
				</Col>

				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<div>
						<div
							className='status-grid-col'
							style={{ padding: '10px 20px' }}>
							<Title level={3}>
								Последно запазени медицински назначения
							</Title>
							<LastCheckedReferralTable />
						</div>
					</div>
				</Col>
			</Row>
		</Fragment>
	);
};
export default observer(ViewReferral);
