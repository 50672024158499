import { Form, Switch } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { observer } from 'mobx-react-lite';
import React, { useContext, useState } from 'react';
import { RootStoreContext } from '../../stores/rootStore';
import Constants from '../../constants/constants';

export interface IProps {
	form: FormInstance;
}

const IsActiveFormItem: React.FC<IProps> = ({ form }) => {
	const rootStore = useContext(RootStoreContext);
	const { electronicDocumentById } = rootStore.electronicDocumentStore;
	const { setIsFormTouched } = rootStore.electronicDocumentStore;

	const [messageDisable, setMessageDisable] = useState(false);
	return (
		<Form.Item
			hidden={
				electronicDocumentById?.isAutomatic ===
					Constants.ExaminationImportType.Automatic ||
				electronicDocumentById?.isAttachedToClaim
			}
			name='isActive'
			rules={[
				({ isFieldsTouched }) => ({
					validator(rule, value) {
						if (isFieldsTouched() === false) {
							return Promise.reject();
						}
						return Promise.resolve();
					},
				}),
			]}>
			<Switch
				disabled={
					electronicDocumentById?.status ===
						Constants.MedicalReferralStatus.Performed ||
					electronicDocumentById?.isActive === false ||
					electronicDocumentById?.documentStatus ===
						Constants.DocumentProcessStatus.Open ||
					electronicDocumentById?.documentStatus ===
						Constants.DocumentProcessStatus.OpenForModification ||
					electronicDocumentById?.documentStatus ===
						Constants.DocumentProcessStatus.Rejected ||
					electronicDocumentById?.documentStatus ===
						Constants.DocumentProcessStatus.Approved
				}
				defaultChecked={electronicDocumentById?.isActive}
				style={{
					display: !electronicDocumentById ? 'none' : 'inline-block',
				}}
				onChange={(value: any) => {
					setMessageDisable(!messageDisable);
					setIsFormTouched(true);
					form.setFieldsValue({ isActive: value });
				}}
				checkedChildren='Активен'
				unCheckedChildren='Неактивен'></Switch>{' '}
			<span
				style={{
					display:
						!electronicDocumentById ||
						messageDisable === false ||
						electronicDocumentById?.isActive === false
							? 'none'
							: 'inline-block',
					fontSize: 16,
					color: 'red',
				}}>
				При деактивиране на документа и натискане на бутона за
				запазване, няма да можете повече да го активирате!
			</span>
		</Form.Item>
	);
};

export default observer(IsActiveFormItem);
