import { action, observable, runInAction, toJS } from 'mobx';
import agent from '../../api/agent';
import { IAttachmentType } from '../models/electronicDocument/AttachmentType';
import { IBaseServices } from '../models/electronicDocument/BaseServices';
import { IDoctorByUinResponse } from '../models/electronicDocument/DoctorByUinResponse';
import { IDocumentType } from '../models/electronicDocument/DocumentType';
import { IElectronicDocument } from '../models/electronicDocument/ElectronicDocument';
import { IMkb } from '../models/electronicDocument/Mkb';
import { IPatientByEgnResponse } from '../models/electronicDocument/PatientByEgnResponse';
import { ISpeciality } from '../models/electronicDocument/Speciality';
import { IEnumResponse } from '../models/EnumResponse';
import { IInsuranceCompany } from '../models/insuranceStatusCheck/InsuranceCompany';
import { RootStore } from './rootStore';

export default class OperationsStore {
	rootStore: RootStore;

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
	}

	@observable baseServices: IBaseServices[] | undefined;
	@observable baseServicesForProceduresList: IBaseServices[] | undefined;
	@observable serviceCategory = 'NH';
	@observable searchTerm = '';
	@observable baseServicesForMdd: IBaseServices[] | undefined;
	@observable mkbs: IMkb[] | undefined;
	@observable documetType: IDocumentType[] | undefined;
	@observable attachmentType: IAttachmentType[] | undefined;
	@observable helpdeskCategories: IEnumResponse[] | undefined;
	@observable helpdeskModules: IEnumResponse[] | undefined;

	@observable doctorByUin: IDoctorByUinResponse | undefined;
	@observable patientByEgn: IPatientByEgnResponse | undefined;
	@observable specialities: ISpeciality[] | undefined;
	@observable companies: IInsuranceCompany[] | undefined;

	@observable electronicDocumentsForPatient:
		| IElectronicDocument[]
		| undefined;

	@action getBaseServices = async () => {
		try {
			let res = await agent.ElectronicDocument.getBaseServices();
			runInAction(() => {
				this.baseServices = res;
				this.baseServicesForProceduresList = res?.find(
					(a) => a.code === this.serviceCategory
				).baseServices;
			});
		} catch (error) {}
	};

	@action getHelpdeskCategories = async () => {
		try {
			let res = await agent.Helpdesk.getCategories();
			runInAction(() => {
				this.helpdeskCategories = res;
			});
		} catch (error) {}
	};

	@action getHelpdeskModules = async () => {
		try {
			let res = await agent.Helpdesk.getModules();
			runInAction(() => {
				this.helpdeskModules = res;
			});
		} catch (error) {}
	};

	@action setSearchTerm = (value: string) => {
		this.searchTerm = value;
	};

	@action filterBaseServicesForProceduresList = (searchTerm: string) => {
		this.setSearchTerm(searchTerm);
		this.resetBaseServicesForProcedureList();
		if (searchTerm.length >= 1) {
			let tableFilterResult = toJS(
				this.baseServicesForProceduresList!
			).filter((o: any) =>
				o.baseServices.some((c: any) =>
					Object.keys(c).some((k) =>
						String(c[k])
							.toLowerCase()
							.includes(searchTerm.toLowerCase())
					)
				)
			);

			let filteredSecondLevel = tableFilterResult.filter((a) =>
				a.baseServices.filter((o) =>
					Object.keys(o).some((k) =>
						String(o[k])
							.toLowerCase()
							.includes(searchTerm.toLowerCase())
					)
				)
			);

			let level3 = tableFilterResult.map((a) =>
				a.baseServices.filter((o) =>
					Object.keys(o).some((k) =>
						String(o[k])
							.toLowerCase()
							.includes(searchTerm.toLowerCase())
					)
				)
			);

			for (let i = 0; i < filteredSecondLevel.length; i++) {
				filteredSecondLevel[i].baseServices = level3[i];
			}

			tableFilterResult = filteredSecondLevel;
			this.baseServicesForProceduresList = tableFilterResult;
		} else {
			this.resetBaseServicesForProcedureList();
		}
	};

	@action resetBaseServicesForProcedureList = () => {
		this.baseServicesForProceduresList = this.baseServices;
		this.baseServicesForProceduresList = this.baseServices.find(
			(a) => a.code === this.serviceCategory
		).baseServices;
	};

	@action setServiceCategory = (value: string) => {
		this.serviceCategory = value;
		this.filterBaseServicesForProceduresList(this.searchTerm);
	};

	@action getBaseServicesForMdd = async () => {
		try {
			let res = await agent.ElectronicDocument.getBaseServicesForMdd();
			runInAction(() => {
				this.baseServicesForMdd = res;
			});
		} catch (error) {}
	};

	@action getMkbs = async () => {
		try {
			let res = await agent.ElectronicDocument.getMkbs();
			runInAction(() => {
				this.mkbs = res;
			});
		} catch (error) {}
	};

	@action getDocumetType = async () => {
		try {
			let res = await agent.ElectronicDocument.getDocumentTypes();
			runInAction(() => {
				this.documetType = res;
			});
		} catch (error) {}
	};

	@action getAttachmentType = async () => {
		try {
			let res = await agent.ElectronicDocument.getAttachmentTypes();
			runInAction(() => {
				this.attachmentType = res;
			});
		} catch (error) {}
	};

	@action getDoctorByUin = async (uinCode: string | undefined) => {
		this.rootStore.loadersStore.setLoadingModal(true);
		this.rootStore.loadersStore.setLoadingCheckForm(true);
		if (uinCode) {
			try {
				let res = await agent.ElectronicDocument.getDoctorByUin(
					uinCode!
				);
				runInAction(() => {
					if (res) {
						this.doctorByUin = res;
					} else {
						this.doctorByUin = undefined;
					}

					this.rootStore.loadersStore.setLoadingModal(false);
					this.rootStore.loadersStore.setLoadingCheckForm(false);
				});
			} catch (error) {
				this.rootStore.loadersStore.setLoadingModal(false);
				this.rootStore.loadersStore.setLoadingCheckForm(false);
			}
		} else {
			this.doctorByUin = undefined;
			this.rootStore.loadersStore.setLoadingModal(false);
			this.rootStore.loadersStore.setLoadingCheckForm(false);
		}
	};

	@action getPatientByEgn = async (egn: string | undefined) => {
		this.rootStore.loadersStore.setLoadingModal(true);
		if (egn) {
			try {
				let res = await agent.ElectronicDocument.getPatientByEgn(egn!);
				runInAction(() => {
					this.patientByEgn = res;
					this.rootStore.loadersStore.setLoadingModal(false);
				});
			} catch (error) {
				this.rootStore.loadersStore.setLoadingModal(false);
			}
		} else {
			this.patientByEgn = undefined;
			this.rootStore.loadersStore.setLoadingModal(false);
		}
	};

	@action getSpecialities = async () => {
		this.rootStore.loadersStore.setLoadingModal(true);
		try {
			await agent.ElectronicDocument.getSpecialities().then((res) => {
				runInAction(() => {
					this.specialities = res;
				});
			});
			this.rootStore.loadersStore.setLoadingModal(false);
		} catch (error) {
			this.rootStore.loadersStore.setLoadingModal(false);
		}
	};

	@action getCompanies = async () => {
		try {
			let res = await agent.InsuranceStatus.getCompanies();
			runInAction(() => {
				this.companies = res;
			});
		} catch (error) {}
	};

	@action getElectronicDocumentsForPatient = async (
		egn: string | undefined
	) => {
		if (!egn) {
			this.electronicDocumentsForPatient = undefined;
		} else {
			this.rootStore.loadersStore.setLoadingModal(true);
			try {
				let res = await agent.ElectronicDocument.getElectronicDocumentsForPatient(
					egn
				);
				runInAction(() => {
					this.electronicDocumentsForPatient = res;
					this.rootStore.loadersStore.setLoadingModal(false);
				});
			} catch (error) {
				this.rootStore.loadersStore.setLoadingModal(false);
			}
		}
	};

	@action clearElectronicDocumentForPatient = () => {
		this.electronicDocumentsForPatient = undefined;
	};
}
