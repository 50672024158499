import {
	Button,
	Form,
	Input,
	notification,
	Popconfirm,
	Table,
	Typography,
} from 'antd';
import { observer } from 'mobx-react-lite';
import React, { Fragment, useContext, useRef, useState } from 'react';
import { IServiceCorrelation } from '../../app/models/servicesCorrelations/ServiceCorrelation';
import { RootStoreContext } from '../../app/stores/rootStore';
import { CorrelationsEditableCell } from './CorrelationsEditableCell';
import {
	FormOutlined,
	DeleteOutlined,
	CloseOutlined,
	CheckCircleOutlined,
} from '@ant-design/icons';
import { toJS } from 'mobx';

const ServicesCorrelationsEditableTable = () => {
	const rootStore = useContext(RootStoreContext);
	const {
		servicesCorrelations,
		createServiceCorrelation,
		updateServiceCorrelation,
		setServicesCorrelations,
		deleteServiceCorrelation,
		setPriceListForCurrentSelectedServiceCorrelation,
		setCurrentSelectedServiceCorrelationId,
		setCurrentSelectedServiceCorrelationCode,
		setServiceCorrelationTableEditMode,
	} = rootStore.servicesCorrelationsStore;
	const { loadingAllTable } = rootStore.loadersStore;

	const emptyRowInitialData = (key: string) => {
		return {
			key: key,
			id: undefined,
			baseServiceName: '',
			additionalInformation: '',
			baseServiceSystemCode: '',
			nhifCode: '',
			code: '',
			servicePrices: [],
			insuranceCompanyCode: '',
		};
	};

	const [form] = Form.useForm();
	const searchRef = useRef(null);
	const [editingKey, setEditingKey] = useState('');
	const [rowClassName, setRowClassName] = useState(
		'ant-table-row ant-table-row-level-0 ant-table-row-selected'
	);
	const [rowKey, setRowKey] = useState(
		servicesCorrelations ? servicesCorrelations[0]?.key : '1'
	);
	const [count, setCount] = useState(
		servicesCorrelations ? servicesCorrelations.length + 1 : 2
	);
	const [temporaryData, setTemporaryData] = useState(
		[] as IServiceCorrelation[]
	);
	const [filterTable, setFilterTable] = useState([]);

	const isEditing = (record: IServiceCorrelation) =>
		record.key === editingKey;

	const data = rootStore.servicesCorrelationsStore.servicesCorrelations;

	const handleAdd = () => {
		searchRef.current.state.value = '';
		setRowKey('');
		setServiceCorrelationTableEditMode(true);
		setPriceListForCurrentSelectedServiceCorrelation([]);
		setCurrentSelectedServiceCorrelationId(undefined);
		setCurrentSelectedServiceCorrelationCode(undefined);
		setTemporaryData(servicesCorrelations!);
		setFilterTable([] as IServiceCorrelation[]);
		const newData = [
			...servicesCorrelations!,
			emptyRowInitialData((servicesCorrelations!.length + 1).toString()),
		];
		setServicesCorrelations(newData);
		form.resetFields();
		if (servicesCorrelations) {
			setCount(newData!.length + 1);
		} else {
			setCount(count + 1);
		}
		setEditingKey((servicesCorrelations!.length + 1).toString());

		setTimeout(() => {
			let newElement = document.querySelectorAll(
				`[data-row-key="${servicesCorrelations!.length + 1}"]`
			)[0];
			if (newElement !== undefined) {
				newElement.scrollIntoView();
			}
		}, 500);
	};

	const edit = (record: IServiceCorrelation) => {
		setTemporaryData(servicesCorrelations!);
		form.setFieldsValue({ ...record });
		setEditingKey(record.key);
		setPriceListForCurrentSelectedServiceCorrelation(record.servicePrices);
		setCurrentSelectedServiceCorrelationId(record.id);
		setCurrentSelectedServiceCorrelationCode(record.code);
		setServiceCorrelationTableEditMode(true);
	};

	const deleteRecord = async (record: any) => {
		setCurrentSelectedServiceCorrelationId(undefined);
		setCurrentSelectedServiceCorrelationCode(undefined);
		await deleteServiceCorrelation(record.id);
		// await getServicesCorrelations();
		setEditingKey('');
		selectRow(rootStore.servicesCorrelationsStore.servicesCorrelations[0]);
		setCount(count - 1);
	};

	const handleCancel = (record: IServiceCorrelation) => {
		if (temporaryData.length === 0) {
			const newData = [];

			record = undefined;
			setServicesCorrelations(newData);
			setEditingKey('');

			form.resetFields();
		} else {
			let newData = toJS(temporaryData);
			for (let index = 0; index < newData.length; index++) {
				newData[index].key = (index + 1).toString();
			}
			setCount(newData!.length + 1);
			setServicesCorrelations(newData);
			setEditingKey('');
		}

		selectRow(servicesCorrelations[0]);
		setServiceCorrelationTableEditMode(false);

		let correlationsTableHeaderElement = document.querySelectorAll(
			'.ant-table-thead'
		)[0];
		if (correlationsTableHeaderElement !== undefined) {
			correlationsTableHeaderElement.scrollIntoView();
		}
	};

	const save = async (key: React.Key) => {
		const row = (await form.validateFields()) as IServiceCorrelation;
		setServiceCorrelationTableEditMode(false);

		let element = toJS(
			servicesCorrelations.find((item) => item.key === key)
		);

		element.code = row.code;
		element.additionalInformation = row.additionalInformation;

		if (element.id !== undefined) {
			await updateServiceCorrelation(element);
			selectRow(element);
		} else {
			await createServiceCorrelation(element);
			selectRow(
				rootStore.servicesCorrelationsStore.servicesCorrelations[
					rootStore.servicesCorrelationsStore.servicesCorrelations
						.length - 1
				]
			);
		}
		setEditingKey('');
	};

	const selectRow = (record: IServiceCorrelation) => {
		if (record !== undefined) {
			if (
				!rootStore.servicesCorrelationsStore.servicePricesTableEditMode
			) {
				setRowClassName(
					'ant-table-row ant-table-row-level-0 ant-table-row-selected'
				);
				setRowKey(record.key);
				setPriceListForCurrentSelectedServiceCorrelation(
					record.servicePrices
				);
				setCurrentSelectedServiceCorrelationId(record.id);
				setCurrentSelectedServiceCorrelationCode(record.code);
			}
		}
	};

	const search = (value: any) => {
		setPriceListForCurrentSelectedServiceCorrelation([]);
		setCurrentSelectedServiceCorrelationId(undefined);
		setCurrentSelectedServiceCorrelationCode(undefined);
		if (value.length >= 1) {
			const tableFilterResult = data!.filter((o: any) =>
				Object.keys(o).some((k) =>
					String(o[k]).toLowerCase().includes(value.toLowerCase())
				)
			);
			setFilterTable(tableFilterResult);
			if (tableFilterResult.length > 0) {
				setRowKey(tableFilterResult[0].key);
				setPriceListForCurrentSelectedServiceCorrelation(
					tableFilterResult[0].servicePrices
				);
				setCurrentSelectedServiceCorrelationId(tableFilterResult[0].id);
				setCurrentSelectedServiceCorrelationCode(
					tableFilterResult[0].code
				);
			} else {
				setRowKey(data[0].key);
				setPriceListForCurrentSelectedServiceCorrelation(
					data[0].servicePrices
				);
				setCurrentSelectedServiceCorrelationId(data[0].id);
				setCurrentSelectedServiceCorrelationCode(data[0].code);
			}
		} else if (value.length === 0) {
			setFilterTable([] as IServiceCorrelation[]);
			setRowKey(data[0].key);
			setPriceListForCurrentSelectedServiceCorrelation(
				data[0].servicePrices
			);
			setCurrentSelectedServiceCorrelationId(data[0].id);
			setCurrentSelectedServiceCorrelationCode(data[0].code);
		}
	};

	const validateCode = (record: any) => {
		let codeValue = form.getFieldValue('code');
		if (codeValue && codeValue.length > 0) {
			let newData = toJS(servicesCorrelations);
			let currentElementIndex = newData!.findIndex(
				(el) => el.key === record.key
			);
			if (
				codeValue &&
				newData!.findIndex(
					(el) => el.code === codeValue && el.key !== record.key
				) === -1
			) {
				newData![currentElementIndex].code = codeValue;
			} else {
				form.setFieldsValue({ code: '' });
				newData![currentElementIndex].code = '';
				notification.warning({
					message: 'Кодът е задължителен и не може да се повтаря!',
					className: 'success-messages',
					duration: 5,
				});
			}
			setServicesCorrelations(newData);
		}
	};

	const columns = [
		{
			title: '',
			dataIndex: 'key',
			width: '2%',
			render: (row: any, record: any) => {
				return <div className='table-div-centered'>{record.key}</div>;
			},
		},
		{
			title: 'Системна услуга',
			dataIndex: 'baseServiceName',
			width: '40%',
			editable: true,
		},
		{
			title: 'Допълнителна информация',
			dataIndex: 'additionalInformation',
			width: '18%',
			editable: true,
		},
		{
			title: 'Код на лечебно заведение',
			dataIndex: 'code',
			width: '10%',
			editable: true,
			render: (row: any, record: any) => {
				return <div className='table-div-centered'>{record.code}</div>;
			},
		},
		{
			title: 'Системен код',
			dataIndex: 'baseServiceSystemCode',
			width: '10%',
			render: (row: any, record: any) => {
				return (
					<div className='table-div-centered'>
						{record.baseServiceSystemCode}
					</div>
				);
			},
		},
		{
			title: 'Код по МКБ 9 / НЗОК',
			dataIndex: 'nhifCode',
			width: '10%',
			render: (row: any, record: any) => {
				return (
					<div className='table-div-centered'>{record.nhifCode}</div>
				);
			},
		},
		{
			title: '',
			dataIndex: 'id',
			width: '5%',
			render: (_: any, record: IServiceCorrelation) => {
				const editable = isEditing(record);
				return editable ? (
					<div className='table-div-centered'>
						<CheckCircleOutlined
							style={{
								fontSize: 18,
								fontWeight: 700,
								color:
									record.baseServiceSystemCode.length === 0 ||
									record.code.length === 0
										? 'grey'
										: 'green',
							}}
							hidden={
								rootStore.servicesCorrelationsStore
									.servicePricesTableEditMode
							}
							onMouseEnter={() => validateCode(record)}
							onClick={async () => await save(record.key)}
							disabled={
								record.baseServiceSystemCode.length === 0 ||
								record.code.length === 0
							}
						/>
					</div>
				) : (
					<div className='table-div-centered'>
						<FormOutlined
							style={{
								fontSize: 18,
								fontWeight: 700,
								color: editingKey !== '' ? 'grey' : 'blue',
							}}
							hidden={
								rootStore.servicesCorrelationsStore
									.servicePricesTableEditMode
							}
							onClick={() => edit(record)}
							disabled={editingKey !== ''}
						/>
					</div>
				);
			},
		},
		{
			title: '',
			dataIndex: 'operation',
			width: '5%',
			render: (_: any, record: IServiceCorrelation) => {
				const editable = isEditing(record);
				return editable ? (
					<div className='table-div-centered'>
						<CloseOutlined
							style={{
								color: 'red',
								fontSize: 18,
								fontWeight: 700,
							}}
							hidden={
								rootStore.servicesCorrelationsStore
									.servicePricesTableEditMode
							}
							onClick={() => handleCancel(record)}
						/>
					</div>
				) : (
					<Popconfirm
						title='Сигурни ли сте, че искате да изтриете реда?'
						onConfirm={async () => await deleteRecord(record)}
						disabled={editingKey !== ''}>
						<div className='table-div-centered'>
							<DeleteOutlined
								style={{
									fontSize: 18,
									fontWeight: 700,
									color: editingKey !== '' ? 'grey' : 'red',
								}}
								hidden={
									rootStore.servicesCorrelationsStore
										.servicePricesTableEditMode
								}
								disabled={editingKey !== ''}
							/>
						</div>
					</Popconfirm>
				);
			},
		},
	];

	const mergedColumns = columns.map((col) => {
		if (!col.editable) {
			return col;
		}
		return {
			...col,
			onCell: (record: IServiceCorrelation) => ({
				record,
				inputType: col.dataIndex === 'age' ? 'number' : 'text',
				dataIndex: col.dataIndex,
				title: col.title,
				editing: isEditing(record),
				passedForm: form,
			}),
		};
	});

	return (
		<Fragment>
			<Form form={form} component={false}>
				<div>
					<Button
						onClick={() => {
							handleAdd();
						}}
						type='primary'
						className='add-service-correlation-btn'
						style={{ float: 'left', height: 25 }}
						disabled={
							editingKey !== '' ||
							rootStore.servicesCorrelationsStore
								.servicePricesTableEditMode
						}>
						Добави нова дейност
					</Button>
					<Input.Search
						className='search-input'
						placeholder='Търсене...'
						enterButton
						ref={searchRef}
						onSearch={search}
					/>
					<Typography.Title
						level={3}
						style={{ display: 'inline-block' }}>
						Данни за съответствие на услугите
					</Typography.Title>
				</div>
				<div className='scroll-table-service-correlations'>
					<Table
						className='servise-correlation-table'
						onRow={(record, index) => {
							return {
								onClick: () => {
									if (record.id) {
										selectRow(record);
									}
								},
							};
						}}
						style={{ marginTop: 10 }}
						scroll={{ y: 'calc(100vh - 460px)' }}
						components={{
							body: {
								cell: CorrelationsEditableCell,
							},
						}}
						loading={loadingAllTable}
						bordered
						dataSource={filterTable.length < 1 ? data : filterTable}
						columns={mergedColumns}
						pagination={false}
						rowClassName={(_record, index) => {
							return _record.key === rowKey ? rowClassName : '';
						}}
					/>
				</div>
			</Form>
		</Fragment>
	);
};

export default observer(ServicesCorrelationsEditableTable);
