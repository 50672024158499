import React, {
	Fragment,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import { observer } from 'mobx-react-lite';
import { Form, Button, Spin, Row, Col, notification, Modal } from 'antd';
import {
	IDetail,
	IAttachment,
} from '../../app/models/electronicDocument/ElectronicDocument';
import { toJS } from 'mobx';
import { useReactToPrint } from 'react-to-print';
import { PrinterOutlined, FileSearchOutlined } from '@ant-design/icons';
import PatientFormItem from '../../app/layout/FormItems/PatientFormItem';
import IssuerFormItem from '../../app/layout/FormItems/IssuerFormItem';
import MkbsFormItem from '../../app/layout/FormItems/MkbsFormItem';
import IsActiveFormItem from '../../app/layout/FormItems/IsActiveFormItem';
import Enums from '../../app/constants/enums';
import { RootStoreContext } from '../../app/stores/rootStore';
import Constants from '../../app/constants/constants';
import PatientMedicalHistoryFormItem from '../../app/layout/FormItems/PatientMedicalHistoryFormItem';
import PatientHealthStatusFormItem from '../../app/layout/FormItems/PatientHealthStatusFormItem';
import PatientExaminationsFormItem from '../../app/layout/FormItems/PatientExaminationsFormItem';
import PatientTherapyFormItem from '../../app/layout/FormItems/PatientTherapyFormItem';
import DocumentNumberAndDateFormItem from '../../app/layout/FormItems/DocumentNumberAndDateFormItem';
import AmbSheetsDetailsTable from './tables/details/AmbSheetsDetailsTable';
import AmbSheetsAttachmentsTable from './tables/attachments/AmbSheetsAttachmentsTable';
import PrintAmbulatorySheet from '../print/PrintAmbulatorySheet';
import IsPrimaryFormItem from '../../app/layout/FormItems/IsPrimaryFormItem';
import Moment from 'react-moment';
import moment from 'moment';
import AmbSheetSubPreview from '../../app/layout/AmbSheetSubPreview';

const DocumentTypeEnum = Enums.DocumentTypeEnum;

interface IProps {
	closeModal: () => Promise<void>;
	openModal: (id: number | undefined) => Promise<void>;
}
const AmbulatorySheetModal: React.FC<IProps> = ({ openModal, closeModal }) => {
	const rootStore = useContext(RootStoreContext);
	const [form] = Form.useForm();
	const [showPrimary, setShowPrimary] = useState(false);

	const {
		electronicDocumentById,
		createElectronicDocument,
		editElectronicDocument,
		detailsOriginData,
		attachmentsOriginData,
		setIsFormTouched,
		setDetailsOriginDataFromElectronicDocument,
		setAttachmentsOriginDataFromElectronicDocument,
		getElectronicSubDocumentById,
	} = rootStore.electronicDocumentStore;

	const { loadingModal } = rootStore.loadersStore;
	const { period, manuallyAttachToClaim } = rootStore.ambulatorySheetsStore;
	const { currentUser } = rootStore.identityStore;

	const defaultValues = {
		id: electronicDocumentById?.id,
		documentNumber: electronicDocumentById?.documentNumber,
		documentDate: electronicDocumentById?.documentDate,
		companyName: electronicDocumentById
			? parseInt(electronicDocumentById?.insuranceCompanyId)
			: undefined,
		patientUid: electronicDocumentById?.patientUid,
		patientName: electronicDocumentById?.patientName,
		pacientEmail: electronicDocumentById?.pacientEmail,
		patientCard: electronicDocumentById?.patientCard,
		issuerUin: !electronicDocumentById
			? currentUser!.uinCode
			: electronicDocumentById?.issuerUin,
		issuerSpecialityCode: electronicDocumentById
			? electronicDocumentById?.issuerSpecialityCode
			: undefined,
		issuerName: electronicDocumentById?.issuerName,
		isActive: electronicDocumentById?.isActive,
		mkbCode: electronicDocumentById?.mkbCode,
		mkbName: electronicDocumentById
			? electronicDocumentById!.mkbCode
			: undefined,
		patientHealthStatus: electronicDocumentById?.patientHealthStatus,
		patientTherapy: electronicDocumentById?.patientTherapy,
		patientExaminations: electronicDocumentById?.patientExaminations,
		patientMedicalHistory: electronicDocumentById?.patientMedicalHistory,
		isPrimary: electronicDocumentById?.isPrimary,
	};

	const prepareAndSubmitForm = async (
		values: any,
		detailsOriginData: IDetail[],
		attachmentsOriginData: IAttachment[]
	) => {
		const { ...electronicDocumentById } = values;

		if (!electronicDocumentById.id) {
			let newAmbulatorySheet = {
				...electronicDocumentById,
			};

			let isPrimaryToggleTouched = form.isFieldTouched('isPrimary');
			if (!isPrimaryToggleTouched) {
				newAmbulatorySheet.isPrimary = true;
			}

			values.details = [] as IDetail[];
			values.attachments = [] as IAttachment[];

			detailsOriginData.forEach((el) => {
				values.details.push(el);
			});

			attachmentsOriginData.forEach((el) => {
				values.attachments.push(el);
			});

			newAmbulatorySheet.documentType = DocumentTypeEnum.AmbSheet;
			newAmbulatorySheet.details = values.details;
			newAmbulatorySheet.attachments = values.attachments;
			newAmbulatorySheet.documentDate = newAmbulatorySheet.documentDate.local();

			let formData = new FormData();
			formData.set('request', JSON.stringify(newAmbulatorySheet));
			newAmbulatorySheet.attachments.forEach((element: IAttachment) => {
				formData.append(`files`, element.file!);
			});

			let lastRecordId = await createElectronicDocument(
				formData,
				DocumentTypeEnum.AmbSheet
			);

			if (lastRecordId !== undefined) {
				await rootStore.electronicDocumentStore.getElectronicDocumentById(
					lastRecordId
				);
				rootStore.electronicDocumentStore.setIsFormTouched(false);
				rootStore.electronicDocumentStore.setGetElDocsAgain(true);

				if (
					moment(electronicDocumentById?.documentDate) <
						moment(period.dateFrom) ||
					moment(electronicDocumentById?.documentDate) >
						moment(period.dateTo)
				) {
					notification.info({
						message:
							'Периодът на този амбулаторен лист е различен от периода, зададен във филтъра на таблицата. За да го видите трябва да смените настройката по период!',
					});
				}
			}
		} else {
			let newMedicalReferral = {
				...electronicDocumentById,
			};
			values.details = [] as IDetail[];
			values.attachments = [] as IAttachment[];

			detailsOriginData.forEach((el) => {
				values.details.push(toJS(el));
			});
			attachmentsOriginData.forEach((el) => {
				values.attachments.push(toJS(el));
			});

			newMedicalReferral.details = values.details;
			newMedicalReferral.attachments = values.attachments;

			if (Number.isInteger(newMedicalReferral.isActive)) {
				newMedicalReferral.isActive =
					newMedicalReferral.isActive === 1 ? true : false;
			}
			await editElectronicDocument(
				newMedicalReferral!,
				DocumentTypeEnum.AmbSheet
			);
			await rootStore.electronicDocumentStore.getElectronicDocumentById(
				newMedicalReferral.id
			);
			rootStore.electronicDocumentStore.setIsFormTouched(false);
			rootStore.electronicDocumentStore.setGetElDocsAgain(true);
			// await closeModal();
			// await openModal(newMedicalReferral.id);
		}
	};

	const preview = async (id: number) => {
		if (id) {
			await getElectronicSubDocumentById(id);
		}

		setShowPrimary(true);
	};

	const onFormFieldsChange = () => {
		setIsFormTouched(true);
	};

	useEffect(() => {
		if (!detailsOriginData) {
			setDetailsOriginDataFromElectronicDocument(electronicDocumentById);
		}
		if (!attachmentsOriginData) {
			setAttachmentsOriginDataFromElectronicDocument(
				electronicDocumentById
			);
		}
	}, [detailsOriginData, attachmentsOriginData]);

	const componentRef = useRef(null);
	const handlePrint = useReactToPrint({
		content: () => componentRef.current!,
	});

	return (
		<Fragment>
			<Spin spinning={loadingModal}>
				<div
					style={{
						textAlign: 'center',
						display:
							electronicDocumentById?.isActive === false
								? 'inherit'
								: 'none',
						color: 'red',
						fontSize: '20px',
					}}>
					{electronicDocumentById?.isActive === false
						? 'Този амбулаторен лист е неактивен!'
						: ''}
				</div>
				<div
					style={{
						display:
							(electronicDocumentById?.claimProcessStatus ===
								Constants.ClaimProcessStatus.ChangeRequested &&
								electronicDocumentById?.insuranceCompanyNotes) ||
							(electronicDocumentById?.claimProcessStatus ===
								Constants.ClaimProcessStatus.Processed &&
								electronicDocumentById?.insuranceCompanyNotes)
								? 'inherit'
								: 'none',

						fontSize: '15px',
						color: 'red',
					}}>
					Резолюция / Забележки:{' '}
					{electronicDocumentById?.insuranceCompanyNotes}
				</div>
				<Form
					form={form}
					initialValues={defaultValues}
					key={electronicDocumentById?.id}
					onFieldsChange={onFormFieldsChange}
					onFinish={(values) =>
						prepareAndSubmitForm(
							values,
							detailsOriginData!,
							attachmentsOriginData!
						)
					}>
					{rootStore.electronicDocumentStore
						.electronicDocumentById === undefined ? (
						<DocumentNumberAndDateFormItem
							label={'Амбулаторен лист'}
							form={form}
						/>
					) : (
						<div
							style={{
								display:
									!electronicDocumentById?.isPrimary &&
									electronicDocumentById?.primaryElectronicDocument
										? 'flex'
										: 'inherit',
							}}>
							<IsPrimaryFormItem form={form} />
							<div
								style={{
									display:
										!electronicDocumentById?.isPrimary &&
										electronicDocumentById?.primaryElectronicDocument
											? 'inherit'
											: 'none',
									color: 'black',
									marginLeft: 13,
								}}>
								<div>
									Този документ е закачен към първичен:
									Документ №{' '}
									{
										electronicDocumentById
											?.primaryElectronicDocument
											?.documentNumber
									}{' '}
									от{' '}
									<Moment
										format={
											Constants.Common
												.FullDate_Format_Without_Seconds
										}>
										{
											electronicDocumentById
												?.primaryElectronicDocument
												?.documentDate
										}
									</Moment>
									. За преглед:{' '}
									<Button
										type='link'
										onClick={() =>
											preview(
												electronicDocumentById
													?.primaryElectronicDocument
													?.id
											)
										}>
										<FileSearchOutlined
											style={{
												color: '#e48800',
												fontSize: 20,
												fontWeight: 700,
											}}
										/>
									</Button>
								</div>
							</div>
						</div>
					)}

					<PatientFormItem form={form} />
					<IssuerFormItem label='Лекар:' form={form} />
					<MkbsFormItem form={form} />
					<Row gutter={[40, 4]}>
						<Col xs={24} sm={12} md={12} lg={12} xl={12}>
							<PatientMedicalHistoryFormItem form={form} />
						</Col>
						<Col xs={24} sm={12} md={12} lg={12} xl={12}>
							<PatientHealthStatusFormItem form={form} />
						</Col>
					</Row>
					<Row gutter={[40, 4]}>
						<Col xs={24} sm={12} md={12} lg={12} xl={12}>
							<PatientExaminationsFormItem form={form} />
						</Col>
						<Col xs={24} sm={12} md={12} lg={12} xl={12}>
							<PatientTherapyFormItem form={form} />
						</Col>
					</Row>

					<AmbSheetsDetailsTable
						passedForm={form}
						openModal={openModal}
						closeModal={closeModal}
					/>
					<AmbSheetsAttachmentsTable
						passedForm={form}
						openModal={openModal}
						closeModal={closeModal}
					/>

					<IsActiveFormItem form={form} />

					<Form.Item name='id'>
						<div style={{ display: 'none' }}>
							<PrintAmbulatorySheet ref={componentRef} />
						</div>
						<Button
							key='print'
							type='primary'
							className='btn-groupe'
							shape='round'
							style={{
								float: 'left',
								margin: 0,
								display:
									!electronicDocumentById ||
									electronicDocumentById?.isActive === false
										? 'none'
										: 'block',
								backgroundColor: '#234254',
								borderRadius: 20,
							}}
							onClick={handlePrint}>
							<PrinterOutlined /> Принтирай
						</Button>

						<Button
							onMouseEnter={(e) =>
								form.setFieldsValue({
									patientName: form
										.getFieldValue('patientName')
										.trim(),
								})
							}
							disabled={
								electronicDocumentById?.isActive === false ||
								rootStore.electronicDocumentStore
									.detailsEditMode === true ||
								rootStore.electronicDocumentStore
									.isFormTouched === false
							}
							type='primary'
							shape='round'
							className='btn-primary submit-referral-btn'
							htmlType='submit'
							style={{
								display:
									electronicDocumentById?.isActive === false
										? 'none'
										: 'block',
							}}>
							{electronicDocumentById
								? 'Запази промените'
								: 'Създай амбул. лист'}
						</Button>
						<Button
							key='attachToClaim'
							type='primary'
							className='btn-primary'
							shape='round'
							style={{
								margin: 0,
								display:
									!electronicDocumentById ||
									electronicDocumentById?.isActive ===
										false ||
									electronicDocumentById?.attachManuallyToClaimId ===
										null
										? 'none'
										: 'inline',
								backgroundColor: '#234254 !important',
								borderRadius: 20,
								marginRight: 10,
								float: 'right',
							}}
							onClick={async () => {
								let eDocId = electronicDocumentById?.id;
								await manuallyAttachToClaim(
									electronicDocumentById?.attachManuallyToClaimId,
									electronicDocumentById?.id
								);
								await closeModal();
								await openModal(eDocId);
							}}>
							Закачи към иск
						</Button>
					</Form.Item>
				</Form>
			</Spin>
			<Modal
				className='appointment-modal'
				title={'Преглед на електронен документ'}
				centered
				maskClosable={false}
				transitionName='none'
				maskTransitionName='none'
				onCancel={() => setShowPrimary(false)}
				visible={showPrimary}
				footer={false}
				destroyOnClose={true}>
				{' '}
				<AmbSheetSubPreview />
			</Modal>
		</Fragment>
	);
};
export default observer(AmbulatorySheetModal);
