import { IUpdateUser } from './../models/userManagement/UpdateUser';
import {
	IForCertificateAuthenticationUser,
} from './../models/userManagement/CreateUser';
import { IUser } from './../models/userManagement/User';
import { observable, action, runInAction } from 'mobx';
import agent from '../../api/agent';
import { notification } from 'antd';
import { RootStore } from './rootStore';

export default class UserManagementStore {
	rootStore: RootStore;

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
	}

	@observable allUsers: IUser[] | undefined;
	@observable userById: IUser | undefined;
	@observable currentSelectedUserId: number | undefined;
	@observable loadingInitial = false;

	@action setCurrentSelectedUserId = (id: number) => {
		this.currentSelectedUserId = id;
	};

	@action getAllUsers = async () => {
		this.loadingInitial = true;
		try {
			let res = await agent.UserManagement.getAll();
			runInAction(() => {
				this.allUsers = res;
				this.loadingInitial = false;
			});
		} catch (error) {
			notification.error({
				message: 'Възникна грешка',
				className: 'success-messages',
				duration: 5,
			});
		}
	};

	@action getUserById = async (id: number | undefined) => {
		if (id) {
			this.loadingInitial = true;
			try {
				let res = await agent.UserManagement.getById(id);
				runInAction(() => {
					this.userById = res;
					this.loadingInitial = false;
				});
			} catch (error) {
				notification.error({
					message: error.data,
					className: 'success-messages',
					duration: 5,
				});
			}
		} else {
			this.userById = undefined;
		}
	};

	@action editUser = async (user: IUpdateUser) => {
		this.loadingInitial = true;
		try {
			await agent.UserManagement.update(user);
			runInAction(() => {
				this.loadingInitial = false;

				notification.success({
					message: 'Профилът е успешно променен!',
					duration: 5,
					className: 'success-messages',
				});
			});
		} catch (error) {
			runInAction(() => {
				this.loadingInitial = false;
				notification.error({
					message: error.data,
					className: 'success-messages',
					duration: 5,
				});
			});
		}
	};

	@action createForCertificateAuthenticationUser = async (
		user: IForCertificateAuthenticationUser
	) => {
		this.loadingInitial = true;
		try {
			await agent.UserManagement.createForCertificateAuthenticationUser(
				user
			);
			runInAction(() => {
				this.loadingInitial = false;
			});
		} catch (error) {
			runInAction(() => {
				this.loadingInitial = false;
			});
			notification.error({
				message: error.data,
				duration: 5,
				className: 'success-messages',
			});
		}
	};

	@action deleteUser = async (id: number) => {
		this.loadingInitial = true;
		try {
			await agent.UserManagement.delete(id);
			runInAction(() => {
				this.loadingInitial = false;
			});
		} catch (error) {
			notification.error({
				message: "Възникна грешка при изтриване на профила!",
				className: "success-messages",
				duration: 5,
			});
			runInAction(() => {
				this.loadingInitial = false;
			});
		}
	};

	@action activateUser = async (id: number) => {
		this.loadingInitial = true;
		try {
			await agent.UserManagement.activate(id);
			runInAction(() => {
				this.loadingInitial = false;
			});
		} catch (error) {
			notification.error({
				message: error.data,
				className: 'success-messages',
				duration: 5,
			});
			runInAction(() => {
				this.loadingInitial = false;
			});
		}
	};

	@action deactivateUser = async (id: number) => {
		this.loadingInitial = true;
		try {
			await agent.UserManagement.deactivate(id);
			runInAction(() => {
				this.loadingInitial = false;
			});
		} catch (error) {
			notification.error({
				message: error.data,
				className: 'success-messages',
				duration: 5,
			});
			runInAction(() => {
				this.loadingInitial = false;
			});
		}
	};
}
