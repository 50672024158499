import React, { useState, useContext, useEffect } from 'react';
import { Button, Form, Input, Modal } from 'antd';
import { FormOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../app/stores/rootStore';
import Constants from '../../app/constants/constants';

const EmailModal: React.FC = () => {
	const rootStore = useContext(RootStoreContext);

	const {
		checkUserEmail,
		userEmail,
		currentUser,
		updateUserEmail,
		setCurrentuserId,
	} = rootStore.identityStore;

	const [form] = Form.useForm();
	const [show, setShow] = useState(false);
	const [newEmail, setNewEmail] = useState();

	const openModal = (id: string) => {
		form.resetFields();
		checkUserEmail().then(() => {
			setCurrentuserId(id).then(() => {
				setShow(true);
			});
		});
	};

	const closeModal = () => {
		form.resetFields();
		setShow(false);
		setNewEmail(undefined);
		setCurrentuserId(undefined!);
	};

	const handleSubmit = () => {
		updateUserEmail(currentUser!.userId, newEmail!).then(() => {
			closeModal();
		});
	};

	useEffect(() => {
		if (show) {
			form.setFieldsValue({
				userEmail: userEmail,
				newEmail: newEmail!,
			});
		} else {
			form.resetFields();
		}
	}, [form, show]);

	return (
		<div className='btn-profile-group'>
			<Button
				type='primary'
				shape='round'
				className='btn-profile'
				onClick={() => openModal(currentUser?.userId!)}>
				<FormOutlined /> Имейл
			</Button>
			<Modal
				width='600px'
				title='Смяна на имейл за получаване на съобщения'
				centered
				maskClosable={false}
				transitionName='none'
				maskTransitionName='none'
				onCancel={() => closeModal()}
				visible={show}
				footer={false}
				destroyOnClose={true}>
				<Form
					layout='vertical'
					form={form}
					onFinish={() => handleSubmit()}
					initialValues={{
						userEmail: userEmail,
						newEmail: newEmail!,
					}}>
					{userEmail.length > 0 ? (
						<Form.Item
							name='userEmail'
							label='Сегашен имейл'
							shouldUpdate={true}
							rules={[
								{
									type: 'email',
									message: 'Моля, попълнете валиден имейл!',
								},
								{
									required: true,
									message:
										Constants.FormMessages.RequiredField,
								},
							]}>
							<Input type='email' disabled={true} />
						</Form.Item>
					) : (
						<div></div>
					)}
					<Form.Item
						name='newEmail'
						label='Нов имейл'
						shouldUpdate={true}
						rules={[
							{
								type: 'email',
								message: 'Моля, попълнете валиден имейл!',
							},
							{
								required: true,
								message: Constants.FormMessages.RequiredField,
							},
							({ getFieldValue }) => ({
								validator(rule, value) {
									if (
										!value ||
										getFieldValue('userEmail') !== value
									) {
										return Promise.resolve();
									}
									return Promise.reject(
										'Новият имейл трябва да е различен от стария!'
									);
								},
							}),
						]}>
						<Input
							type='email'
							onChange={(e: any) => setNewEmail(e.target.value)}
						/>
					</Form.Item>
					<Form.Item shouldUpdate>
						<Button
							className='btn-primary'
							type='primary'
							htmlType='submit'
							onClick={() => handleSubmit}>
							Промени
						</Button>
					</Form.Item>
				</Form>
			</Modal>
		</div>
	);
};
export default observer(EmailModal);
