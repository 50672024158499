import { observer } from 'mobx-react-lite';
import React from 'react';
import DeseaseHistoryGrid from './DeseaseHistoryGrid';

const DeseaseHistoryMain: React.FC = () => {
	return (
		<div>
			<DeseaseHistoryGrid/>
		</div>
	);
};
export default observer(DeseaseHistoryMain);
