import React, { Fragment, useContext, useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { Form, Button, Spin } from 'antd';
import {
	IDetail,
	IAttachment,
} from '../../../app/models/electronicDocument/ElectronicDocument';
import { toJS } from 'mobx';
import { useReactToPrint } from 'react-to-print';
import PrintElectronicDocument from '../../print/PrintElectronicDocument';
import { PrinterOutlined } from '@ant-design/icons';
import PatientFormItem from '../../../app/layout/FormItems/PatientFormItem';
import IssuerFormItem from '../../../app/layout/FormItems/IssuerFormItem';
import MkbsFormItem from '../../../app/layout/FormItems/MkbsFormItem';
import DetailsFormItem from '../../../app/layout/FormItems/DetailsFormItem';
import AttachmentsFormItem from '../../../app/layout/FormItems/AttachmentsFormItem';
import MedicalNotesFormItem from '../../../app/layout/FormItems/MedicalNotesFormItem';
import IsActiveFormItem from '../../../app/layout/FormItems/IsActiveFormItem';
import Enums from '../../../app/constants/enums';
import { RootStoreContext } from '../../../app/stores/rootStore';
import Constants from '../../../app/constants/constants';
import ToSpecialistSpecCodeFormItem from '../../../app/layout/FormItems/ToSpecialistSpecCodeFormItem';

const DocumentTypeEnum = Enums.DocumentTypeEnum;

interface IProps {
	closeModal: () => Promise<void>;
	openModal: (id: number | undefined) => Promise<void>;
}

const CreateReferralModal: React.FC<IProps> = ({ closeModal, openModal }) => {
	const [form] = Form.useForm();
	const rootStore = useContext(RootStoreContext);

	const { sendPatientEmail } = rootStore.medicalReferralStore;

	const { loadingModal, loadingEmailSending } = rootStore.loadersStore;

	const {
		detailsOriginData,
		attachmentsOriginData,
		electronicDocumentById,
		createElectronicDocument,
		editElectronicDocument,
		setDetailsOriginDataFromElectronicDocument,
		setAttachmentsOriginDataFromElectronicDocument,
		setIsFormTouched,
	} = rootStore.electronicDocumentStore;

	const { currentUser } = rootStore.identityStore;

	const defaultValues = {
		patientName: electronicDocumentById?.patientName,
		patientUid: electronicDocumentById?.patientUid,
		issuerUin: !electronicDocumentById
			? currentUser!.uinCode
			: electronicDocumentById?.issuerUin,
		issuerName: electronicDocumentById?.issuerName,
		issuerSpecialityCode: electronicDocumentById
			? electronicDocumentById?.issuerSpecialityCode
			: undefined,
		details: electronicDocumentById?.details,
		attachments: electronicDocumentById?.attachments,
		medicalNotes: electronicDocumentById?.medicalNotes,
		isActive: electronicDocumentById?.isActive,
		id: electronicDocumentById?.id,
		pacientEmail: electronicDocumentById?.pacientEmail,
		mkbCode: electronicDocumentById?.mkbCode,
		mkbName: electronicDocumentById
			? electronicDocumentById?.mkbCode
			: undefined,
		toSpecialistSpecialityCode:
			electronicDocumentById?.toSpecialistSpecialityCode,
	};

	const prepareAndSubmitForm = async (
		values: any,
		detailsOriginData: IDetail[],
		attachmentsOriginData: IAttachment[]
	) => {
		const { ...electronicDocumentById } = values;

		if (!electronicDocumentById.id) {
			let newMedicalReferral = {
				...electronicDocumentById,
			};
			values.details = [] as IDetail[];
			values.attachments = [] as IAttachment[];

			detailsOriginData.forEach((el) => {
				values.details.push(el);
			});

			attachmentsOriginData.forEach((el) => {
				values.attachments.push(el);
			});

			newMedicalReferral.documentType =
				Enums.DocumentTypeEnum.MedicalReferral;
			newMedicalReferral.details = values.details;
			newMedicalReferral.attachments = values.attachments;

			let formData = new FormData();
			formData.set('request', JSON.stringify(newMedicalReferral));
			newMedicalReferral.attachments.forEach((element: IAttachment) => {
				formData.append(`files`, element.file!);
			});

			await createElectronicDocument(
				formData,
				DocumentTypeEnum.MedicalReferral
			).then(async (lastRecordId) => {
				if (form.getFieldValue('pacientEmail')) {
					await sendPatientEmail(lastRecordId!, false, false);
				}
				await rootStore.electronicDocumentStore.getElectronicDocumentById(
					lastRecordId
				);
				rootStore.electronicDocumentStore.setIsFormTouched(false);
				rootStore.electronicDocumentStore.setGetElDocsAgain(true);
				rootStore.medicalReferralStore.setActivateSendEmailButton(
					false
				);
				// await closeModal();
				// form.resetFields();
				// await openModal(lastRecordId);
			});
		} else {
			let newMedicalReferral = {
				...electronicDocumentById,
			};
			values.details = [] as IDetail[];
			values.attachments = [] as IAttachment[];

			detailsOriginData.forEach((el) => {
				values.details.push(toJS(el));
			});
			attachmentsOriginData.forEach((el) => {
				values.attachments.push(toJS(el));
			});

			newMedicalReferral.details = values.details;
			newMedicalReferral.attachments = values.attachments;

			if (Number.isInteger(newMedicalReferral.isActive)) {
				newMedicalReferral.isActive =
					newMedicalReferral.isActive === 1 ? true : false;
			}
			await editElectronicDocument(
				newMedicalReferral!,
				DocumentTypeEnum.MedicalReferral
			);
			if (electronicDocumentById.pacientEmail) {
				if (!newMedicalReferral.isActive) {
					await sendPatientEmail(
						electronicDocumentById.id,
						false,
						true
					);
				} else {
					await sendPatientEmail(
						electronicDocumentById.id,
						true,
						false
					);
				}
			}
			//rootStore.electronicDocumentStore.getElectronicDocumentById(electronicDocumentById?.id);
			rootStore.electronicDocumentStore.setIsFormTouched(false);
			rootStore.electronicDocumentStore.setGetElDocsAgain(true);
			rootStore.medicalReferralStore.setActivateSendEmailButton(false);
			// await closeModal();
			// await openModal(newMedicalReferral.id);
		}
	};

	const onFormFieldsChange = () => {
		setIsFormTouched(true);
	};

	useEffect(() => {
		if (!detailsOriginData) {
			setDetailsOriginDataFromElectronicDocument(electronicDocumentById);
		}
		if (!attachmentsOriginData) {
			setAttachmentsOriginDataFromElectronicDocument(
				electronicDocumentById
			);
		}
	}, [detailsOriginData, attachmentsOriginData]);

	const componentRef = useRef(null);
	const handlePrint = useReactToPrint({
		content: () => componentRef.current!,
	});

	return (
		<Fragment>
			<Spin
				tip={
					loadingEmailSending === true
						? 'Изпращане на имейл до пациент...'
						: 'Зареждане'
				}
				spinning={
					loadingEmailSending === true
						? loadingEmailSending
						: loadingModal
				}>
				<div
					style={{
						textAlign: 'center',
						display:
							electronicDocumentById?.status ===
								Constants.MedicalReferralStatus.Performed ||
							electronicDocumentById?.isActive === false
								? 'inherit'
								: 'none',
						color: 'red',
						fontSize: '21px',
					}}>
					{electronicDocumentById?.isActive === false
						? 'Това медицинско назначение е неактивно!'
						: 'Това медицинско назначение вече е запазено!'}
				</div>
				<Form
					form={form}
					initialValues={defaultValues}
					onFieldsChange={onFormFieldsChange}
					onFinish={(values) =>
						prepareAndSubmitForm(
							values,
							detailsOriginData!,
							attachmentsOriginData!
						)
					}>
					<PatientFormItem form={form} />
					<IssuerFormItem label='Издадено от:' form={form} />
					<MkbsFormItem form={form} />
					<ToSpecialistSpecCodeFormItem form={form} />
					<DetailsFormItem
						form={form}
						openModal={openModal}
						closeModal={closeModal}
					/>
					<AttachmentsFormItem
						form={form}
						openModal={openModal}
						closeModal={closeModal}
					/>
					<MedicalNotesFormItem form={form} />
					<IsActiveFormItem form={form} />

					<Form.Item name='id'>
						<div style={{ display: 'none' }}>
							<PrintElectronicDocument ref={componentRef} />
						</div>
						<Button
							key='print'
							type='primary'
							className='btn-groupe'
							shape='round'
							style={{
								float: 'left',
								margin: 0,
								display:
									!electronicDocumentById ||
									electronicDocumentById?.isActive ===
										false ||
									electronicDocumentById?.status ===
										Constants.MedicalReferralStatus
											.Performed
										? 'none'
										: 'block',
								backgroundColor: '#234254',
								borderRadius: 20,
							}}
							onClick={handlePrint}>
							<PrinterOutlined /> Принтирай
						</Button>
						<Button
							disabled={
								electronicDocumentById?.status ===
									Constants.MedicalReferralStatus.Performed ||
								electronicDocumentById?.isActive === false ||
								rootStore.electronicDocumentStore
									.detailsEditMode === true ||
								rootStore.electronicDocumentStore
									.isFormTouched === false
							}
							key='submit'
							type='primary'
							shape='round'
							className='btn-primary submit-referral-btn'
							htmlType='submit'
							style={{
								display:
									electronicDocumentById?.isActive === false
										? 'none'
										: 'block',
							}}
							onMouseEnter={(e) =>
								form.setFieldsValue({
									patientName: form
										.getFieldValue('patientName')
										.trim(),
								})
							}>
							{electronicDocumentById
								? 'Запази промените'
								: 'Създай назначение'}
						</Button>
					</Form.Item>
				</Form>
			</Spin>
		</Fragment>
	);
};
export default observer(CreateReferralModal);
