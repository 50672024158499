import { Button, Form, notification, Select, Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useContext, useRef, useState } from 'react';
import { RootStoreContext } from '../../app/stores/rootStore';
import { v4 as uuidv4 } from 'uuid';
import Constants from '../../app/constants/constants';
import moment from 'moment';

interface IProps {
	closeModal: () => Promise<void>;
	examinationTypeInitialValue: any;
	insuranceCompanyInitialValue: any;
	rowKey: undefined;
	setRowKey: React.Dispatch<(prevState: undefined) => undefined>;
}

const XmlUploadModal: React.FC<IProps> = ({
	closeModal,
	examinationTypeInitialValue,
	insuranceCompanyInitialValue,
	rowKey,
	setRowKey,
}) => {
	const [form] = Form.useForm();
	const rootStore = useContext(RootStoreContext);

	const { companies } = rootStore.operationsStore;
	const {
		createXmlRequest,
		updateXmlRequest,
		xmlById,
		downloadXml,
		period,
	} = rootStore.xmlImportStore;

	const examinations = [
		{ value: 1, label: 'Амбулаторна' },
		{ value: 2, label: 'МДД' },
	];

	const { Option } = Select;
	const fileLabelRef = useRef(null);

	const [fileS, setFile] = useState({} as File);

	const onFileUploadChange = (e: any) => {
		if (
			xmlById !== undefined ||
			insuranceCompanyInitialValue !== undefined
		) {
			form.setFields([{ name: 'insuranceCompanyId', touched: true }]);
		}
		if (
			xmlById !== undefined ||
			examinationTypeInitialValue !== undefined
		) {
			form.setFields([{ name: 'examinationType', touched: true }]);
		}

		if (
			form.isFieldTouched('insuranceCompanyId') &&
			form.isFieldTouched('examinationType')
		) {
			if (e.target.files.length > 0) {
				let file = e.target.files[0] as File;
				const isXml =
					file.type === 'application/xml' || file.type === 'text/xml';

				if (!isXml) {
					notification.error({
						message:
							'Прикачените документи могат да са само в XML формат!',
						className: 'success-messages',
						duration: 5,
					});

					return;
				}

				setFile(file);
			}
		} else {
			e.target.value = '';
			notification.error({
				message: 'Попълнете първо другите полета!',
				className: 'success-messages',
				duration: 5,
			});
		}
	};

	const download = async () => {
		await downloadXml(xmlById?.id);

		if (rootStore.xmlImportStore.xmlDownloadResult) {
			const url = window.URL.createObjectURL(
				new Blob([rootStore.xmlImportStore.xmlDownloadResult!])
			);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute(
				'download',
				`${xmlById?.doctorUin}_${
					xmlById?.insuranceCompanyName
				}_${moment(xmlById?.dateFrom)
					.format(Constants.Common.ShortDate_Format)
					.toString()}_${moment(xmlById?.dateTo)
					.format(Constants.Common.ShortDate_Format)
					.toString()}.xml`
			);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	};

	const createOrUpdateXmlImport = async (values) => {
		let formData = new FormData();
		values.xmlImportRecordId = xmlById?.id;
		values.date = period;

		let requestObject = { ...values, period };

		formData.set('request', JSON.stringify(requestObject));
		formData.append(`files`, fileS);

		if (xmlById) {
			await updateXmlRequest(formData);
		} else {
			let targetId = (await createXmlRequest(formData)) as any;
			setRowKey(targetId);
			let newElement = document.querySelectorAll(
				`[data-row-key="${targetId}"]`
			)[0];
			if (newElement !== undefined) {
				newElement.scrollIntoView();
			}
		}

		await closeModal();
	};

	return (
		<Spin spinning={rootStore.loadersStore.loadingModal}>
			<Form
				form={form}
				layout='vertical'
				onFinish={(values) => createOrUpdateXmlImport(values)}
				initialValues={{
					insuranceCompanyId: xmlById
						? xmlById?.insuranceCompanyId
						: insuranceCompanyInitialValue
						? insuranceCompanyInitialValue
						: undefined,
					examinationType: xmlById
						? xmlById?.examinationType
						: examinationTypeInitialValue
						? examinationTypeInitialValue
						: undefined,
				}}>
				<Form.Item
					label='Застрахователна компания'
					name='insuranceCompanyId'
					rules={[
						{
							required: true,
						},
					]}>
					<Select
						showSearch
						optionFilterProp='title'
						placeholder='Застрахователна компания'
						disabled={xmlById !== undefined}>
						{companies?.map((company) => (
							<Option
								key={uuidv4()}
								value={company.id}
								title={company.name}>
								{company.name}
							</Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item
					label='Тип на дейността'
					name='examinationType'
					rules={[
						{
							required: true,
						},
					]}>
					<Select
						showSearch
						optionFilterProp='title'
						placeholder='Тип на дейността'
						disabled={xmlById !== undefined}>
						{examinations?.map((examination) => (
							<Option
								key={uuidv4()}
								value={examination.value}
								title={examination.label}>
								{examination.label}
							</Option>
						))}
					</Select>
				</Form.Item>
				<div
					onClick={() => download()}
					hidden={xmlById?.id === undefined}
					className='xml-upload-bnt'
					style={{ background: 'rgb(30,47,16)' }}>
					Свали XML
				</div>
				<Form.Item name='file'>
					<label htmlFor='file' id='file_label' ref={fileLabelRef}>
						{
							<div style={{ fontSize: '14px' }}>
								<div className='xml-upload-bnt'>
									{xmlById?.id === undefined
										? 'Избери XML'
										: 'Избери нов XML'}
								</div>
								<div
									style={{
										display: 'inline-block',
										width: '60%',
										marginLeft: 10,
										lineHeight: 'normal',
									}}>
									{fileS?.name
										? fileS.name
										: xmlById !== undefined
										? ''
										: 'Няма качен файл'}
								</div>
							</div>
						}
					</label>
					<input
						id='file'
						style={{ display: 'none' }}
						type='file'
						accept='.xml'
						onChange={(e: any) => onFileUploadChange(e)}></input>
				</Form.Item>

				<Form.Item>
					<Button
						disabled={form.isFieldsTouched() === false}
						type='primary'
						shape='round'
						className='btn-primary submit-referral-btn'
						htmlType='submit'>
						Пускане за обработка
					</Button>
				</Form.Item>
			</Form>
		</Spin>
	);
};

export default observer(XmlUploadModal);
