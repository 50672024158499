enum DocumentTypeEnum {
	//[Description("Амбулаторен лист")]
	AmbSheet = 0,

	//[Description("Медицинско назначение")]
	MedicalReferral = 1,

	//[Description("Лабораторни резултати")]
	LabResults = 2,

	//[Description("Искане за предварително одобрение")]
	PriorApproval = 3,

	//[Description("История на заболяването")]
	DeseaseHistory = 4,

	//[Description("Етапна епикриза")]
	StageEpicrisis = 5,

	//[Description("Декларация за упълномощаване от пациент")]
	AuthorizationByPatient = 6,

	//[Description("Рецепта")]
	Prescription = 7,
	//[Description("Карта профилактика")]
	Preventions = 8,

	//[Description("Друг документ")]
	Other = 9,
}

enum DocumentStatusEnum {
	//[Description("Регистриран")]
	Registered = 0,

	//[Description("Отворен")]
	Open = 1,

	//[Description("Отворен за промяна")]
	OpenForModification = 2,

	//[Description("Потвърден")]
	Approved = 3,

	//[Description("Отхвърлен")]
	Rejected = 4,
}

enum AttachmentTypeEnum {
	ElectronicDocument = 0,

	PhysicalCopy = 1,
}

export default { DocumentTypeEnum, AttachmentTypeEnum, DocumentStatusEnum };
