import { Button, Popconfirm, Spin, Switch } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useContext, useRef, useState } from 'react';
import constants from '../../app/constants/constants';
import { RootStoreContext } from '../../app/stores/rootStore';
import { PrinterOutlined, FileExcelOutlined } from '@ant-design/icons';
import { useReactToPrint } from 'react-to-print';
import PrintDetailsOnClaims from '../print/PrintDetailsOnClaims';
import PrintDocOnClaims from '../print/PrintDocOnClaims';
import DocumtentsInClaimTable from './DocumtentsInClaimTable';
import { setTimeout } from 'timers';

interface IProps {
	closeModal: () => Promise<void>;
}

const ClaimByIdModal: React.FC<IProps> = ({ closeModal }) => {
	const rootStore = useContext(RootStoreContext);
	const {
		claimById,
		SetInProcessingStatus,
		getAllClaims,
		period,
		setIsPrintingClaimDoc,
		setIsPrintingClaimDetails,
	} = rootStore.claimStore;

	const {
		exportClaimDetailsToExcel,
		exportClaimAttachmentsToExcel,
	} = rootStore.electronicDocumentStore;

	const [documentsVisible, setDocumentsVisible] = useState(false);
	const [detailsVisible, setDetailsVisible] = useState(false);

	const componentRef = useRef(null);
	const handlePrint = useReactToPrint({
		content: () => componentRef.current!,
	});

	const componentRefDoc = useRef(null);
	const handlePrintDoc = useReactToPrint({
		content: () => componentRefDoc.current!,
	});

	const exportDetailsToExcel = async () => {
		await exportClaimDetailsToExcel(claimById?.id);

		if (rootStore.electronicDocumentStore.claimDetailsExcelDownloadResult) {
			const url = window.URL.createObjectURL(
				new Blob([
					rootStore.electronicDocumentStore
						.claimDetailsExcelDownloadResult!,
				])
			);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute(
				'download',
				`${claimById?.documentNumber}_procedures.xlsx`
			);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	};

	const exportAttachmentsToExcel = async () => {
		await exportClaimAttachmentsToExcel(claimById?.id);

		if (
			rootStore.electronicDocumentStore
				.claimAttachmentsExcelDownloadResult
		) {
			const url = window.URL.createObjectURL(
				new Blob([
					rootStore.electronicDocumentStore
						.claimAttachmentsExcelDownloadResult!,
				])
			);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute(
				'download',
				`${claimById?.documentNumber}_documents.xlsx`
			);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	};

	return (
		<div>
			<Spin
				tip={'Зареждане...'}
				spinning={rootStore.loadersStore.loadingModal}>
				<h4 style={{ marginTop: 20 }}>
					Разбивка по извършени дейности{' '}
					<Switch
						unCheckedChildren='Виж'
						checkedChildren='Скрий'
						defaultChecked={detailsVisible}
						onChange={() => setDetailsVisible(!detailsVisible)}
					/>
					<div style={{ display: 'none' }}>
						<PrintDetailsOnClaims ref={componentRef} />
					</div>
					<span style={{float: 'right',}}>
					<Button
						key='printDetails'
						type='primary'
						shape='round'
						style={{
							
							display: detailsVisible ? 'inline-block' : 'none',
							position: 'inherit',
							marginBottom: 10,
							borderRadius: 20,
							fontSize: 13,
						}}
						onClick={() => {
							setIsPrintingClaimDetails(true);
							setTimeout(() => {
								handlePrint();
							}, 2000);
						}}>
						<PrinterOutlined /> Принтирай
					</Button>
					<Button
						key='exportDetails'
						type='primary'
						shape='round'
						style={{
							
							display: detailsVisible ? 'inline-block' : 'none',
							position: 'inherit',
							marginBottom: 10,
							borderRadius: 20,
							marginLeft: 5,
							fontSize: 13,
						}}
						onClick={async () => await exportDetailsToExcel()}>
						<FileExcelOutlined /> Изтегляне (xlsx)
					</Button></span>
				</h4>

				<div
					className='table-fixed-header-div'
					style={{
						display: detailsVisible ? 'block' : 'none',
						maxHeight: '29vh',
						width: '100%',
						borderBottom: '1px solid',
					}}>
					<table
						className='claim-table-striped'
						style={{
							borderCollapse: 'collapse',
							textAlign: 'center',
							width: '100%',
							color: 'black',
							fontSize: 13,
						}}>
						<thead>
							<tr style={{ border: '1px solid ' }}>
								<th
									style={{ width: '40%' }}
									className='table-fixed-header-th'>
									{' '}
									Дейност{' '}
								</th>
								<th
									style={{ width: '5%' }}
									className='table-fixed-header-th'>
									{' '}
									Спец.{' '}
								</th>
								<th
									style={{ width: '20%' }}
									className='table-fixed-header-th'>
									{' '}
									Допълнителна информация{' '}
								</th>
								<th
									style={{ width: '10%' }}
									className='table-fixed-header-th'>
									{' '}
									Код ЛЗ{' '}
								</th>
								<th
									style={{ width: '10%' }}
									className='table-fixed-header-th'>
									{' '}
									Код по МКБ 9КМ / НЗОК{' '}
								</th>

								<th
									style={{ width: '5%' }}
									className='table-fixed-header-th'>
									{' '}
									Брой
								</th>
								<th
									style={{ width: '5%' }}
									className='table-fixed-header-th'>
									{' '}
									Ед.цена{' '}
								</th>
								<th
									style={{ width: '5%' }}
									className='table-fixed-header-th'>
									{' '}
									Сума{' '}
								</th>
							</tr>
						</thead>
						<tbody
							style={{
								border: '1px solid ',
							}}>
							{claimById?.details.map((detail) => (
								<tr>
									<td
										style={{
											border: '1px solid ',
											textAlign: 'left',
										}}>
										{' '}
										{detail?.name}{' '}
									</td>
									<td
										style={{
											border: '1px solid ',
											textAlign: 'center',
										}}>
										{' '}
										{detail?.specialityCode}{' '}
									</td>
									<td
										style={{
											border: '1px solid ',
											textAlign: 'left',
										}}>
										{' '}
										{detail?.description
											? detail?.description
											: ''}{' '}
									</td>
									<td
										style={{
											border: '1px solid ',
											textAlign: 'center',
										}}>
										{' '}
										{detail?.code}{' '}
									</td>
									<td
										style={{
											border: '1px solid ',
											textAlign: 'center',
										}}>
										{' '}
										{detail?.nhifCode}{' '}
									</td>

									<td
										style={{
											border: '1px solid ',
											textAlign: 'center',
										}}>
										{' '}
										{detail?.count}{' '}
									</td>
									<td
										style={{
											border: '1px solid ',
											textAlign: 'right',
										}}>
										{' '}
										{detail?.price}{' '}
									</td>
									<td
										style={{
											border: '1px solid ',
											textAlign: 'right',
										}}>
										{' '}
										{detail?.totalAmount}{' '}
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
				<div
					style={{
						textAlign: 'end',
						display: detailsVisible ? 'block' : 'none',
						color: 'black',
					}}>
					Общо:{' '}
					{claimById?.details
						.map((detail) => parseFloat(detail.totalAmount))
						.reduce((a, b) => a + b)
						.toFixed(2)}{' '}
				</div>

				<h4 style={{ marginTop: 20 }}>
					Разбивка по документи{' '}
					<Switch
						style={{ display: 'inline-block' }}
						unCheckedChildren='Виж'
						checkedChildren='Скрий'
						defaultChecked={documentsVisible}
						onChange={() => setDocumentsVisible(!documentsVisible)}
					/>
					<div style={{ display: 'none' }}>
						<PrintDocOnClaims ref={componentRefDoc} />
					</div>
					<span style={{float: 'right',}}>
					<Button
						key='printAttachments'
						type='primary'
						shape='round'
						style={{
						
							display: documentsVisible ? 'inline-block' : 'none',
							position: 'inherit',
							marginBottom: 10,
							borderRadius: 20,
							fontSize: 13,
						}}
						onClick={() => {
							setIsPrintingClaimDoc(true);
							setTimeout(() => {
								handlePrintDoc();
							}, 2000);
						}}>
						<PrinterOutlined /> Принтирай
					</Button>
					<Button
						key='exportAttachments'
						type='primary'
						shape='round'
						style={{
							
							display: documentsVisible ? 'inline-block' : 'none',
							position: 'inherit',
							marginBottom: 10,
							borderRadius: 20,
							marginLeft: 5,
							fontSize: 13,
						}}
						onClick={async () => await exportAttachmentsToExcel()}>
						<FileExcelOutlined /> Изтегляне (xlsx)
					</Button>
					</span>
				</h4>

				<div
					className='scroll-table-service-correlations '
					style={{
						display: documentsVisible ? 'block' : 'none',
						width: '100%',
					}}>
					<DocumtentsInClaimTable closeModal={closeModal} />
				</div>

				<Popconfirm
					title='Сигурни ли сте, че желаете да депозирате иска към застрахователната компания?'
					onConfirm={async () => {
						await SetInProcessingStatus(claimById?.id);
						getAllClaims(period);
						await closeModal();
					}}
					destroyTooltipOnHide={
						claimById?.status ===
						constants.ClaimProcessStatus.Processing
					}>
					<Button
						className='btn-primary'
						type='primary'
						htmlType='submit'
						hidden={
							claimById?.status ===
								constants.ClaimProcessStatus.Processing ||
							claimById?.status ===
								constants.ClaimProcessStatus.Processed
						}>
						Депозиране на иск
					</Button>
				</Popconfirm>
			</Spin>
		</div>
	);
};

export default observer(ClaimByIdModal);
