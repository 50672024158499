import React, { Fragment, useContext } from 'react';
import { Menu } from 'antd';
import {
	UserOutlined,
	LayoutOutlined,
	NotificationOutlined,
	ContainerOutlined,
	UsergroupAddOutlined,
	CustomerServiceOutlined,
	SettingOutlined,
	ProfileOutlined,
} from '@ant-design/icons';
import logo from '../../img/Logo.png';
import { NavLink } from 'react-router-dom';

import { useLocation } from 'react-router-dom';
import { RootStoreContext } from '../../app/stores/rootStore';
import { v4 as uuidv4 } from 'uuid';

const { SubMenu } = Menu;

const SideBar: React.FC = () => {
	const location = useLocation();
	const rootStore = useContext(RootStoreContext);
	const { currentUser } = rootStore.identityStore;

	return (
		<Fragment>
			<div className='logo-placeholder'>
				<img src={logo} alt='Logo' className='logo' />
			</div>

			<Menu
				mode='inline'
				theme='dark'
				className='sidebar'
				selectedKeys={[location.pathname]}>
				<Menu.Item key='/' icon={<LayoutOutlined />}>
					Начало <NavLink key={uuidv4()} to='/' />
				</Menu.Item>

				<SubMenu
					key='sub1'
					icon={<ContainerOutlined />}
					title='Дейности'>
					<Menu.Item key='/status'>
						Проверка на застрахователен статус{' '}
						<NavLink key={uuidv4()} to='/status' />
					</Menu.Item>
					<Menu.Item key='/referral'>
						Медицински назначения{' '}
						<NavLink key={uuidv4()} to='/referral' />
					</Menu.Item>
					<Menu.Item key='/priorApproval'>
						Искане за предварително одобрение
						<NavLink key={uuidv4()} to='/priorApproval' />
					</Menu.Item>
					<Menu.Item key='/ambulatorySheets'>
						Амбулаторни листове{' '}
						<NavLink key={uuidv4()} to='/ambulatorySheets' />
					</Menu.Item>
					<Menu.Item key='/laboratoryResults'>
						МДД
						<NavLink key={uuidv4()} to='/laboratoryResults' />
					</Menu.Item>
					<Menu.Item key='/diseaseHistory'>
						История на заболяването{' '}
						<NavLink key={uuidv4()} to='/diseaseHistory' />
					</Menu.Item>
					<Menu.Item key='/preventions'>
						Профилактична дейност
						<NavLink key={uuidv4()} to='/preventions' />
					</Menu.Item>
					<Menu.Item key='/xmlUpload'>
						XML-и <NavLink key={uuidv4()} to='/xmlUpload' />
					</Menu.Item>
					<Menu.Item key='/claims'>
						Искове
						<NavLink key={uuidv4()} to='/claims' />
					</Menu.Item>
				</SubMenu>

				<Menu.Item
					style={{
						display:
							currentUser?.manager?.length > 0 ? 'block' : 'none',
					}}
					key='/accounts'
					icon={<UsergroupAddOutlined />}>
					Потребители <NavLink to='/accounts' />
				</Menu.Item>
				<Menu.Item key='/profile' icon={<UserOutlined />}>
					Профил <NavLink key={uuidv4()} to='/profile' />
				</Menu.Item>
				<Menu.Item key='/notifications' icon={<NotificationOutlined />}>
					Известия <NavLink key={uuidv4()} to='/notifications' />
				</Menu.Item>
				<Menu.Item key='/settings' icon={<SettingOutlined />}>
					Общи настройки
					<NavLink key={uuidv4()} to='/settings' />
				</Menu.Item>
				<Menu.Item key='/procedures' icon={<ProfileOutlined />}>
					Списък с процедури
					<NavLink key={uuidv4()} to='/procedures' />
				</Menu.Item>
				<Menu.Item key='/conection' icon={<CustomerServiceOutlined />}>
					Връзка с ЗК <NavLink key={uuidv4()} to='/connection' />
				</Menu.Item>
			</Menu>
		</Fragment>
	);
};

export default SideBar;
