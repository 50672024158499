import { FormInstance } from 'antd/lib/form';
import { IServiceCorrelation } from './../models/servicesCorrelations/ServiceCorrelation';
import { notification } from 'antd';
import { action, observable, runInAction, toJS } from 'mobx';
import moment from 'moment';
import agent from '../../api/agent';
import { IByPeriodSearch } from '../models/electronicDocument/ByPeriodSearch';
import { IElectronicDocument } from '../models/electronicDocument/ElectronicDocument';
import { RootStore } from './rootStore';

export default class DeseaseHistoryStore {
	rootStore: RootStore;

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
	}

	@observable allDeseaseHistory: IElectronicDocument[] | undefined;
	@observable period: IByPeriodSearch = {
		dateFrom: moment().startOf('month').local().toDate(),
		dateTo: moment().endOf('month').local().toDate(),
	};
	@observable filteredServiceCorelations = [] as IServiceCorrelation[];

	@observable detailsCount: number;
	@observable detailsEditingKey: string;
	@observable detailsEditMode = false;
	@observable detailsForm: FormInstance;
	@observable isLongListIsDisable = true;

	@observable attachCount: number;
	@observable attachEditingKey: string;
	@observable attachForm: FormInstance;

	@observable filterType: string;

	@action setFilterType = (value: string) => {
		this.filterType = value;
	};

	@action setCount = (num: number) => {
		this.detailsCount = num;
	};

	@action setEditingKey = (val: string) => {
		this.detailsEditingKey = val;
	};

	@action setEditMode = (bool: boolean) => {
		this.detailsEditMode = bool;
	};

	@action setIsLongListIsDisable = (bool: boolean) => {
		this.isLongListIsDisable = bool;
	};

	@action setAttachCount = (num: number) => {
		this.attachCount = num;
	};

	@action setAttachEditingKey = (val: string) => {
		this.attachEditingKey = val;
	};

	@action setDeseaseHistory = (collection: IElectronicDocument[]) => {
		this.allDeseaseHistory = collection;
	};

	@action sortDeseaseHistory = (
		comparer: (a: any, b: any) => 1 | -1,
		sortDirection: string
	) => {
		if (sortDirection !== 'NONE') {
			let sorted = [...this.allDeseaseHistory].sort(comparer);
			this.allDeseaseHistory = sorted;
		}
	};

	@action getDeseaseHistoryByPeriod = async (period: IByPeriodSearch) => {
		this.rootStore.loadersStore.setLoadingAllTables(true);
		try {
			let res = [] as IElectronicDocument[];
			if (this.filterType !== undefined && this.filterType.length > 0) {
				switch (this.filterType) {
					case 'all':
						res = await agent.DeseaseHistory.getAllByPeriod(
							period
						);
						break;
					case 'active':
						res = await agent.DeseaseHistory.getActiveByPeriod(
							period
						);
						break;
					case 'editable':
						res = await agent.DeseaseHistory.getForEditingByPeriod(
							period
						);
						runInAction(() => {
							if (res && res.length > 0) {
								this.setPeriod(
									res[0].documentDate,
									moment().local().endOf('month').toDate()
								);
								notification.warning({
									message: 'Периодът беше сменен!',
									className: 'success-messages',
									duration: 5,
								});
							}
						});
						break;
				}
			} else {
				res = await agent.DeseaseHistory.getActiveByPeriod(period);
				this.setFilterType('active');
			}

			runInAction(() => {
				this.allDeseaseHistory = res;
				this.rootStore.electronicDocumentStore.setGetElDocsAgain(false);
				this.rootStore.loadersStore.setLoadingAllTables(false);
			});
		} catch (error) {
			notification.error({
				message: error.data,
				className: 'success-messages',
				duration: 5,
			});
			this.rootStore.loadersStore.setLoadingAllTables(false);
		}
	};

	@action setPeriod = (dateFrom: Date, dateTo: Date) => {
		this.period.dateFrom = dateFrom;
		this.period.dateTo = dateTo;
	};

	@action spliceAllFilteredServiceCorrelations = () => {
		this.resetDetailsAndAttachmentsOriginData();
		this.filteredServiceCorelations.splice(
			0,
			this.filteredServiceCorelations.length
		);
	};

	@action filterServiceCorelationsOnChangeCompanyName = async (
		insuranceCompanyId: any,
		specialityCode: any
	) => {
		if (
			specialityCode !== null &&
			specialityCode !== undefined &&
			specialityCode.length !== 0
		) {
			this.spliceAllFilteredServiceCorrelations();

			if (
				this.rootStore.servicesCorrelationsStore
					.servicesCorrelations === undefined
			) {
				await this.rootStore.servicesCorrelationsStore.getServicesCorrelations();
			}

			let tempServiceCorrelationArray = toJS(
				this.rootStore.servicesCorrelationsStore.servicesCorrelations
			);

			this.resetDetailsAndAttachmentsOriginData();

			tempServiceCorrelationArray?.forEach((sc) => {
				let modifiedPrices = sc.servicePrices.filter(
					(el) =>
						(el.insuranceCompanyId === insuranceCompanyId &&
							el.specialityCode === specialityCode) ||
						(el.insuranceCompanyId === insuranceCompanyId &&
							el.specialityCode.length === 0)
				);
				if (modifiedPrices.length !== 0) {
					sc.servicePrices = modifiedPrices;
					runInAction(() => {
						this.filteredServiceCorelations.push(sc);
					});
				}
			});
		}
	};

	@action resetDetailsAndAttachmentsOriginData = () => {
		if (
			this.rootStore.electronicDocumentStore.electronicDocumentById ===
			undefined
		) {
			if (
				this.rootStore.electronicDocumentStore.detailsOriginData !==
					undefined &&
				this.rootStore.electronicDocumentStore.detailsOriginData
					.length > 0
			) {
				this.rootStore.electronicDocumentStore.detailsOriginData = [
					{
						key: '1',
						code: '',
						systemCode: '',
						nhifCode: '',
						name: ' ',
						count: 1,
						description: '',
						id: 0,
						toMainId: 0,
						lastModifiedByUsername: ' ',
						lastModifiedOn: null,
						price: '0.00',
						totalAmount: '',
						isPayable: true,
						specialityCode: '',
						specialityName: '',
					},
				];
				this.rootStore.electronicDocumentStore.setDetailsEditMode(true);
				this.detailsForm?.resetFields();
				this.setEditMode(true);
				this.setEditingKey('1');
				this.setCount(2);
			}

			if (
				this.rootStore.electronicDocumentStore.attachmentsOriginData !==
					undefined &&
				this.rootStore.electronicDocumentStore.attachmentsOriginData
					.length > 0
			) {
				this.rootStore.electronicDocumentStore.setAttachmentTableVisible(
					false
				);
				this.rootStore.electronicDocumentStore.attachmentsOriginData = [
					{
						key: '1',
						id: 0,
						documentType: '',
						attachmentType: '',
						eDocumentNumber: '',
						eDocumentDate: undefined,
						eDocumentDoctorName: '',
						fileName: '',
						description: '',
						file: undefined,
						toAttachmentId: 0,
						lastModifiedByUsername: ' ',
						lastModifiedOn: null,
					},
				];
				this.rootStore.electronicDocumentStore.setUploadEditMode(false);
				this.attachForm?.resetFields();
				this.setAttachEditingKey('1');
				this.setAttachCount(2);
			}
		}
	};

	@action removeAllDeseaseHistory = () => {
		this.allDeseaseHistory = undefined;
		this.spliceAllFilteredServiceCorrelations();
		this.setPeriod(
			moment().startOf('month').local().toDate(),
			moment().endOf('month').local().toDate()
		);
	};
}
