import React from 'react';
import PrintPriceListForInsuranceCompanySheet from './PrintPriceListForInsuranceCompanySheet';

class PrintPriceListForInsuranceCompany extends React.Component {
	render() {
		return (
			<div>
				<PrintPriceListForInsuranceCompanySheet />
			</div>
		);
	}
}
export default PrintPriceListForInsuranceCompany;
