import { Form, Input, Select } from 'antd';
import { FormInstance } from 'antd/lib/form/Form';
import React, { useContext, useEffect, useState } from 'react';
import { Picker, List } from 'antd-mobile';
import { RootStoreContext } from '../../stores/rootStore';
import { observer } from 'mobx-react-lite';
import Constants from '../../constants/constants';
import { v4 as uuidv4 } from 'uuid';

export interface IProps {
	form: FormInstance;
	label: any;
}

const IssuerFormItem: React.FC<IProps> = ({ form, label }) => {
	const rootStore = useContext(RootStoreContext);
	const {
		getDoctorByUin,
		doctorByUin,
		specialities,
	} = rootStore.operationsStore;

	const { electronicDocumentById } = rootStore.electronicDocumentStore;
	const { currentUser } = rootStore.identityStore;
	const {
		spliceAllFilteredServiceCorrelations,
	} = rootStore.ambulatorySheetsStore;
	const {
		spliceAllFilteredServiceCorrelationsForMdd,
	} = rootStore.laboratoryResultsStore;

	const [isMobile, setIsMobile] = useState(false);
	const { Option } = Select;

	const searchForDoctor = async (issuerUin: string) => {
		if (
			!currentUser.uinCode &&
			(issuerUin.length === 10 || issuerUin.length === 9)
		) {
			await getDoctorByUin(issuerUin);
		} else {
			getDoctorByUin(undefined);
			form.setFieldsValue({
				issuerName: undefined,
				issuerSpecialityCode: undefined,
			});
		}
	};

	const handleWindowResize = () => {
		setIsMobile(window.innerWidth < 770);
	};

	useEffect(() => {
		handleWindowResize();
		window.addEventListener('resize', handleWindowResize);

		form.setFieldsValue({
			issuerName: doctorByUin
				? doctorByUin!.fullName
				: electronicDocumentById
				? electronicDocumentById.issuerName
				: '',
			issuerSpecialityCode: doctorByUin
				? doctorByUin!.specialityCode
				: electronicDocumentById
				? electronicDocumentById.issuerSpecialityCode
				: undefined,
		});
	}, [doctorByUin, form, getDoctorByUin]);

	const dataSpecialties = specialities?.map((p) => ({
		value: p.code,
		label: p.name,
		children: [],
	}));

	return (
		<Form.Item
			className='issuer-form-item'
			label={label}
			rules={[
				{
					required: true,
				},
			]}>
			<Form.Item
				name='issuerUin'
				className='uin-input'
				rules={[
					{
						required: true,
						message: Constants.FormMessages.RequiredField,
					},
					({ getFieldValue }) => ({
						validator(rule, value) {
							const expression = new RegExp('^[0-9]*$');
							if (expression.test(value)) {
								return Promise.resolve();
							}
							return Promise.reject(
								'УИН-ът трябва да съдържа само цифри!'
							);
						},
					}),
					({ isFieldsTouched }) => ({
						validator(rule, value) {
							if (isFieldsTouched() === false) {
								return Promise.reject();
							}
							return Promise.resolve();
						},
					}),
					{
						min: 9,
						message: ' УИН-ът трябва да е  9 или 10 цифри! ',
					},
					{
						max: 10,
						message: ' УИН-ът трябва да е  9 или 10 цифри! ',
					},
				]}>
				<Input
					placeholder='УИН'
					onChange={async (e: any) => {
						if (e.target.value.length === 10) {
							await searchForDoctor(e.target.value);
						} else {
							await searchForDoctor('');
						}
						if (
							rootStore.ambulatorySheetsStore
								.filteredServiceCorelations !== undefined
						) {
							spliceAllFilteredServiceCorrelations();
						}
						if (
							rootStore.laboratoryResultsStore
								.filteredServiceCorelationsForMdd !== undefined
						) {
							spliceAllFilteredServiceCorrelationsForMdd();
						}
					}}
					onBlur={async (e: any) => {
						if (e.target.value.length === 9) {
							await searchForDoctor(e.target.value);
						} else if (e.target.value.length === 0) {
							await searchForDoctor('');
						}
					}}
					disabled={
						currentUser?.uinCode !== '' ||
						electronicDocumentById?.status ===
							Constants.MedicalReferralStatus.Performed ||
						currentUser!.uinCode.length > 0 ||
						electronicDocumentById?.isActive === false ||
						electronicDocumentById?.documentType.toString() ===
							Constants.DocumentType.AmbSheet ||
						electronicDocumentById?.documentType.toString() ===
							Constants.DocumentType.LabResults ||
						electronicDocumentById?.isAutomatic ===
							Constants.ExaminationImportType.Automatic ||
						electronicDocumentById?.isAttachedToClaim
					}
				/>
			</Form.Item>
			<Form.Item
				name='issuerName'
				className='docName-input'
				rules={[
					{
						required: true,
						message:
							' Полето е задължително! Ако не се е попълнило автоматично, дадения лекар не е все още въведен в сиситемата! Попълнете го лично. ',
					},
					({ isFieldsTouched }) => ({
						validator(rule, value) {
							if (isFieldsTouched() === false) {
								return Promise.reject();
							}
							return Promise.resolve();
						},
					}),
				]}>
				<Input
					placeholder='Име'
					disabled={
						currentUser?.uinCode !== '' ||
						electronicDocumentById?.status ===
							Constants.MedicalReferralStatus.Performed ||
						currentUser!.uinCode.length > 0 ||
						electronicDocumentById?.isActive === false ||
						electronicDocumentById?.documentType.toString() ===
							Constants.DocumentType.AmbSheet ||
						electronicDocumentById?.documentType.toString() ===
							Constants.DocumentType.LabResults ||
						electronicDocumentById?.isAutomatic ===
							Constants.ExaminationImportType.Automatic ||
						electronicDocumentById?.isAttachedToClaim
					}
				/>
			</Form.Item>
			<Form.Item
				name='issuerSpecialityCode'
				className='spec-input'
				rules={[
					{
						required:
							rootStore.electronicDocumentStore.typeOfDocument ===
								Constants.DocumentType.AmbSheet ||
							rootStore.electronicDocumentStore.typeOfDocument ===
								Constants.DocumentType.LabResults,
						message:
							' Полето е задължително! Ако не се е попълнило автоматично, дадения лекар не е все още въведен в сиситемата! Попълнете го лично. ',
					},
					({ isFieldsTouched }) => ({
						validator(rule, value) {
							if (isFieldsTouched() === false) {
								return Promise.reject();
							}
							return Promise.resolve();
						},
					}),
				]}>
				{!isMobile ? (
					<Select
						showSearch
						optionFilterProp='title'
						placeholder='Специалност'
						onChange={() => {
							if (
								rootStore.ambulatorySheetsStore
									.filteredServiceCorelations !== undefined
							) {
								spliceAllFilteredServiceCorrelations();
							}
							if (
								rootStore.laboratoryResultsStore
									.filteredServiceCorelationsForMdd !==
								undefined
							) {
								spliceAllFilteredServiceCorrelationsForMdd();
							}
						}}
						disabled={
							electronicDocumentById?.status ===
								Constants.MedicalReferralStatus.Performed ||
							electronicDocumentById?.isActive === false ||
							electronicDocumentById?.documentType.toString() ===
								Constants.DocumentType.AmbSheet ||
							electronicDocumentById?.documentType.toString() ===
								Constants.DocumentType.LabResults ||
							electronicDocumentById?.isAutomatic ===
								Constants.ExaminationImportType.Automatic ||
							electronicDocumentById?.isAttachedToClaim
						}>
						{specialities?.map((speciality) => (
							<Option
								key={uuidv4()}
								value={
									speciality.code
										? speciality.code
										: doctorByUin?.specialityCode!
								}
								title={speciality.code + '-' + speciality.name}>
								{speciality.code + '-' + speciality.name}
							</Option>
						))}
					</Select>
				) : (
					<Picker
						data={dataSpecialties!}
						cols={1}
						extra={
							electronicDocumentById
								? electronicDocumentById.issuerSpecialityName
								: doctorByUin
								? doctorByUin.specialityName
								: 'Избери'
						}
						okText='Избери'
						dismissText=' Обратно'
						disabled={
							electronicDocumentById?.status ===
								Constants.MedicalReferralStatus.Performed ||
							electronicDocumentById?.isActive === false ||
							electronicDocumentById?.isAutomatic ===
								Constants.ExaminationImportType.Automatic ||
							electronicDocumentById?.isAttachedToClaim
						}>
						<List.Item arrow='horizontal' extra=''>
							{' '}
						</List.Item>
					</Picker>
				)}
			</Form.Item>
		</Form.Item>
	);
};

export default observer(IssuerFormItem);
