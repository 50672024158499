import { notification } from 'antd';
import { action, observable, runInAction } from 'mobx';
import agent from '../../api/agent';
import { ISettings } from '../models/settings/Settings';
import { RootStore } from './rootStore';

export default class SettingsStore {
	rootStore: RootStore;

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
	}

	@observable settings: ISettings | undefined;
	@observable commonSettingsVisible = false;
	@observable settingsLoading = false;

	@action setCommonSettingsVisible = (val: boolean) => {
		this.commonSettingsVisible = val;
	};

	@action getSettings = async () => {
		this.rootStore.identityStore.loadingInitial = true;
		try {
			let res = await agent.Settings.GetSettings();
			runInAction(() => {
				this.settings = res;
				this.rootStore.identityStore.loadingInitial = false;
			});
		} catch (error) {
			runInAction(() => {
				this.rootStore.identityStore.loadingInitial = false;
			});
		}
	};

	@action updateShowAmbulatorySheets = async (value: boolean) => {
		this.settingsLoading = true;
		try {
			let res = await agent.Settings.UpdateShowAmbulatorySheets(value);
			runInAction(() => {
				if (res === true) {
					this.settings.showAmbulatorySheetsComponent = value;
				} else {
					notification.error({
						message: 'Възникна грешка със смяна на настройката!',
						className: 'success-messages',
						duration: 5,
					});
				}
				this.settingsLoading = false;
			});
		} catch (error) {
			runInAction(() => {
				this.settingsLoading = false;
			});
		}
	};

	@action updateShowHospitalityComponent = async (value: boolean) => {
		this.settingsLoading = true;
		try {
			let res = await agent.Settings.UpdateShowHospitalityComponent(
				value
			);
			runInAction(() => {
				if (res === true) {
					this.settings.showHospitalityComponent = value;
				} else {
					notification.error({
						message: 'Възникна грешка със смяна на настройката!',
						className: 'success-messages',
						duration: 5,
					});
				}
				this.settingsLoading = false;
			});
		} catch (error) {
			runInAction(() => {
				this.settingsLoading = false;
			});
		}
	};

	@action updateShowMdd = async (value: boolean) => {
		this.settingsLoading = true;
		try {
			let res = await agent.Settings.UpdateShowMdd(value);
			runInAction(() => {
				if (res === true) {
					this.settings.showMddComponent = value;
				} else {
					notification.error({
						message: 'Възникна грешка със смяна на настройката!',
						className: 'success-messages',
						duration: 5,
					});
				}
				this.settingsLoading = false;
			});
		} catch (error) {
			runInAction(() => {
				this.settingsLoading = false;
			});
		}
	};

	@action updateShowPreventionsComponent = async (value: boolean) => {
		this.settingsLoading = true;
		try {
			let res = await agent.Settings.UpdateShowPreventionsComponent(
				value
			);
			runInAction(() => {
				if (res === true) {
					this.settings.showPreventionsComponent = value;
				} else {
					notification.error({
						message: 'Възникна грешка със смяна на настройката!',
						className: 'success-messages',
						duration: 5,
					});
				}
				this.settingsLoading = false;
			});
		} catch (error) {
			runInAction(() => {
				this.settingsLoading = false;
			});
		}
	};

	@action updateShowServicesFromShortListOnly = async (value: boolean) => {
		this.settingsLoading = true;
		try {
			let res = await agent.Settings.UpdateShowServicesFromShortListOnly(
				value
			);
			runInAction(() => {
				if (res === true) {
					this.settings.showServicesFromShortListOnly = value;
				} else {
					notification.error({
						message: 'Възникна грешка със смяна на настройката!',
						className: 'success-messages',
						duration: 5,
					});
				}
				this.settingsLoading = false;
			});
		} catch (error) {
			runInAction(() => {
				this.settingsLoading = false;
			});
		}
	};

	@action updateShowMddFromShortListOnly = async (value: boolean) => {
		this.settingsLoading = true;
		try {
			let res = await agent.Settings.UpdateShowMddFromShortListOnly(
				value
			);
			runInAction(() => {
				if (res === true) {
					this.settings.showMddFromShortListOnly = value;
				} else {
					notification.error({
						message: 'Възникна грешка със смяна на настройката!',
						className: 'success-messages',
						duration: 5,
					});
				}
				this.settingsLoading = false;
			});
		} catch (error) {
			runInAction(() => {
				this.settingsLoading = false;
			});
		}
	};

	@action updateShowPreventionFromShortListOnly = async (value: boolean) => {
		this.settingsLoading = true;
		try {
			let res = await agent.Settings.UpdateShowPreventionFromShortListOnly(
				value
			);
			runInAction(() => {
				if (res === true) {
					this.settings.showPreventionFromShortListOnly = value;
				} else {
					notification.error({
						message: 'Възникна грешка със смяна на настройката!',
						className: 'success-messages',
						duration: 5,
					});
				}
				this.settingsLoading = false;
			});
		} catch (error) {
			runInAction(() => {
				this.settingsLoading = false;
			});
		}
	};
}
