import { Form, Select } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { toJS } from 'mobx';
import React, { useContext, useState } from 'react';
import Moment from 'react-moment';
import { IAttachment } from '../../../../models/electronicDocument/ElectronicDocument';
import { RootStoreContext } from '../../../../stores/rootStore';
import { observer } from 'mobx-react-lite';
import Constants from '../../../../constants/constants';
import { v4 as uuidv4 } from 'uuid';

interface IProps {
	formTmp: FormInstance;
	dataIndex: string;
	record: IAttachment;
}

const DocumentTypeItem: React.FC<IProps> = ({ formTmp, dataIndex, record }) => {
	const [valueDocType, setValueDocType] = useState(undefined);
	const [elValueDocType, setElValueDocType] = useState(undefined);

	const rootStore = useContext(RootStoreContext);

	const {
		documetType,
		electronicDocumentsForPatient,
	} = rootStore.operationsStore;
	const {
		attachmentsOriginData,
		setAttachmentsOriginData,
		electronicDocumentById,
		isElectronicDocumentAttaching,
		setIsUploadFormTouched,
	} = rootStore.electronicDocumentStore;

	const title = (eDocument: any) => {
		return (
			<div>
				<div>
					{eDocument.documentType} № {eDocument.documentNumber}
				</div>
				<div>
					от{' '}
					<Moment format={Constants.Common.FullDate_Format}>
						{eDocument.usedOn}
					</Moment>
					,
				</div>
				<div>запазено от {eDocument.performerName}</div>
			</div>
		);
	};

	const onChangeElectronicDocumentType = (value: any, extra: any) => {
		setIsUploadFormTouched(true);
		value = extra.doc_type;
		setElValueDocType(value);
		formTmp.setFields([
			{ name: 'documentType', value: value },
			{ name: 'toAttachmentId', value: extra.to_attachment_id },
		]);

		let newData = toJS(attachmentsOriginData);
		let currentElementIndex = newData!.findIndex(
			(el) => el.key === record.key
		);

		newData![currentElementIndex].documentType = extra.doc_type;
		newData![currentElementIndex].attachmentType =
			Constants.AttachmentType.ElectronicDocument;
		newData![currentElementIndex].eDocumentDate = extra.doc_date;
		newData![currentElementIndex].eDocumentDoctorName = extra.doc_doctor;
		newData![currentElementIndex].eDocumentNumber = extra.doc_number;
		newData![currentElementIndex].toAttachmentId = extra.to_attachment_id;

		setAttachmentsOriginData(newData);
	};

	const onChangeDocumentType = (value: any, extra: any) => {
		setIsUploadFormTouched(true);
		setValueDocType(value);
		let currentValueDescription = documetType!.find(
			(el) => el.value === value
		)?.description;
		formTmp.setFields([
			{ name: 'documentType', value: currentValueDescription },
		]);

		let newData = toJS(attachmentsOriginData);
		let currentElementIndex = newData!.findIndex(
			(el) => el.key === record.key
		);
		newData![currentElementIndex].documentType = value;

		setAttachmentsOriginData(newData);
	};

	const { Option } = Select;

	return !isElectronicDocumentAttaching ? (
		<Form.Item
			style={{ minWidth: 200 }}
			name={dataIndex}
			rules={[
				{
					required: true,
					message: Constants.FormMessages.RequiredField,
				},
			]}>
			<Select
				showSearch={false}
				optionFilterProp='title'
				placeholder='Избери вид на документ'
				value={valueDocType}
				onChange={(value, extra) => onChangeDocumentType(value, extra)}
				disabled={
					electronicDocumentById?.status ===
						Constants.MedicalReferralStatus.Performed ||
					electronicDocumentById?.isActive === false
				}
				dropdownMatchSelectWidth={false}>
				{documetType?.map((docType) => (
					<Option
						key={uuidv4()}
						value={docType.value}
						title={docType.description}>
						{docType.description}
					</Option>
				))}
			</Select>
		</Form.Item>
	) : (
		<Form.Item
			style={{ minWidth: 300 }}
			name={dataIndex}
			rules={[
				{
					required: true,
					message: Constants.FormMessages.RequiredField,
				},
			]}>
			<Select
				showSearch={false}
				optionFilterProp='title'
				placeholder='Избери електронен документ'
				value={elValueDocType}
				onChange={(value, extra) =>
					onChangeElectronicDocumentType(value, extra)
				}
				disabled={
					electronicDocumentById?.status ===
						Constants.MedicalReferralStatus.Performed ||
					electronicDocumentById?.isActive === false
				}
				dropdownMatchSelectWidth={false}>
				{electronicDocumentsForPatient?.map((eDocument) => (
					<Option
						key={uuidv4()}
						value={eDocument.id}
						to_attachment_id={eDocument.id}
						doc_type={eDocument.documentType}
						doc_number={eDocument.documentNumber}
						doc_date={eDocument.documentDate}
						doc_doctor={eDocument.performerName}>
						{title(eDocument)}
					</Option>
				))}
			</Select>
		</Form.Item>
	);
};

export default observer(DocumentTypeItem);
