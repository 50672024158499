import { Form, Select } from 'antd';
import { FormInstance } from 'antd/lib/form/Form';
import React, { useContext, useEffect, useState } from 'react';
import { Picker, List } from 'antd-mobile';
import { RootStoreContext } from '../../stores/rootStore';
import { observer } from 'mobx-react-lite';
import Constants from '../../constants/constants';
import { v4 as uuidv4 } from 'uuid';

export interface IProps {
	form: FormInstance;
}

const ToSpecialistSpecCodeFormItem: React.FC<IProps> = ({ form }) => {
	const rootStore = useContext(RootStoreContext);
	const { electronicDocumentById } = rootStore.electronicDocumentStore;
	const { specialities } = rootStore.operationsStore;

	const { Option } = Select;

	const [isMobile, setIsMobile] = useState(false);
	const isFormEditingDisabled =
		electronicDocumentById?.documentStatus &&
		electronicDocumentById?.documentStatus !==
			Constants.DocumentProcessStatus.Registered;

	const handleWindowResize = () => {
		setIsMobile(window.innerWidth < 770);
	};

	useEffect(() => {
		handleWindowResize();
		window.addEventListener('resize', handleWindowResize);
	}, []);

	const dataSpecialties = specialities?.map((p) => ({
		value: p.code,
		label: p.name,
		children: [],
	}));

	return (
		<Form.Item label='Специалност, към която се отнася: '>
			<Form.Item name='toSpecialistSpecialityCode'>
				{!isMobile ? (
					<Select
						showSearch
						optionFilterProp='title'
						placeholder='Избери специалност'
						disabled={
							electronicDocumentById?.status ===
								Constants.MedicalReferralStatus.Performed ||
							electronicDocumentById?.isActive === false ||
							isFormEditingDisabled
						}
						onSelect={(option: any) => {
							form.setFieldsValue({
								toSpecialistSpecialityCode: option,
							});
						}}>
						{specialities?.map((speciality: any) => (
							<Option
								key={uuidv4()}
								value={speciality.code}
								title={speciality.code + '-' + speciality.name}>
								{speciality.code + '-' + speciality.name}
							</Option>
						))}
					</Select>
				) : (
					<Picker
						data={dataSpecialties!}
						cols={1}
						extra={
							electronicDocumentById
								? electronicDocumentById.toSpecialistSpecialityCode
								: 'Избери'
						}
						okText='Избери'
						dismissText=' Обратно'
						disabled={
							electronicDocumentById?.status ===
								Constants.MedicalReferralStatus.Performed ||
							electronicDocumentById?.isActive === false ||
							isFormEditingDisabled
						}>
						<List.Item key={uuidv4()} arrow='horizontal' extra=''>
							{' '}
						</List.Item>
					</Picker>
				)}
			</Form.Item>
		</Form.Item>
	);
};

export default observer(ToSpecialistSpecCodeFormItem);
