import { observable, action, runInAction } from 'mobx';
import agent from '../../api/agent';
import { notification } from 'antd';
import { IPerformMedicalReferral } from '../models/electronicDocument/PerformMedicalReferral';

import { RootStore } from './rootStore';
import { IElectronicDocument } from '../models/electronicDocument/ElectronicDocument';
import moment from 'moment';
import { IByPeriodSearch } from '../models/electronicDocument/ByPeriodSearch';

export default class MedicalRefferalStore {
	rootStore: RootStore;

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
	}

	@observable allIssuedMedicalRefferals: IElectronicDocument[] | undefined;
	@observable allPerformedMedicalReferrals: IElectronicDocument[] | undefined;
	@observable checkMedicalReferral: IPerformMedicalReferral | undefined;
	@observable checkMedicalReferralResponseMessage: string | undefined;
	@observable activateSendEmailButton = false;
	@observable issuedPeriod: IByPeriodSearch = {
		dateFrom: moment()
			.subtract(1, 'month')
			.startOf('month')
			.local()
			.toDate(),
		dateTo: moment().local().toDate(),
	};
	@observable performedPeriod: IByPeriodSearch = {
		dateFrom: moment()
			.subtract(1, 'month')
			.startOf('month')
			.local()
			.toDate(),
		dateTo: moment().local().toDate(),
	};

	@action setIssuedPeriod = (dateFrom: Date, dateTo: Date) => {
		this.issuedPeriod.dateFrom = dateFrom;
		this.issuedPeriod.dateTo = dateTo;
	};

	@action setPerformedPeriod = (dateFrom: Date, dateTo: Date) => {
		this.performedPeriod.dateFrom = dateFrom;
		this.performedPeriod.dateTo = dateTo;
	};

	@action setActivateSendEmailButton = (value: boolean) => {
		this.activateSendEmailButton = value;
	};

	@action checkMedicalReferralForPerform = async (values: any) => {
		this.rootStore.loadersStore.setLoadingCheckForm(true);
		let checkForPerformModel: IPerformMedicalReferral = { ...values };
		try {
			let res = await agent.MedicalRefferal.checkForPerform(
				checkForPerformModel
			);
			runInAction(() => {
				this.rootStore.electronicDocumentStore.electronicDocumentById =
					res.complexModel;
				this.checkMedicalReferralResponseMessage = res.message;
				this.checkMedicalReferral = checkForPerformModel;
				this.rootStore.loadersStore.setLoadingCheckForm(false);

				if (this.checkMedicalReferralResponseMessage) {
					notification.error({
						message: this.checkMedicalReferralResponseMessage,
						duration: 5,
						className: 'success-messages',
					});
				}
			});
		} catch (error) {
			runInAction(() => {
				this.rootStore.loadersStore.setLoadingCheckForm(false);
				notification.error({
					message: error.data,
					duration: 5,
					className: 'success-messages',
				});
			});
		}
	};

	@action performMedicalReferral = async (
		performModel: IPerformMedicalReferral
	) => {
		this.rootStore.loadersStore.setLoadingCheckForm(true);
		try {
			await agent.MedicalRefferal.perform(performModel);
			runInAction(() => {
				this.rootStore.loadersStore.setLoadingCheckForm(false);
				notification.success({
					message: 'Медицинското назначение беше запазено успешно! ',
					duration: 5,
					className: 'success-messages',
				});
			});
		} catch (error) {
			runInAction(() => {
				this.rootStore.loadersStore.setLoadingCheckForm(false);
				notification.error({
					message: error.data,
					duration: 5,
					className: 'success-messages',
				});
			});
		}
	};

	@action unperformMedicalReferral = async (referralId: number) => {
		this.rootStore.loadersStore.setLoadingCheckForm(true);
		try {
			await agent.MedicalRefferal.unperform(referralId);
			runInAction(() => {
				this.rootStore.loadersStore.setLoadingCheckForm(false);
				notification.success({
					message: 'Медицинското назначение беше отказано успешно! ',
					duration: 5,
					className: 'success-messages',
				});
			});
		} catch (error) {
			runInAction(() => {
				this.rootStore.loadersStore.setLoadingCheckForm(false);
				notification.error({
					message: error.data,
					duration: 5,
					className: 'success-messages',
				});
			});
		}
	};

	@action setPerformMedicalReferral = (
		performModel: IPerformMedicalReferral
	) => {
		this.checkMedicalReferral = performModel;
	};

	@action setAllIssuedMedicalReferrals = async (period: IByPeriodSearch) => {
		this.rootStore.loadersStore.setLoadingAllTables(true);
		try {
			let res = await agent.MedicalRefferal.getAllIssuedByPeriod(period);
			runInAction(() => {
				this.rootStore.electronicDocumentStore.allElectronicDocuments = res;
				// this.allElectronicDocuments = res;
				this.allIssuedMedicalRefferals = res;
			});
			this.rootStore.loadersStore.setLoadingAllTables(false);
		} catch (error) {
			notification.error({
				message: error.data,
				className: 'success-messages',
				duration: 5,
			});
			this.rootStore.loadersStore.setLoadingAllTables(false);
		}
	};

	@action setAllPerformedMedicalReferrals = async (
		period: IByPeriodSearch
	) => {
		this.rootStore.loadersStore.setLoadingAllTables(true);
		try {
			let res = await agent.MedicalRefferal.getAllPerformedByPeriod(
				period
			);
			runInAction(() => {
				this.rootStore.electronicDocumentStore.allElectronicDocuments = res;
				this.allPerformedMedicalReferrals = res;
			});
			this.rootStore.loadersStore.setLoadingAllTables(false);
		} catch (error) {
			notification.error({
				message: error.data,
				className: 'success-messages',
				duration: 5,
			});
			this.rootStore.loadersStore.setLoadingAllTables(false);
		}
	};

	@action sendPatientEmail = async (
		referalId: number,
		isEditRecordMode: boolean,
		isDeactivated: boolean
	) => {
		this.rootStore.loadersStore.setLoadingEmailSending(true);
		this.rootStore.loadersStore.setLoadingModal(true);

		try {
			await agent.MedicalRefferal.sendPatientEmail(
				referalId,
				isEditRecordMode,
				isDeactivated
			);
			runInAction(() => {
				this.rootStore.loadersStore.setLoadingEmailSending(false);
				this.rootStore.loadersStore.setLoadingModal(false);
				notification.success({
					message: 'Имейл съобщението е изпратено!',
					duration: 5,
					className: 'success-messages',
				});
			});
		} catch (error) {
			runInAction(() => {
				this.rootStore.loadersStore.setLoadingEmailSending(false);
				this.rootStore.loadersStore.setLoadingModal(false);
				notification.error({
					message: error.data,
					duration: 5,
					className: 'success-messages',
				});
			});
		}
	};

	@action removeAllIssuedMedicalReferrals = () => {
		this.allIssuedMedicalRefferals = undefined;
		this.setIssuedPeriod(
			moment().local().subtract(1, 'month').startOf('month').toDate(),
			moment().local().toDate()
		);
	};

	@action removeAllPerformedMedicalReferrals = () => {
		this.allPerformedMedicalReferrals = undefined;
		this.setPerformedPeriod(
			moment().local().subtract(1, 'month').startOf('month').toDate(),
			moment().local().toDate()
		);
	};
}
