import React, { Fragment, useState, useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import {
	Input,
	Table,
	Space,
	Button,
	Modal,
	DatePicker,
	notification,
	Popconfirm,
} from 'antd';
import { TablePaginationConfig } from 'antd/lib/table';
import { PlusOutlined, SyncOutlined, DeleteOutlined } from '@ant-design/icons';
import Title from 'antd/lib/typography/Title';
import Moment from 'react-moment';
import { RootStoreContext } from '../../app/stores/rootStore';
import Constants from '../../app/constants/constants';
import moment from 'moment';
import PrepareClaimsModal from './PrepareClaimsModal';
import ClaimByIdModal from './ClaimByIdModal';
import { IClaim } from '../../app/models/claims/Claim';

const PrepareClaimsTable: React.FC<TablePaginationConfig> = ({ total }) => {
	const [show, setShow] = useState(false);
	const [rowClassName, setRowClassName] = useState(
		'ant-table-row ant-table-row-level-0'
	);
	const [filterTable, setFilterTable] = useState([]);
	const [rowKey, setRowKey] = useState();

	const rootStore = useContext(RootStoreContext);

	const {
		allClaims,
		getAllClaims,
		period,
		removeAllClaims,
		setPeriod,
		getClaimById,
		claimById,
		setIsPrintingClaimDoc,
		setIsPrintingClaimDetails,
		deleteClaim,
	} = rootStore.claimStore;

	const { loadingAllTable } = rootStore.loadersStore;

	const openModal = async (id: number) => {
		if (id) {
			await getClaimById(id);
			if (
				rootStore.claimStore.claimById &&
				rootStore.claimStore.claimById!.details.length > 0
			) {
				setShow(true);
			} else {
				notification.warning({
					message: 'Няма налични документи!',
					className: 'success-messages',
					duration: 5,
				});
			}
		} else {
			getClaimById(undefined);
			setShow(true);
		}
	};

	const closeModal = async () => {
		setShow(false);
		setIsPrintingClaimDoc(false);
		setIsPrintingClaimDetails(false);
		getClaimById(undefined);
		setRowKey(undefined);
	};

	useEffect(() => {
		if (!allClaims) {
			getAllClaims(period);
		}
		return () => {
			removeAllClaims();
		};
	}, []);

	const data = allClaims;

	const compareByAlph = (a: any, b: any) => {
		if (a > b) {
			return -1;
		}
		if (a < b) {
			return 1;
		}
		return 0;
	};

	const search = (value: any) => {
		if (value.length >= 1) {
			const tableFilterResult = data!.filter((o: any) =>
				Object.keys(o).some((k) =>
					String(o[k]).toLowerCase().includes(value.toLowerCase())
				)
			);
			setFilterTable(tableFilterResult);
		} else if (value.length === 0) {
			setFilterTable([] as IClaim[]);
		}
	};

	const changeDatesRange = async (date: any) => {
		setPeriod(date.toDate());
		await getAllClaims(rootStore.claimStore.period);
	};

	const columns = [
		{
			title: ' №',
			width: '7%',
			dataIndex: 'documentNumber',
			align: 'center' as 'center',
		},
		{
			title: 'От дата',
			width: '5%',
			dataIndex: 'dateCreated',
			align: 'center' as 'center',
			render: (_text: any, row: any, index: any) => (
				<Moment format={Constants.Common.ShortDate_Format}>
					{row.dateCreated}
				</Moment>
			),
		},
		{
			title: 'За период',
			width: '12%',
			align: 'center' as 'center',
			render: (_text: any, row: any, index: any) => (
				<div>
					<Moment format={Constants.Common.ShortDate_Format}>
						{row.dateFrom}
					</Moment>{' '}
					{' - '}{' '}
					<Moment format={Constants.Common.ShortDate_Format}>
						{row.dateTo}
					</Moment>
				</div>
			),
		},
		{
			title: 'Застрахователна компания',
			dataIndex: 'insuranceCompanyName',
			key: 'insuranceCompanyName',
			width: '20%',
			align: 'center' as 'center',
			sorter: (a: any, b: any) => {
				return compareByAlph(
					a.insuranceCompanyName,
					b.insuranceCompanyName
				);
			},
		},
		{
			title: 'Тип дейност',
			dataIndex: 'examinationType',
			key: 'examinationType',
			width: '8%',
			align: 'center' as 'center',
			sorter: (a: any, b: any) => {
				return compareByAlph(a.examinationType, b.examinationType);
			},
			render: (text: any, row: any, index: any) => (
				<div>
					{row.examinationType === '1'
						? 'Амбулаторна'
						: row.examinationType === '2'
						? 'МДД'
						: row.examinationType === '3'
						? 'Профилактична'
						: 'Болнична'}
				</div>
			),
		},
		{
			title: 'Бр.док.',
			dataIndex: 'totalAttachedDocuments',
			key: 'totalAttachedDocuments',
			width: '5%',
			align: 'center' as 'center',
		},
		{
			title: 'Искана сума',
			dataIndex: 'totalAmount',
			key: 'totalAmount',
			width: '5%',
			align: 'right' as 'right',
		},
		{
			title: 'Крайна сума',
			dataIndex: 'payableAmount',
			key: 'payableAmount',
			width: '5%',
			align: 'right' as 'right',
			render: (text: any, row: any, index: any) => (
				<div>
					{row.status ===
						Constants.ClaimProcessStatus.ChangeRequested ||
					row.status === Constants.ClaimProcessStatus.Processed
						? row.payableAmount !== '0.00'
							? row.payableAmount
							: row.totalAmount
						: ''}
				</div>
			),
		},
		{
			title: 'Статус',
			dataIndex: 'status',
			key: 'status',
			width: '10%',
			align: 'center' as 'center',
		},
		{
			width: '2%',
			align: 'center' as 'center',
			render: (_text: any, row: IClaim, index: any) =>
				row.status === Constants.ClaimProcessStatus.New ? (
					<Popconfirm
						title='Сигурни ли сте, че искате да изтриете този иск? '
						onConfirm={async () => {
							await deleteClaim(row.id);
							await getAllClaims(period);
						}}>
						<DeleteOutlined style={{ color: 'red' }} />
					</Popconfirm>
				) : null,
		},
		{
			title: 'Док за кор.',
			dataIndex: 'documentsForEditingCount',
			key: 'documentsForEditingCount',
			width: '5%',
			align: 'center' as 'center',
			render: (text: any, row: any, index: any) => (
				<div>
					{row.status ===
						Constants.ClaimProcessStatus.ChangeRequested ||
					row.status === Constants.ClaimProcessStatus.Processed
						? row.documentsForEditingCount
						: ''}
				</div>
			),
		},
	];

	const title = (
		<div>
			{' '}
			Иск № {claimById?.documentNumber} за период{' '}
			<Moment format={Constants.Common.ShortDate_Format}>
				{claimById?.dateFrom}
			</Moment>{' '}
			-{' '}
			<Moment format={Constants.Common.ShortDate_Format}>
				{claimById?.dateTo}
			</Moment>{' '}
			за{'  '}
			{claimById?.examinationType === '1'
				? 'Амбулаторна дейност'
				: claimById?.examinationType === '2'
				? 'МДД'
				: claimById?.examinationType === '3'
				? 'Профилактична дейност'
				: 'Болнична дейност'}{' '}
			към "{claimById?.insuranceCompanyName}"
		</div>
	);

	return (
		<Fragment>
			<div className='home-grid-col-3'>
				<Title level={3}>Искове</Title>

				<Space className='space-table-head'>
					<Button
						className='btn-groupe'
						style={{
							background:
								'radial-gradient(circle, rgba(30,134,17,0.908000700280112) 28%, rgba(27,180,32,0.9248074229691877) 100%)',
						}}
						type='primary'
						icon={<PlusOutlined />}
						onClick={async () => await openModal(undefined!)}>
						Създаване
					</Button>
					<Button
						className='btn-groupe'
						style={{
							background: '#234254',
						}}
						type='primary'
						icon={<SyncOutlined />}
						onClick={() => getAllClaims(period)}>
						Опресняване
					</Button>
				</Space>
				<Space className='space-table-head'>
					<DatePicker
						onChange={(date) => changeDatesRange(date)}
						className='date-range-tables'
						picker='month'
						format={'MMMM - YYYY'}
						allowClear={false}
						defaultValue={moment().local().subtract(1, 'months')}
						disabledDate={(current) =>
							current > moment().local().endOf('month')
						}
					/>
					<Input.Search
						className='search-input'
						placeholder='Търсене...'
						enterButton
						onSearch={search}
					/>
				</Space>
				<div className='scroll-table-service-correlations'>
					<Table
						onRow={(record, index) => {
							return {
								onClick: () => {
									setRowClassName(
										'ant-table-row ant-table-row-level-0 ant-table-row-selected'
									);
									setRowKey(record.id);
								},
								onDoubleClick: async () => {
									await openModal(record.id);
								},
							};
						}}
						loading={loadingAllTable}
						columns={columns}
						dataSource={filterTable.length < 1 ? data : filterTable}
						locale={{ emptyText: 'Няма налични данни' }}
						sortDirections={['descend', 'ascend']}
						showSorterTooltip={false}
						pagination={false}
						rowClassName={(_record, index) => {
							return _record.id === rowKey ? rowClassName : '';
						}}
						rowKey={rowKey}
						footer={(records: typeof data) => (
							<div
								style={{
									display: 'inline-flex',
									paddingRight: '23%',
								}}>
								Брой резултати: {records?.length}{' '}
								<div style={{ margin: '0px 0px 0px 20px' }}>
									Сума (искана):{' '}
									{records !== undefined &&
									records.length !== 0
										? records
												?.map((a) =>
													parseFloat(
														a.totalAmount ?? '0'
													)
												)
												.reduce((a, b) => a + b)
												.toFixed(2)
										: 0.0}{' '}
									/ Сума(крайна):{' '}
									{records !== undefined &&
									records.length !== 0
										? records
												?.map((a) =>
													parseFloat(
														a.payableAmount !==
															'0.00'
															? a.payableAmount
															: a.totalAmount
													)
												)
												.reduce((a, b) => a + b)
												.toFixed(2)
										: 0.0}{' '}
								</div>
							</div>
						)}
					/>
				</div>
				{claimById ? (
					<Modal
						width='100%'
						maskClosable={false}
						transitionName='none'
						maskTransitionName='none'
						title={title}
						centered
						onCancel={async () => await closeModal()}
						visible={show}
						footer={false}
						destroyOnClose={true}>
						<ClaimByIdModal closeModal={closeModal} />
					</Modal>
				) : (
					<Modal
						width='30%'
						title={'Създаване на иск'}
						maskClosable={false}
						transitionName='none'
						maskTransitionName='none'
						centered
						onCancel={async () => await closeModal()}
						visible={show}
						footer={false}
						destroyOnClose={true}>
						<PrepareClaimsModal closeModal={closeModal} />
					</Modal>
				)}
			</div>
		</Fragment>
	);
};

export default observer(PrepareClaimsTable);
