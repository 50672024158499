import React from 'react';
import PrintInsuranceStatusTable from './PrintInsiranceStatusTable';

class PrintIsuranceStatus extends React.Component {
	render() {
		return (
			<div>
				<PrintInsuranceStatusTable />
			</div>
		);
	}
}
export default PrintIsuranceStatus;
