import React, { useContext, useEffect } from 'react';
import { Badge, Button, Avatar, Dropdown } from 'antd';
import {
	NotificationOutlined,
	UserOutlined,
	LogoutOutlined,
} from '@ant-design/icons';
import { Link, RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../app/stores/rootStore';
import { HubConnectionState } from '@microsoft/signalr';

const NavBar: React.FC<RouteComponentProps> = ({ location }) => {
	const rootStore = useContext(RootStoreContext);

	const { currentUser, logout } = rootStore.identityStore;
	const {
		createNotificationHubConnection,
		notificationHubConnection,
	} = rootStore.notificationStore;
	const {
		createMessangerHubConnection,
		messangerHubConnection,
	} = rootStore.messangerStore;

	useEffect(() => {
		if (rootStore.identityStore.currentUser) {
			if (
				notificationHubConnection?.state !==
				HubConnectionState.Connected
			) {
				createNotificationHubConnection();
			}
			if (
				messangerHubConnection?.state !== HubConnectionState.Connected
			) {
				createMessangerHubConnection();
			}
		}
	}, [createNotificationHubConnection, rootStore.identityStore.currentUser]);

	const menu =
		rootStore.notificationStore.notReadNotifications &&
		rootStore.notificationStore.notReadNotifications?.length > 0 ? (
			<div
				style={{
					background: 'white',
					boxShadow: '0px 0px 13px 6px #6363637d',
					maxHeight: 300,
					overflow: 'scroll',
				}}>
				{rootStore.notificationStore.notReadNotifications?.map((a) => (
					<div
						style={{
							maxWidth: 500,
							margin: 5,
							borderBottom: '1px solid rgb(99 99 99 / 65%)',
						}}>
						{a.content}
					</div>
				))}
			</div>
		) : (
			<div></div>
		);

	return (
		<div className='navBar'>
			<div className='document-type-div'>
				{location.pathname === '/referral/create' ||
				location.pathname === '/referral' ||
				location.pathname === '/referral/check'
					? ' Mедицински назначения '
					: location.pathname === '/priorApproval'
					? 'ИПО'
					: location.pathname === '/ambulatorySheets'
					? 'Амбулаторни листове'
					: location.pathname === '/laboratoryResults'
					? 'МДД'
					: location.pathname === '/claims'
					? 'Искове'
					: location.pathname === '/xmlUpload'
					? 'XML-и'
					: location.pathname === '/settings'
					? 'Настройки'
					: ''}
			</div>
			<div className='navBarItems'>
				<div className='companyName-div'>
					{currentUser?.medicalFacilityName
						? currentUser.medicalFacilityName
						: 'CompanyName'}{' '}
					<div>
						{currentUser?.rziCode
							? `РЗИ: ${currentUser.rziCode}`
							: ''}
					</div>
				</div>
				<Dropdown placement='bottomCenter' overlay={menu}>
					<Link to='/notifications'>
						<Badge
							count={
								rootStore.notificationStore
									.unreadNotificationsCount
							}
							className='navBar-badge'>
							<Avatar
								className='navBar-icon'
								icon={<NotificationOutlined />}
							/>
						</Badge>
					</Link>
				</Dropdown>
				<Link to='/profile'>
					<span className='ant-dropdown-link'>
						{currentUser?.userName}
						<Avatar
							className='navBar-avatar'
							icon={<UserOutlined />}
						/>
					</span>
				</Link>
				<Button
					className='btn-primary'
					shape='round'
					icon={<LogoutOutlined />}
					onClick={async () => await logout()}>
					Изход
				</Button>
			</div>
		</div>
	);
};
export default observer(NavBar);
