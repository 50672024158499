import React, { Fragment, useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, ConfigProvider, List, Modal, Popconfirm, Switch } from 'antd';
import { RootStoreContext } from '../../app/stores/rootStore';
import { INotification } from '../../app/models/notifications/Notification';
import Moment from 'react-moment';
import { CloseOutlined } from '@ant-design/icons';
import Constants from '../../app/constants/constants';
import { toJS } from 'mobx';
import Title from 'antd/lib/typography/Title';
import PriorApprovalModal from '../priorApprovalPage/PriorApprovalModal';
import {
	addUserMessage,
	renderCustomComponent,
	addResponseMessage,
	setBadgeCount,
	dropMessages,
} from 'react-chat-widget';
import CurrentDateChat from '../../app/layout/CustomComponents/CurrentDateChat';

const Notifications: React.FC = () => {
	const rootStore = useContext(RootStoreContext);

	const {
		notReadNotifications,
		allNotifications,
		markNotificationAsRead,
		markAllNotificationsAsRead,
		getAllNotifications,
		getNotReadNotifications,
		unreadNotificationsCount,
		removeSelfNotificationForUnreadMessages,
	} = rootStore.notificationStore;
	const {
		electronicDocumentById,
		setUploadEditMode,
		setIsElectronicDocumentAttaching,
		isFormTouched,
		getElectronicDocumentById,
		setDetailsEditMode,
		setDetailsOriginDataFromElectronicDocument,
		setAttachmentsOriginDataFromElectronicDocument,
		setDetailsOriginData,
		setAttachmentsOriginData,
		setTypeOfDocument,
		setCurrentSelectedElectronicDocument,
		setIsFormTouched,
		setIsUploadFormTouched,
	} = rootStore.electronicDocumentStore;

	const {
		getPatientByEgn,
		getElectronicDocumentsForPatient,
	} = rootStore.operationsStore;

	const { loadingNotifications } = rootStore.loadersStore;

	const {
		getAllMessages,
		removeMessages,
		addToMessangerHubGroup,
		markAsRead,
		clearTemporaryMessageQueue,
		removeFromMessangerHubGroup,
	} = rootStore.messangerStore;

	const [show, setShow] = useState(false);
	const [isAllOpen, setIsAllOpen] = useState(false);

	const openPriorApprovalModal = async (id: number | undefined) => {
		if (id) {
			await getElectronicDocumentById(id);

			if (rootStore.electronicDocumentStore.electronicDocumentById) {
				await getElectronicDocumentsForPatient(
					rootStore.electronicDocumentStore.electronicDocumentById
						.patientUid
				);

				await getAllMessages(id);
				await addToMessangerHubGroup(id);
				rootStore.messangerStore.messages?.forEach((element) => {
					if (!element.isInsuranceCompanyMessage) {
						addUserMessage(element.content);
						renderCustomComponent(CurrentDateChat, {
							date: element.createdOn,
							className: 'rcw-client',
						});
					} else {
						addResponseMessage(element.content);
						renderCustomComponent(CurrentDateChat, {
							date: element.createdOn,
							className: 'rcw-response',
						});
					}

					setBadgeCount(0);
				});

				if (
					rootStore.electronicDocumentStore.electronicDocumentById
						?.attachments &&
					rootStore.electronicDocumentStore.electronicDocumentById!
						.attachments?.length >= 1
				) {
					setUploadEditMode(true);
				} else {
					setUploadEditMode(false);
				}
			}
		}
		setTypeOfDocument(Constants.DocumentType.PriorApproval);
		setShow(true);
	};

	const closePriorApprovalModal = async () => {
		if (rootStore.electronicDocumentStore.electronicDocumentById) {
			await markAsRead(
				true,
				rootStore.electronicDocumentStore.electronicDocumentById!.id
			);
			await removeSelfNotificationForUnreadMessages(
				parseInt(rootStore.identityStore.currentUser!.userId),
				rootStore.electronicDocumentStore.electronicDocumentById!.id
			);

			dropMessages();
			clearTemporaryMessageQueue();
		}

		if (rootStore.electronicDocumentStore.electronicDocumentById) {
			await removeFromMessangerHubGroup(electronicDocumentById!.id);
		}

		setShow(false);
		setUploadEditMode(false);
		await getElectronicDocumentById(undefined);
		await getElectronicDocumentsForPatient(undefined);
		setCurrentSelectedElectronicDocument(undefined);
		setDetailsOriginDataFromElectronicDocument(undefined);
		setAttachmentsOriginDataFromElectronicDocument(undefined);
		setDetailsOriginData(undefined);
		setAttachmentsOriginData(undefined);
		setDetailsEditMode(false);
		setIsFormTouched(false);
		setIsUploadFormTouched(false);
		await getPatientByEgn(undefined);
		setTypeOfDocument(undefined);
		setIsElectronicDocumentAttaching(false);
		removeMessages();
		if (isAllOpen) {
			getAllNotifications();
		} else {
			getNotReadNotifications();
		}
	};

	const dateFormatted = (item: INotification) => {
		return (
			<div>
				<Moment format={Constants.Common.FullDate_Format}>
					{item.createdOn}
				</Moment>{' '}
				<div
					style={{
						display: 'inline-block',
						color: item.isRead ? 'inherit' : ' red',
						fontWeight: 'bold',
					}}>
					{' '}
					{item.isRead ? 'Прочетено' : ' Непрочетено'}{' '}
				</div>{' '}
			</div>
		);
	};

	const data = !isAllOpen
		? toJS(notReadNotifications)
		: toJS(allNotifications);

	const title =
		electronicDocumentById?.status ===
			Constants.MedicalReferralStatus.Performed ||
		electronicDocumentById?.isActive === false ||
		(electronicDocumentById?.documentStatus !==
			Constants.DocumentProcessStatus.Registered &&
			electronicDocumentById?.documentStatus !==
				Constants.DocumentProcessStatus.OpenForModification) ? (
			<div>
				Документ № {electronicDocumentById?.documentNumber} от{' '}
				<Moment format={Constants.Common.FullDate_Format}>
					{electronicDocumentById?.createdOn}
				</Moment>{' '}
			</div>
		) : (
			<div>
				Редактиране на документ №{' '}
				{electronicDocumentById?.documentNumber} от{' '}
				<Moment format={Constants.Common.FullDate_Format}>
					{electronicDocumentById?.createdOn}
				</Moment>{' '}
			</div>
		);

	const customizeRenderEmpty = () => (
		<div style={{ textAlign: 'center', fontSize: 20, color: 'black' }}>
			<p>Нямате нови непрочетени известия!</p>
		</div>
	);

	return (
		<Fragment>
			<div className='home-grid-col-3'>
				<Title style={{ textAlign: 'center' }} level={3}>
					Известия
				</Title>
				<Button
					onClick={async () => {
						await markAllNotificationsAsRead();
						await getNotReadNotifications();
					}}
					disabled={unreadNotificationsCount === 0}
					className='btn-groupe'
					style={{
						background: '#234254',
						marginBottom: 20,
						marginRight: 20,
					}}
					type='primary'>
					Маркирай всички като прочетени
				</Button>
				<Switch
					className='switch-in-tables'
					defaultChecked={false}
					onChange={(value: any) => {
						value === false
							? getNotReadNotifications() && setIsAllOpen(false)
							: getAllNotifications() && setIsAllOpen(true);
					}}
					checkedChildren='Всички'
					unCheckedChildren='Непрочетени'></Switch>
				<ConfigProvider renderEmpty={customizeRenderEmpty}>
					<List
						loading={loadingNotifications}
						pagination={{
							pageSize: 5,
						}}
						size='large'
						dataSource={data}
						renderItem={(item) => (
							<List.Item
								style={{
									background: 'fff8f8',
								}}>
								<List.Item.Meta
									title={dateFormatted(item)}
									description={item.content}
								/>
								<Button
									onClick={async () => {
										await markNotificationAsRead(item.id);
										await getNotReadNotifications();
									}}
									className='btn-groupe'
									type='primary'
									style={{
										display: item.isRead ? 'none' : 'block',
										fontSize: 12,
										marginRight: 20,
										background: '#234254',
									}}>
									Маркирай като прочетено
								</Button>
								<Button
									onClick={async () => {
										await openPriorApprovalModal(
											item.documentId
										);
										await markNotificationAsRead(item.id);
										await getNotReadNotifications();
									}}
									className='btn-groupe'
									style={{
										background: '#234254',
									}}
									type='primary'>
									Преглед
								</Button>
							</List.Item>
						)}
					/>
				</ConfigProvider>
			</div>
			<Modal
				className='appointment-modal prior-approval-modal'
				title={title}
				centered
				maskClosable={false}
				transitionName='none'
				maskTransitionName='none'
				closeIcon={
					isFormTouched ? (
						<Popconfirm
							title='Сигурни ли сте, че искате да затворите прозореца. Вашите промени няма да бъдат запазени!'
							onConfirm={() => closePriorApprovalModal()}>
							<CloseOutlined />
						</Popconfirm>
					) : (
						<CloseOutlined onClick={closePriorApprovalModal} />
					)
				}
				visible={show}
				footer={false}
				destroyOnClose={true}>
				<PriorApprovalModal
					closeModal={closePriorApprovalModal}
					openModal={openPriorApprovalModal}
				/>
			</Modal>
		</Fragment>
	);
};
export default observer(Notifications);
