import React, { Fragment, useContext, useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { Form, Button, Spin, notification } from 'antd';
import {
	IDetail,
	IAttachment,
} from '../../app/models/electronicDocument/ElectronicDocument';
import { toJS } from 'mobx';
import { useReactToPrint } from 'react-to-print';
import { PrinterOutlined } from '@ant-design/icons';
import PatientFormItem from '../../app/layout/FormItems/PatientFormItem';
import IssuerFormItem from '../../app/layout/FormItems/IssuerFormItem';
import MkbsFormItem from '../../app/layout/FormItems/MkbsFormItem';
import IsActiveFormItem from '../../app/layout/FormItems/IsActiveFormItem';
import Enums from '../../app/constants/enums';
import { RootStoreContext } from '../../app/stores/rootStore';
import DocumentNumberAndDateFormItem from '../../app/layout/FormItems/DocumentNumberAndDateFormItem';
import PrintAmbulatorySheet from '../print/PrintAmbulatorySheet';
import AmbSheetsAttachmentsTable from '../ambulatorySheetsPage/tables/attachments/AmbSheetsAttachmentsTable';
import constants from '../../app/constants/constants';
import LabResultsDetailsTable from './detailsTable/LabResultsDetailsTable';
import moment from 'moment';

const DocumentTypeEnum = Enums.DocumentTypeEnum;

interface IProps {
	closeModal: () => Promise<void>;
	openModal: (id: number | undefined) => Promise<void>;
}
const LaboratoryResultsModal: React.FC<IProps> = ({
	openModal,
	closeModal,
}) => {
	const rootStore = useContext(RootStoreContext);
	const [form] = Form.useForm();

	const {
		electronicDocumentById,
		createElectronicDocument,
		editElectronicDocument,
		detailsOriginData,
		attachmentsOriginData,
		setIsFormTouched,
		setDetailsOriginDataFromElectronicDocument,
		setAttachmentsOriginDataFromElectronicDocument,
	} = rootStore.electronicDocumentStore;

	const { period } = rootStore.laboratoryResultsStore;
	const { loadingModal } = rootStore.loadersStore;
	const { currentUser } = rootStore.identityStore;

	const defaultValues = {
		id: electronicDocumentById?.id,
		documentNumber: electronicDocumentById?.documentNumber,
		documentDate: electronicDocumentById?.documentDate,
		companyName: electronicDocumentById
			? parseInt(electronicDocumentById?.insuranceCompanyId)
			: undefined,
		patientUid: electronicDocumentById?.patientUid,
		patientName: electronicDocumentById?.patientName,
		pacientEmail: electronicDocumentById?.pacientEmail,
		patientCard: electronicDocumentById?.patientCard,
		issuerUin: !electronicDocumentById
			? currentUser!.uinCode
			: electronicDocumentById?.issuerUin,
		issuerSpecialityCode: electronicDocumentById
			? electronicDocumentById?.issuerSpecialityCode
			: undefined,
		issuerName: electronicDocumentById?.issuerName,
		isActive: electronicDocumentById?.isActive,
		mkbCode: electronicDocumentById?.mkbCode,
		mkbName: electronicDocumentById
			? electronicDocumentById!.mkbCode
			: undefined,
	};

	const prepareAndSubmitForm = async (
		values: any,
		detailsOriginData: IDetail[],
		attachmentsOriginData: IAttachment[]
	) => {
		const { ...electronicDocumentById } = values;

		if (!electronicDocumentById.id) {
			let newLaboratoryResult = {
				...electronicDocumentById,
			};
			values.details = [] as IDetail[];
			values.attachments = [] as IAttachment[];

			detailsOriginData.forEach((el) => {
				values.details.push(el);
			});

			attachmentsOriginData.forEach((el) => {
				values.attachments.push(el);
			});

			newLaboratoryResult.documentType = DocumentTypeEnum.LabResults;
			newLaboratoryResult.details = values.details;
			newLaboratoryResult.attachments = values.attachments;
			newLaboratoryResult.documentDate = newLaboratoryResult.documentDate.local();

			let formData = new FormData();
			formData.set('request', JSON.stringify(newLaboratoryResult));
			newLaboratoryResult.attachments.forEach((element: IAttachment) => {
				formData.append(`files`, element.file!);
			});

			if (
				attachmentsOriginData.findIndex(
					(el) =>
						el.documentType ===
						constants.DocumentType.MedicalReferral
				) > -1 &&
				attachmentsOriginData.findIndex(
					(el) =>
						el.documentType === constants.DocumentType.LabResults
				) > -1
			) {
				let lastRecordId = await createElectronicDocument(
					formData,
					DocumentTypeEnum.LabResults
				);
				if (lastRecordId !== undefined) {
					await rootStore.electronicDocumentStore.getElectronicDocumentById(
						lastRecordId
					);
					rootStore.electronicDocumentStore.setIsFormTouched(false);
					rootStore.electronicDocumentStore.setGetElDocsAgain(true);

					if (
						moment(electronicDocumentById?.documentDate) <
							moment(period.dateFrom) ||
						moment(electronicDocumentById?.documentDate) >
							moment(period.dateTo)
					) {
						notification.info({
							message:
								'Периодът на това МДД е различен от периода, зададен във филтъра на таблицата. За да го видите трябва да смените настройката по период!',
						});
					}
				}
			} else {
				const key = `open${Date.now()}`;
				notification.open({
					key: key,
					message:
						'Сигурни ли сте, че желаете да запазите МДД без прикачени медицинско назначение или лабораторни резултати?',
					btn: (
						<Button
							type='primary'
							size='small'
							onClick={async () => {
								notification.close(key);
								let lastRecordId = await createElectronicDocument(
									formData,
									DocumentTypeEnum.LabResults
								);
								if (lastRecordId !== undefined) {
									await rootStore.electronicDocumentStore.getElectronicDocumentById(
										lastRecordId
									);
									rootStore.electronicDocumentStore.setIsFormTouched(
										false
									);
									rootStore.electronicDocumentStore.setGetElDocsAgain(
										true
									);

									if (
										moment(
											electronicDocumentById?.documentDate
										) < moment(period.dateFrom) ||
										moment(
											electronicDocumentById?.documentDate
										) > moment(period.dateTo)
									) {
										notification.info({
											message:
												'Периодът на това МДД е различен от периода, зададен във филтъра на таблицата. За да го видите трябва да смените настройката по период!',
										});
									}
								}
							}}>
							Потвърди
						</Button>
					),
				});
			}
		} else {
			let newLaboratoryResult = {
				...electronicDocumentById,
			};
			values.details = [] as IDetail[];
			values.attachments = [] as IAttachment[];

			detailsOriginData.forEach((el) => {
				values.details.push(toJS(el));
			});
			attachmentsOriginData.forEach((el) => {
				values.attachments.push(toJS(el));
			});

			newLaboratoryResult.details = values.details;
			newLaboratoryResult.attachments = values.attachments;

			if (Number.isInteger(newLaboratoryResult.isActive)) {
				newLaboratoryResult.isActive =
					newLaboratoryResult.isActive === 1 ? true : false;
			}

			if (
				(attachmentsOriginData.findIndex(
					(el) =>
						el.documentType ===
						constants.DocumentType.MedicalReferral
				) > -1 &&
					attachmentsOriginData.findIndex(
						(el) =>
							el.documentType ===
							constants.DocumentType.LabResults
					) > -1) ||
				newLaboratoryResult.isActive === false
			) {
				await editElectronicDocument(
					newLaboratoryResult!,
					DocumentTypeEnum.LabResults
				);
				await rootStore.electronicDocumentStore.getElectronicDocumentById(
					newLaboratoryResult.id
				);
				rootStore.electronicDocumentStore.setIsFormTouched(false);
				rootStore.electronicDocumentStore.setGetElDocsAgain(true);
			} else {
				const key = `open${Date.now()}`;
				notification.open({
					key: key,
					message:
						'Сигурни ли сте, че желаете да запазите МДД без прикачени медицинско назначение или лабораторни резултати?',
					btn: (
						<Button
							type='primary'
							size='small'
							onClick={async () => {
								notification.close(key);
								await editElectronicDocument(
									newLaboratoryResult!,
									DocumentTypeEnum.LabResults
								);
								await rootStore.electronicDocumentStore.getElectronicDocumentById(
									newLaboratoryResult.id
								);
								rootStore.electronicDocumentStore.setIsFormTouched(
									false
								);
								rootStore.electronicDocumentStore.setGetElDocsAgain(
									true
								);
							}}>
							Потвърди
						</Button>
					),
				});
			}
		}
	};

	const onFormFieldsChange = () => {
		setIsFormTouched(true);
	};

	useEffect(() => {
		if (!detailsOriginData) {
			setDetailsOriginDataFromElectronicDocument(electronicDocumentById);
		}
		if (!attachmentsOriginData) {
			setAttachmentsOriginDataFromElectronicDocument(
				electronicDocumentById
			);
		}
	}, [detailsOriginData, attachmentsOriginData]);

	const componentRef = useRef(null);
	const handlePrint = useReactToPrint({
		content: () => componentRef.current!,
	});

	return (
		<Fragment>
			<Spin spinning={loadingModal}>
				<div
					style={{
						textAlign: 'center',
						display:
							electronicDocumentById?.isActive === false
								? 'inherit'
								: 'none',
						color: 'red',
						fontSize: '21px',
					}}>
					{electronicDocumentById?.isActive === false
						? 'Това МДД е неактивно!'
						: ''}
				</div>
				<div
					style={{
						display:
							(electronicDocumentById?.claimProcessStatus ===
								constants.ClaimProcessStatus.ChangeRequested &&
								electronicDocumentById?.insuranceCompanyNotes) ||
							(electronicDocumentById?.claimProcessStatus ===
								constants.ClaimProcessStatus.Processed &&
								electronicDocumentById?.insuranceCompanyNotes)
								? 'inherit'
								: 'none',

						fontSize: '15px',
						color: 'red',
					}}>
					Резолюция / Забележки:{' '}
					{electronicDocumentById?.insuranceCompanyNotes}
				</div>
				<Form
					form={form}
					initialValues={defaultValues}
					key={electronicDocumentById?.id}
					onFieldsChange={onFormFieldsChange}
					onFinish={(values) =>
						prepareAndSubmitForm(
							values,
							detailsOriginData!,
							attachmentsOriginData!
						)
					}>
					{rootStore.electronicDocumentStore
						.electronicDocumentById === undefined ? (
						<DocumentNumberAndDateFormItem
							label={'МДД'}
							form={form}
						/>
					) : null}

					<PatientFormItem form={form} />
					<IssuerFormItem label='Лекар:' form={form} />
					<MkbsFormItem form={form} />

					<LabResultsDetailsTable
						passedForm={form}
						openModal={openModal}
						closeModal={closeModal}
					/>
					<AmbSheetsAttachmentsTable
						passedForm={form}
						openModal={openModal}
						closeModal={closeModal}
					/>
					<IsActiveFormItem form={form} />

					<Form.Item name='id'>
						<div style={{ display: 'none' }}>
							<PrintAmbulatorySheet ref={componentRef} />
						</div>
						<Button
							key='print'
							type='primary'
							className='btn-groupe'
							shape='round'
							style={{
								float: 'left',
								margin: 0,
								display:
									!electronicDocumentById ||
									electronicDocumentById?.isActive === false
										? 'none'
										: 'block',
								backgroundColor: '#234254',
								borderRadius: 20,
							}}
							onClick={handlePrint}>
							<PrinterOutlined /> Принтирай
						</Button>
						<Button
							onMouseEnter={(e) =>
								form.setFieldsValue({
									patientName: form
										.getFieldValue('patientName')
										.trim(),
								})
							}
							disabled={
								electronicDocumentById?.isActive === false ||
								rootStore.electronicDocumentStore
									.detailsEditMode === true ||
								rootStore.electronicDocumentStore
									.isFormTouched === false
							}
							type='primary'
							shape='round'
							className='btn-primary submit-referral-btn'
							htmlType='submit'
							style={{
								display:
									electronicDocumentById?.isActive === false
										? 'none'
										: 'block',
							}}>
							{electronicDocumentById
								? 'Запази промените'
								: 'Създай МДД'}
						</Button>
					</Form.Item>
				</Form>
			</Spin>
		</Fragment>
	);
};
export default observer(LaboratoryResultsModal);
