import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { RootStoreContext } from '../../app/stores/rootStore';
import Constants from '../../app/constants/constants';
import Moment from 'react-moment';

const PrintAmbulatorySheetFile: React.FC = () => {
	const rootStore = useContext(RootStoreContext);
	const { electronicDocumentById } = rootStore.electronicDocumentStore;
	return electronicDocumentById?.documentType.toString() ===
		Constants.DocumentType.Preventions ? (
		<div style={{ margin: '10px', color: 'black', fontFamily: 'Calibri' }}>
			<h4
				style={{
					textAlign: 'center',
				}}>
				{electronicDocumentById?.medicalFacilityIssuerName}{' '}
			</h4>
			<h4
				style={{
					textAlign: 'center',
				}}>
				{' '}
				{electronicDocumentById?.medicalFacilityIssuerCity}
			</h4>
			<h4
				style={{
					textAlign: 'center',
				}}>
				{' '}
				РЗИ: {electronicDocumentById?.medicalFacilityIssuerRziCode}
			</h4>

			<h3 style={{ fontWeight: 'bolder' }}>
				{' '}
				Профилактична дейност № {
					electronicDocumentById?.documentNumber
				}{' '}
			</h3>
			<h3 style={{ fontWeight: 'bolder' }}>
				{' '}
				Дата:{' '}
				<Moment format={Constants.Common.ShortDate_Format}>
					{electronicDocumentById?.preventionDateFrom}
				</Moment>{' '}
				-{' '}
				<Moment format={Constants.Common.ShortDate_Format}>
					{electronicDocumentById?.preventionDateTo}
				</Moment>{' '}
			</h3>
			<h3>
				Застрахователна компания: "
				{electronicDocumentById?.insuranceCompanyName}"
			</h3>
			<div>
				<div>Пациент: </div>
				<h4 style={{ marginLeft: 10, fontWeight: 'bolder' }}>
					ЕГН: {electronicDocumentById?.patientUid}{' '}
				</h4>
				<h4 style={{ marginLeft: 10, fontWeight: 'bolder' }}>
					Име: {electronicDocumentById?.patientName}
				</h4>
				<h4 style={{ marginLeft: 10, fontWeight: 'bolder' }}>
					Номер на карта: {electronicDocumentById?.patientCard}
				</h4>
			</div>
			<br />

			<h5> Извършени дейности: </h5>
			<table
				style={{
					borderCollapse: 'collapse',
					textAlign: 'center',
					width: '100%',
					color: 'black',
					fontSize: 11,
				}}>
				<thead>
					<tr style={{ border: '1px solid ' }}>
						<th style={{ border: '1px solid ', width: '10%' }}>
							{' '}
							Код по МКБ 9КМ / НЗОК{' '}
						</th>
						<th
							style={{
								border: '1px solid ',
								width:
									electronicDocumentById?.claimProcessStatus ===
										Constants.ClaimProcessStatus
											.ChangeRequested ||
									electronicDocumentById?.claimProcessStatus ===
										Constants.ClaimProcessStatus.Processed
										? '5%'
										: ' 10%',
							}}>
							{' '}
							Код ЛЗ{' '}
						</th>
						<th style={{ border: '1px solid ', width: '30%' }}>
							{' '}
							Дейност{' '}
						</th>
						<th style={{ border: '1px solid ', width: '5%' }}>
							{' '}
							Спец.{' '}
						</th>
						<th style={{ border: '1px solid ', width: '30%' }}>
							{' '}
							Допълнителна информация{' '}
						</th>

						<th style={{ border: '1px solid ', width: '5%' }}>
							{' '}
							Брой
						</th>
						<th style={{ border: '1px solid ', width: '5%' }}>
							{' '}
							Ед.цена{' '}
						</th>
						<th style={{ border: '1px solid ', width: '5%' }}>
							{' '}
							Сума{' '}
						</th>
						<th
							style={{
								border: '1px solid ',
								width:
									electronicDocumentById?.claimProcessStatus ===
										Constants.ClaimProcessStatus
											.ChangeRequested ||
									electronicDocumentById?.claimProcessStatus ===
										Constants.ClaimProcessStatus.Processed
										? '5%'
										: ' 0%',
							}}></th>
					</tr>
				</thead>
				<tbody style={{ border: '1px solid ' }}>
					{electronicDocumentById?.details.map((detail) => (
						<tr>
							<td
								style={{
									border: '1px solid ',
									textAlign: 'center',
								}}>
								{detail.nhifCode}
							</td>
							<td
								style={{
									border: '1px solid ',
									textAlign: 'center',
								}}>
								{detail.code}
							</td>
							<td
								style={{
									border: '1px solid ',
									textAlign: 'left',
								}}>
								{detail.name}
							</td>
							<td
								style={{
									border: '1px solid ',
									textAlign: 'center',
								}}>
								{detail.specialityCode}
							</td>
							<td style={{ border: '1px solid ' }}>
								{' '}
								{detail.description}{' '}
							</td>

							<td style={{ border: '1px solid ' }}>
								{' '}
								{detail.count}{' '}
							</td>
							<td
								style={{
									border: '1px solid ',
									textAlign: 'right',
								}}>
								{' '}
								{detail.price}{' '}
							</td>
							<td
								style={{
									border: '1px solid ',
									textAlign: 'right',
								}}>
								{' '}
								{detail.totalAmount}{' '}
							</td>
							<td
								style={{
									border: '1px solid black',
									textAlign: 'center',
									color: 'red',
								}}>
								{detail.isPayable ? '' : 'Отказана'}
							</td>
						</tr>
					))}
				</tbody>
			</table>
			<div style={{ textAlign: 'end' }}>
				Общо:{' '}
				{electronicDocumentById?.details
					.map((detail) => parseFloat(detail.totalAmount))
					.reduce((a, b) => a + b)
					.toFixed(2)}{' '}
				<span
					style={{
						display:
							electronicDocumentById?.claimProcessStatus ===
								Constants.ClaimProcessStatus.ChangeRequested ||
							electronicDocumentById?.claimProcessStatus ===
								Constants.ClaimProcessStatus.Processed
								? 'inherit'
								: 'none',
					}}>
					{' '}
					Общо(ново): {electronicDocumentById?.payableAmount}{' '}
				</span>
			</div>
			<br />
			<span
				style={{
					display:
						electronicDocumentById?.attachments.length === 0
							? 'none'
							: 'inherit',
				}}>
				<h5>Други документи: </h5>
				<table
					style={{
						borderCollapse: 'collapse',
						textAlign: 'center',
						width: '100%',
						color: 'black',
						fontSize: 11,
					}}>
					<thead>
						<tr style={{ border: '1px solid ' }}>
							<th
								style={{
									border: '1px solid ',
									width: '15%',
								}}>
								{' '}
								Тип на документ{' '}
							</th>
							<th
								style={{
									border: '1px solid ',
									width: '30%',
								}}>
								{' '}
								Съдържание тип{' '}
							</th>
							<th
								style={{
									border: '1px solid ',
									width: '30%',
								}}>
								Допълнителна информация
							</th>
						</tr>
					</thead>
					<tbody style={{ border: '1px solid ' }}>
						{electronicDocumentById?.attachments.map(
							(attachment) => (
								<tr>
									<td
										style={{
											border: '1px solid ',
											textAlign: 'left',
										}}>
										{attachment.attachmentType}
									</td>
									<td
										style={{
											border: '1px solid ',
											textAlign: 'left',
										}}>
										{attachment.documentType}
									</td>

									<td
										style={{
											border: '1px solid ',
											textAlign: 'left',
										}}>
										{attachment.description}
									</td>
								</tr>
							)
						)}
					</tbody>
				</table>
				<br />
			</span>

			<h4
				style={{
					display:
						electronicDocumentById?.claimProcessStatus ===
							Constants.ClaimProcessStatus.ChangeRequested ||
						electronicDocumentById?.claimProcessStatus ===
							Constants.ClaimProcessStatus.Processed
							? 'inherit'
							: 'none',
				}}>
				{' '}
				Резолюция / Забележки:{' '}
				{electronicDocumentById?.insuranceCompanyNotes} <br />
			</h4>
			<div>
				<h4 style={{ display: 'inline-block' }}>Подпис/Печат лекар</h4>
				<h4
					style={{
						display: 'inline-block',
						float: 'right',
						marginRight: '100px',
					}}>
					Подпис пациент
				</h4>
			</div>
		</div>
	) : (
		<div style={{ margin: '10px', color: 'black', fontFamily: 'Calibri' }}>
			<h4
				style={{
					textAlign: 'center',
				}}>
				{electronicDocumentById?.medicalFacilityIssuerName}{' '}
			</h4>
			<h4
				style={{
					textAlign: 'center',
				}}>
				{' '}
				{electronicDocumentById?.medicalFacilityIssuerCity}
			</h4>
			<h4
				style={{
					textAlign: 'center',
				}}>
				{' '}
				РЗИ: {electronicDocumentById?.medicalFacilityIssuerRziCode}
			</h4>
			<h3 style={{ fontWeight: 'bolder' }}>
				{electronicDocumentById?.documentType.toString() ===
				Constants.DocumentType.AmbSheet
					? electronicDocumentById?.documentType
					: electronicDocumentById?.documentType.toString() ===
					  Constants.DocumentType.LabResults
					? 'МДД'
					: electronicDocumentById?.documentType.toString() ===
					  Constants.DocumentType.DeseaseHistory
					? 'ИЗ'
					: ''}{' '}
				№ {electronicDocumentById?.documentNumber}{' '}
				<div style={{ float: 'right', display: 'inline-block' }}>
					дата:{' '}
					<Moment
						format={
							electronicDocumentById?.documentType.toString() ===
								Constants.DocumentType.AmbSheet ||
							electronicDocumentById?.documentType.toString() ===
								Constants.DocumentType.DeseaseHistory
								? Constants.Common
										.FullDate_Format_Without_Seconds
								: Constants.Common.ShortDate_Format
						}>
						{electronicDocumentById?.documentDate}
					</Moment>{' '}
				</div>
			</h3>
			<h3
				style={{
					display:
						electronicDocumentById?.documentType.toString() ===
							Constants.DocumentType.LabResults ||
						electronicDocumentById?.documentType.toString() ===
							Constants.DocumentType.DeseaseHistory
							? 'none'
							: 'block',
				}}>
				Тип:{' '}
				{electronicDocumentById?.isPrimary === false
					? 'Вторичен'
					: 'Първичен'}{' '}
			</h3>
			<h3>
				Застрахователна компания: "
				{electronicDocumentById?.insuranceCompanyName}"
			</h3>
			<div>
				<div
					style={{
						width: '49%',
						display: ' inline-block',
						border: '1px solid',
						margin: 5,
						marginLeft: 0,
						padding: 5,
						verticalAlign: 'top',
						minHeight: 170,
					}}>
					<div>Пациент: </div>
					<h4 style={{ marginLeft: 10, fontWeight: 'bolder' }}>
						ЕГН: {electronicDocumentById?.patientUid}{' '}
					</h4>
					<h4 style={{ marginLeft: 10, fontWeight: 'bolder' }}>
						Име: {electronicDocumentById?.patientName}
					</h4>
					<h4 style={{ marginLeft: 10, fontWeight: 'bolder' }}>
						Номер на карта: {electronicDocumentById?.patientCard}
					</h4>
				</div>
				<div
					style={{
						width: '49%',
						float: 'right',
						display: ' inline-block',
						border: '1px solid',
						margin: 5,
						marginRight: 0,
						padding: 5,
						verticalAlign: 'top',
						minHeight: 170,
					}}>
					<div>Лекар: </div>
					<h4 style={{ marginLeft: 10, fontWeight: 'bolder' }}>
						УИН: {electronicDocumentById?.issuerUin}{' '}
					</h4>
					<h4 style={{ marginLeft: 10, fontWeight: 'bolder' }}>
						Име: {electronicDocumentById?.issuerName}
					</h4>
					<h4 style={{ marginLeft: 10, fontWeight: 'bolder' }}>
						Специалност:{' '}
						{electronicDocumentById?.issuerSpecialityCode} -{' '}
						{electronicDocumentById?.issuerSpecialityName}
					</h4>
				</div>
			</div>
			<br />
			<div style={{ fontSize: 16 }}>
				<span
					style={{
						fontWeight: 'bolder',
						fontStyle: 'italic',
						fontSize: 16,
					}}>
					Основна диагноза:
				</span>{' '}
				{electronicDocumentById?.mkbCode} -{' '}
				{electronicDocumentById?.mkbName}{' '}
			</div>
			<br />

			<div
				style={{
					display:
						rootStore.electronicDocumentStore.typeOfDocument ===
							Constants.DocumentType.AmbSheet ||
						electronicDocumentById?.documentType.toString() ===
							Constants.DocumentType.DeseaseHistory
							? 'block'
							: 'none',
				}}>
				<div
					style={{
						width: '100%',
						border: '1px solid',
						marginBottom: '3px',
						padding: 5,
					}}>
					<div
						style={{
							fontWeight: 'bolder',
							fontStyle: 'italic',
							fontSize: 12,
						}}>
						Анамнеза
					</div>
					<div>{electronicDocumentById?.patientMedicalHistory}</div>
				</div>
				<br />
			</div>

			<div
				style={{
					display:
						rootStore.electronicDocumentStore.typeOfDocument ===
							Constants.DocumentType.AmbSheet ||
						electronicDocumentById?.documentType.toString() ===
							Constants.DocumentType.DeseaseHistory
							? 'block'
							: 'none',
				}}>
				<div
					style={{
						width: '100%',
						border: '1px solid',
						marginBottom: '3px',
						padding: 5,
					}}>
					<div
						style={{
							fontWeight: 'bolder',
							fontStyle: 'italic',
							fontSize: 12,
						}}>
						Обективно състояние
					</div>
					<div>{electronicDocumentById?.patientHealthStatus}</div>
				</div>
				<br />
			</div>

			<div
				style={{
					display: electronicDocumentById?.patientExaminations
						? 'block'
						: 'none',
				}}>
				<div
					style={{
						width: '100%',
						border: '1px solid',
						marginBottom: '3px',
						padding: 5,
					}}>
					<div
						style={{
							fontWeight: 'bolder',
							fontStyle: 'italic',
							fontSize: 12,
						}}>
						Изследвания
					</div>
					<div>{electronicDocumentById?.patientExaminations}</div>
				</div>
				<br />
			</div>

			<div
				style={{
					display: electronicDocumentById?.patientTherapy
						? 'block'
						: 'none',
				}}>
				<div
					style={{
						width: '100%',
						border: '1px solid',
						marginBottom: '3px',
						padding: 5,
					}}>
					<div
						style={{
							fontWeight: 'bolder',
							fontStyle: 'italic',
							fontSize: 12,
						}}>
						Терапия
					</div>
					<div>{electronicDocumentById?.patientTherapy}</div>
				</div>
				<br />
			</div>

			<h5> Извършени дейности: </h5>
			<table
				style={{
					borderCollapse: 'collapse',
					textAlign: 'center',
					width: '100%',
					color: 'black',
					fontSize: 11,
				}}>
				<thead>
					<tr style={{ border: '1px solid ' }}>
						<th style={{ border: '1px solid ', width: '10%' }}>
							{' '}
							Код по МКБ 9КМ / НЗОК{' '}
						</th>
						<th
							style={{
								border: '1px solid ',
								width:
									electronicDocumentById?.claimProcessStatus ===
										Constants.ClaimProcessStatus
											.ChangeRequested ||
									electronicDocumentById?.claimProcessStatus ===
										Constants.ClaimProcessStatus.Processed
										? '5%'
										: ' 10%',
							}}>
							{' '}
							Код ЛЗ{' '}
						</th>
						<th style={{ border: '1px solid ', width: '35%' }}>
							{' '}
							Дейност{' '}
						</th>
						<th style={{ border: '1px solid ', width: '30%' }}>
							{' '}
							Допълнителна информация{' '}
						</th>

						<th style={{ border: '1px solid ', width: '5%' }}>
							{' '}
							Брой
						</th>
						<th style={{ border: '1px solid ', width: '5%' }}>
							{' '}
							Ед.цена{' '}
						</th>
						<th style={{ border: '1px solid ', width: '5%' }}>
							{' '}
							Сума{' '}
						</th>
						<th
							style={{
								border: '1px solid ',
								width:
									electronicDocumentById?.claimProcessStatus ===
										Constants.ClaimProcessStatus
											.ChangeRequested ||
									electronicDocumentById?.claimProcessStatus ===
										Constants.ClaimProcessStatus.Processed
										? '5%'
										: ' 0%',
							}}></th>
					</tr>
				</thead>
				<tbody style={{ border: '1px solid ' }}>
					{electronicDocumentById?.details.map((detail) => (
						<tr>
							<td
								style={{
									border: '1px solid ',
									textAlign: 'center',
								}}>
								{detail.nhifCode}
							</td>
							<td
								style={{
									border: '1px solid ',
									textAlign: 'center',
								}}>
								{detail.code}
							</td>
							<td
								style={{
									border: '1px solid ',
									textAlign: 'left',
								}}>
								{detail.name}
							</td>
							<td style={{ border: '1px solid ' }}>
								{' '}
								{detail.description}{' '}
							</td>

							<td style={{ border: '1px solid ' }}>
								{' '}
								{detail.count}{' '}
							</td>
							<td
								style={{
									border: '1px solid ',
									textAlign: 'right',
								}}>
								{' '}
								{detail.price}{' '}
							</td>
							<td
								style={{
									border: '1px solid ',
									textAlign: 'right',
								}}>
								{' '}
								{detail.totalAmount}{' '}
							</td>
							<td
								style={{
									border: '1px solid black',
									textAlign: 'center',
									color: 'red',
								}}>
								{detail.isPayable ? '' : 'Отказана'}
							</td>
						</tr>
					))}
				</tbody>
			</table>
			<div style={{ textAlign: 'end' }}>
				Общо:{' '}
				{electronicDocumentById?.details
					.map((detail) => parseFloat(detail.totalAmount))
					.reduce((a, b) => a + b)
					.toFixed(2)}{' '}
				<span
					style={{
						display:
							electronicDocumentById?.claimProcessStatus ===
								Constants.ClaimProcessStatus.ChangeRequested ||
							electronicDocumentById?.claimProcessStatus ===
								Constants.ClaimProcessStatus.Processed
								? 'inherit'
								: 'none',
					}}>
					{' '}
					Общо(ново): {electronicDocumentById?.payableAmount}{' '}
				</span>
			</div>
			<br />

			<span
				style={{
					display:
						electronicDocumentById?.attachments.length === 0
							? 'none'
							: 'inherit',
				}}>
				<h5>Други документи: </h5>
				<table
					style={{
						borderCollapse: 'collapse',
						textAlign: 'center',
						width: '100%',
						color: 'black',
						fontSize: 11,
					}}>
					<thead>
						<tr style={{ border: '1px solid ' }}>
							<th
								style={{
									border: '1px solid ',
									width: '15%',
								}}>
								{' '}
								Тип на документ{' '}
							</th>
							<th
								style={{
									border: '1px solid ',
									width: '30%',
								}}>
								{' '}
								Съдържание тип{' '}
							</th>
							<th
								style={{
									border: '1px solid ',
									width: '10%',
								}}>
								{' '}
								Номер на документ{' '}
							</th>
							<th
								style={{
									border: '1px solid ',
									width: '15%',
								}}>
								{' '}
								Дата на документ{' '}
							</th>
							<th
								style={{
									border: '1px solid ',
									width: '30%',
								}}>
								Допълнителна информация / Лекар
							</th>
						</tr>
					</thead>
					<tbody style={{ border: '1px solid ' }}>
						{electronicDocumentById?.attachments.map(
							(attachment) => (
								<tr>
									<td
										style={{
											border: '1px solid ',
											textAlign: 'left',
										}}>
										{attachment.attachmentType}
									</td>
									<td
										style={{
											border: '1px solid ',
											textAlign: 'left',
										}}>
										{attachment.documentType}
									</td>
									<td
										style={{
											border: '1px solid ',
											textAlign: 'center',
										}}>
										{attachment.eDocumentNumber}
									</td>
									<td
										style={{
											border: '1px solid ',
											textAlign: 'center',
										}}>
										{attachment.eDocumentDate !== null ? (
											<Moment
												format={
													Constants.Common
														.ShortDate_Format
												}>
												{attachment.eDocumentDate}
											</Moment>
										) : (
											''
										)}
									</td>
									<td
										style={{
											border: '1px solid ',
											textAlign: 'left',
										}}>
										{attachment.description
											? attachment.description
											: attachment.eDocumentDoctorName}
									</td>
								</tr>
							)
						)}
					</tbody>
				</table>
			</span>
			<br />
			<h4
				style={{
					display:
						electronicDocumentById?.claimProcessStatus ===
							Constants.ClaimProcessStatus.ChangeRequested ||
						electronicDocumentById?.claimProcessStatus ===
							Constants.ClaimProcessStatus.Processed
							? 'inherit'
							: 'none',
				}}>
				{' '}
				Резолюция / Забележки:{' '}
				{electronicDocumentById?.insuranceCompanyNotes} <br />
			</h4>
			<div>
				<h4 style={{ display: 'inline-block' }}>Подпис/Печат лекар</h4>
				<h4
					style={{
						display: 'inline-block',
						float: 'right',
						marginRight: '100px',
					}}>
					Подпис пациент
				</h4>
			</div>
		</div>
	);
};

export default observer(PrintAmbulatorySheetFile);
