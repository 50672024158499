import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { IPriceListDetail } from '../../app/models/servicesCorrelations/PriceList';
import { RootStoreContext } from '../../app/stores/rootStore';

const PrintPriceListForInsuranceCompanySheet: React.FC = () => {
	const rootStore = useContext(RootStoreContext);

	const { currentUser } = rootStore.identityStore;
	const { generatedPriceList } = rootStore.servicesCorrelationsStore;

	return (
		<div>
			<h3
				style={{
					textAlign: 'center',
				}}>
				Ценоразпис на лечебно заведение: "
				{currentUser?.medicalFacilityName}" за застрахователна компания
				"{generatedPriceList?.insuranceCompanyName}"
			</h3>

			<table
				style={{
					borderCollapse: 'collapse',
					textAlign: 'center',
					width: '100%',
					color: 'black',
					fontSize: 11,
				}}>
				<thead>
					<tr style={{ border: '1px solid ' }}>
						<th style={{ border: '1px solid ', width: '30%' }}>
							Име на услуга
						</th>
						<th style={{ border: '1px solid ', width: '15%' }}>
							Допълнителна информация
						</th>
						<th style={{ border: '1px solid ', width: '10%' }}>
							Код ЛЗ
						</th>
						<th style={{ border: '1px solid ', width: '10%' }}>
							Код ЗК
						</th>
						<th style={{ border: '1px solid ', width: '10%' }}>
							Код МКБ 9/ НЗОК
						</th>
						<th style={{ border: '1px solid ', width: '5%' }}>
							Спец.
						</th>
						<th style={{ border: '1px solid ', width: '5%' }}>
							Цена
						</th>
						<th style={{ border: '1px solid ', width: '5%' }}>
							Цена (проф.)
						</th>
					</tr>
				</thead>
				<tbody style={{ border: '1px solid ' }}>
					{generatedPriceList?.proceduresWithPrices.map(
						(record: IPriceListDetail) => (
							<tr>
								<td
									style={{
										border: '1px solid ',
										textAlign: 'left',
									}}>
									{record.procedure}
								</td>
								<td
									style={{
										border: '1px solid ',
										textAlign: 'left',
									}}>
									{record.description}
								</td>
								<td style={{ border: '1px solid ' }}>
									{record.medicalFacilityCode}
								</td>
								<td style={{ border: '1px solid ' }}>
									{record.insuranceCompanyCode}
								</td>
								<td style={{ border: '1px solid ' }}>
									{record.nhifCode}
								</td>
								<td style={{ border: '1px solid ' }}>
									{' '}
									{record.specialityCode}
								</td>
								<td
									style={{
										border: '1px solid ',
										textAlign: 'right',
									}}>
									{record.price}
								</td>
								<td
									style={{
										border: '1px solid ',
										textAlign: 'right',
									}}>
									{record.prophylacticPrice}
								</td>
							</tr>
						)
					)}
				</tbody>
			</table>
		</div>
	);
};

export default observer(PrintPriceListForInsuranceCompanySheet);
