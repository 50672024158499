import React, { useContext } from 'react';
import Moment from 'react-moment';
import { RootStoreContext } from '../../app/stores/rootStore';
import Constants from '../../app/constants/constants';
import { observer } from 'mobx-react-lite';

const PrintDocSheet: React.FC = () => {
	const rootStore = useContext(RootStoreContext);
	const { claimById, isPrintingClaimDoc } = rootStore.claimStore;
	return (
		<div>
			{isPrintingClaimDoc ? (
				<div>
					{' '}
					<h3 style={{ textAlign: 'center' }}>
						{' '}
						Иск № {claimById?.documentNumber} за период{' '}
						<Moment format={Constants.Common.ShortDate_Format}>
							{claimById?.dateFrom}
						</Moment>{' '}
						-{' '}
						<Moment format={Constants.Common.ShortDate_Format}>
							{claimById?.dateTo}
						</Moment>{' '}
						за{'  '}
						{claimById?.examinationType === '1'
							? 'Амбулаторна дейност'
							: claimById?.examinationType === '2'
							? 'МДД'
							: claimById?.examinationType === '3'
							? 'Профилактична дейност'
							: 'Болнична дейност'}{' '}
						от "{claimById?.medicalFacilityName}" с РЗИ:{' '}
						{claimById?.rziCode} към "
						{claimById?.insuranceCompanyName}"
					</h3>
					<h3 style={{ textAlign: 'center' }}>
						Разбивка по документи
					</h3>
					<br />
					{claimById?.examinationType === '3' ? (
						<table
							style={{
								borderCollapse: 'collapse',
								textAlign: 'center',
								width: '100%',
								color: 'black',
								fontSize: 11,
							}}>
							<thead>
								<tr style={{ border: '1px solid ' }}>
									<th
										style={{
											width: '10%',
											border: '1px solid ',
										}}>
										№
									</th>
									<th
										style={{
											width: '20%',
											border: '1px solid ',
										}}>
										Дата
									</th>
									<th
										style={{
											width: '10%',
											border: '1px solid ',
										}}>
										ЕГН
									</th>
									<th
										style={{
											width:
												claimById?.status ===
													Constants.ClaimProcessStatus
														.Processed ||
												claimById?.status ===
													Constants.ClaimProcessStatus
														.ChangeRequested
													? '20%'
													: '40%',
											border: '1px solid ',
										}}>
										Име пациент
									</th>
									<th
										style={{
											width: '10%',
											border: '1px solid ',
										}}>
										№ Карта
									</th>
									<th
										style={{
											width: '10%',
											border: '1px solid ',
										}}>
										Сума
									</th>
									<th
										style={{
											display:
												claimById?.status ===
													Constants.ClaimProcessStatus
														.Processed ||
												claimById?.status ===
													Constants.ClaimProcessStatus
														.ChangeRequested
													? 'table-cell'
													: 'none',
											width:
												claimById?.status ===
													Constants.ClaimProcessStatus
														.Processed ||
												claimById?.status ===
													Constants.ClaimProcessStatus
														.ChangeRequested
													? '10%'
													: '0%',
											border: '1px solid ',
										}}>
										Сума (нова)
									</th>

									<th
										style={{
											display:
												claimById?.status ===
													Constants.ClaimProcessStatus
														.Processed ||
												claimById?.status ===
													Constants.ClaimProcessStatus
														.ChangeRequested
													? 'table-cell'
													: 'none',
											width:
												claimById?.status ===
													Constants.ClaimProcessStatus
														.Processed ||
												claimById?.status ===
													Constants.ClaimProcessStatus
														.ChangeRequested
													? '10%'
													: '0%',
											border: '1px solid ',
										}}>
										Отказ. дейн.
									</th>
								</tr>
							</thead>

							<tbody style={{ border: '1px solid ' }}>
								{claimById?.electronicDocuments.map(
									(document) => (
										<tr>
											<td
												style={{
													border: '1px solid ',
													textAlign: 'center',
												}}>
												{document?.documentNumber}
											</td>
											<td
												style={{
													border: '1px solid ',
													textAlign: 'center',
												}}>
												<div>
													<Moment
														format={
															Constants.Common
																.ShortDate_Format
														}>
														{
															document?.preventionDateFrom
														}
													</Moment>{' '}
													-{' '}
													<Moment
														format={
															Constants.Common
																.ShortDate_Format
														}>
														{
															document?.preventionDateTo
														}
													</Moment>
												</div>
											</td>
											<td
												style={{
													border: '1px solid ',
													textAlign: 'center',
												}}>
												{' '}
												{document?.patientUid}{' '}
											</td>
											<td
												style={{
													border: '1px solid ',
													textAlign: 'center',
												}}>
												{' '}
												{document?.patientName}{' '}
											</td>
											<td
												style={{
													border: '1px solid ',
													textAlign: 'center',
												}}>
												{' '}
												{document?.patientCard}{' '}
											</td>
											<td
												style={{
													border: '1px solid ',
													textAlign: 'right',
												}}>
												{' '}
												{document?.totalAmount}{' '}
											</td>
											<td
												className={
													document?.claimProcessStatus ===
														Constants
															.ClaimProcessStatus
															.Processed ||
													document?.claimProcessStatus ===
														Constants
															.ClaimProcessStatus
															.ChangeRequested
														? ''
														: 'hidden-text-in-table'
												}
												style={{
													border: '1px solid black ',
													textAlign: 'right',
													fontWeight:
														document.payableAmount !==
														document.totalAmount
															? 'bold'
															: 'normal',
													color:
														document.payableAmount !==
														document.totalAmount
															? 'red'
															: 'black',
												}}>
												{' '}
												{document?.payableAmount}{' '}
											</td>

											<td
												className={
													document?.claimProcessStatus ===
														Constants
															.ClaimProcessStatus
															.Processed ||
													document?.claimProcessStatus ===
														Constants
															.ClaimProcessStatus
															.ChangeRequested
														? ''
														: 'hidden-text-in-table'
												}
												style={{
													border: '1px solid  black',
													textAlign: 'center',
													fontWeight:
														document?.details.filter(
															(v) => !v.isPayable
														).length > 0
															? 'bold'
															: 'normal',
													color:
														document?.details.filter(
															(v) => !v.isPayable
														).length > 0
															? 'red'
															: 'black',
												}}>
												{' '}
												{
													document?.details.filter(
														(v) => !v.isPayable
													).length
												}{' '}
											</td>
										</tr>
									)
								)}
							</tbody>
						</table>
					) : (
						<table
							style={{
								borderCollapse: 'collapse',
								textAlign: 'center',
								width: '100%',
								color: 'black',
								fontSize: 11,
							}}>
							<thead>
								<tr style={{ border: '1px solid ' }}>
									<th
										style={{
											width: '5%',
											border: '1px solid ',
										}}>
										№
									</th>
									<th
										style={{
											width: '8%',
											border: '1px solid ',
										}}>
										От дата
									</th>
									<th
										style={{
											width: '8%',
											border: '1px solid ',
										}}>
										Вид
									</th>
									<th
										style={{
											width: '8%',
											border: '1px solid ',
										}}>
										УИН
									</th>
									<th
										style={{
											width:
												claimById?.status ===
													Constants.ClaimProcessStatus
														.Processed ||
												claimById?.status ===
													Constants.ClaimProcessStatus
														.ChangeRequested
													? '18%'
													: '28%',
											border: '1px solid ',
										}}>
										Лекар
									</th>
									<th
										style={{
											width: '5%',
											border: '1px solid ',
										}}>
										Спец.
									</th>
									<th
										style={{
											width: '8%',
											border: '1px solid ',
										}}>
										ЕГН
									</th>
									<th
										style={{
											width: '15%',
											border: '1px solid ',
										}}>
										Име пациент
									</th>
									<th
										style={{
											width: '10%',
											border: '1px solid ',
										}}>
										№ Карта
									</th>
									<th
										style={{
											width: '5%',
											border: '1px solid ',
										}}>
										Сума
									</th>
									<th
										style={{
											display:
												claimById?.status ===
													Constants.ClaimProcessStatus
														.Processed ||
												claimById?.status ===
													Constants.ClaimProcessStatus
														.ChangeRequested
													? 'table-cell'
													: 'none',
											width:
												claimById?.status ===
													Constants.ClaimProcessStatus
														.Processed ||
												claimById?.status ===
													Constants.ClaimProcessStatus
														.ChangeRequested
													? '5%'
													: '0%',
											border: '1px solid ',
										}}>
										Сума (нова)
									</th>

									<th
										style={{
											display:
												claimById?.status ===
													Constants.ClaimProcessStatus
														.Processed ||
												claimById?.status ===
													Constants.ClaimProcessStatus
														.ChangeRequested
													? 'table-cell'
													: 'none',
											width:
												claimById?.status ===
													Constants.ClaimProcessStatus
														.Processed ||
												claimById?.status ===
													Constants.ClaimProcessStatus
														.ChangeRequested
													? '5%'
													: '0%',
											border: '1px solid ',
										}}>
										Отказ. дейн.
									</th>
								</tr>
							</thead>

							<tbody style={{ border: '1px solid ' }}>
								{claimById?.electronicDocuments.map(
									(document) => (
										<tr>
											<td
												style={{
													border: '1px solid ',
													textAlign: 'center',
												}}>
												{document?.documentNumber}
											</td>
											<td
												style={{
													border: '1px solid ',
													textAlign: 'center',
												}}>
												<Moment
													format={
														document?.documentType.toString() ===
														Constants.DocumentType
															.AmbSheet
															? Constants.Common
																	.FullDate_Format_Without_Seconds
															: Constants.Common
																	.ShortDate_Format
													}>
													{document?.documentDate}
												</Moment>
											</td>
											<td
												style={{
													border: '1px solid ',
													textAlign: 'center',
												}}>
												{' '}
												{claimById?.examinationType ===
												'1'
													? document?.isPrimaryText
													: ''}{' '}
											</td>
											<td
												style={{
													border: '1px solid ',
													textAlign: 'center',
												}}>
												{' '}
												{document?.issuerUin}{' '}
											</td>
											<td
												style={{
													border: '1px solid ',
													textAlign: 'center',
												}}>
												{' '}
												{document?.issuerName}{' '}
											</td>
											<td
												style={{
													border: '1px solid ',
													textAlign: 'center',
												}}>
												{' '}
												{
													document?.issuerSpecialityCode
												}{' '}
											</td>
											<td
												style={{
													border: '1px solid ',
													textAlign: 'center',
												}}>
												{' '}
												{document?.patientUid}{' '}
											</td>
											<td
												style={{
													border: '1px solid ',
													textAlign: 'center',
												}}>
												{' '}
												{document?.patientName}{' '}
											</td>
											<td
												style={{
													border: '1px solid ',
													textAlign: 'center',
												}}>
												{' '}
												{document?.patientCard}{' '}
											</td>
											<td
												style={{
													border: '1px solid ',
													textAlign: 'right',
												}}>
												{' '}
												{document?.totalAmount}{' '}
											</td>
											<td
												className={
													document?.claimProcessStatus ===
														Constants
															.ClaimProcessStatus
															.Processed ||
													document?.claimProcessStatus ===
														Constants
															.ClaimProcessStatus
															.ChangeRequested
														? ''
														: 'hidden-text-in-table'
												}
												style={{
													border: '1px solid black ',
													textAlign: 'right',
													fontWeight:
														document.payableAmount !==
														document.totalAmount
															? 'bold'
															: 'normal',
													color:
														document.payableAmount !==
														document.totalAmount
															? 'red'
															: 'black',
												}}>
												{' '}
												{document?.payableAmount}{' '}
											</td>

											<td
												className={
													document?.claimProcessStatus ===
														Constants
															.ClaimProcessStatus
															.Processed ||
													document?.claimProcessStatus ===
														Constants
															.ClaimProcessStatus
															.ChangeRequested
														? ''
														: 'hidden-text-in-table'
												}
												style={{
													border: '1px solid  black',
													textAlign: 'center',
													fontWeight:
														document?.details.filter(
															(v) => !v.isPayable
														).length > 0
															? 'bold'
															: 'normal',
													color:
														document?.details.filter(
															(v) => !v.isPayable
														).length > 0
															? 'red'
															: 'black',
												}}>
												{' '}
												{
													document?.details.filter(
														(v) => !v.isPayable
													).length
												}{' '}
											</td>
										</tr>
									)
								)}
							</tbody>
						</table>
					)}
					<div
						style={{
							textAlign: 'end',
							color: 'black',
						}}>
						Общо:{' '}
						{claimById?.electronicDocuments
							.map((doc) => parseFloat(doc.totalAmount))
							.reduce((a, b) => a + b)
							.toFixed(2)}{' '}
						<span
							style={{
								display:
									claimById?.status ===
										Constants.ClaimProcessStatus
											.ChangeRequested ||
									claimById?.status ===
										Constants.ClaimProcessStatus.Processed
										? 'auto'
										: 'none',
							}}>
							/ Платимо:{' '}
							{claimById?.electronicDocuments
								.map((doc) => parseFloat(doc.payableAmount))
								.reduce((a, b) => a + b)
								.toFixed(2)}{' '}
						</span>
					</div>
				</div>
			) : null}
		</div>
	);
};

export default observer(PrintDocSheet);
