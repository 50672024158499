import { Select } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { RootStoreContext } from '../../stores/rootStore';
import Constants from '../../constants/constants';
import { v4 as uuidv4 } from 'uuid';
import { DateRangePicker } from 'rsuite';

export interface IProps {
	form: FormInstance;
}

const PreventionDateAndCompanyFormItem: React.FC<IProps> = ({ form }) => {
	const rootStore = useContext(RootStoreContext);

	const { electronicDocumentById } = rootStore.electronicDocumentStore;

	const { companies } = rootStore.operationsStore;

	const {
		spliceAllFilteredServiceCorrelationsForPreventions,
		resetDetailsOriginData,
	} = rootStore.preventionsStore;

	const { Option } = Select;

	const changeDatesRange = (dates: Date[]) => {
		form.setFieldsValue({ preventionDateRange: [dates[0], dates[1]] });
	};

	return (
		<Form.Item
			label='Профилактика'
			rules={[
				{
					required: true,
				},
			]}>
			<Form.Item
				name='preventionDateRange'
				className='docDate-input-preventions'
				rules={[
					{
						required: true,
						message: Constants.FormMessages.RequiredField,
					},
				]}>
				<DateRangePicker
					placeholder={['дд.мм.гггг', ' - дд.мм.гггг']}
					value={form.getFieldValue('preventionDateRange')}
					cleanable={false}
					className='date-range-tables'
					format={Constants.Common.ShortDate_Format}
					onOk={(date: Date[]) => changeDatesRange(date)}
					locale={Constants.ReactSuiteCalendarLocale}
					size='xs'
					color='green'
					ranges={[]}
					isoWeek={true}
				/>
			</Form.Item>
			<Form.Item
				name='companyName'
				className='preventions-ins-comp-input'>
				<Select
					onChange={() => {
						if (
							rootStore.preventionsStore
								.filteredServiceCorelationsForPreventions !==
							undefined
						) {
							resetDetailsOriginData();
							spliceAllFilteredServiceCorrelationsForPreventions();
						}
					}}
					showSearch
					optionFilterProp='title'
					placeholder='Застрахователна компания'
					disabled={
						(electronicDocumentById &&
							electronicDocumentById?.isActive === false) ||
						electronicDocumentById?.documentStatus !== undefined
					}>
					{companies?.map((company) => (
						<Option
							key={uuidv4()}
							value={company.id}
							title={company.name}>
							{company.name}
						</Option>
					))}
				</Select>
			</Form.Item>
		</Form.Item>
	);
};

export default observer(PreventionDateAndCompanyFormItem);
