import React, { useContext } from 'react';
import { Form, Input } from 'antd';
import { RootStoreContext } from '../../stores/rootStore';
import { observer } from 'mobx-react-lite';
import Constants from '../../constants/constants';
import { FormInstance } from 'antd/lib/form';

export interface IProps {
	form: FormInstance;
}

const MedicalNotesFormItem: React.FC<IProps> = ({ form }) => {
	const rootStore = useContext(RootStoreContext);
	const { electronicDocumentById } = rootStore.electronicDocumentStore;
	const isFormEditingDisabled =
		electronicDocumentById?.documentStatus &&
		(electronicDocumentById?.documentStatus ===
			Constants.DocumentProcessStatus.Approved ||
			electronicDocumentById?.documentStatus ===
				Constants.DocumentProcessStatus.Rejected ||
			electronicDocumentById?.documentStatus ===
				Constants.DocumentProcessStatus.Open);
	return (
		<Form.Item name='medicalNotes' label='Пояснения:'>
			<Input.TextArea
				disabled={
					electronicDocumentById?.status ===
						Constants.MedicalReferralStatus.Performed ||
					electronicDocumentById?.isActive === false ||
					isFormEditingDisabled
				}
			/>
		</Form.Item>
	);
};

export default observer(MedicalNotesFormItem);
