import { observer } from 'mobx-react-lite';
import React, { useContext, useState } from 'react';
import { RootStoreContext } from '../../app/stores/rootStore';
import Constants from '../../app/constants/constants';
import Moment from 'react-moment';
import { Button, Modal } from 'antd';
import { IDownloadAttachment } from '../models/electronicDocument/DownloadAttachment';
import ElectonicDocumentPreveiw from './ElectonicDocumentPreveiw';
import { FileSearchOutlined } from '@ant-design/icons';

const AmbSheetSubPreview: React.FC = () => {
	const rootStore = useContext(RootStoreContext);
	const {
		electronicSubDocumentById,
		downloadAttachment,
		getElectronicSubDocumentById,
	} = rootStore.electronicDocumentStore;

	const [show, setShow] = useState(false);

	const preview = async (id: number) => {
		if (id) {
			await getElectronicSubDocumentById(id);
		}

		setShow(true);
	};

	const download = async (
		patientUid: string,
		fileName: string,
		documentType: string
	) => {
		let model: IDownloadAttachment = {
			documentType: documentType,
			patientUid: patientUid,
			fileName: fileName,
		};

		await downloadAttachment(model);

		if (rootStore.electronicDocumentStore.attachmentDownloadResult) {
			const url = window.URL.createObjectURL(
				new Blob([
					rootStore.electronicDocumentStore.attachmentDownloadResult!,
				])
			);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `${model.documentType}.pdf`);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	};

	return (
		<div style={{ margin: '10px', color: 'black', fontFamily: 'Calibri' }}>
			<h4
				style={{
					textAlign: 'center',
				}}>
				{electronicSubDocumentById?.medicalFacilityIssuerName}{' '}
			</h4>
			<h4
				style={{
					textAlign: 'center',
				}}>
				{' '}
				{electronicSubDocumentById?.medicalFacilityIssuerCity}
			</h4>
			<h4
				style={{
					textAlign: 'center',
				}}>
				{' '}
				РЗИ: {electronicSubDocumentById?.medicalFacilityIssuerRziCode}
			</h4>
			<h3 style={{ fontWeight: 'bolder' }}>
				{electronicSubDocumentById?.documentType} №{' '}
				{electronicSubDocumentById?.documentNumber}{' '}
				<div style={{ float: 'right', display: 'inline-block' }}>
					дата:{' '}
					<Moment
						format={
							Constants.Common.FullDate_Format_Without_Seconds
						}>
						{electronicSubDocumentById?.documentDate}
					</Moment>{' '}
				</div>
			</h3>

			<h3
				style={{
					display:
						electronicSubDocumentById?.documentType.toString() ===
						Constants.DocumentType.DeseaseHistory
							? 'none'
							: '',
				}}>
				Тип:{' '}
				{electronicSubDocumentById?.isPrimary === false
					? 'Вторичен'
					: 'Първичен'}{' '}
			</h3>
			<h3>
				Застрахователна компания: "
				{electronicSubDocumentById?.insuranceCompanyName}"
			</h3>
			<div>
				<div
					style={{
						width: '49%',
						display: ' inline-block',
						border: '1px solid',
						margin: 5,
						marginLeft: 0,
						padding: 5,
						verticalAlign: 'top',
						minHeight: 170,
					}}>
					<div>Пациент: </div>
					<h4 style={{ marginLeft: 10, fontWeight: 'bolder' }}>
						ЕГН: {electronicSubDocumentById?.patientUid}{' '}
					</h4>
					<h4 style={{ marginLeft: 10, fontWeight: 'bolder' }}>
						Име: {electronicSubDocumentById?.patientName}
					</h4>
					<h4 style={{ marginLeft: 10, fontWeight: 'bolder' }}>
						Номер на карта: {electronicSubDocumentById?.patientCard}
					</h4>
				</div>
				<div
					style={{
						width: '49%',
						float: 'right',
						display: ' inline-block',
						border: '1px solid',
						margin: 5,
						marginRight: 0,
						padding: 5,
						verticalAlign: 'top',
						minHeight: 170,
					}}>
					<div>Лекар: </div>
					<h4 style={{ marginLeft: 10, fontWeight: 'bolder' }}>
						УИН: {electronicSubDocumentById?.issuerUin}{' '}
					</h4>
					<h4 style={{ marginLeft: 10, fontWeight: 'bolder' }}>
						Име: {electronicSubDocumentById?.issuerName}
					</h4>
					<h4 style={{ marginLeft: 10, fontWeight: 'bolder' }}>
						Специалност:{' '}
						{electronicSubDocumentById?.issuerSpecialityCode} -{' '}
						{electronicSubDocumentById?.issuerSpecialityName}
					</h4>
				</div>
			</div>
			<br />
			<div style={{ fontSize: 16 }}>
				<span
					style={{
						fontWeight: 'bolder',
						fontStyle: 'italic',
						fontSize: 16,
					}}>
					Основна диагноза:
				</span>{' '}
				{electronicSubDocumentById?.mkbCode} -{' '}
				{electronicSubDocumentById?.mkbName}{' '}
			</div>
			<br />

			<div>
				<div
					style={{
						width: '100%',
						border: '1px solid',
						marginBottom: '3px',
						padding: 5,
					}}>
					<div
						style={{
							fontWeight: 'bolder',
							fontStyle: 'italic',
							fontSize: 12,
						}}>
						Анамнеза
					</div>
					<div>
						{electronicSubDocumentById?.patientMedicalHistory}
					</div>
				</div>
				<br />
			</div>

			<div>
				<div
					style={{
						width: '100%',
						border: '1px solid',
						marginBottom: '3px',
						padding: 5,
					}}>
					<div
						style={{
							fontWeight: 'bolder',
							fontStyle: 'italic',
							fontSize: 12,
						}}>
						Обективно състояние
					</div>
					<div>{electronicSubDocumentById?.patientHealthStatus}</div>
				</div>
				<br />
			</div>

			<div
				style={{
					display: electronicSubDocumentById?.patientExaminations
						? 'block'
						: 'none',
				}}>
				<div
					style={{
						width: '100%',
						border: '1px solid',
						marginBottom: '3px',
						padding: 5,
					}}>
					<div
						style={{
							fontWeight: 'bolder',
							fontStyle: 'italic',
							fontSize: 12,
						}}>
						Изследвания
					</div>
					<div>{electronicSubDocumentById?.patientExaminations}</div>
				</div>
				<br />
			</div>

			<div
				style={{
					display: electronicSubDocumentById?.patientTherapy
						? 'block'
						: 'none',
				}}>
				<div
					style={{
						width: '100%',
						border: '1px solid',
						marginBottom: '3px',
						padding: 5,
					}}>
					<div
						style={{
							fontWeight: 'bolder',
							fontStyle: 'italic',
							fontSize: 12,
						}}>
						Терапия
					</div>
					<div>{electronicSubDocumentById?.patientTherapy}</div>
				</div>
				<br />
			</div>

			<h5> Извършени дейности: </h5>
			<table
				style={{
					borderCollapse: 'collapse',
					textAlign: 'center',
					width: '100%',
					color: 'black',
					fontSize: 12,
				}}>
				<thead>
					<tr style={{ border: '1px solid ' }}>
						<th style={{ border: '1px solid ', width: '10%' }}>
							{' '}
							Код по МКБ 9КМ / НЗОК{' '}
						</th>
						<th
							style={{
								border: '1px solid ',
								color:
									electronicSubDocumentById?.claimProcessStatus ===
										Constants.ClaimProcessStatus
											.Processed ||
									electronicSubDocumentById?.claimProcessStatus ===
										Constants.ClaimProcessStatus
											.ChangeRequested
										? '5%'
										: '10%',
							}}>
							{' '}
							Код ЛЗ{' '}
						</th>
						<th style={{ border: '1px solid ', width: '35%' }}>
							{' '}
							Дейност{' '}
						</th>
						<th style={{ border: '1px solid ', width: '30%' }}>
							{' '}
							Допълнителна информация{' '}
						</th>

						<th style={{ border: '1px solid ', width: '5%' }}>
							{' '}
							Брой
						</th>
						<th style={{ border: '1px solid ', width: '5%' }}>
							{' '}
							Ед.цена{' '}
						</th>
						<th style={{ border: '1px solid ', width: '5%' }}>
							{' '}
							Сума{' '}
						</th>
						<th
							style={{
								display:
									electronicSubDocumentById?.claimProcessStatus ===
										Constants.ClaimProcessStatus
											.Processed ||
									electronicSubDocumentById?.claimProcessStatus ===
										Constants.ClaimProcessStatus
											.ChangeRequested
										? 'table-cell'
										: 'none',
								border: '1px solid ',
								width:
									electronicSubDocumentById?.claimProcessStatus ===
										Constants.ClaimProcessStatus
											.Processed ||
									electronicSubDocumentById?.claimProcessStatus ===
										Constants.ClaimProcessStatus
											.ChangeRequested
										? '5%'
										: '0%',
							}}>
							{' '}
						</th>
					</tr>
				</thead>
				<tbody style={{ border: '1px solid ' }}>
					{electronicSubDocumentById?.details.map((detail) => (
						<tr>
							<td
								style={{
									border: '1px solid ',
									textAlign: 'center',
								}}>
								{detail.nhifCode}
							</td>
							<td
								style={{
									border: '1px solid ',
									textAlign: 'center',
								}}>
								{detail.code}
							</td>
							<td
								style={{
									border: '1px solid ',
									textAlign: 'left',
								}}>
								{detail.name}
							</td>
							<td style={{ border: '1px solid ' }}>
								{' '}
								{detail.description}{' '}
							</td>

							<td style={{ border: '1px solid ' }}>
								{' '}
								{detail.count}{' '}
							</td>
							<td
								style={{
									border: '1px solid ',
									textAlign: 'right',
								}}>
								{' '}
								{detail.price}{' '}
							</td>
							<td
								style={{
									border: '1px solid ',
									textAlign: 'right',
								}}>
								{' '}
								{detail.totalAmount}{' '}
							</td>
							<td
								style={{
									display:
										electronicSubDocumentById?.claimProcessStatus ===
											Constants.ClaimProcessStatus
												.Processed ||
										electronicSubDocumentById?.claimProcessStatus ===
											Constants.ClaimProcessStatus
												.ChangeRequested
											? 'table-cell'
											: 'none',
									border: '1px solid black ',
									textAlign: 'center',
									color: 'red',
								}}>
								{' '}
								{detail.isPayable ? '' : ' Отказана'}{' '}
							</td>
						</tr>
					))}
				</tbody>
			</table>
			<div style={{ textAlign: 'end' }}>
				Общо:{' '}
				{electronicSubDocumentById?.details
					.map((detail) => parseFloat(detail.totalAmount))
					.reduce((a, b) => a + b)
					.toFixed(2)}{' '}
				<span
					style={{
						display:
							electronicSubDocumentById?.claimProcessStatus ===
								Constants.ClaimProcessStatus.ChangeRequested ||
							electronicSubDocumentById?.claimProcessStatus ===
								Constants.ClaimProcessStatus.Processed
								? 'auto'
								: 'none',
					}}>
					{' '}
					/ Платимо: {electronicSubDocumentById?.payableAmount}{' '}
				</span>
			</div>
			<br />
			<span
				style={{
					display:
						electronicSubDocumentById?.attachments.length === 0
							? 'none'
							: 'inherit',
				}}>
				<h5>Други документи: </h5>
				<table
					style={{
						borderCollapse: 'collapse',
						textAlign: 'center',
						width: '100%',
						color: 'black',
						fontSize: 12,
					}}>
					<thead>
						<tr style={{ border: '1px solid ' }}>
							<th
								style={{
									border: '1px solid ',
									width: '15%',
								}}>
								{' '}
								Тип на документ{' '}
							</th>
							<th
								style={{
									border: '1px solid ',
									width: '30%',
								}}>
								{' '}
								Съдържание тип{' '}
							</th>
							<th
								style={{
									border: '1px solid ',
									width: '10%',
								}}>
								{' '}
								Номер на документ{' '}
							</th>
							<th
								style={{
									border: '1px solid ',
									width: '10%',
								}}>
								{' '}
								Дата на документ{' '}
							</th>
							<th
								style={{
									border: '1px solid ',
									width: '25%',
								}}>
								Допълнителна информация / Лекар
							</th>
							<th
								style={{
									border: '1px solid ',
									width: '10%',
								}}>
								Запазване
							</th>
						</tr>
					</thead>
					<tbody style={{ border: '1px solid ' }}>
						{electronicSubDocumentById?.attachments.map(
							(attachment) => (
								<tr>
									<td
										style={{
											border: '1px solid ',
											textAlign: 'left',
										}}>
										{attachment.attachmentType}
									</td>
									<td
										style={{
											border: '1px solid ',
											textAlign: 'left',
										}}>
										{attachment.documentType}
									</td>
									<td
										style={{
											border: '1px solid ',
											textAlign: 'center',
										}}>
										{attachment.eDocumentNumber}
									</td>
									<td
										style={{
											border: '1px solid ',
											textAlign: 'center',
										}}>
										{attachment.eDocumentDate !== null ? (
											<Moment
												format={
													Constants.Common
														.ShortDate_Format
												}>
												{attachment.eDocumentDate}
											</Moment>
										) : (
											''
										)}
									</td>
									<td
										style={{
											border: '1px solid ',
											textAlign: 'left',
										}}>
										{attachment.description
											? attachment.description
											: attachment.eDocumentDoctorName}
									</td>
									<td
										style={{
											border: '1px solid ',
											textAlign: 'center',
										}}>
										<Button
											type='link'
											onClick={() =>
												attachment.attachmentType ===
												Constants.AttachmentType
													.PhysicalCopy
													? download(
															electronicSubDocumentById?.patientUid,
															attachment.fileName,
															attachment.documentType
													  )
													: preview(
															attachment!
																.toAttachmentId
													  )
											}>
											<FileSearchOutlined
												style={{
													color: '#e48800',
													fontSize: 20,
													fontWeight: 700,
												}}
											/>
										</Button>
									</td>
								</tr>
							)
						)}
					</tbody>
				</table>
			</span>
			<span
				style={{
					display:
						electronicSubDocumentById?.claimProcessStatus ===
							Constants.ClaimProcessStatus.ChangeRequested ||
						electronicSubDocumentById?.claimProcessStatus ===
							Constants.ClaimProcessStatus.Processed
							? 'auto'
							: 'none',
				}}>
				<br />
				<h5>
					Резолюция / Забележки:{' '}
					{electronicSubDocumentById.insuranceCompanyNotes}
				</h5>{' '}
			</span>
			<Modal
				className='appointment-modal'
				title={'Преглед на електронен документ'}
				centered
				maskClosable={false}
				transitionName='none'
				maskTransitionName='none'
				onCancel={() => setShow(false)}
				visible={show}
				footer={false}
				destroyOnClose={true}>
				{' '}
				<ElectonicDocumentPreveiw />
			</Modal>
		</div>
	);
};

export default observer(AmbSheetSubPreview);
