import React from 'react';
import CreateReferralTable from './CreateReferralTable';
import { observer } from 'mobx-react-lite';

const CreateReferral: React.FC = () => {
	return (
		<div>
			<CreateReferralTable />
		</div>
	);
};
export default observer(CreateReferral);
