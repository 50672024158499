import { Checkbox, Row, Col, Form, Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { Fragment, useContext } from 'react';
import { RootStoreContext } from '../../app/stores/rootStore';
const CommonSettings: React.FC = () => {
	const rootStore = useContext(RootStoreContext);

	const {
		settings,
		updateShowAmbulatorySheets,
		updateShowHospitalityComponent,
		updateShowPreventionsComponent,
		updateShowMdd,
		updateShowServicesFromShortListOnly,
		updateShowMddFromShortListOnly,
		updateShowPreventionFromShortListOnly,
		settingsLoading,
	} = rootStore.settingsStore;

	return (
		<Fragment>
			<Form>
				<Row>
					<Col xs={24} sm={24} md={24} lg={16} xl={16}>
						<Typography.Title level={4}>
							Настройки документи
						</Typography.Title>
						<Row>
							<Col xs={6} sm={6} md={6} lg={3} xl={3}>
								<Checkbox
									checked={settings?.showMddComponent}
									disabled={settingsLoading}
									onChange={async (event: any) =>
										await updateShowMdd(
											event.target.checked
										)
									}>
									МДД
								</Checkbox>
							</Col>
							<Col xs={10} sm={10} md={10} lg={7} xl={7}>
								<Checkbox
									checked={
										settings?.showAmbulatorySheetsComponent
									}
									disabled={settingsLoading}
									onChange={async (event: any) =>
										await updateShowAmbulatorySheets(
											event.target.checked
										)
									}>
									Амбулаторни листове
								</Checkbox>
							</Col>
							<Col xs={8} sm={8} md={8} lg={5} xl={5}>
								<Checkbox
									checked={settings?.showPreventionsComponent}
									disabled={settingsLoading}
									onChange={async (event: any) =>
										await updateShowPreventionsComponent(
											event.target.checked
										)
									}>
									Профилактика
								</Checkbox>
							</Col>
							<Col xs={8} sm={8} md={8} lg={7} xl={7}>
								<Checkbox
									checked={settings?.showHospitalityComponent}
									disabled={settingsLoading}
									onChange={async (event: any) =>
										await updateShowHospitalityComponent(
											event.target.checked
										)
									}>
									Болнична дейност
								</Checkbox>
							</Col>
						</Row>
					</Col>
					<Col xs={24} sm={24} md={24} lg={8} xl={8}>
						<Typography.Title level={4}>
							Настройки услуги
						</Typography.Title>
						<Row>
							<Col xs={12} sm={12} md={12} lg={12} xl={12}>
								<Checkbox
									checked={
										settings?.showServicesFromShortListOnly
									}
									disabled={settingsLoading}
									onChange={async (event: any) =>
										await updateShowServicesFromShortListOnly(
											event.target.checked
										)
									}>
									Процедури само от списък
								</Checkbox>
							</Col>
							<Col xs={12} sm={12} md={12} lg={12} xl={12}>
								<Checkbox
									checked={settings?.showMddFromShortListOnly}
									disabled={settingsLoading}
									onChange={async (event: any) =>
										await updateShowMddFromShortListOnly(
											event.target.checked
										)
									}>
									МДД само от списък
								</Checkbox>
							</Col>
						</Row>
						<Row>
							<Col xs={16} sm={16} md={16} lg={16} xl={16}>
								<Checkbox
									checked={
										settings?.showPreventionFromShortListOnly
									}
									disabled={settingsLoading}
									onChange={async (event: any) =>
										await updateShowPreventionFromShortListOnly(
											event.target.checked
										)
									}>
									Профилактика само от списък
								</Checkbox>
							</Col>
						</Row>
					</Col>
				</Row>
			</Form>
		</Fragment>
	);
};

export default observer(CommonSettings);
