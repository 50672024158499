import React, { Fragment, useContext, useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Form, Input, Button, Select } from 'antd';
import { Picker, List } from 'antd-mobile';
import { RootStoreContext } from '../../app/stores/rootStore';
import { v4 as uuidv4 } from 'uuid';
import Constants from '../../app/constants/constants';

const InsuranceStatusForm: React.FC = () => {
	const [form] = Form.useForm();
	const { Option } = Select;
	const [isMobile, setIsMobile] = useState(false);

	const handleWindowResize = () => {
		setIsMobile(window.innerWidth < 770);
	};

	const rootStore = useContext(RootStoreContext);

	const { createRequest } = rootStore.insuranceStatusStore;

	const { companies } = rootStore.operationsStore;

	useEffect(() => {
		handleWindowResize();
		window.addEventListener('resize', handleWindowResize);
	}, []);

	const dataCompanyes = companies?.map((p) => ({
		value: p.id,
		label: p.name,
		children: [],
	}));

	return (
		<Fragment>
			<Form
				layout='vertical'
				form={form}
				initialValues={{ remember: true }}
				onFinish={(value: any) => {
					if (Array.isArray(value.companyId)) {
						value.companyId = value.companyId[0];
					}

					createRequest(value).then(() => form.resetFields());
				}}>
				<Form.Item
					name='companyId'
					label='Изберете застрахователно дружество'
					rules={[
						{
							required: true,
							message: Constants.FormMessages.RequiredField,
						},
					]}>
					{!isMobile ? (
						<Select>
							{companies?.map((company) => (
								<Option key={uuidv4()} value={company.id}>
									{company.name}
								</Option>
							))}
						</Select>
					) : (
						<Picker
							data={dataCompanyes!}
							cols={1}
							extra='Избери'
							okText='Избери'
							dismissText=' Обратно'>
							<List.Item
								key={uuidv4()}
								arrow='horizontal'
								extra=''>
								{' '}
							</List.Item>
						</Picker>
					)}
				</Form.Item>
				<Form.Item
					label='ЕГН'
					name='patientUid'
					rules={[
						{
							required: true,
							message: Constants.FormMessages.RequiredField,
						},
						({ getFieldValue }) => ({
							validator(rule, value) {
								const expression = new RegExp('^[0-9]*$');
								if (expression.test(value)) {
									return Promise.resolve();
								}
								return Promise.reject(
									'EГН-то трябва да е 10 цифри!'
								);
							},
						}),
						{ min: 10, message: ' EГН-то трябва да е 10 цифри! ' },
						{ max: 10, message: ' EГН-то трябва да е 10 цифри! ' },
					]}>
					<Input type='text' />
				</Form.Item>
				<Form.Item
					label='Номер на карта'
					name='patientCardNumber'
					rules={[
						{
							required: true,
							message: Constants.FormMessages.RequiredField,
						},
					]}>
					<Input type='text' />
				</Form.Item>
				<Form.Item>
					<Button
						className='btn-primary'
						type='primary'
						htmlType='submit'>
						Изпрати запитване
					</Button>
				</Form.Item>
			</Form>
		</Fragment>
	);
};
export default observer(InsuranceStatusForm);
