import { Input, Select } from 'antd';
import { Form } from 'antd';
import { toJS } from 'mobx';
import React, { useContext } from 'react';
import { IServicePrice } from '../../app/models/servicesCorrelations/ServicePrice';
import { RootStoreContext } from '../../app/stores/rootStore';
import { v4 as uuidv4 } from 'uuid';
import { observer } from 'mobx-react-lite';
import { FormInstance } from 'antd/lib/form';

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
	editing: boolean;
	dataIndex: string;
	title: any;
	inputType: 'number' | 'text';
	record: IServicePrice;
	index: number;
	children: React.ReactNode;
	form: FormInstance;
}

const PriceEditableCell: React.FC<EditableCellProps> = ({
	editing,
	dataIndex,
	title,
	inputType,
	record,
	index,
	children,
	form,
	...restProps
}) => {
	const rootStore = useContext(RootStoreContext);
	const {
		priceListForCurrentSelectedServiceCorrelation,
		setPriceListForCurrentSelectedServiceCorrelation,
	} = rootStore.servicesCorrelationsStore;

	const { companies, specialities } = rootStore.operationsStore;

	const { Option } = Select;

	const onInsuranceCompanyChange = (value: any, option: any) => {
		let newData = toJS(priceListForCurrentSelectedServiceCorrelation);
		let currentElementIndex = newData!.findIndex(
			(el) => el.key === record.key
		);

		if (value) {
			let title = option.props.title;
			newData![currentElementIndex].insuranceCompanyId = value;
			newData![currentElementIndex].insuranceCompanyName = title;
		} else {
			newData![currentElementIndex].insuranceCompanyId = null;
			newData![currentElementIndex].insuranceCompanyName = '';
		}

		setPriceListForCurrentSelectedServiceCorrelation(newData);
	};

	const onSpecialityChange = (value: any, option: any) => {
		let newData = toJS(priceListForCurrentSelectedServiceCorrelation);
		let currentElementIndex = newData!.findIndex(
			(el) => el.key === record.key
		);

		if (value) {
			let title = option.props.title;
			newData![currentElementIndex].specialityCode = value;
			newData![currentElementIndex].specialityName = title;
		} else {
			newData![currentElementIndex].specialityCode = '';
			newData![currentElementIndex].specialityName = '';
		}

		setPriceListForCurrentSelectedServiceCorrelation(newData);
	};

	const onSpecialityClear = () => {
		let newData = toJS(priceListForCurrentSelectedServiceCorrelation);
		let currentElementIndex = newData!.findIndex(
			(el) => el.key === record.key
		);

		newData![currentElementIndex].specialityCode = '';
		newData![currentElementIndex].specialityName = '';

		setPriceListForCurrentSelectedServiceCorrelation(newData);
	};

	const inputNode =
		dataIndex === 'insuranceCompanyName' ? (
			<Form.Item
				name={dataIndex}
				rules={[
					{
						required: true,
						message: ' ',
					},
				]}>
				<Select
					autoFocus={true}
					showSearch
					optionFilterProp='title'
					onChange={(value, option) =>
						onInsuranceCompanyChange(value, option)
					}>
					{companies?.map((company) => (
						<Option
							key={uuidv4()}
							value={company.id}
							title={company.name}>
							{company.name}
						</Option>
					))}
				</Select>
			</Form.Item>
		) : dataIndex === 'specialityName' ? (
			<Form.Item name={dataIndex}>
				<Select
					showSearch
					allowClear={true}
					onClear={() => onSpecialityClear()}
					optionFilterProp='title'
					onSelect={(value, option) =>
						onSpecialityChange(value, option)
					}>
					{specialities?.map((speciality) => (
						<Option
							key={uuidv4()}
							value={speciality.code}
							title={`${speciality.code} - ${speciality.name}`}>
							{speciality.code} - {speciality.name}
						</Option>
					))}
				</Select>
			</Form.Item>
		) : dataIndex === 'price' ? (
			<Form.Item
				name={dataIndex}
				rules={[
					{
						required:
							form.getFieldValue('prophylacticPrice') ===
							undefined
								? true
								: false,
						message: ' ',
					},
					{
						pattern: new RegExp('[0-9]+(\\.[0-9][0-9]?)?'),
						message: 'Формат: 0.00',
					},
				]}>
				<Input
					pattern={'[0-9]+(\\.[0-9][0-9]?)?'}
					className='priceField'
					placeholder='0.00'
				/>
			</Form.Item>
		) : dataIndex === 'code' ? (
			<Form.Item name={dataIndex}>
				<Input />
			</Form.Item>
		) : dataIndex === 'insuranceCompanyCode' ? (
			<Form.Item name={dataIndex}>
				<Input />
			</Form.Item>
		) : dataIndex === 'prophylacticPrice' ? (
			<Form.Item
				name={dataIndex}
				rules={[
					{
						pattern: new RegExp('[0-9]+(\\.[0-9][0-9]?)?'),
						message: 'Формат: 0.00',
					},
				]}>
				<Input
					pattern={'[0-9]+(\\.[0-9][0-9]?)?'}
					className='priceField'
					placeholder='0.00'
				/>
			</Form.Item>
		) : null;

	return (
		<td {...restProps}>
			{editing ? (
				<Form.Item name={dataIndex} style={{ margin: 0 }}>
					{inputNode}
				</Form.Item>
			) : (
				children
			)}
		</td>
	);
};

export default observer(PriceEditableCell);
