import React, { Fragment, useContext, useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { Form, Button, Spin, notification } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import { RootStoreContext } from '../../app/stores/rootStore';
import PatientFormItem from '../../app/layout/FormItems/PatientFormItem';
import MkbsFormItem from '../../app/layout/FormItems/MkbsFormItem';
import DetailsFormItem from '../../app/layout/FormItems/DetailsFormItem';
import AttachmentsFormItem from '../../app/layout/FormItems/AttachmentsFormItem';
import MedicalNotesFormItem from '../../app/layout/FormItems/MedicalNotesFormItem';
import IsActiveFormItem from '../../app/layout/FormItems/IsActiveFormItem';
import PrintElectronicDocument from '../print/PrintElectronicDocument';
import { useReactToPrint } from 'react-to-print';
import {
	IAttachment,
	IDetail,
} from '../../app/models/electronicDocument/ElectronicDocument';
import Enums from '../../app/constants/enums';
import { toJS } from 'mobx';
import IsImportantFormItem from '../../app/layout/FormItems/IsImportantFormItem';
import Constants from '../../app/constants/constants';
import Messanger from '../../app/layout/Messanger/Messanger';
import InsuranceCompanyNameFormItem from '../../app/layout/FormItems/InsuranceCompanyNameFormItem';

const DocumentTypeEnum = Enums.DocumentTypeEnum;

interface IProps {
	closeModal: () => Promise<void>;
	openModal: (id: number | undefined) => Promise<void>;
}

const PriorApprovalModal: React.FC<IProps> = ({ closeModal, openModal }) => {
	const [form] = Form.useForm();

	const rootStore = useContext(RootStoreContext);

	const { getBackInOpenMode } = rootStore.priorApprovalStore;
	const {
		electronicDocumentById,
		setIsFormTouched,
		createElectronicDocument,
		editElectronicDocument,
		detailsOriginData,
		attachmentsOriginData,
		setDetailsOriginDataFromElectronicDocument,
		setAttachmentsOriginDataFromElectronicDocument,
	} = rootStore.electronicDocumentStore;

	const defaultValues = {
		patientUid: electronicDocumentById?.patientUid,
		patientName: electronicDocumentById?.patientName,
		pacientEmail: electronicDocumentById?.pacientEmail,
		companyName: electronicDocumentById
			? parseInt(electronicDocumentById?.insuranceCompanyId)
			: undefined,
		mkbCode: electronicDocumentById?.mkbCode,
		mkbName: electronicDocumentById
			? electronicDocumentById!.mkbCode
			: undefined,
		details: electronicDocumentById?.details,
		attachments: electronicDocumentById?.attachments,
		medicalNotes: electronicDocumentById?.medicalNotes,
		isActive: electronicDocumentById?.isActive,
		id: electronicDocumentById?.id,
		isImportant: electronicDocumentById?.isImportant,
	};

	const prepareAndSubmitForm = async (
		values: any,
		detailsOriginData: IDetail[],
		attachmentsOriginData: IAttachment[]
	) => {
		const { ...electronicDocumentById } = values;

		if (
			(electronicDocumentById.isImportant &&
				!electronicDocumentById.medicalNotes) ||
			(electronicDocumentById.isImportant &&
				electronicDocumentById.medicalNotes &&
				electronicDocumentById.medicalNotes.length === 0)
		) {
			notification.error({
				message:
					'Докуметът е отбелязан като спешен. Моля, попълнете допълнителни бележки!',
				className: 'success-messages',
				duration: 5,
			});

			return;
		}

		if (!electronicDocumentById.id) {
			let newApproval = {
				...electronicDocumentById,
			};
			values.details = [] as IDetail[];
			values.attachments = [] as IAttachment[];

			detailsOriginData.forEach((el) => {
				values.details.push(el);
			});

			attachmentsOriginData.forEach((el) => {
				values.attachments.push(el);
			});

			newApproval.documentType = Enums.DocumentTypeEnum.PriorApproval;
			newApproval.details = values.details;
			newApproval.attachments = values.attachments;

			let formData = new FormData();
			formData.set('request', JSON.stringify(newApproval));
			newApproval.attachments.forEach((element: IAttachment) => {
				formData.append(`files`, element.file!);
			});

			try {
				let createdRecordId = await createElectronicDocument(
					formData,
					DocumentTypeEnum.PriorApproval
				);
				await rootStore.electronicDocumentStore.getElectronicDocumentById(
					createdRecordId
				);

				form.setFieldsValue(
					rootStore.electronicDocumentStore.electronicDocumentById!
				);

				rootStore.electronicDocumentStore.setIsFormTouched(false);
				rootStore.electronicDocumentStore.setGetElDocsAgain(true);
			} catch (error) {
				rootStore.electronicDocumentStore.setIsFormTouched(true);
			}
		} else {
			let newApproval = {
				...electronicDocumentById,
			};
			values.details = [] as IDetail[];
			values.attachments = [] as IAttachment[];

			detailsOriginData.forEach((el) => {
				values.details.push(toJS(el));
			});
			attachmentsOriginData.forEach((el) => {
				values.attachments.push(toJS(el));
			});

			newApproval.details = values.details;
			newApproval.attachments = values.attachments;

			if (Number.isInteger(newApproval.isActive)) {
				newApproval.isActive =
					parseInt(newApproval.isActive.toString()) === 1
						? true
						: false;
			}

			try {
				await editElectronicDocument(
					newApproval!,
					DocumentTypeEnum.PriorApproval
				);
				await rootStore.electronicDocumentStore.getElectronicDocumentById(
					newApproval?.id
				);

				form.setFieldsValue(
					rootStore.electronicDocumentStore.electronicDocumentById!
				);

				rootStore.electronicDocumentStore.setIsFormTouched(false);
				rootStore.electronicDocumentStore.setGetElDocsAgain(true);
			} catch (error) {
				rootStore.electronicDocumentStore.setIsFormTouched(false);
			}
		}
	};

	const getBackInOpenModeAction = async (id: number, message: string) => {
		await getBackInOpenMode(id, message);
		await rootStore.electronicDocumentStore.getElectronicDocumentById(
			electronicDocumentById?.id
		);
		rootStore.electronicDocumentStore.setGetElDocsAgain(true);
	};

	const onFormFieldsChange = () => {
		setIsFormTouched(true);
	};

	useEffect(() => {
		if (!detailsOriginData) {
			setDetailsOriginDataFromElectronicDocument(electronicDocumentById);
		}
		if (!attachmentsOriginData) {
			setAttachmentsOriginDataFromElectronicDocument(
				electronicDocumentById
			);
		}
	}, [detailsOriginData, attachmentsOriginData]);

	const componentRef = useRef(null);
	const handlePrint = useReactToPrint({
		content: () => componentRef.current!,
	});

	return (
		<Fragment>
			<Spin
				tip={'Зареждане'}
				spinning={rootStore.loadersStore.loadingModal}>
				<Form
					form={form}
					initialValues={defaultValues}
					onFieldsChange={onFormFieldsChange}
					onFinish={(values) =>
						prepareAndSubmitForm(
							values,
							detailsOriginData!,
							attachmentsOriginData!
						)
					}>
					<div
						style={{
							display: electronicDocumentById?.documentStatus
								? 'inherit'
								: 'none',
							fontSize: 17,
						}}>
						Актуален статус:{' '}
						<span
							style={{
								color:
									electronicDocumentById?.documentStatus ===
									Constants.DocumentProcessStatus.Open
										? 'blue'
										: electronicDocumentById?.documentStatus ===
										  Constants.DocumentProcessStatus
												.OpenForModification
										? '#FF5722'
										: electronicDocumentById?.documentStatus ===
										  Constants.DocumentProcessStatus
												.Approved
										? 'green'
										: electronicDocumentById?.documentStatus ===
												Constants.DocumentProcessStatus
													.Rejected ||
										  !electronicDocumentById?.isActive
										? 'red'
										: 'inherit',
							}}>
							{!electronicDocumentById?.isActive
								? 'Неактивен'
								: electronicDocumentById?.documentStatus}
						</span>
					</div>

					<h3
						style={{
							display: electronicDocumentById?.insuranceCompanyNotes
								? 'inherit'
								: 'none',
							color: 'black',
						}}>
						{' '}
						Резолюция от застрахователя:{' '}
						<span
							style={{
								color:
									electronicDocumentById?.documentStatus ===
									Constants.DocumentProcessStatus.Rejected
										? 'red'
										: 'inherit',
							}}>
							{electronicDocumentById?.insuranceCompanyNotes}
						</span>
					</h3>

					<IsImportantFormItem form={form} />
					<PatientFormItem form={form} />
					<InsuranceCompanyNameFormItem form={form} />
					<MkbsFormItem form={form} />
					<DetailsFormItem
						form={form}
						openModal={openModal}
						closeModal={closeModal}
					/>
					<AttachmentsFormItem
						form={form}
						openModal={openModal}
						closeModal={closeModal}
					/>
					<MedicalNotesFormItem form={form} />
					<IsActiveFormItem form={form} />

					<Form.Item name='id'>
						<div style={{ display: 'none' }}>
							<PrintElectronicDocument ref={componentRef} />
						</div>
						<Button
							type='primary'
							className='btn-groupe'
							shape='round'
							style={{
								float: 'left',
								margin: 0,
								display:
									!electronicDocumentById ||
									electronicDocumentById?.isActive ===
										false ||
									electronicDocumentById?.status ===
										Constants.MedicalReferralStatus
											.Performed
										? 'none'
										: 'block',
								backgroundColor: '#234254',
								borderRadius: 20,
							}}
							onClick={handlePrint}>
							<PrinterOutlined /> Принтирай
						</Button>

						<Button
							onMouseEnter={(e) =>
								form.setFieldsValue({
									patientName: form
										.getFieldValue('patientName')
										.trim(),
								})
							}
							disabled={
								electronicDocumentById?.isActive === false ||
								rootStore.electronicDocumentStore
									.detailsEditMode === true ||
								rootStore.electronicDocumentStore
									.isFormTouched === false
							}
							type='primary'
							shape='round'
							className='btn-primary submit-referral-btn'
							htmlType='submit'
							style={{
								display:
									electronicDocumentById?.isActive ===
										false ||
									(electronicDocumentById &&
										electronicDocumentById?.documentType.toString() ===
											Constants.DocumentType
												.PriorApproval &&
										electronicDocumentById?.documentStatus !==
											Constants.DocumentProcessStatus
												.Registered)
										? 'none'
										: 'block',
							}}>
							{electronicDocumentById
								? 'Запази промените'
								: 'Създай искане за предварително одобрение'}
						</Button>

						<Button
							type='primary'
							shape='round'
							className='btn-primary submit-referral-btn'
							onClick={() =>
								getBackInOpenModeAction(
									electronicDocumentById.id,
									form.getFieldValue('medicalNotes')
								)
							}
							style={{
								display:
									electronicDocumentById?.documentStatus !==
									Constants.DocumentProcessStatus
										.OpenForModification
										? 'none'
										: 'block',
							}}>
							{' '}
							Върни статуса на ИПО на отворен за застрахователя
						</Button>
					</Form.Item>
				</Form>
				<span
					hidden={
						!electronicDocumentById ||
						electronicDocumentById.documentStatus ===
							Constants.DocumentProcessStatus.Registered ||
						!rootStore.messangerStore.messages ||
						rootStore.messangerStore.messages.length === 0
					}>
					<Messanger />
				</span>
			</Spin>
		</Fragment>
	);
};
export default observer(PriorApprovalModal);
