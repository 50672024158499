import { observer } from 'mobx-react-lite';
import React, { Fragment, useContext, useEffect } from 'react';
import {
	addResponseMessage,
	renderCustomComponent,
	toggleWidget,
	Widget,
} from 'react-chat-widget';
import { RootStoreContext } from '../../stores/rootStore';
import { IMessage } from '../../models/messages/Message';
import CurrentDateChat from '../CustomComponents/CurrentDateChat';
import constants from '../../constants/constants';

const Messanger: React.FC = () => {
	const rootStore = useContext(RootStoreContext);

	const { electronicDocumentById } = rootStore.electronicDocumentStore;
	const {
		addMessage,
		markAsRead,
		addToTemporaryMessageQueue,
	} = rootStore.messangerStore;
	const {
		addToOtherNotificationForUnreadMessage,
		removeSelfNotificationForUnreadMessages,
	} = rootStore.notificationStore;

	useEffect(() => {
		rootStore.messangerStore.messangerHubConnection?.on(
			'ReceiveMessage',
			(res) => {
				if (
					res !== undefined &&
					res.isInsuranceCompanyMessage === true &&
					rootStore.messangerStore.temporaryMessageQueue!.findIndex(
						(el) => el === res.id
					) === -1
				) {
					addToTemporaryMessageQueue(res.id);
					addResponseMessage(res.content);
					renderCustomComponent(CurrentDateChat, {
						date: res.createdOn,
						className: 'rcw-response',
					});
				}
			}
		);
	});

	const handleNewUserMessage = async (messageContent: any) => {
		let message: IMessage = {
			content: messageContent,
			createdOn: new Date(),
			isInsuranceCompanyMessage: false,
			isRead: false,
			electronicDocumentId: rootStore.electronicDocumentStore
				.electronicDocumentById!.id,
		};

		await addMessage(message);

		await addToOtherNotificationForUnreadMessage(
			rootStore.electronicDocumentStore.electronicDocumentById!
				.insuranceCompanyUserId,
			rootStore.electronicDocumentStore.electronicDocumentById!.id
		);

		renderCustomComponent(CurrentDateChat, {
			date: message.createdOn,
			className: 'rcw-client',
		});
	};

	setTimeout(async () => {
		let messangerRef = document.getElementsByClassName(
			'rcw-close-button'
		)[0];
		if (
			messangerRef === undefined &&
			rootStore.messangerStore.messages !== undefined &&
			rootStore.messangerStore.messages.findIndex(
				(el) => el.isInsuranceCompanyMessage && !el.isRead
			) > -1
		) {
			toggleWidget();

			await markAsRead(
				false,
				rootStore.electronicDocumentStore.electronicDocumentById!.id
			);
			await removeSelfNotificationForUnreadMessages(
				parseInt(rootStore.identityStore.currentUser!.userId),
				rootStore.electronicDocumentStore.electronicDocumentById!.id
			);
		} else if (
			messangerRef !== undefined &&
			rootStore.messangerStore.messages !== undefined &&
			rootStore.messangerStore.messages.findIndex(
				(el) => el.isInsuranceCompanyMessage && !el.isRead
			) === -1
		) {
			toggleWidget();
		}
	}, 500);

	return (
		<Fragment>
			<div
				className={
					electronicDocumentById?.documentStatus ===
						constants.DocumentProcessStatus.Approved ||
					electronicDocumentById?.documentStatus ===
						constants.DocumentProcessStatus.Rejected
						? 'disabled-chat'
						: ''
				}>
				<Widget
					handleNewUserMessage={handleNewUserMessage}
					title={'Комуникация на живо'}
					subtitle={`З.К. ${electronicDocumentById?.insuranceCompanyName}`}
					autofocus={true}
					sendButtonAlt={'Изпращане'}
					senderPlaceHolder={'Въведете съобщение...'}
					showTimeStamp={false}
				/>
			</div>
		</Fragment>
	);
};

export default observer(Messanger);
