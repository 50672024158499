import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Table } from 'antd';
import { EyeTwoTone } from '@ant-design/icons';
import { RootStoreContext } from '../../../../app/stores/rootStore';
import { v4 as uuidv4 } from 'uuid';

const InsiranceStatusResultTable: React.FC = () => {
	const [rowClassName, setRowClassName] = useState(
		'ant-table-row ant-table-row-level-0'
	);
	const [rowKey, setRowKey] = useState();
	const rootStore = useContext(RootStoreContext);

	const { insuranceProduct, loadingInitial } = rootStore.insuranceStatusStore;

	const columns = [
		{
			title: '',
			key: 'id',
			dataIndex: '',
			width: '1%',
			render: (_text: any, row: any, index: any) => (
				<div
					className='table-div-centered eye-icon'
					onClick={() => [
						setRowClassName('ant-table-row-selected'),
						setRowKey(row.key),
					]}>
					{row.insuranceServices ? (
						row.insuranceServices.length !== 0 ? (
							<EyeTwoTone twoToneColor='darkgreen' />
						) : (
							''
						)
					) : (
						''
					)}
				</div>
			),
		},
		{
			title: 'No',
			key: 'number',
			dataIndex: 'number',
			render: (_text: any, row: any, index: any) => (
				<div
					style={{ margin: '0px 5px' }}
					onClick={() => [
						setRowClassName('ant-table-row-selected'),
						setRowKey(row.key),
					]}>
					{row.number}
				</div>
			),
			width: '2%',
		},
		{
			title: 'Услуги',
			key: 'name',
			dataIndex: 'name',
			render: (_text: any, row: any, index: any) => (
				<div
					style={{ margin: '0px 5px' }}
					onClick={() => [
						setRowClassName('ant-table-row-selected'),
						setRowKey(row.key),
					]}>
					{row.name}
				</div>
			),
			width: '35%',
		},
		{
			title: 'Опция',
			key: 'serviceOption',
			dataIndex: 'serviceOption',
			render: (_text: any, row: any, index: any) => (
				<div
					style={{ margin: '0px 5px' }}
					className='table-div-centered'
					onClick={() => [
						setRowClassName('ant-table-row-selected'),
						setRowKey(row.key),
					]}>
					{row.level === 1 ? row.serviceOption.name : ''}
				</div>
			),
			width: '5%',
		},
		{
			title: 'Форма на предоставяне',
			dataIndex: 'grantForm',
			key: 'grantForm',
			render: (_text: any, row: any, index: any) => (
				<div
					style={{ margin: '0px 5px' }}
					className='table-div-centered'
					onClick={() => [
						setRowClassName('ant-table-row-selected'),
						setRowKey(row.key),
					]}>
					{row.serviceOption.grantForm === '0' ? (
						<span>Абонамент</span>
					) : row.serviceOption.grantForm === '1' ? (
						<span>Възстановяване на разходи</span>
					) : row.serviceOption.grantForm === '2' ? (
						<span>Абонамент и възстановяване на разходи</span>
					) : (
						<span></span>
					)}
				</div>
			),
			width: '15%',
		},
		{
			title: 'Процент самоучастие',
			dataIndex: 'grantValue',
			key: 'grantValue',
			render: (_text: any, row: any, index: any) => (
				<div
					style={{ margin: '0px 5px' }}
					className='table-div-centered'
					onClick={() => [
						setRowClassName('ant-table-row-selected'),
						setRowKey(row.key),
					]}>
					{row.serviceOption.grantValue !== 0.0 ? (
						<div>{row.serviceOption.grantValue}</div>
					) : (
						<span></span>
					)}
				</div>
			),
			width: '10%',
		},
		{
			title: 'Допълнителна информация',
			dataIndex: 'name',
			key: 'insuranceServiceFlags',
			render: (_text: any, row: any, index: any) => (
				<div
					style={{ margin: '0px 5px' }}
					onClick={() => [
						setRowClassName(' ant-table-row-selected'),
						setRowKey(row.key),
					]}>
					{row.serviceOption.insuranceServiceFlags.length >= 1 ? (
						<span>
							{row.serviceOption.insuranceServiceFlags.map(
								(i: any) => (
									<span key={uuidv4()}>
										<div
											className={
												row.serviceOption
													.insuranceServiceFlags
													.length > 1
													? 'table-info'
													: ''
											}>
											{i.name &&
											i.additionalInformation ? (
												<span>
													{i.name} :{' '}
													{i.additionalInformation}
												</span>
											) : i.additionalInformation ===
											  null ? (
												i.name
											) : (
												i.additionalInformation
											)}
										</div>
									</span>
								)
							)}
						</span>
					) : (
						''
					)}
				</div>
			),
			width: '35%',
		},
	];

	const data = insuranceProduct?.insuranceServices;

	return (
		<div>
			<Table
				className='insurance-result-table'
				loading={loadingInitial}
				columns={columns}
				dataSource={data}
				pagination={false}
				expandable={{
					childrenColumnName: 'insuranceServices',
					expandRowByClick: true,
					expandIconColumnIndex: -1,
				}}
				rowClassName={(_record, index) => {
					return _record.key === rowKey ? rowClassName : '';
				}}
			/>
		</div>
	);
};

export default observer(InsiranceStatusResultTable);
