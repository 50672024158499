import React, { useContext, Fragment } from 'react';
import { Row, Col } from 'antd';
import { observer } from 'mobx-react-lite';
import Moment from 'react-moment';
import { RootStoreContext } from '../../app/stores/rootStore';
import Constants from '../../app/constants/constants';
import { v4 as uuidv4 } from 'uuid';

const PrintInsuranceStatusTable: React.FC = () => {
	const rootStore = useContext(RootStoreContext);

	const {
		insuranceProduct,
		insuranceStatusCurrentHeader,
	} = rootStore.insuranceStatusStore;

	return (
		<div
			className='print-container'
			style={{ margin: '0', padding: '0', color: 'black' }}>
			<div style={{ fontSize: 16, fontWeight: 700, margin: 10 }}>
				<Row>
					<Col xs={8}>
						<div style={{ fontWeight: 'bold', fontSize: 20 }}>
							Общи данни
						</div>
						<div>
							Отговор по заявка {insuranceStatusCurrentHeader?.id}
							/{' '}
							<Moment format={Constants.Common.FullDate_Format}>
								{insuranceStatusCurrentHeader?.answerTime}
							</Moment>
						</div>
						<div>От: {insuranceStatusCurrentHeader?.userName}</div>
						<div>Към ЗК: {insuranceProduct?.companyName}</div>
					</Col>
					<Col xs={8}>
						<div style={{ fontWeight: 'bold', fontSize: 20 }}>
							Данни на пациент
						</div>
						<div>
							ЕГН: {insuranceStatusCurrentHeader?.patientUid}{' '}
						</div>
						<div>
							Карта номер:{' '}
							{insuranceStatusCurrentHeader?.patientCardNumber}{' '}
						</div>
					</Col>
					<Col xs={8}>
						<div style={{ fontWeight: 'bold', fontSize: 20 }}>
							Резултат
						</div>
						<div>Продукт: {insuranceProduct?.name}</div>
						<div>
							{' '}
							Период на валидност:{' '}
							{insuranceStatusCurrentHeader?.period}
						</div>
						<div>
							Статус:
							{insuranceStatusCurrentHeader?.status}
						</div>
					</Col>
				</Row>
				<br />
			</div>

			{insuranceProduct?.insuranceServices.map((item) => (
				<div className='page-break' key={uuidv4()}>
					<div style={{ marginLeft: 10 }}>
						<div style={{ fontSize: 15, fontWeight: 700 }}>
							{item.number}. {item.name}
						</div>
						<div style={{ marginLeft: 20 }}>
							<div
								style={{
									marginLeft: 20,
									maxWidth: 600,
								}}>
								Опция: {item.serviceOption.name}{' '}
							</div>
							<div
								style={{
									marginLeft: 20,
									maxWidth: 600,
									display: item.serviceOption.grantForm
										? 'block'
										: 'none',
								}}>
								Форма на предоставяне:{' '}
								{item.serviceOption.grantForm === '0'
									? 'Абонамент'
									: item.serviceOption.grantForm === '1'
									? 'Възстановяване на разходи. Процент самоучастие при възстановяване: ' +
									  '' +
									  item.serviceOption.grantValue +
									  '.00'
									: item.serviceOption.grantForm === '2'
									? ' Абонамент и възстановяване на разходи. Процент самоучастие при възстановяване :' +
									  '' +
									  item.serviceOption.grantValue +
									  '.00'
									: ' '}
							</div>
							{item.serviceOption.insuranceServiceFlags?.map(
								(i) => (
									<div
										style={{
											marginLeft: 20,
											maxWidth: 600,
										}}
										key={uuidv4()}>
										{i.name && i.additionalInformation ? (
											<span>
												{i.name} :{' '}
												{i.additionalInformation}
											</span>
										) : i.additionalInformation === null ? (
											i.name
										) : (
											i.additionalInformation
										)}
									</div>
								)
							)}
						</div>
					</div>
					<br />

					<table
						style={{
							marginLeft: 10,
							borderCollapse: 'collapse',
							color: 'black',
						}}>
						<tbody style={{ border: '1px solid black' }}>
							{item.insuranceServices.map((i) =>
								i.insuranceServices.length >= 1 ? (
									<Fragment>
										<tr
											key={uuidv4()}
											style={{
												border: '1px solid black',
											}}>
											<td
												style={{
													border: '1px solid black',
													width: 450,
													fontSize: 12,
												}}>
												<div>
													{i.number}. {i.name}
												</div>
											</td>
											<td
												style={{
													border: '1px solid black',
													width: 300,
												}}>
												{i.serviceOption.insuranceServiceFlags?.map(
													(i) => (
														<div
															className='bold-flags'
															key={uuidv4()}>
															{i.name &&
															i.additionalInformation ? (
																<span>
																	{i.name} :{' '}
																	{
																		i.additionalInformation
																	}
																</span>
															) : i.additionalInformation ===
															  null ? (
																i.name
															) : (
																i.additionalInformation
															)}
														</div>
													)
												)}
											</td>
										</tr>

										{i.insuranceServices.map((t) => (
											<tr
												key={uuidv4()}
												style={{
													border: '1px solid black',
												}}>
												<td
													style={{
														border:
															'1px solid black',
														width: 450,
													}}>
													<div
														style={{
															marginLeft: 30,
															fontSize: 11,
														}}>
														{' '}
														{t.number}. {t.name}{' '}
													</div>
												</td>
												<td
													style={{
														border:
															'1px solid black',
														width: 300,
													}}>
													{t.serviceOption.insuranceServiceFlags?.map(
														(i) => (
															<div
																className='bold-flags'
																key={uuidv4()}>
																{i.name &&
																i.additionalInformation ? (
																	<span>
																		{i.name}{' '}
																		:{' '}
																		{
																			i.additionalInformation
																		}
																	</span>
																) : i.additionalInformation ===
																  null ? (
																	i.name
																) : (
																	i.additionalInformation
																)}
															</div>
														)
													)}
												</td>
											</tr>
										))}
									</Fragment>
								) : (
									<tr
										style={{ border: '1px solid black' }}
										key={uuidv4()}>
										<td
											style={{
												border: '1px solid black',
												width: 450,
												fontSize: 12,
											}}>
											<div>
												{i.number}. {i.name}
											</div>
										</td>
										<td
											style={{
												border: '1px solid black',
												width: 300,
											}}>
											{i.serviceOption.insuranceServiceFlags?.map(
												(i) => (
													<div
														className='bold-flags'
														key={uuidv4()}>
														{i.name &&
														i.additionalInformation ? (
															<span>
																{i.name} :{' '}
																{
																	i.additionalInformation
																}
															</span>
														) : i.additionalInformation ===
														  null ? (
															i.name
														) : (
															i.additionalInformation
														)}
													</div>
												)
											)}
										</td>
									</tr>
								)
							)}
						</tbody>
					</table>
					<br />
				</div>
			))}
		</div>
	);
};

export default observer(PrintInsuranceStatusTable);
