import React, { Fragment, useState, useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import {
	Input,
	Table,
	Space,
	Button,
	Modal,
	DatePicker,
	Popover,
	notification,
	Popconfirm,
	Switch,
	Select,
	Form,
} from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { TablePaginationConfig } from 'antd/lib/table';
import {
	PlusOutlined,
	SyncOutlined,
	InfoCircleOutlined,
	DeleteOutlined,
} from '@ant-design/icons';
import Title from 'antd/lib/typography/Title';
import Moment from 'react-moment';
import { RootStoreContext } from '../../app/stores/rootStore';
import Constants from '../../app/constants/constants';
import moment from 'moment';
import XmlUploadModal from './XmlUploadModal';
import { IXmlImportResponse } from '../../app/models/xmlImport/XmlImportRequest';

const XmlUploadTable: React.FC<TablePaginationConfig> = ({ total }) => {
	const [form] = Form.useForm();
	const [show, setShow] = useState(false);
	const [rowClassName, setRowClassName] = useState(
		'ant-table-row ant-table-row-level-0'
	);
	const [filterTable, setFilterTable] = useState([]);
	const [rowKey, setRowKey] = useState();

	const [initialValuesVisible, setInitialValuesVisible] = useState(false);

	const [
		insuranceCompanyInitialValue,
		setInsuranceCompanyInitialValue,
	] = useState(undefined);
	const [
		examinationTypeInitialValue,
		setExaminationTypeInitialValue,
	] = useState(undefined);

	const initialValuesVidible = () => {
		setInitialValuesVisible(!initialValuesVisible);
		if (insuranceCompanyInitialValue !== undefined) {
			setInsuranceCompanyInitialValue(undefined);
		}
		if (examinationTypeInitialValue !== undefined) {
			setExaminationTypeInitialValue(undefined);
		}

		form.resetFields();
	};

	const rootStore = useContext(RootStoreContext);
	const { companies } = rootStore.operationsStore;
	const { loadingAllTable } = rootStore.loadersStore;
	const {
		allXmls,
		getAllXmls,
		removeAllXmls,
		period,
		setPeriod,
		xmlById,
		getXmlById,
		deleteXmlRequest,
	} = rootStore.xmlImportStore;

	const { Option } = Select;

	const examinations = [
		{ value: 1, label: 'Амбулаторна' },
		{ value: 2, label: 'МДД' },
	];

	const openModal = async (id: number | undefined) => {
		if (id && moment(new Date()).subtract(3, 'months').toDate() > period) {
			notification.warning({
				message: 'Не е възможна редакция на този запис!',
				className: 'success-messages',
				duration: 5,
			});
			return;
		}

		if (id) {
			await getXmlById(id);
		} else {
			getXmlById(undefined);
		}

		setShow(true);
	};

	const closeModal = async () => {
		setShow(false);
		await getAllXmls(period);
		getXmlById(undefined);
		setTimeout(() => {
			let newElement = document.querySelectorAll(
				`[data-row-key="${allXmls!.length + 1}"]`
			)[0];
			if (newElement !== undefined) {
				newElement.scrollIntoView();
			}
		}, 500);
	};

	const changeDatesRange = async (date: any) => {
		setPeriod(date.toDate());
		await getAllXmls(rootStore.xmlImportStore.period);
	};

	const deletRecord = async (id: number) => {
		await deleteXmlRequest(id);
		await getAllXmls(period);
	};

	useEffect(() => {
		if (!allXmls) {
			getAllXmls(period);
		}

		return () => {
			setPeriod(moment().local().subtract(1, 'months').toDate());
			removeAllXmls();
		};
	}, []);

	const data = allXmls;

	const compareByAlph = (a: any, b: any) => {
		if (a > b) {
			return -1;
		}
		if (a < b) {
			return 1;
		}
		return 0;
	};

	const search = (value: any) => {
		if (value.length >= 1) {
			const tableFilterResult = data!.filter((o: any) =>
				Object.keys(o).some((k) =>
					String(o[k]).toLowerCase().includes(value.toLowerCase())
				)
			);
			setFilterTable(tableFilterResult);
		} else if (value.length === 0) {
			setFilterTable([] as IXmlImportResponse[]);
		}
	};

	const contentUnsuccessful = (row: IXmlImportResponse) => {
		return (
			<div style={{ maxWidth: '850px' }}>
				<h3>Валидационен резултат</h3>
				<hr />
				<div>
					<strong>Валидна схема: </strong>
					{row.xmlImportValidationResult.isValidBySchema
						? 'да'
						: 'не'}
				</div>
				<div>
					<strong>Съобщение: </strong>
					{row.xmlImportValidationResult.schemaValidationMessage}
				</div>
				<div>
					<strong>Липсващи съответствия: </strong>
					{row.xmlImportValidationResult.missingCorrelationsCodes !==
						null &&
					row.xmlImportValidationResult.missingCorrelationsCodes
						.length > 0
						? row.xmlImportValidationResult.missingCorrelationsCodes
						: 'няма'}
				</div>
				<div>
					<strong>Дейности с нулева цена: </strong>
					{row.xmlImportValidationResult.zeroPriceProcedures !==
						null &&
					row.xmlImportValidationResult.zeroPriceProcedures.length > 0
						? row.xmlImportValidationResult.zeroPriceProcedures
						: 'няма'}
				</div>
			</div>
		);
	};

	const contentSuccessful = (row: IXmlImportResponse) => {
		return (
			<div style={{ maxWidth: '850px' }}>
				<h3>Валидационен резултат</h3>
				<hr />
				{row.xmlImportValidationResult.schemaValidationMessage?.length >
				0 ? (
					<div>
						<strong>Съобщение: </strong>
						{row.xmlImportValidationResult.schemaValidationMessage}
					</div>
				) : (
					<div></div>
				)}
				{row.xmlImportValidationResult.invalidMkb?.length > 0 ? (
					<div>
						<strong>Валидация на МКБ: </strong>
						{row.xmlImportValidationResult.invalidMkb}
					</div>
				) : (
					<div></div>
				)}
				{row.xmlImportValidationResult.zeroPriceProcedures?.length >
				0 ? (
					<div>
						<strong>Дейности с нулева цена: </strong>
						{row.xmlImportValidationResult.zeroPriceProcedures}
					</div>
				) : (
					<div></div>
				)}
			</div>
		);
	};

	const contentInfo = (row: any) => (
		<div>
			Последна редакция на дата:{' '}
			<Moment format={Constants.Common.FullDate_Format}>
				{row.dateUpdated}
			</Moment>
			, от потребител: {row.lastModifiedByUsername}
		</div>
	);

	const columns = [
		{
			align: 'center' as 'center',
			title: 'Дата ',
			dataIndex: 'dateCreated',
			width: '7%',
			render: (text: any, row: any, index: any) => (
				<Moment format={Constants.Common.FullDate_Format}>
					{text}
				</Moment>
			),
		},
		{
			title: 'За период',
			width: '8%',
			align: 'center' as 'center',
			render: (_text: any, row: any, index: any) => (
				<div>
					<Moment format={Constants.Common.ShortDate_Format}>
						{row.dateFrom}
					</Moment>{' '}
					{' - '}{' '}
					<Moment format={Constants.Common.ShortDate_Format}>
						{row.dateTo}
					</Moment>
				</div>
			),
		},
		{
			title: 'Застрахователна компания',
			dataIndex: 'insuranceCompanyName',
			key: 'insuranceCompanyName',
			width: '17%',
			align: 'center' as 'center',
			sorter: (a: any, b: any) => {
				return compareByAlph(
					a.insuranceCompanyName,
					b.insuranceCompanyName
				);
			},
		},
		{
			title: 'УИН',
			dataIndex: 'doctorUin',
			key: 'doctorUin',
			width: '7%',
			align: 'center' as 'center',
			sorter: (a: any, b: any) => {
				return compareByAlph(a.doctorUin, b.doctorUin);
			},
		},
		{
			title: 'Спец.',
			dataIndex: 'doctorSpecialityCode',
			key: 'doctorSpecialityCode',
			width: '4%',
			align: 'center' as 'center',
		},
		{
			title: 'Лекар',
			dataIndex: 'doctorName',
			key: 'doctorName',
			width: '18%',
			align: 'center' as 'center',
			sorter: (a: any, b: any) => {
				return compareByAlph(a.doctorName, b.doctorName);
			},
		},
		{
			title: 'Тип дейност',
			dataIndex: 'examinationType',
			key: 'examinationType',
			width: '8%',
			align: 'center' as 'center',
			sorter: (a: any, b: any) => {
				return compareByAlph(a.examinationType, b.examinationType);
			},
			render: (text: any, row: any, index: any) => (
				<div>{row.examinationType === 1 ? 'Амбулаторна' : 'МДД'}</div>
			),
		},

		{
			title: 'Статус',
			dataIndex: 'status',
			key: 'status',
			width: '9%',
			align: 'center' as 'center',
			sorter: (a: any, b: any) => {
				return compareByAlph(a.status, b.status);
			},
			render: (text: any, row: any, index: any) => (
				<div
					style={{
						color:
							row.status === 'Неуспешен'
								? 'red'
								: row.status === 'Успешен'
								? 'green'
								: 'inherit',
					}}>
					{row.status !== 'Неуспешен' ? (
						row.xmlImportValidationResult?.schemaValidationMessage
							?.length > 0 ||
						row.xmlImportValidationResult?.invalidMkb?.length > 0 ||
						row.xmlImportValidationResult?.zeroPriceProcedures
							?.length > 0 ? (
							<div>
								{row.status}{' '}
								<Popover
									content={contentSuccessful(row)}
									trigger='hover'
									placement='topRight'
									style={{ fontSize: 18 }}>
									<InfoCircleOutlined />
								</Popover>{' '}
							</div>
						) : (
							row.status
						)
					) : (
						<div>
							{row.status}{' '}
							<Popover
								content={contentUnsuccessful(row)}
								trigger='hover'
								placement='topRight'
								style={{ fontSize: 18 }}>
								<InfoCircleOutlined />
							</Popover>{' '}
						</div>
					)}
				</div>
			),
		},
		{
			title: '',
			dataIndex: 'numberOfCreatedDocuments',
			key: 'numberOfCreatedDocuments',
			width: '4%',
			align: 'center' as 'center',
		},
		{
			title: 'Сума',
			dataIndex: 'totalSum',
			key: 'totalSum',
			width: '6%',
			align: 'right' as 'right',
			sorter: (a: any, b: any) => {
				return compareByAlph(
					parseFloat(a.totalSum),
					parseFloat(b.totalSum)
				);
			},
		},
		{
			width: '2%',
			align: 'center' as 'center',
			render: (_text: any, row: any, index: any) => (
				<Popconfirm
					title='Сигурни ли сте, че искате да изтриете записа? Всички документи, качени автоматично от него също ще бъдат изтрити! '
					onConfirm={() => deletRecord(row.id)}>
					<DeleteOutlined style={{ color: 'red' }} />
				</Popconfirm>
			),
		},
		{
			width: '2%',
			align: 'center' as 'center',
			render: (_text: any, row: any, index: any) => (
				<Popover
					content={contentInfo(row)}
					trigger='hover'
					placement='topRight'
					style={{ fontSize: 18 }}>
					<InfoCircleOutlined />
				</Popover>
			),
		},
	];

	return (
		<Fragment>
			<div className='home-grid-col-3' style={{ padding: 10 }}>
				<Title level={3}>Качени XML</Title>
				<Space className='space-table-head'>
					<Button
						className='btn-groupe'
						style={{
							background:
								'radial-gradient(circle, rgba(30,134,17,0.908000700280112) 28%, rgba(27,180,32,0.9248074229691877) 100%)',
						}}
						type='primary'
						icon={<PlusOutlined />}
						disabled={
							moment(new Date()).subtract(3, 'months').toDate() >
							period
						}
						onClick={async () => await openModal(undefined!)}>
						Създаване
					</Button>
					<Button
						className='btn-groupe'
						style={{
							background: '#234254',
						}}
						type='primary'
						icon={<SyncOutlined />}
						onClick={() => getAllXmls(period)}>
						Опресняване
					</Button>
				</Space>
				<Space className='space-table-head'>
					<DatePicker
						style={{ width: 160 }}
						onChange={(date) => changeDatesRange(date)}
						className='date-range-tables'
						picker='month'
						format={'MMMM - YYYY'}
						allowClear={false}
						defaultValue={moment().local().subtract(1, 'months')}
						disabledDate={(current) =>
							current > moment().local().endOf('month')
						}
					/>
					<Input.Search
						className='search-input'
						placeholder='Търсене...'
						enterButton
						onSearch={search}
					/>
					<Switch
						defaultChecked={initialValuesVisible}
						unCheckedChildren={'Настройки по подразбиране'}
						onChange={() => initialValuesVidible()}></Switch>
					<div
						style={{
							display: initialValuesVisible
								? 'inline-block'
								: 'none',
						}}>
						<Form
							layout='horizontal'
							form={form}
							style={{ display: 'inline-block' }}>
							<Form.Item
								name='insuranceCompanyInitialValue'
								style={{
									display: 'inline-block',
									marginRight: 10,
									marginBottom: '0px !important',
								}}>
								<Select
									allowClear={true}
									onClear={() =>
										setInsuranceCompanyInitialValue(
											undefined
										)
									}
									showSearch
									onSelect={(value) =>
										setInsuranceCompanyInitialValue(value)
									}
									style={{}}
									defaultValue={insuranceCompanyInitialValue}
									optionFilterProp='title'
									placeholder='Застрахователна компания'>
									{companies?.map((company) => (
										<Option
											key={uuidv4()}
											value={company.id}
											title={company.name}>
											{company.name}
										</Option>
									))}
								</Select>
							</Form.Item>
							<Form.Item
								name='examinationTypeInitialValue'
								style={{ display: 'inline-block' }}>
								{' '}
								<Select
									showSearch
									allowClear={true}
									onClear={() =>
										setExaminationTypeInitialValue(
											undefined
										)
									}
									optionFilterProp='title'
									placeholder='Тип на дейността'
									onSelect={(value) =>
										setExaminationTypeInitialValue(value)
									}
									defaultValue={examinationTypeInitialValue}>
									{examinations?.map((examination) => (
										<Option
											key={uuidv4()}
											value={examination.value}
											title={examination.label}>
											{examination.label}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Form>
					</div>
				</Space>
				<div className='scroll-table-service-correlations'>
					<Table
						onRow={(record, index) => {
							return {
								onClick: () => {
									setRowClassName(
										'ant-table-row ant-table-row-level-0 ant-table-row-selected'
									);
									setRowKey(record.id);
								},
								onDoubleClick: async () => {
									await openModal(record.id);
								},
							};
						}}
						scroll={{ y: 'calc(100vh - 253px)' }}
						loading={loadingAllTable}
						columns={columns}
						dataSource={filterTable.length < 1 ? data : filterTable}
						locale={{ emptyText: 'Няма налични данни' }}
						sortDirections={['descend', 'ascend']}
						showSorterTooltip={false}
						pagination={false}
						rowClassName={(_record, index) => {
							return _record.id === rowKey ? rowClassName : '';
						}}
						rowKey={'id'}
						footer={(records: IXmlImportResponse[]) => (
							<div
								style={{
									display: 'inline-flex',
									paddingRight: '4%',
								}}>
								Брой резултати: {records?.length}{' '}
								<div style={{ margin: '0px 0px 0px 26px' }}>
									Сума:{' '}
									{records !== undefined &&
									records.length !== 0
										? records
												?.map((a) =>
													parseFloat(
														a.totalSum ?? '0'
													)
												)
												.reduce((a, b) => a + b)
												.toFixed(2)
										: 0.0}{' '}
								</div>
							</div>
						)}
					/>
				</div>
				<Modal
					confirmLoading={rootStore.loadersStore.loadingModal}
					width='40%'
					title={!xmlById ? 'Качване на XML' : 'Редактиране на XML'}
					centered
					onCancel={async () => await closeModal()}
					visible={show}
					footer={false}
					destroyOnClose={true}
					maskClosable={false}
					transitionName='none'
					maskTransitionName='none'>
					<XmlUploadModal
						closeModal={closeModal}
						insuranceCompanyInitialValue={
							insuranceCompanyInitialValue
						}
						examinationTypeInitialValue={
							examinationTypeInitialValue
						}
						rowKey={rowKey}
						setRowKey={setRowKey}
					/>
				</Modal>
			</div>
		</Fragment>
	);
};

export default observer(XmlUploadTable);
