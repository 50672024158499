import React from 'react';
import { observer } from 'mobx-react-lite';
import PrepareClaimsTable from './PrepareClaimsTable';

const PrepareClaims: React.FC = () => {
	return (
		<div>
			<PrepareClaimsTable />
		</div>
	);
};
export default observer(PrepareClaims);
