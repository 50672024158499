import { observer } from 'mobx-react-lite';
import React from 'react';
import AmbulatorySheetNewGridTable from './AmbulatorySheetNewGridTable';

const AmbulatorySheetsNewGrid: React.FC = () => {
	return (
		<div>
			<AmbulatorySheetNewGridTable />
		</div>
	);
};
export default observer(AmbulatorySheetsNewGrid);
