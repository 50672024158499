import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import Moment from 'react-moment';
import { IElectronicDocument } from '../../app/models/electronicDocument/ElectronicDocument';
import Constants from '../../app/constants/constants';
import {
	Popover,
	Button,
	Input,
	Modal,
	Space,
	Popconfirm,
	Spin,
	Empty,
	Radio,
} from 'antd';
import Title from 'antd/lib/typography/Title';
import {
	InfoCircleOutlined,
	PlusOutlined,
	SyncOutlined,
	CloseOutlined,
} from '@ant-design/icons';
import { RootStoreContext } from '../../app/stores/rootStore';
import ReactDataGrid from 'react-data-grid';
import { DateRangePicker } from 'rsuite';
import PreventionsModal from './PreventionsModal';

const PreventionsTable: React.FC = () => {
	const rootStore = useContext(RootStoreContext);
	const {
		electronicDocumentById,
		getElectronicDocumentById,
		setDetailsEditMode,
		setUploadEditMode,
		setDetailsOriginDataFromElectronicDocument,
		setAttachmentsOriginDataFromElectronicDocument,
		setDetailsOriginData,
		setAttachmentsOriginData,
		setCurrentSelectedElectronicDocument,
		setIsFormTouched,
		isFormTouched,
		setIsUploadFormTouched,
		automaticallySearchForElectronicDocumentsForAbulatorySheetsAndMdd,
		setAttachmentTableVisible,
		setTypeOfDocument,
	} = rootStore.electronicDocumentStore;

	const { getPatientByEgn, getDoctorByUin } = rootStore.operationsStore;

	const { currentUser } = rootStore.identityStore;
	const { loadingAllTable } = rootStore.loadersStore;

	const {
		allPreventions,
		getPreventionsByPeriod,
		period,
		setPeriod,
		sortPreventions,
		removeAllPreventions,
		setFilterType,
	} = rootStore.preventionsStore;

	const [show, setShow] = useState(false);
	const [filterTable, setFilterTable] = useState([]);

	useEffect(() => {
		if (!allPreventions) {
			getPreventionsByPeriod(period);
		}
		return () => {
			removeAllPreventions();
			setFilterType('active');
		};
	}, []);
	const openModal = async (id: number | undefined) => {
		if (id) {
			await getElectronicDocumentById(id);

			if (
				rootStore.electronicDocumentStore.electronicDocumentById
					?.attachments &&
				rootStore.electronicDocumentStore.electronicDocumentById!
					.attachments?.length >= 1
			) {
				setUploadEditMode(true);
				setAttachmentTableVisible(true);
			} else {
				setUploadEditMode(false);
				setAttachmentTableVisible(false);
			}
		} else {
			setDetailsEditMode(true);
			setUploadEditMode(false);
			setAttachmentTableVisible(false);
			setDetailsOriginDataFromElectronicDocument(undefined);
			setAttachmentsOriginDataFromElectronicDocument(undefined);
			setDetailsOriginData(undefined);
			setAttachmentsOriginData(undefined);
			await getElectronicDocumentById(undefined);
		}
		setTypeOfDocument(Constants.DocumentType.Preventions);

		setShow(true);
	};

	const closeModal = async () => {
		setShow(false);
		if (isFormTouched || rootStore.electronicDocumentStore.getElDocsAgain) {
			getPreventionsByPeriod(period);
		}
		setCurrentSelectedElectronicDocument(undefined);
		setDetailsOriginDataFromElectronicDocument(undefined);
		setAttachmentsOriginDataFromElectronicDocument(undefined);
		setDetailsOriginData(undefined);
		setAttachmentsOriginData(undefined);
		setDetailsEditMode(false);
		setIsFormTouched(false);
		setIsUploadFormTouched(false);
		rootStore.laboratoryResultsStore.setIsLongListIsDisable(true);
		await getPatientByEgn(undefined);
		setTypeOfDocument(undefined);
		await automaticallySearchForElectronicDocumentsForAbulatorySheetsAndMdd(
			undefined
		);
		if (!currentUser.uinCode || currentUser.uinCode.length === 0) {
			await getDoctorByUin(undefined);
		}
	};

	const contentInfo = (row: any) => (
		<div>
			<div>
				<strong>Последна редакция:</strong>{' '}
				<Moment format={Constants.Common.FullDate_Format}>
					{row.lastModifiedOn}
				</Moment>
			</div>
			<div>
				<strong>Потребител:</strong> {row.lastModifiedByUsername}
			</div>
			<div>
				<strong>Прикачен към иск:</strong>{' '}
				{row.isAttachedToClaim ? 'Да' : 'Не'}
			</div>
			<div>
				<strong>Статус:</strong> {row.claimProcessStatus}
			</div>
			<div>{row.toPrimaryInfo}</div>
		</div>
	);

	const changeDatesRange = (dates: Date[]) => {
		setPeriod(dates[0], dates[1]);
		setFilterTable([] as IElectronicDocument[]);
		getPreventionsByPeriod(rootStore.preventionsStore.period);
	};

	const search = (value: any) => {
		if (value.length >= 1) {
			const tableFilterResult = data!.filter((o: any) =>
				Object.keys(o).some((k) =>
					String(o[k]).toLowerCase().includes(value.toLowerCase())
				)
			);
			setFilterTable(tableFilterResult);
		} else if (value.length === 0) {
			setFilterTable([]);
		}
	};

	const sortRows = (initialRows, sortColumn, sortDirection) => {
		const comparer = (a, b) => {
			if (sortDirection === 'ASC') {
				return a[sortColumn] > b[sortColumn] ? 1 : -1;
			} else if (sortDirection === 'DESC') {
				return a[sortColumn] < b[sortColumn] ? 1 : -1;
			}
		};
		sortPreventions(comparer, sortDirection);
	};

	const markSelectedRow = (rowIndex: number, row: any) => {
		if (row !== undefined) {
			var allSelected = document.querySelectorAll(
				'.react-grid-Cell-selected'
			);
			allSelected.forEach((el) =>
				el.classList.remove('react-grid-Cell-selected')
			);

			let targetRowToSelect = document.querySelector(
				'[value="' + row.id + '"]'
			).parentElement;
			let childrenArray = Array.from(
				targetRowToSelect.children as HTMLCollectionOf<HTMLElement>
			);

			childrenArray.forEach((element) => {
				element.classList.add('react-grid-Cell-selected');
			});
		}
	};

	const columns = [
		{
			name: '№',
			key: 'documentNumber',
			width: 85,
			sortable: true,
			resizable: true,
			formatter: (row: any) => (
				<div
					className='table-div-centered'
					onDoubleClick={async () => [await openModal(row.row.id)]}>
					{row.row.documentNumber}
				</div>
			),
		},
		{
			name: 'Дата',
			key: 'preventionDateFrom',
			width: 150,
			sortable: true,
			resizable: true,
			formatter: (row: any) => (
				<div
					className='table-div-centered'
					onDoubleClick={async () => [await openModal(row.row.id)]}>
					<Moment format={Constants.Common.ShortDate_Format}>
						{row.row.preventionDateFrom}
					</Moment>{' '}
					-{' '}
					<Moment format={Constants.Common.ShortDate_Format}>
						{row.row.preventionDateTo}
					</Moment>
				</div>
			),
		},
		{
			name: 'Застр. компания',
			key: 'insuranceCompanyName',
			sortable: true,
			resizable: true,
			formatter: (row: any) => (
				<div
					className='table-div-centered'
					onDoubleClick={async () => [await openModal(row.row.id)]}>
					{row.row.insuranceCompanyName}
				</div>
			),
		},
		{
			name: 'ЕГН',
			key: 'patientUid',
			width: 100,
			sortable: true,
			resizable: true,
			formatter: (row: any) => (
				<div
					className='table-div-centered'
					onDoubleClick={async () => [await openModal(row.row.id)]}>
					{row.row.patientUid}
				</div>
			),
		},
		{
			name: 'Име на пациент',
			key: 'patientName',
			sortable: true,
			resizable: true,
			formatter: (row: any) => (
				<div
					className='table-div-centered'
					onDoubleClick={async () => [await openModal(row.row.id)]}>
					{row.row.patientName}
				</div>
			),
		},
		{
			key: 'isActive',
			name: 'Акт.',
			width: 75,
			sortable: true,
			formatter: (row: any) => (
				<div
					className={'table-div-centered'}
					onDoubleClick={async () => [await openModal(row.row.id)]}>
					<span
						style={{
							color:
								row.row.isActive === false
									? 'red'
									: row.row.status ===
									  Constants.MedicalReferralStatus.Performed
									? 'auto'
									: 'green',
						}}>
						{row.row.isActive === true ? 'Дa' : 'Нe'}
					</span>
				</div>
			),
		},
		{
			name: 'Сума',
			key: 'totalAmount',
			sortable: true,
			resizable: true,
			width: 60,
			formatter: (row: any) => (
				<div
					style={{ textAlignLast: 'right' }}
					onDoubleClick={async () => [await openModal(row.row.id)]}>
					{row.row.totalAmount}
				</div>
			),
		},
		{
			key: 'isForEditing',
			name: 'Иск',
			width: 60,
			sortable: true,
			formatter: (row: any) => (
				<div
					className={'table-div-centered'}
					style={{
						color: row.row.isForEditing
							? 'red'
							: row.row.isAttachedToClaim
							? 'green'
							: 'red',
					}}
					onDoubleClick={async () => [await openModal(row.row.id)]}>
					{row.row.isForEditing
						? 'За кор.'
						: row.row.isAttachedToClaim
						? 'Да'
						: 'Не'}
				</div>
			),
		},
		{
			key: 'id',
			name: '',
			width: 30,
			formatter: (row: any) => (
				<div className='table-div-centered'>
					<Popover
						content={contentInfo(row.row)}
						trigger='hover'
						placement='topRight'
						style={{ fontSize: 18 }}>
						<InfoCircleOutlined />
					</Popover>
				</div>
			),
		},
	];
	const data = allPreventions ? allPreventions : [];
	const title =
		electronicDocumentById?.isActive === false ||
		electronicDocumentById?.claimProcessStatus ===
			Constants.ClaimProcessStatus.New ||
		electronicDocumentById?.claimProcessStatus ===
			Constants.ClaimProcessStatus.Processed ||
		electronicDocumentById?.claimProcessStatus ===
			Constants.ClaimProcessStatus.Processing ||
		(electronicDocumentById?.claimProcessStatus ===
			Constants.ClaimProcessStatus.ChangeRequested &&
			electronicDocumentById?.isForEditing === false) ? (
			<div>
				Документ по профилактика №{' '}
				{electronicDocumentById?.documentNumber} /{' '}
				<Moment format={Constants.Common.ShortDate_Format}>
					{electronicDocumentById?.preventionDateFrom}
				</Moment>{' '}
				-{' '}
				<Moment format={Constants.Common.ShortDate_Format}>
					{electronicDocumentById?.preventionDateTo}
				</Moment>{' '}
				до ЗК " {electronicDocumentById?.insuranceCompanyName} "
			</div>
		) : (
			<div>
				Редактиране на документ по профилактика №{' '}
				{electronicDocumentById?.documentNumber} /{' '}
				<Moment format={Constants.Common.ShortDate_Format}>
					{electronicDocumentById?.preventionDateFrom}
				</Moment>{' '}
				-{' '}
				<Moment format={Constants.Common.ShortDate_Format}>
					{electronicDocumentById?.preventionDateTo}
				</Moment>{' '}
				до ЗК " {electronicDocumentById?.insuranceCompanyName} "
			</div>
		);
	return (
		<div className='home-grid-col-3'>
			<Title level={3}>Профилактична дейност</Title>
			<Space className='space-table-head'>
				<Button
					className='btn-groupe'
					style={{
						background:
							'radial-gradient(circle, rgba(30,134,17,0.908000700280112) 28%, rgba(27,180,32,0.9248074229691877) 100%)',
					}}
					type='primary'
					icon={<PlusOutlined />}
					onClick={async () => await openModal(undefined!)}>
					Създаване
				</Button>

				<Button
					className='btn-groupe'
					style={{
						background: '#234254',
					}}
					type='primary'
					icon={<SyncOutlined />}
					onClick={() => getPreventionsByPeriod(period)}>
					Опресняване
				</Button>
			</Space>
			<Space className='space-table-head'>
				<Radio.Group
					defaultValue='active'
					buttonStyle='solid'
					size='small'
					onChange={async (event) => {
						setFilterType(event.target.value);
						await getPreventionsByPeriod(period);
					}}>
					<Radio.Button value='active'>Активни</Radio.Button>
					<Radio.Button value='all'>Всички</Radio.Button>
					<Radio.Button value='editable'>За корекция</Radio.Button>
				</Radio.Group>
				<DateRangePicker
					placeholder={['дд.мм.гггг', 'дд.мм.гггг']}
					cleanable={false}
					className='date-range-tables'
					format={Constants.Common.ShortDate_Format}
					value={[
						rootStore.preventionsStore.period.dateFrom,
						rootStore.preventionsStore.period.dateTo,
					]}
					onOk={(date: Date[]) => changeDatesRange(date)}
					locale={Constants.ReactSuiteCalendarLocale}
					size='xs'
					color='green'
					ranges={[]}
					isoWeek={true}
				/>

				<Input.Search
					className='search-input'
					placeholder='Търсене...'
					enterButton
					onSearch={search}
				/>
			</Space>
			<Spin size='large' spinning={loadingAllTable}>
				<div
					className='scroll-table-service-correlations labRes-table-grid'
					style={{
						maxHeight: 'calc(100vh - 210px)',
					}}>
					<ReactDataGrid
						rowHeight={35}
						columns={columns}
						rowGetter={(i) =>
							filterTable.length < 1 ? data[i] : filterTable[i]
						}
						rowsCount={
							filterTable.length < 1
								? data.length
								: filterTable.length
						}
						onRowClick={(rowIndex: number, row: any) =>
							markSelectedRow(rowIndex, row)
						}
						emptyRowsView={() => <Empty />}
						onGridSort={(sortColumn, sortDirection) => {
							return sortRows(
								filterTable.length < 1 ? data : filterTable,
								sortColumn,
								sortDirection
							);
						}}
					/>
				</div>
			</Spin>
			<div
				style={{
					display: 'inline-flex',
					paddingRight: '54px',
					fontSize: 16,
					color: ' black',
					width: '100%',
					justifyContent: ' flex-end',
				}}>
				Брой резултати:{' '}
				{filterTable === undefined ||
				(filterTable !== undefined && filterTable!.length === 0)
					? data?.length
					: filterTable!.length}{' '}
				<div style={{ margin: '0px 0px 0px 26px' }}>
					Сума:{' '}
					{(filterTable === undefined ||
						(filterTable !== undefined &&
							filterTable!.length === 0)) &&
					data !== undefined &&
					data.length !== 0
						? data
								?.map((a) => parseFloat(a.totalAmount ?? '0'))
								.reduce((a, b) => a + b)
								.toFixed(2)
						: filterTable.length >= 1
						? filterTable
								?.map((a) => parseFloat(a.totalAmount ?? '0'))
								.reduce((a, b) => a + b)
								.toFixed(2)
						: 0.0}{' '}
				</div>
			</div>
			<Modal
				key={
					electronicDocumentById?.id
						? electronicDocumentById!.id
						: undefined
				}
				className='appointment-modal prior-approval-modal'
				title={
					electronicDocumentById
						? title
						: 'Създаване на документ по профилактика'
				}
				centered
				maskClosable={false}
				transitionName='none'
				maskTransitionName='none'
				closeIcon={
					isFormTouched ? (
						<Popconfirm
							title='Сигурни ли сте, че искате да затворите прозореца. Вашите промени няма да бъдат запазени!'
							onConfirm={async () => await closeModal()}>
							<CloseOutlined />
						</Popconfirm>
					) : (
						<CloseOutlined
							onClick={async () => await closeModal()}
						/>
					)
				}
				visible={show}
				footer={false}
				destroyOnClose={true}>
				<PreventionsModal
					openModal={openModal}
					closeModal={closeModal}
				/>
			</Modal>
		</div>
	);
};

export default observer(PreventionsTable);
