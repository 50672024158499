import React, { Fragment, useState, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { Form, Input, Button, Modal, Spin } from 'antd';
import Moment from 'react-moment';
import ViewMedicalReferralModal from './ViewMedicalReferralModal';
import PerformerFormItem from '../../../app/layout/FormItems/PerformerFormItem';
import { RootStoreContext } from '../../../app/stores/rootStore';
import Constants from '../../../app/constants/constants';

const CheckForReferralForm = () => {
	const [form] = Form.useForm();
	const rootStore = useContext(RootStoreContext);

	const {
		setAllPerformedMedicalReferrals,
		performedPeriod,
	} = rootStore.medicalReferralStore;

	const { loadingCheckForm } = rootStore.loadersStore;

	const {
		electronicDocumentById,
		setCurrentSelectedElectronicDocument,
	} = rootStore.electronicDocumentStore;

	const [show, setShow] = useState(false);

	const { currentUser } = rootStore.identityStore;

	const openModal = async (id: number | undefined) => {
		setShow(true);
	};

	const closeModal = async () => {
		setShow(false);
		setCurrentSelectedElectronicDocument(undefined);
		await setAllPerformedMedicalReferrals(performedPeriod);
	};

	const onFormSubmit = async (value: any) => {
		await rootStore.medicalReferralStore.checkMedicalReferralForPerform(
			value
		);
		if (rootStore.electronicDocumentStore.electronicDocumentById) {
			await openModal(
				rootStore.electronicDocumentStore.electronicDocumentById?.id
			);
		}
		if (
			currentUser?.uinCode ||
			rootStore.medicalReferralStore.checkMedicalReferralResponseMessage
		) {
			form.setFieldsValue({
				performerKey: '',
				patientUid: '',
			});
		} else {
			form.setFieldsValue({
				performerUin: '',
				performerName: '',
				performerSpecialityCode: '',
				performerKey: '',
				patientUid: '',
			});
		}
	};

	const title = (
		<div>
			Mедицинско назначение № {electronicDocumentById?.documentNumber} от{' '}
			<Moment format={Constants.Common.FullDate_Format}>
				{electronicDocumentById?.createdOn}
			</Moment>{' '}
		</div>
	);

	return (
		<Fragment>
			<Spin tip='Зареждане' spinning={loadingCheckForm}>
				<Form
					className='check-for-refferal-form'
					layout='vertical'
					form={form}
					onFinish={(value: any) => onFormSubmit(value)}
					initialValues={{ performerUin: currentUser?.uinCode }}>
					<PerformerFormItem form={form} />

					<Form.Item
						label='Данни за пациента: '
						rules={[
							{
								required: true,
							},
						]}>
						<Form.Item
							className='egn-input'
							name='performerKey'
							rules={[
								{
									required: true,
									message:
										Constants.FormMessages.RequiredField,
								},
							]}>
							<Input placeholder='Ключ на медицинско назначение' />
						</Form.Item>
						<Form.Item
							name='patientUid'
							className='egn-input'
							rules={[
								{
									required: true,
									message:
										Constants.FormMessages.RequiredField,
								},
								({ getFieldValue }) => ({
									validator(rule, value) {
										const expression = new RegExp(
											'^[0-9]*$'
										);
										if (expression.test(value)) {
											return Promise.resolve();
										}
										return Promise.reject(
											'EГН-то трябва да е 10 цифри!'
										);
									},
								}),
								{
									min: 10,
									message: ' EГН-то трябва да е 10 цифри! ',
								},
								{
									max: 10,
									message: ' EГН-то трябва да е 10 цифри! ',
								},
							]}>
							<Input placeholder='ЕГН на пациент' type='text' />
						</Form.Item>
						<Form.Item className='egn-input'>
							<Button
								className='btn-primary'
								style={{ margin: '0px' }}
								type='primary'
								htmlType='submit'>
								Провери назначение
							</Button>
						</Form.Item>
					</Form.Item>
				</Form>
			</Spin>
			<Modal
				className='appointment-modal'
				title={title}
				centered
				maskClosable={false}
				transitionName='none'
				maskTransitionName='none'
				onCancel={() => closeModal()}
				visible={show}
				footer={false}
				destroyOnClose={true}>
				{' '}
				<ViewMedicalReferralModal
					openModal={openModal}
					closeModal={closeModal}
				/>
			</Modal>
		</Fragment>
	);
};
export default observer(CheckForReferralForm);
